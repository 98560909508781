import { types } from "mobx-state-tree";
import {defaultNotificationBar} from "./Root";

function evaluateBoolean(value: any) {
    return Boolean(value) && value !== "false";
}

export type INavigationBarFlag = boolean | string | undefined;

export interface INavigationBarDetailsFlag {
    barText?: string | undefined | null;
    date?: string | undefined | null;
    timeText?: string | undefined | null;
    time?: string | undefined | null;
    modalTitle?: string | undefined | null;
    modalText?: string | undefined | null;
    buttonText?: string | undefined | null;
    buttonUrl?: string | undefined | null;
    button2Text?: string | undefined | null;
    button2Url?: string | undefined | null;
    active?: boolean | string | undefined | null;
    green?: boolean | string | undefined | null;
    enabled?: boolean | string | undefined | null;
    clientLocalStorageDisableKey?: string | undefined | null;
}

export const NotificationBar = types
    .model({
        isOpen: types.boolean,
        barText: types.string,
        date: types.string,
        timeText: types.string,
        time: types.string,
        buttonText: types.string,
        modalTitle: types.string,
        modalText: types.string,
        buttonUrl: types.string,
        button2Text: types.string,
        button2Url: types.string,
        active: types.boolean,
        enabled: types.boolean,
        green: types.boolean,
        isBackdropOpen: types.boolean
    })
    .actions((self) => ({
        setIsOpen(isOpen: boolean) {
            if (!isOpen && !self.active) {
                self.isOpen = isOpen;
            }
        },
        initialize() {
            Object.keys(defaultNotificationBar).forEach((key) => {
                // @ts-ignore
                self[key] = defaultNotificationBar[key];
            });
        }
    }))
    .actions((self) => {
        let details: INavigationBarDetailsFlag | null = null;
        let on = false;
        let hideNotificationBar = false;
        return {
            update(
                notificationBarDetails: INavigationBarDetailsFlag | null = null,
                notificationBar: INavigationBarFlag | null = null,
                hideNotificationBarSessionValue = false
            ) {
                if (notificationBarDetails) {
                    details = notificationBarDetails;
                    on = evaluateBoolean(notificationBar);
                    hideNotificationBar = hideNotificationBarSessionValue;
                }

                if (details?.clientLocalStorageDisableKey && localStorage.getItem(details?.clientLocalStorageDisableKey ?? "") === "disable") {
                    self.initialize();
                    return;
                }
                const notificationBarOpen =
                    evaluateBoolean(details?.enabled) &&
                    on &&
                    (evaluateBoolean(notificationBarDetails?.active) || !hideNotificationBar);

                self.barText = details?.barText ?? "";
                self.date = details?.date ?? "";
                self.timeText = details?.timeText ?? "";
                self.time = details?.time ?? "";
                self.buttonText = details?.buttonText ?? "";
                self.modalTitle = details?.modalTitle ?? "";
                self.modalText = details?.modalText ?? "";
                self.buttonUrl = details?.buttonUrl ?? "";
                self.button2Text = details?.button2Text ?? "";
                self.button2Url = details?.button2Url ?? "";
                self.active = evaluateBoolean(details?.active);
                self.enabled = evaluateBoolean(details?.enabled);
                self.green = evaluateBoolean(details?.green);
                self.isOpen = notificationBarOpen;
                self.isBackdropOpen = self.isOpen && self.active;
            }
        };
    });
