import _ from "lodash";
import { IdentificationType } from "../models/enums/IdentificationType";
import { IIndividualModel } from "../models/IndividualModel";
import { emptyValidation, regexValidationBuilder, requiredValidationBuilder } from "./validationFunctions";
import { ValidationSeverity } from "../models/enums/ValidationSeverity";
import { getDateTimeAEST } from "../helpers/dateHelpers";

export const driversLicenceRequiredMapping: { [state: string]: boolean } = {
  "NSW": getDateTimeAEST() >= new Date(2022, 8, 1),
  "ACT": getDateTimeAEST() >= new Date(2022, 8, 1),
  "VIC": getDateTimeAEST() >= new Date(2022, 8, 1),
  "SA": getDateTimeAEST() >= new Date(2022, 8, 1),
  "QLD": getDateTimeAEST() >= new Date(2022, 10, 1),
  "WA": getDateTimeAEST() >= new Date(2022, 8, 1),
  "NT": getDateTimeAEST() >= new Date(2022, 8, 1),
  "TAS": getDateTimeAEST() >= new Date(2022, 8, 1)
};

export const passportNumberValidationBuilder = (title: string) => regexValidationBuilder(title, /^[XBCEGATHJLMNPxbcegathjlmnp]{1,2}(\d{7})$/);

export const nswLicenceCardNumberValidationBuilder = (title: string) => regexValidationBuilder(title, /^\d{10}$/);
export const actLicenceCardNumberValidationBuilder = (title: string) => regexValidationBuilder(title, /^([a-zA-Z0-9_-]){10}$/);
export const saAndTsaLicenceCardNumberValidationBuilder = (title: string) => regexValidationBuilder(title, /^([a-zA-Z0-9_-]){9}$/);
export const vicLicenceCardNumberValidationBuilder = (title: string) => regexValidationBuilder(title, /^([a-zA-Z0-9]){8}$/);
export const waLicenceCardNumberValidationBuilder = (title: string) => regexValidationBuilder(title, /^([a-zA-Z0-9]){8,10}$/);

export const driversLicenseAsteriskValidationBuilder = (title: string) => regexValidationBuilder(title, /[*]{5,9}$/);
export const licenseCardAsteriskValidationBuilder = (title: string) => regexValidationBuilder(title, /[*]{8,10}$/);

export const driversLicenseValidationBuilder = (title: string) => regexValidationBuilder(title, /^[a-zA-Z0-9]{5,9}$/);

export const idNumberValidation = (title: string) => (value: string, model: IIndividualModel) => {
  if (value.includes("*")) {
    return driversLicenseAsteriskValidationBuilder(title)(value);
  }
  if (value !== "" && model.identificationType.value === IdentificationType.Passport) {
    return passportNumberValidationBuilder(title)(value);
  }
  if (value !== "" && model.identificationType.value === IdentificationType.DriversLicence) {
    return driversLicenseValidationBuilder(title)(value);
  }
  return requiredValidationBuilder(ValidationSeverity.NoSave, title)(value?.trim());
};

export const driversLicenceCardNumberValidation = (title: string) => (value: string, model: IIndividualModel) => {
  if(model.identificationType.value === IdentificationType.Passport) return emptyValidation();

  if (value.includes("*")) {
    return licenseCardAsteriskValidationBuilder(title)(value);
  }
  if (!_.isNil(model.idState) && driversLicenceRequiredMapping[model.idState.value]) {
    if (model.idState.value === "NSW") {
      return nswLicenceCardNumberValidationBuilder(title)(value);
    }
    if (model.idState.value === "ACT") {
      return actLicenceCardNumberValidationBuilder(title)(value);
    }
    if (model.idState.value === "SA" || (value !== "" && model.idState.value === "TAS")) {
      return saAndTsaLicenceCardNumberValidationBuilder(title)(value);
    }
    if (model.idState.value === "WA") {
      return waLicenceCardNumberValidationBuilder(title)(value);
    } 
    // DXPS-3393 - made Drivers licence card number mandatory for VIC
    if (model.idState.value === "VIC") {
      return vicLicenceCardNumberValidationBuilder(title)(value);
    }
    return requiredValidationBuilder(ValidationSeverity.NoSave, title)(value);
  } 

  return emptyValidation();
};

export const idStateValidation = (title: string) => (value: string, model: IIndividualModel) => {
  if (model.identificationType.value === IdentificationType.DriversLicence) {
    return requiredValidationBuilder(ValidationSeverity.NoSave, title)(value);
  }
  return emptyValidation();
};