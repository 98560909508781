import React from "react";
import { observer } from "mobx-react-lite";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

const SecureRoutes = () => {
  const { hasToken, isAuthenticated } = useAuth();

  const location = useLocation();

  return (
    <>
      {isAuthenticated
        ? (<Outlet />)
        : (<Navigate to={hasToken ? "/requestCode" : "/expired"}  replace state={{from: location}}/>)
      }
    </>
  )
}

export default observer(SecureRoutes);