import React from "react";
import {Grid, GridProps, Typography} from "@mui/material";
import {observer} from "mobx-react-lite";
import DisplayValue from "./DisplayValueComponent";
import Line from "../common/Line";
import {css} from "@emotion/css";
import {useMst} from "../../models/Root";
import {useLocation, useNavigate} from "react-router-dom";
import {getAccountUrl, getBranchesUrl} from "../../helpers/urlHelpers";
import {ApplicationSource} from "../../models/AccountModel";
import EditLinkButton from "../common/EditLinkButton";

interface AccountDetailsReviewProps {
  canEdit: boolean;
}


const AccountDetailsReview = (props: AccountDetailsReviewProps) => {
  const ROW_GRID_OPTIONS: GridProps = {xs: 12, md: 12};
  const headingCss = {
    heading: css`
      font-size: 20px !important;
      font-weight: 700;
      padding-top: 16px;
      padding-bottom: 16px;
    `,
  };

  const containerCss = css`
    padding-bottom: 40px;
  `;

  const { customer, anzsicCodes } = useMst();
  const navigate = useNavigate();
  const location = useLocation();

  const onEditClick = () => {
    if(customer.source === ApplicationSource.Digital) {
      navigate(getBranchesUrl(true), {state: {from: location }});
    } else {
      navigate(getAccountUrl(true), {state: {from: location }});
    }
  }

  return <>
    {
      <>
        <Grid item {...ROW_GRID_OPTIONS}>
          <Line/>
        </Grid>
        <Grid container direction="row" alignContent={"flex-start"} justifyContent={"space-between"}>
          <Grid item className="text">
            <Typography variant="h3" color="textSecondary" className={headingCss.heading}>
              Account Details
            </Typography>
          </Grid>
          <Grid item>
            {props.canEdit && (
              <EditLinkButton id="editButton" showEndIcon={true} onClick={() => onEditClick()}>{"Edit"}</EditLinkButton>
            )}
          </Grid>
        </Grid>
        <Grid container className={containerCss}>
          <DisplayValue label={"Branch"} value={customer.branchName}/>
          <DisplayValue label={"Industry"} value={anzsicCodes.find(customer.account.anzsicCode.value).description}/>
          <DisplayValue label={"Business Activities"} value={customer.account.getActivityDescriptions()}/>
          {customer.account?.isLivestock.value && (
            <DisplayValue label={"Transit Insurance"} value={customer.account.addTransitInsurance.getFormattedValue()}/>
          )}
        </Grid>
      </>
    }
  </>
}

export default observer(AccountDetailsReview);