import { IAuthApi } from "./IAuthApi";
import { callAPI } from "./apiUtilities";
import { apiConfig } from "../config/api.config";
import {ILoginResponse, ISendVerificationCodeResponse, TokenDto} from "../dto/ISendVerificationCodeResponse";

export default class AuthApi implements IAuthApi {
  verifyToken(token: string): Promise<TokenDto> {
    return callAPI<TokenDto>(`${apiConfig.fmsCustomerPathUrl}/token?token=${token}`, {
      method: "GET"
    });
  }

  sendVerificationCode(token: string): Promise<ISendVerificationCodeResponse> {
    return callAPI<ISendVerificationCodeResponse>(`${apiConfig.fmsCustomerPathUrl}/verificationCode`, {
      method: "POST",
      body: JSON.stringify({token: token})
    });
  }

  login(token: string, code: string): Promise<ILoginResponse> {
    return callAPI<ILoginResponse>(`${apiConfig.fmsCustomerPathUrl}/login`, {
      method: "POST",
      body: JSON.stringify({token: token, verificationCode: code})
    });
  }
}
