import * as _ from "lodash";
import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from "react";
import {Grid, GridProps} from "@mui/material";
import ApplicationContainer from "./common/ApplicationContainer";
import {useMst} from "../models/Root";
import {H3} from "./common/Text";
import Line from "./common/Line";
import ActionBar from "./common/ActionBar";
import NextButton from "./common/NextButton";
import TextFieldWrapper from "./editors/TextFieldWrapper";
import {useNavigate} from "react-router-dom";
import SelectFieldWrapper from "./editors/SelectFieldWrapper";
import {toSelectFieldOptions} from "../helpers/selectFieldHelpers";
import {AllIdentificationTypes} from "../models/enums/IdentificationType";
import CheckBoxFieldWrapper from "./editors/CheckBoxFieldWrapper";
import {formatPhoneNumber} from "./editors/formatPhoneNumber";
import AddressLookupComponent from "./common/AddressLookupComponent";
import DatePickerWrapper from "./editors/DatePickerWrapper";
import AutoCompleteWrapper from "./common/AutoCompleteWrapper";
import {toJS} from "mobx";
import {formatPoBox} from "../helpers/stringHelpers";
import ActionButton from "./common/ActionButton";
import {getAccountUrl, getEntityMembersUrl, isEditFrom} from "../helpers/urlHelpers";
import CancelButton from "./common/CancelButton";
import {ProgressStep} from "./ProgressBar/ProgressBarComponent";
import {BorrowingEntityType} from "../models/enums/BorrowingEntityType";
import DriversLicenceNumberEditor from "./editors/DriversLicenceNumberEditor";
import OwnershipPercentageEditor from "./editors/OwnershipPercentageEditor";
import {MemberType} from "../models/enums/MemberType";
import {currencyFormatter} from "../helpers/currencyFormatter";
import {ApplicationSource} from "../models/AccountModel";
import DirectorValidationDialog from "./EntityMembers/DirectorValidationDialog";
import apiRoot from "../helpers/apiRoot";
import NoAccessComponent from "./NoAccessComponent";
import {AddressType} from "../models/enums/AddressType";

const EntityDetails = () => {
  const ROW_GRID_OPTIONS: GridProps = { xs: 12, md: 12 };
  const ITEM_GRID_OPTIONS: GridProps = { xs: 12, md: 3 };
  const TRADING_NAME_GRID_OPTIONS: GridProps = { xs: 12, md: 4 };
  const FULL_ROW_GRID_OPTIONS: GridProps = { xs: 12, md: 12 };

  const { customer, states, anzsicCodes, borrowingEntitySearch } = useMst();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showDirectorValidationDialog, setShowDirectorValidationDialog] = useState<boolean>(false);

  const load = async () => {
    await states.load();
    await anzsicCodes.load();
    await customer.load(sessionStorage.getItem("authorization") ?? "");

    if(customer.source === ApplicationSource.Digital && customer.borrowingEntity?.type === BorrowingEntityType.Company) {
      await borrowingEntitySearch.pullIndividuals(customer.borrowingEntity.id);
    }
  }

  useEffect(() => {
    setIsLoading(true);

    load()
      .then(() => {
        customer.individual.isResidentialAddressSameAsBusinessAddress.update(!customer.individual.residentialAddress.isAddressDifferentFrom(customer.borrowingEntity.businessAddress));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [customer]);  // eslint-disable-line react-hooks/exhaustive-deps

  const HeadingEntry = (heading: string) => {
    return (
      <Grid item {...ROW_GRID_OPTIONS}>
        <H3>{heading}</H3>
      </Grid>
    );
  }

  const LineEntry = () => {
    return (
      <Grid item {...ROW_GRID_OPTIONS}>
        <Line />
      </Grid>
    )
  }

  const CancelButtonClicked = () => {
    customer.borrowingEntity.cancelChanges();
    customer.individual.cancelChanges();
    navigate(-1);
  }

  const isLeadApplicantADirector = (): boolean => {
    const directorIds = borrowingEntitySearch.individuals ? _.map(borrowingEntitySearch.individuals, x => x.id) : [];
    return _.some(directorIds, x => x === customer.individual.id);
  }

  const adjustLeadApplicantToDirectorIndividualId = async (directorIndividualId: number) => {
    try {
      setIsSaving(true);
      await apiRoot.customerApi.updateDirectorLeadApplicant(customer.individual.id, directorIndividualId);
      await customer.reload();
      navigate(getEntityMembersUrl());
    } catch (error) {
      apiRoot.informationalMessageHelper.addErrorMessage(error);
    } finally {
      setIsSaving(false);
    }
  }

  const LeadApplicantSave = async () => {
    try {
      setIsSaving(true);
      if(await customer.leadApplicantSave()) {
        if(customer.source === ApplicationSource.Digital && customer.borrowingEntity.type === BorrowingEntityType.Company && !isLeadApplicantADirector()) {
          setShowDirectorValidationDialog(true);
        } else {
          if(isEditFrom()) {
            navigate(-1);
            return;
          }

          if(customer.individual.isLeadApplicant() && customer.borrowingEntity.type === BorrowingEntityType.Trust) {
            navigate("/trustDetails");
            return;
          }

          if(customer.borrowingEntity.canAddMembers()) {
            navigate(getEntityMembersUrl());
          } else {
            if(customer.source === ApplicationSource.Digital && customer.account.isCropActivityOnly()) {
              navigate("/review");
            } else {
              navigate(getAccountUrl());
            }
          }
        }
      }
    } finally {
      setIsSaving(false);
    }
  }

  const CreateNextButton = () => {
    if(isEditFrom()) {
      return (
        <ActionButton onClick={async () => await LeadApplicantSave()} disabled={isSaving}>Save</ActionButton>
      );
    }
    if(customer.individual.isLeadApplicant() && customer.borrowingEntity.type === BorrowingEntityType.Trust) {
      return (<NextButton onClick={async () => await LeadApplicantSave()} disabled={isSaving}>Next: Trust Details</NextButton>);
    }
    return (<NextButton onClick={async () => await LeadApplicantSave()} disabled={isSaving}>Next: {customer.borrowingEntity.canAddMembers() ? "Members Details" : "Account Details" }</NextButton>);
  }

  const updateResidentialAddress = () => {
    if(customer.individual.isResidentialAddressSameAsBusinessAddress.value) {
      customer.individual.residentialAddress.copyFrom(customer.borrowingEntity.businessAddress);
    } else {
      customer.individual.residentialAddress.initialize(AddressType.Residential);
    }
  }

  function onIdTypeChanged() {
    customer.individual.idState.update("");
    customer.individual.driversCardNumber.update("");
  }

  const disableNameAndDobFields = customer.individual?.memberType === MemberType.Director;

  function clearMailingAddress() {
    customer.borrowingEntity.mailingAddress.initialize(AddressType.Mailing);
  }

  return (
    <>
      <ApplicationContainer isLoading={isLoading} isSaving={isSaving} currentStep={ProgressStep.BusinessDetailStep}>
        {customer.isLoaded && customer.individual.isCoApplicant() && (
          <NoAccessComponent/>
        )}
        {customer.individual.isLeadApplicant() && (
          <>
        <Grid container direction={"row"} spacing={1}>
          {HeadingEntry("Business Details")}
          {customer.borrowingEntity.displayAbn() && (
            <TextFieldWrapper fieldModel={customer.borrowingEntity.abn} disabled gridOptions={ITEM_GRID_OPTIONS} />
          )}
          {customer.borrowingEntity.displayAcn() && (
            <>
              <TextFieldWrapper fieldModel={customer.borrowingEntity.acn} disabled gridOptions={ITEM_GRID_OPTIONS} />
            </>
          )}
          <TextFieldWrapper fieldModel={customer.borrowingEntity.legalName} disabled gridOptions={ITEM_GRID_OPTIONS} />
          <TextFieldWrapper fieldModel={customer.borrowingEntity.tradingName} gridOptions={TRADING_NAME_GRID_OPTIONS} placeholder={"Optional - add if different from organisation name"} />
          {customer.source === ApplicationSource.Digital && (
            <>
              <SelectFieldWrapper options={anzsicCodes.selectFieldOptions()} fieldModel={customer.account.anzsicCode} gridOptions={ITEM_GRID_OPTIONS} />
              <TextFieldWrapper fieldModel={customer.account.monthlySpend} formatter={currencyFormatter} gridOptions={ITEM_GRID_OPTIONS} />
            </>
          )}
          <LineEntry />

          {HeadingEntry("Business Address")}
          <AddressLookupComponent
            controlId={"businessAddress"}
            fieldModel={customer.borrowingEntity.businessAddress.streetAddress}
            addressSelected={customer.borrowingEntity.businessAddress.addressSelected}
            gridOptions={ITEM_GRID_OPTIONS}
          />
          <AutoCompleteWrapper fieldModel={customer.borrowingEntity.businessAddress.city} list={toJS(customer.borrowingEntity.businessAddress.cityPostcodes.cities)} gridOptions={ITEM_GRID_OPTIONS} />
          <SelectFieldWrapper fieldModel={customer.borrowingEntity.businessAddress.state} options={states.selectFieldOptions()} gridOptions={ITEM_GRID_OPTIONS} />
          <AutoCompleteWrapper fieldModel={customer.borrowingEntity.businessAddress.postCode} list={toJS(customer.borrowingEntity.businessAddress.cityPostcodes.postcodes)} gridOptions={ITEM_GRID_OPTIONS} />

          <LineEntry />

          {HeadingEntry("Mailing Address")}
          <Grid item {...FULL_ROW_GRID_OPTIONS}>
            <Grid container direction={"row"} justifyContent={"flex-start"} spacing={1}>
              <Grid item>
                <CheckBoxFieldWrapper fieldModel={customer.borrowingEntity.isMailingAddressSameAsBusinessAddress} action={() => clearMailingAddress()} />
              </Grid>
              {!customer.borrowingEntity.isMailingAddressSameAsBusinessAddress.value && (
                <Grid item>
                  <CheckBoxFieldWrapper fieldModel={customer.borrowingEntity.mailingAddress.isPOBox} />
                </Grid>
              )}
            </Grid>
          </Grid>

          {!customer.borrowingEntity.isMailingAddressSameAsBusinessAddress.value && (
            <>
              {customer.borrowingEntity.mailingAddress.isPOBox.value && (
                <TextFieldWrapper fieldModel={customer.borrowingEntity.mailingAddress.poBox} gridOptions={ITEM_GRID_OPTIONS} formatter={formatPoBox} />
              )}
              {!customer.borrowingEntity.mailingAddress.isPOBox.value && (
                <AddressLookupComponent
                  controlId={"mailingAddress"}
                  fieldModel={customer.borrowingEntity.mailingAddress.streetAddress}
                  addressSelected={customer.borrowingEntity.mailingAddress.addressSelected}
                  gridOptions={ITEM_GRID_OPTIONS}
                />
              )}
              <AutoCompleteWrapper fieldModel={customer.borrowingEntity.mailingAddress.city} list={toJS(customer.borrowingEntity.mailingAddress.cityPostcodes.cities)} gridOptions={ITEM_GRID_OPTIONS} />
              <SelectFieldWrapper fieldModel={customer.borrowingEntity.mailingAddress.state} options={states.selectFieldOptions()} gridOptions={ITEM_GRID_OPTIONS} />
              <AutoCompleteWrapper fieldModel={customer.borrowingEntity.mailingAddress.postCode} list={toJS(customer.borrowingEntity.mailingAddress.cityPostcodes.postcodes)} gridOptions={ITEM_GRID_OPTIONS} />
            </>
          )}

          <LineEntry />

          {HeadingEntry("My Details")}
          <TextFieldWrapper fieldModel={customer.individual.firstName} gridOptions={ITEM_GRID_OPTIONS} disabled={disableNameAndDobFields}/>
          <TextFieldWrapper fieldModel={customer.individual.middleName} gridOptions={ITEM_GRID_OPTIONS} disabled={disableNameAndDobFields}/>
          <TextFieldWrapper fieldModel={customer.individual.lastName} gridOptions={ITEM_GRID_OPTIONS} disabled={disableNameAndDobFields}/>
          <DatePickerWrapper fieldModel={customer.individual.dateOfBirth} gridOptions={ITEM_GRID_OPTIONS}  disabled={disableNameAndDobFields}/>
          <TextFieldWrapper fieldModel={customer.individual.email} gridOptions={ITEM_GRID_OPTIONS} disabled={true} tooltip={{
            toolTipContent: "This email address will be used as the primary contact for this application and future correspondence on the new account (e.g. statements). This can be changed at a later stage by contacted your branch."
          }}/>
          <TextFieldWrapper fieldModel={customer.individual.primaryPhone} formatter={formatPhoneNumber} gridOptions={ITEM_GRID_OPTIONS} />
          <OwnershipPercentageEditor individual={customer.individual} gridOptions={ITEM_GRID_OPTIONS} currentUserIsLeadApplicant={customer.individual.isLeadApplicant()} />

          <LineEntry />

          {HeadingEntry("Residential Address")}
          <CheckBoxFieldWrapper fieldModel={customer.individual.isResidentialAddressSameAsBusinessAddress}
                                action={() => updateResidentialAddress()}
          />
          {!customer.individual.isResidentialAddressSameAsBusinessAddress.value && (
            <>
              <AddressLookupComponent
                  controlId={"residentialAddress"}
                  fieldModel={customer.individual.residentialAddress.streetAddress}
                  addressSelected={customer.individual.residentialAddress.addressSelected}
                  gridOptions={ITEM_GRID_OPTIONS}
              />
              <AutoCompleteWrapper fieldModel={customer.individual.residentialAddress.city} list={toJS(customer.individual.residentialAddress.cities)} gridOptions={ITEM_GRID_OPTIONS} />
              <SelectFieldWrapper fieldModel={customer.individual.residentialAddress.state} options={states.selectFieldOptions()} gridOptions={ITEM_GRID_OPTIONS} />
              <AutoCompleteWrapper fieldModel={customer.individual.residentialAddress.postCode} list={toJS(customer.individual.residentialAddress.cityPostcodes.postcodes)} gridOptions={ITEM_GRID_OPTIONS} />
            </>
          )}
          <LineEntry />

          {HeadingEntry("Identification Details")}
          <SelectFieldWrapper fieldModel={customer.individual.identificationType} options={toSelectFieldOptions(AllIdentificationTypes)} gridOptions={ITEM_GRID_OPTIONS} afterChangeAction={onIdTypeChanged} />
          {customer.individual.showIdStateOrDriversCardNumber() && (
            <SelectFieldWrapper fieldModel={customer.individual.idState} options={states.selectFieldOptions()} gridOptions={ITEM_GRID_OPTIONS} />
          )}
          <TextFieldWrapper fieldModel={customer.individual.identificationNumber} label={customer.individual.identificationNumberLabel()} gridOptions={ITEM_GRID_OPTIONS} />
          <DriversLicenceNumberEditor individual={customer.individual} gridOptions={ITEM_GRID_OPTIONS} />

        </Grid>
          </>
        )}
      </ApplicationContainer>
      {customer.individual.isLeadApplicant() && (
      <ActionBar
        isLoading={isLoading}
        leftComponents={isEditFrom() ? <CancelButton onClick={() => CancelButtonClicked()} /> : undefined}
        rightComponents={CreateNextButton()}
      />
      )}

      <DirectorValidationDialog
        isOpen={showDirectorValidationDialog}
        onClose={() => setShowDirectorValidationDialog(false)}
        directorSelected={async (directorIndividualId) => adjustLeadApplicantToDirectorIndividualId(directorIndividualId)}
        isEntityDirectorCheck preventAutoRedirect />
    </>
  )
}

export default observer(EntityDetails);