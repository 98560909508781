export const AUTO_DISMISS_TIMEOUT = 5000;

export const NO_PRINT_CLASS_NAME = "axp-no-print";

export const DATETIME_LOCALE_STRING_FORMAT = "MM/DD/YYYY, h:mm:ss A";
export const UI_DATE_FORMAT = "DD/MM/YYYY";
export const UI_DATETIME_FORMAT = "DD/MM/YYYY - hh:mm A";
export const QUERY_PARAMETER_DATE_FORMAT = "YYYY-MM-DD";

export const MOBILE_BREAKPOINT = "960px";
export const SM_MEDIA_QUERY = "(max-width:959px)";

export const MAX_ATTACHMENT_FILE_SIZE = 20 * 1024 * 1024;
export const ALLOWED_ATTACHMENT_TYPES = ["DOC", "DOCX", "PDF", "XLS", "XLSM", "XLSX", "JPG", "JPEG", "TIF", "PNG", "BMP", "HTM", "HTML", "MSG", "TXT"];
export const INVALID_FILE_NAME = "The file name contains one of the following disallowed characters: # $ ' . Please correct the file name and try uploading it again."


export const ABN_LENGTH = 11;
export const ACN_LENGTH = 9;

export const GOOGLE_API_KEY = "AIzaSyAdpOdsnvcgapEoBlCozfFd5OiasmvYMFw";

export const ABN_SEARCH_POLLING_DELAY = 5000; // 5 seconds

// Designer wants some actions/backgrounds to be consistent regardless of theme colors
export const STANDARD_ACTION_COLOR = "#2AAB3F";
export const STANDARD_ACTION_LIGHT_COLOR = "#E1F5E4";

export const NOTIFICATION_HEIGHT_DESKTOP = 59;
export const NOTIFICATION_HEIGHT_MOBILE = 95;