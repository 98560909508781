import _ from "lodash";
import React from "react"
import { observer } from "mobx-react-lite";
import ApplicationBanner from "./ApplicationBanner";
import { css } from "@emotion/css";
import BrandLogo from "./BrandLogo";
import { MOBILE_BREAKPOINT } from "../../config/constants";
import ProgressBarComponent, {ProgressStep} from "../ProgressBar/ProgressBarComponent";
import ProgressIndicator from "./ProgressIndicator";
import {useMst} from "../../models/Root";

interface ApplicationContainerProps {
  isLoading?: boolean;
  isSaving?: boolean;
  children: React.ReactNode
  label?: string;
  currentStep?: ProgressStep;
}

const ApplicationContainer = (props: ApplicationContainerProps) => {
  const containerCss = {
    savingContainer: css`
      position: fixed;
      width: 100%;
      height: 100%;
      
      background: #e9e9e9;
      opacity: 0.5;      
      z-index: 2;
    `,
    container: css`
      padding: 40px 20px 60px 20px;
      min-width: ${MOBILE_BREAKPOINT};
      max-width: 1250px;
      margin: 0px auto;
      @media (max-width: ${MOBILE_BREAKPOINT}) {
        min-width: 0px;
        width: calc(100vw - 60px);
      }
    `,
  };

  const {brand} = useMst();

  return (
    <>
      {brand.isLoaded && (<>
        {props.isLoading && (
          <ProgressIndicator />
        )}

        {!props.isLoading && (
          <>
            {props.isSaving && (
              <>
                <div className={containerCss.savingContainer} />
                <ProgressIndicator />
              </>
            )}


            {_.isNil(props.currentStep) && (
              <BrandLogo/>
            )}
            {!_.isNil(props.currentStep) && (
              <ProgressBarComponent currentStep={props.currentStep} />
            )}


            <ApplicationBanner label={props.label}/>

            <div className={containerCss.container}>
              {props.children}
            </div>
          </>
        )}
      </>)}
    </>
  )
}

export default observer(ApplicationContainer)