import React, {useState} from "react";
import {Dialog, Tooltip, useMediaQuery, useTheme} from "@mui/material";
import {css} from "@emotion/css";
import {Icon} from "@nutrien/ddc-components";
import {SM_MEDIA_QUERY} from "../../config/constants";

export type TooltipPlacement =
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';

export interface FieldTooltipProps {
    toolTipContent?: any;
    toolTipMobileContent?: any; // not required, only if content should appear be different on mobile devices (like the driver's licence tooltips)
    toolTipPlacement?: TooltipPlacement; // defaults to top-end
    modalOnMobile?: boolean; // large tooltips can be slightly off screen when popped up on mobile, this will display them as a modal on mobile devices
}

const FieldTooltip = (props: FieldTooltipProps) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(SM_MEDIA_QUERY);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [showTooltip, setShowTooltip] = useState<boolean>(false)

    const isStringContent = typeof props.toolTipContent === "string";

    return <>
        {/* desktop or mobile when not using modal on mobile */}
        {props.toolTipContent && (!props.modalOnMobile || !isSmall) && (
            <Tooltip
                componentsProps={{tooltip: {sx: isStringContent ? {} : {maxWidth: "100vw", margin: 0, padding: "2px 2px 0px 2px"}}}}
                title={props.toolTipContent}
                placement={props.toolTipPlacement ?? "top-end"}
                open={showTooltip}
                onOpen={() => setShowTooltip(true)}
                onClose={() => setShowTooltip(false)}
                className={css`position:absolute; top:32px; right: 0; z-index: 1;`}>
                <div>
                    <Icon.InfoFeather fontSize="small" strokeWidth={1.5} stroke={theme.palette.info.dark} onClick={() => setShowTooltip(!showTooltip)}/>
                </div>
            </Tooltip>
        )}
        {/* mobile when using modal on mobile */}
        {props.toolTipContent && props.modalOnMobile && isSmall && (
            <div className={css`position:absolute; width:0; height: 0; top:32px; right: 20px; z-index: 1;`} onClick={() => setShowModal(true)}>
                <Icon.InfoFeather fontSize="small" strokeWidth={1.5} stroke={theme.palette.info.dark}/>
            </div>
        )}
        {/* dialog box for modal*/}
        {props.modalOnMobile && isSmall && (
            <Dialog PaperProps={{sx: {background: "none", boxShadow: "none"}}} componentsProps={{root: {className: css`background: transparent; box-shadow: none;`}}} open={showModal} onClose={() => setShowModal(false)}>
                {props.toolTipMobileContent ?? props.toolTipContent}
            </Dialog>
        )}
    </>
}

export default FieldTooltip;