import { ICustomerApi } from "../api/ICustomerApi";
import { ILookupApi } from "../api/ILookupApi";
import { IBranchApi } from "../api/IBranchApi";
import { IInformationalMessageHelper } from "./systemMessageHelpers";
import {IApplicationApi} from "../api/IApplicationApi";
import { IAuthApi } from "../api/IAuthApi";
import { IMembersApi } from "../api/IMembersApi";
import {IAttachmentApi} from "../api/IAttachmentApi";

class ApiRoot {
    informationalMessageHelper: IInformationalMessageHelper = {} as IInformationalMessageHelper;

    authApi: IAuthApi = {} as IAuthApi;
    customerApi: ICustomerApi = {} as ICustomerApi;
    lookupApi: ILookupApi = {} as ILookupApi;
    branchApi: IBranchApi = {} as IBranchApi;
    applicationApi: IApplicationApi = {} as IApplicationApi;
    membersApi: IMembersApi = {} as IMembersApi;
    attachmentApi: IAttachmentApi = {} as IAttachmentApi;
}

const apiRoot = new ApiRoot();
export default apiRoot;