import {callAPI} from "./apiUtilities";
import {apiConfig} from "../config/api.config";
import {ILookupApi} from "./ILookupApi";
import {CityPostcodeDto} from "../dto/CityPostcodeDto";
import {AnzsicCodeDto} from "../dto/AnzsicCodeDto";
import {BankBSBDto} from "../dto/BankBSBDto";
import {HobbyFarmConditionDto} from "../dto/HobbyFarmConditionDto";

export default class LookupApi implements ILookupApi {
  getStates(): Promise<string[]> {
    return callAPI(`${apiConfig.fmsCustomerPathUrl}/auth/lookup?lookupTerm=States`, {
      method: "GET"
    })
      .then((x: any) => x.states);
  }

  getCityPostcodes(city: string | undefined, state: string | undefined, postcode: string | undefined): Promise<CityPostcodeDto[]> {
    return callAPI<{ cityPostcodes: CityPostcodeDto[] }>(`${apiConfig.fmsCustomerPathUrl}/auth/lookup?lookupTerm=CityPostcodes&city=${city}&state=${state}&postcode=${postcode}`, {
      method: "GET"
    })
      .then((x) => x.cityPostcodes);
  }

  getAnzsicCodes(): Promise<AnzsicCodeDto[]> {
    return callAPI<{ anzsicCodes: AnzsicCodeDto[] }>(`${apiConfig.fmsCustomerPathUrl}/auth/anzsicCodes`, {
      method: "GET"
    })
      .then((x) => x.anzsicCodes);
  }

  getBankBSB(bankBSB: string): Promise<BankBSBDto> {
    return callAPI<BankBSBDto>(`${apiConfig.fmsCustomerPathUrl}/auth/lookup?lookupTerm=BankBSB&value=${bankBSB}`, {method: "get"});
  }

  getHobbyFarmConditions(): Promise<HobbyFarmConditionDto[]> {
    return callAPI<{ conditions: HobbyFarmConditionDto[] }>(`${apiConfig.fmsCustomerPathUrl}/auth/lookup?lookupTerm=HobbyFarmConditions`, {
      method: "get"
    })
        .then(x => x.conditions);
  }
}