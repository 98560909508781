import _ from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { TextFieldProps } from "@nutrien/ddc-components/dist/components/TextField";
import { IFieldModel } from "../../models/IFieldModel";
import { TextField } from "@nutrien/ddc-components";
import {Grid} from "@mui/material";
import { buildError } from "../../models/shared";
import { handleTabFocus } from "./handleTabFocus";
import {css} from "@emotion/css";
import FieldTooltip, {FieldTooltipProps} from "../common/FieldTooltip";

const TYPING_TIMEOUT_MS = 300;

type TextFieldPropsWithoutOnChange = Omit<TextFieldProps, "id" | "onChange">;

export interface TextFieldWrapperProps extends TextFieldPropsWithoutOnChange {
  formatter?: ((input: string, previousValue: string) => string) & { isNumericFormatter?: boolean };
  fieldModel: IFieldModel & { update: (value: string) => void };
  controlId?: string;
  noLabel?: boolean;
  disabled?: boolean;
  gridOptions?: any;
  action?: () => void;
  tooltip?: FieldTooltipProps;
}

const TextFieldWrapper = ({ fieldModel, noLabel, formatter, controlId, gridOptions, disabled = false, action = () => {}, ...rest }: TextFieldWrapperProps) => {
  const inputRef = useRef();

  const [currentValue, setCurrentValue] = useState("");
  const [lastPublishedValue, setLastPublishedValue] = useState("");
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | undefined>();

  useEffect(() => {
    if (fieldModel.value !== currentValue && inputRef && rest.maxLength) {
      // work-around for character counter in cxp-components library.  It doesn't pick up when value is changed through the model... it only handles when the value is changed via text input.
      // currently only have to worry about this when clearing the "notes" field on the history screen.
      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
        rest.multiline ? window.HTMLTextAreaElement.prototype : window.HTMLInputElement.prototype,
        "value"
      )?.set;
      if (nativeInputValueSetter && inputRef.current) {
        nativeInputValueSetter.call(inputRef.current, "");
        // @ts-ignore
        inputRef.current?.dispatchEvent(new Event("input", { bubbles: true }));
      }
    }
    setCurrentValue(getFormattedValue(fieldModel.value));
    setLastPublishedValue(getFormattedValue(fieldModel.value));
  }, [fieldModel.value]);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(() => {
        publishValue();
      }, TYPING_TIMEOUT_MS)
    );
  }, [currentValue]);  // eslint-disable-line react-hooks/exhaustive-deps

  function publishValue() {
    if (lastPublishedValue !== currentValue) {
      fieldModel.update(currentValue);
      setLastPublishedValue(currentValue);
      action();
    }
  }

  function getFormattedValue(newValue: string) {
    return formatter ? formatter(newValue, currentValue) : newValue;
  }

  function setValue(value: string) {
    let selectionStart = (inputRef?.current as any).selectionStart ?? -1;

    const formattedValue = getFormattedValue(value);

    if (!formatter?.isNumericFormatter) {
      setCurrentValue(formattedValue);
      return;
    }

    while (value[selectionStart] < "0" || value[selectionStart] > "9") {
      selectionStart++;
      if (selectionStart > value.length) {
        break;
      }
    }

    setCurrentValue(getFormattedValue(value));
    console.log("CV1 :: " + currentValue);
    console.log("FV1 :: " + formattedValue);

    if (selectionStart !== -1) {
      let newCursorIndex = selectionStart;
      while (formattedValue[newCursorIndex] !== value[selectionStart]) {
        newCursorIndex++;
        if (newCursorIndex > formattedValue.length) {
          break;
        }
      }
      setTimeout(() => {
        if (inputRef.current !== undefined) {
          // @ts-ignore
          inputRef.current.selectionStart = newCursorIndex;
          // @ts-ignore
          inputRef.current.selectionEnd = newCursorIndex;
        }
      }, 0);
    }
  }

  function handleOnBlur(e: any) {
    publishValue();

    if (!_.isNil(rest.onBlur)) {
      rest.onBlur(e);
    }
  }

  function handleOnFocus(e: any) {
    handleTabFocus(e);
    if (!_.isNil(rest.onFocus)) {
      rest.onFocus(e);
    }
  }

  return (
    <>
      <Grid item className={css`position: relative`} {...gridOptions} >
        {rest.tooltip && <FieldTooltip {...rest.tooltip}/>}
          <TextField
            {...rest}
            {...buildError(fieldModel)}
            inputRef={inputRef}
            id={controlId ?? fieldModel.id}
            label={noLabel ? "" : rest.label ?? fieldModel.title}
            value={currentValue}
            onFocus={handleOnFocus}
            onChange={(value: string) => setValue(value)}
            onBlur={handleOnBlur}
            disabled={disabled}
          />
      </Grid>
    </>
  );
};

export default observer(TextFieldWrapper);