import { cast, Instance, types } from "mobx-state-tree";
import { AUTO_DISMISS_TIMEOUT } from "../config/constants";
import { SystemMessageSeverity } from "./enums/SystemMessageSeverity";
import { getNextInteger } from "../helpers/numberHelpers";
import { rootStore } from "./Root";

export const SystemMessageModel = types.model({
  id: types.number,
  message: types.string,
  severity: types.enumeration([SystemMessageSeverity.Success, SystemMessageSeverity.Error, SystemMessageSeverity.Warning])
});

export const SystemMessagesModel = types.model({ messages: types.array(SystemMessageModel) }).actions((self) => ({
  addMessage(message: string, severity: SystemMessageSeverity, autoClear = true, autoClearTimeout = AUTO_DISMISS_TIMEOUT) {
    console.log({ severity: severity, autoClear: autoClear, autoClearTimeout: autoClearTimeout, message: message });

    const systemMessageId = getNextInteger();
    self.messages.push({ id: systemMessageId, severity, message });
    window.scrollTo(0, 0);
    if (autoClear) {
      setTimeout(() => {
        rootStore.systemMessages.dismissMessage(systemMessageId);
      }, autoClearTimeout);
    }
  },
  dismissMessage(id: number) {
    const item = self.messages.find((x) => x.id === id);
    if (item) {
      self.messages.remove(item);
    }
  }
}));

export const defaultSystemMessagesModel = {
  messages: cast([])
} as Instance<typeof SystemMessagesModel>;