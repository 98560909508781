import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {useMst} from "../../models/Root";
import {Status, Wrapper} from "@googlemaps/react-wrapper";
import Map, {LatLong} from "./Map";
import BranchMarker from "./BranchMarker";
import InitialSearchCriteria from "./InitialSearchCriteria";
import {css} from "@emotion/css";
import ApplicationBanner from "../common/ApplicationBanner";
import BrandLogo from "../common/BrandLogo";
import "./BranchLocator.css";
import {GOOGLE_API_KEY, SM_MEDIA_QUERY} from "../../config/constants";
import SearchCriteria from "./SearchCriteria";
import SearchResults from "./BranchResults";
import LeftPanel from "./LeftPanel";
import {useMediaQuery} from "@mui/material";
import MobileSearchResultDrawer from "./MobileSearchResultDrawer";
import MobileSearchCriteria from "./MobileSearchCriteria";
import SearchLocationMarker from "./SearchLocationMarker";
import _ from "lodash";
import {useNavigate} from "react-router-dom";
import {ApplicantType} from "../../models/enums/ApplicantType";
import ProgressIndicator from "../common/ProgressIndicator";
import NoAccessComponent from "../NoAccessComponent";

const DEFAULT_CENTER = { lat: -23.6980, lng: 133.8807 };
const DEFAULT_ZOOM = 5;
const OFFSET_TOP = "127px";

const BranchLocator = () => {
    const { customer, branches, brand } = useMst();
    const isSmall = useMediaQuery(SM_MEDIA_QUERY);
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(true);

    function setSelectedBranch(id: number) {
        if (branches.selectedBranch?.id === id) {
            branches.setSelectedBranch(0);
        } else {
            branches.setSelectedBranch(id);
            navigate("/yourBranch");
        }
    }

    useEffect(() => {
        // this is just for some custom styling of the google "pac-container" element.
        document.body.classList.add("branch-locator");
        return () => {
            document.body.classList.remove("branch-locator");
        };
    }, []);

    useEffect(() => {
        customer.load(sessionStorage.getItem("authorization") ?? "").then(() => {
            // If Application is Already Set, then move to entity page
            if(customer.applicationId > 0) {
                navigate(customer.individual.applicantType === ApplicantType.LeadApplicant ? "/entity" : "/review");
            }
        })
          .finally(() => {
              setIsLoading(false);
          });        ;
    }, [customer, navigate]);

    useEffect(() => {
        if(brand.isLoaded && branches.branches.length === 0 && Boolean(brand.companyCode)) {
            branches.load(brand.companyCode);
        }
    }, [branches, brand.isLoaded]);  // eslint-disable-line react-hooks/exhaustive-deps

    function render(status: Status) {
        if (status === Status.FAILURE) {
            return <h1>ERROR</h1>
        }
        if (status === Status.SUCCESS) {
            return <h1>{status}</h1>;
        }
        return <h1>Loading</h1>
    }

    const filteredBranches = branches.filteredBranches;

    function getVisiblePoints() : LatLong[] {
        const positions : LatLong[] = [...branches.closestBranches];
        if (branches.searchLocation) {
            positions.push(branches.searchLocation)
        }
        if (isSmall && positions.length) {
            const maxCoordinates = {
                latitude: _.chain(positions.map(x => x.latitude)).max().value(),
                longitude: _.chain(positions.map(x => x.longitude)).max().value()
            };
            const minCoordinates = {
                latitude: _.chain(positions.map(x => x.latitude)).min().value(),
                longitude: _.chain(positions.map(x => x.longitude)).min().value()
            };

            // forcing it to zoom out a bit to compensate for the components that cover up parts of the map on mobile
            positions.push({
                latitude: maxCoordinates.latitude + (maxCoordinates.latitude - minCoordinates.latitude) * .2,
                longitude: maxCoordinates.longitude
                },
                {
                    latitude: minCoordinates.latitude + (maxCoordinates.latitude - minCoordinates.latitude) * -.5,
                    longitude: minCoordinates.longitude
                });
        }
        return positions.filter(x => x);
    }

    const offsetLeft = !isSmall && branches.searchLocation ? "350px" : "0";

    return (
      <>
          {isLoading && (
            <ProgressIndicator />
          )}

          {!isLoading && (
            <>
                <div id="branchLocator">
                    <BrandLogo/>
                    <ApplicationBanner label="Trade Account Application" />
                    {customer.individual.isCoApplicant() && (
                      <NoAccessComponent/>
                    )}
                    {customer.individual.isLeadApplicant() && (
                      <>
                    <Wrapper apiKey={GOOGLE_API_KEY} libraries={["visualization", "places", "localContext", "geometry"]} render={render}>
                        <Map className={css`width: calc(100vw - ${offsetLeft}); margin-left: ${offsetLeft}; height: calc(100vh - ${OFFSET_TOP}); max-width:none;`} center={DEFAULT_CENTER} zoom={DEFAULT_ZOOM} disableDefaultUI visiblePoints={getVisiblePoints()}>
                            {filteredBranches.map(branch => <BranchMarker key={branch.code} position={{lat: branch.latitude, lng: branch.longitude}} branchId={branch.id} branchName={branch.name} isSelected={branches.selectedBranch?.id === branch.id} onSelect={setSelectedBranch} />)}
                            {branches.searchLocation && <SearchLocationMarker position={{lat: branches.searchLocation.latitude, lng: branches.searchLocation.longitude}} />}
                        </Map>
                    </Wrapper>
                    {!branches.searchLocation && <InitialSearchCriteria />}
                    {branches.searchLocation && (<>

                        {!isSmall && <>
                            <SearchCriteria />
                            <SearchResults take={10} />
                            <LeftPanel />
                        </>}

                        {isSmall && <>
                            <MobileSearchCriteria/>
                            <MobileSearchResultDrawer/>
                        </>}
                    </>)}
                    </>
                    )}
                </div>
            </>
          )}
      </>
      );
}

export default observer(BranchLocator);
