import { applySnapshot, flow, Instance, types } from "mobx-state-tree";
import { AddressModel, defaultAddressModel } from "./AddressModel";
import FieldModel, { defaultFieldModel } from "./FieldModel";
import DateFieldModel, { defaultDateFieldModel } from "./DateFieldModel";
import { cancelChanges, canSave, IFieldDefinition, initializeFields, reValidate, updateInitialValues } from "./shared";
import { BasicCustomerIndividualDto, CustomerIndividualDto } from "../dto/CustomerDto";
import { AddressType } from "./enums/AddressType";
import { IdentificationType } from "./enums/IdentificationType";
import { primaryPhoneNumberValidation } from "../validation/phoneNumberValidation";
import { emailAddressValidation } from "../validation/emailAddressValidation";
import { driversLicenceCardNumberValidation, idNumberValidation, idStateValidation } from "../validation/identificationValidation";
import {
  buildConditionalValidation, firstNameValidation, lastNameValidation, manuallyValidated, middleNameValidation,
  requiredToSaveValidation
} from "../validation/validationFunctions";
import apiRoot from "../helpers/apiRoot";
import { stripNonNumericCharactersOut } from "../helpers/stringHelpers";
import { ApplicantType, ApplicantTypes } from "./enums/ApplicantType";
import { getNextInteger } from "../helpers/numberHelpers";
import {MemberType, MemberTypes} from "./enums/MemberType";
import BooleanFieldModel, {defaultBooleanFieldModel} from "./BooleanFieldModel";

// @ts-ignore
const IndividualFieldBuilder: (self: IIndividualModel) => IFieldDefinition[] = (self: IIndividualModel) => {
  return [
    {
      title: "First Name",
      field: self.firstName,
      validation: firstNameValidation
    } as IFieldDefinition,
    {
      title: "Middle Name",
      field: self.middleName,
      validation: middleNameValidation
    } as IFieldDefinition,
    {
      title: "Last Name",
      field: self.lastName,
      validation: lastNameValidation
    } as IFieldDefinition,
    {
      title: "Date of Birth",
      field: self.dateOfBirth,
      validation: (title: string) => buildConditionalValidation(title, requiredToSaveValidation, (m: IIndividualModel) => !m.onlyBasicInfo)
    } as IFieldDefinition,
    {
      title: "Email Address",
      field: self.email,
      validation: emailAddressValidation
    } as IFieldDefinition,
    {
      title: "Phone Number",
      field: self.primaryPhone,
      validation: primaryPhoneNumberValidation
    } as IFieldDefinition,
    {
      title: "Ownership",
      field: self.ownershipPercentage,
      validation: manuallyValidated(self.ownershipPercentage) // validated by validateForOwnershipPercentage in OwnershipPercentageEditor
    } as IFieldDefinition,
    {
      title: "Identification Type",
      field: self.identificationType,
      validation: (title: string) => buildConditionalValidation(title, requiredToSaveValidation, (m: IIndividualModel) => !m.onlyBasicInfo),
      fieldsToRevalidate: ["identificationNumber", "idState", "driversCardNumber"]
    } as IFieldDefinition,
    {
      title: "Identification #",
      field: self.identificationNumber,
      validation: (title: string) => buildConditionalValidation(title, idNumberValidation, (m: IIndividualModel) => !m.onlyBasicInfo),
    } as IFieldDefinition,
    {
      title: "Driver's Licence Card Number",
      field: self.driversCardNumber,
      validation: (title: string) => buildConditionalValidation(title, driversLicenceCardNumberValidation, (m: IIndividualModel) => !m.onlyBasicInfo),
    } as IFieldDefinition,
    {
      title: "State",
      field: self.idState,
      validation: (title: string) => buildConditionalValidation(title, idStateValidation, (m: IIndividualModel) => !m.onlyBasicInfo),
      fieldsToRevalidate: ["driversCardNumber"]
    } as IFieldDefinition,
    {
      id: "isResidentialAddressSameAsBusinessAddress",
      title: "Check if same as Business Address",
      field: self.isResidentialAddressSameAsBusinessAddress,
    } as IFieldDefinition
  ];
};

// @ts-ignore
export const IndividualModel = types.model({
  mobxId: types.identifierNumber,
  id: types.number,
  onlyBasicInfo: types.boolean,
  applicantType:types.maybe( types.enumeration(ApplicantTypes)),
  memberType: types.maybe(types.enumeration(MemberTypes)),
  storedMemberType: types.maybe(types.enumeration(MemberTypes)),
  firstName: FieldModel,
  middleName: FieldModel,
  lastName: FieldModel,
  dateOfBirth: DateFieldModel,
  email: FieldModel,
  primaryPhone: FieldModel,
  ownershipPercentage: FieldModel,
  identificationType: FieldModel,
  identificationNumber: FieldModel,
  driversCardNumber: FieldModel,
  idState: FieldModel,
  residentialAddress: AddressModel,
  isResidentialAddressSameAsBusinessAddress: BooleanFieldModel
})
  .views((self) => ({
    getUpdateIndividualButtonText() {
      if (self.memberType === MemberType.Director) {
        return "Update Director"
      }
      return undefined;
    },
    isExistingIndividual() {
      return self.id > 0;
    },
    isLeadApplicant() {
      return self.applicantType === ApplicantType.LeadApplicant;
    },
    isCoApplicant() {
      return self.applicantType === ApplicantType.CoApplicant;
    },
    displayOwnershipPercentage() {
      return self.memberType === MemberType.Director;
    },
    canRemove() {
      return self.applicantType !== ApplicantType.LeadApplicant && self.memberType !== MemberType.Director
    },
    getNonStandardEditButtonText() {
      if (self.memberType === MemberType.Director && !self.email.value) {
        return "Provide Director Details";
      }
      return undefined;
    },
    showIdStateOrDriversCardNumber() {
      return self.identificationType.value === IdentificationType.DriversLicence;
    },
    identificationNumberLabel() {
      return self.identificationType.value === IdentificationType.DriversLicence
        ? "Driver’s Licence Number"
        : "Identification #";
    },
    getDto(): CustomerIndividualDto {
      return {
        id: self.id,
        firstName: self.firstName.value,
        middleName: self.middleName.value,
        lastName: self.lastName.value,
        dateOfBirth: self.dateOfBirth.getFormattedDateForJsonString(),
        email: self.email.value,
        primaryPhone: stripNonNumericCharactersOut(self.primaryPhone.value),
        identificationType: self.identificationType.value,
        identificationNumber: self.identificationNumber.value,
        driversCardNumber: self.driversCardNumber.value,
        idState: self.idState.value,
        residentialAddress: self.residentialAddress.getDto(),
        isSimpleUpdate: self.onlyBasicInfo,
        memberType: self.memberType,
        ownershipPercentage: self.ownershipPercentage.value ? +self.ownershipPercentage.value : null
      } as CustomerIndividualDto;
    },
    getBasicDto(): BasicCustomerIndividualDto {
      return {
        firstName: self.firstName.value,
        lastName: self.lastName.value,
        email: self.email.value,
        primaryPhone: stripNonNumericCharactersOut(self.primaryPhone.value)
      } as BasicCustomerIndividualDto;
    }
  }))
  .actions((self) => ({
    reset() {
      applySnapshot(self, { ...defaultIndividualModel, mobxId: self.mobxId });
    },
    initialize(onlyBasicInfo: boolean = false) {
      this.reset();

      self.onlyBasicInfo = onlyBasicInfo;
      self.residentialAddress.initialize(AddressType.Residential);

      initializeFields(self, IndividualFieldBuilder);
    },
    isValid: flow(function* isValid() {
      yield reValidate(self, IndividualFieldBuilder);

      const isResidentialAddressValid = self.onlyBasicInfo ? true : yield self.residentialAddress.isValid();

      return canSave(self, IndividualFieldBuilder) && isResidentialAddressValid;
    }),
    cancelChanges() {
      cancelChanges(self, IndividualFieldBuilder);
      self.residentialAddress.cancelChanges();
      self.memberType = self.storedMemberType;
    },
    updateMemberType(memberType: MemberType, onlyBasicInfo: boolean) {
      self.memberType = memberType;
      self.onlyBasicInfo = onlyBasicInfo;
    }
  }))
  .actions((self) => ({
    load: flow(function* load(individualDto: CustomerIndividualDto | undefined, onlyBasicInfo: boolean = false, defaultMemberType?: MemberType) {
      self.initialize(onlyBasicInfo);
      self.memberType = individualDto?.memberType ?? defaultMemberType;
      self.storedMemberType = individualDto?.memberType;

      if(individualDto) {
        self.id = individualDto.id;
        self.applicantType = individualDto.applicantType as ApplicantType;

        yield self.firstName.update(individualDto.firstName, true);
        yield self.lastName.update(individualDto.lastName, true);
        yield self.email.update(individualDto.email, true);
        yield self.primaryPhone.update(individualDto.primaryPhone, true);

        if ((!onlyBasicInfo || self.memberType === MemberType.Director) && Boolean(individualDto.dateOfBirth)) {
          yield self.dateOfBirth.update(individualDto.dateOfBirth, true);
        }

        if ((!onlyBasicInfo || self.memberType === MemberType.Director) && Boolean(individualDto.ownershipPercentage)) {
          yield self.ownershipPercentage.update(individualDto.ownershipPercentage ?? "", true);
        }


        if(!onlyBasicInfo) {
          yield self.middleName.update(individualDto.middleName, true);
          yield self.identificationType.update(individualDto.identificationType, true);
          yield self.identificationNumber.update(individualDto.identificationNumber, true);
          yield self.driversCardNumber.update(individualDto.driversCardNumber, true);
          yield self.idState.update(individualDto.idState, true);

          yield self.residentialAddress.load(AddressType.Residential, individualDto.residentialAddress);
        }
      }
    }),
    save: flow(function* save() {
      if(yield self.isValid()) {
        const dto = self.getDto();

        if (self.ownershipPercentage.isDirty()) {
          yield apiRoot.membersApi.updateMember(dto);
        }

        yield apiRoot.customerApi.saveIndividual(dto);

        updateInitialValues(self, IndividualFieldBuilder);
        self.residentialAddress.updateInitialValues();
        self.storedMemberType = self.memberType;
      }
    })
  }));

// @ts-ignore
export type IIndividualModel = Instance<typeof IndividualModel>;

export let defaultIndividualModel = {
  id: 0,
  onlyBasicInfo: false,
  applicantType: ApplicantType.CoApplicant,
  firstName: defaultFieldModel,
  middleName: defaultFieldModel,
  lastName: defaultFieldModel,
  dateOfBirth: defaultDateFieldModel,
  email: defaultFieldModel,
  primaryPhone: defaultFieldModel,
  ownershipPercentage: defaultFieldModel,
  identificationType: defaultFieldModel,
  identificationNumber: defaultFieldModel,
  driversCardNumber: defaultFieldModel,
  idState: defaultFieldModel,
  residentialAddress: defaultAddressModel,
  isResidentialAddressSameAsBusinessAddress: defaultBooleanFieldModel
} as Instance<typeof IndividualModel>;

export const createIndividualModelFrom = async (dto: CustomerIndividualDto | undefined = undefined, onlyBasicInfo: boolean = false, memberType: MemberType | undefined = undefined): IIndividualModel => {
  const individualModel = IndividualModel.create({ ...defaultIndividualModel, mobxId: getNextInteger() });
  await individualModel.load(dto, onlyBasicInfo, memberType);
  return individualModel;
}