import { observer } from "mobx-react-lite";
import React from "react";
import { Button } from "@nutrien/ddc-components";
import {
  Dialog,
  DialogContent,
  Grid,
  Typography,
  useTheme
} from "@mui/material";
import { css } from "@emotion/css";
import {Icon} from "@nutrien/ddc-components";

interface PartnerErrorModalProps {
  show: boolean;
  onClose: () => void;
  onAddPartnerClick: () => void;
}

const PartnerErrorModal = (props: PartnerErrorModalProps) => {
  const theme = useTheme();

  const PartnerErrorModalCss = {
    partnerText: css`
      font-family: "Open Sans";
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: ${theme.palette.text.secondary}
    `,
    banner: css`
      font-family: "Open Sans";
      font-weight: 300;
      font-size: 24px;
      line-height: 24px;
    `,
    container: css`
      text-align: center;
      height: 257px;
    `
  }



  return (
    <>
      <Dialog
        open={props.show}
        onClose={() => props.onClose()}
        maxWidth={"sm"}
      >
        <DialogContent>
          <Grid container direction={"column"} spacing={5} alignContent={"center"} justifyContent="center" className={PartnerErrorModalCss.container}>
            <Grid item>
              <Typography variant={"inherit"} color={theme.palette.primary.main} className={PartnerErrorModalCss.banner}>Two or more partners are required to complete your Partnership organisation.</Typography>
            </Grid>
            <Grid item>
              <Button variant="contained"
                      color="primary"
                      fullWidth={false}
                      onClick={() => props.onAddPartnerClick()}
                      startIcon={<Icon.UserPlusFeather color={theme.palette.common.white}/>}
              >
                Add a Partner
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default observer(PartnerErrorModal);