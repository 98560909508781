import {flow, Instance, types} from "mobx-state-tree";
import {
  ALL_ATTACHMENT_PARENT_TYPES,
  ALL_ATTACHMENT_TYPES,
  AttachmentParentType,
  AttachmentType
} from "./enums/AttachmentType";
import apiRoot from "../helpers/apiRoot";

export const AttachmentModel = types
  .model({
    tempId: types.identifierNumber,
    attachmentId: types.number,
    key: types.string,
    parentId: types.number,
    fileName: types.string,
    attachmentType: types.enumeration<AttachmentType>(ALL_ATTACHMENT_TYPES),
    parentType: types.enumeration<AttachmentParentType>(ALL_ATTACHMENT_PARENT_TYPES),
    isRequired: types.maybe(types.boolean),
    errorMessage: types.maybe(types.string),
    applicationId: types.maybe(types.number),
    borrowingEntityId: types.maybe(types.number),
    individualId: types.maybe(types.number),
    createdBy: types.maybe(types.string),
    date: types.maybe(types.Date),
    manualVerifiedAml: types.maybe(types.boolean),
    uploadStatus: types.maybe(types.string)
  })
  .views((self) => ({
    isUploaded(): boolean {
      return Boolean(self.fileName);
    }
  }))
  .actions((self) => ({
    error(error?: string) {
      self.errorMessage = error;
    },
    getAttachmentUploadUrl: flow(function* getAttachmentUploadUrl(fileName: string) {
      const response = yield apiRoot.attachmentApi.getAttachmentUploadUrl
      (self.parentId, fileName, self.attachmentType, self.parentType, self.borrowingEntityId ?? null, self.applicationId ?? null, self.individualId ?? null);

      return response;
    }),
    getAttachmentDownloadUrl: flow(function* getAttachmentDownloadUrl() {
      const response = yield apiRoot.attachmentApi.getAttachmentDownloadUrl(self.attachmentId);

      return response;
    }),
    getAttachmentDeleteUrl: flow(function* getAttachmentDeleteUrl() {
      const response = yield apiRoot.attachmentApi.getAttachmentDeleteUrl(self.attachmentId);

      return response;
    })
  }));

export type IAttachmentModel = Instance<typeof AttachmentModel>;
