import * as _ from "lodash";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DATETIME_LOCALE_STRING_FORMAT } from "../config/constants";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat)

export function getFormattedDateForJsonString(value: Date | null) {
  if (_.isNil(value)) {
    return null;
  }
  return dayjs(value, { utc: false }).format("YYYY-MM-DD");
}

export function parseDate(value: string | null | undefined, format = "MMM DD, YYYY"): Date | null {
  return _.isNil(value) ? null : dayjs(value, format).toDate();
}

export function formatDate(value: Date | null | undefined, format = "MMM DD, YYYY", defaultValue = ""): string | undefined {
  return _.isNil(value) ? defaultValue : dayjs(value).format(format);
}

export function getDateTimeAEST() {
  return new Date(dayjs().tz("Australia/Sydney").format(DATETIME_LOCALE_STRING_FORMAT));
}

export function convertDateFromUtcToLocal(date: Date | string): Date {
  const day = dayjs.utc(date);
  return day.local().toDate();
}