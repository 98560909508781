import { IMembersApi } from "./IMembersApi";
import { BasicCustomerIndividualDto, CustomerIndividualDto } from "../dto/CustomerDto";
import { callAPI } from "./apiUtilities";
import { apiConfig } from "../config/api.config";

export default class MembersApi implements IMembersApi {
  getMembers(applicationId: number): Promise<CustomerIndividualDto[]> {
    return callAPI(`${apiConfig.fmsCustomerPathUrl}/auth/applications/${applicationId}/members`, {
      method: "GET"
    }).then((x: any) => x.payload);
  }
  addMember(applicationId: number, dto: BasicCustomerIndividualDto): Promise<any> {
    return callAPI(`${apiConfig.fmsCustomerPathUrl}/auth/applications/${applicationId}/members`, {
      method: "POST",
      body: JSON.stringify(dto)
    })
  }
  removeMember(applicationId: number, individualId: number): Promise<any> {
    return callAPI(`${apiConfig.fmsCustomerPathUrl}/auth/applications/${applicationId}/members`, {
      method: "DELETE",
      body: JSON.stringify({ individualId: individualId })
    })
  }
  updateMember(dto: BasicCustomerIndividualDto): Promise<any> {
    return callAPI(`${apiConfig.fmsCustomerPathUrl}/auth/application/members`, {
      method: "PUT",
      body: JSON.stringify(dto)
    })
  }
  addCompanyTrustee(applicationId: number, childEntityId: number): Promise<number> {
    return callAPI(`${apiConfig.fmsCustomerPathUrl}/auth/applications/${applicationId}/entityMembers`, {
      method: "POST",
      body: JSON.stringify({childEntityId: childEntityId})
    }).then((x: any) => x.payload)
  }
  GetBorrowingEntityIndividuals(borrowingEntityId: number): Promise<CustomerIndividualDto[]>{
    return callAPI(`${apiConfig.fmsCustomerPathUrl}/auth/entities/${borrowingEntityId}/individualMembers`, {
      method: "GET"}).then((x: any) => x.payload);
  }
}