import {ValidationSeverity} from "../models/enums/ValidationSeverity";
import {emptyValidation} from "./validationFunctions";
import {IBorrowingEntitySearch} from "../models/BorrowingEntitySearch";
import {isAbn, isAcn} from "../helpers/stringHelpers";

export const abnAcnValidation = (title: string) => async (value: string, model: IBorrowingEntitySearch) => {
  if (value.length > 0 && !isAbn(value) && !isAcn(value)) {
    return {
      message: `Please enter an ABN or ACN`,
      severity: ValidationSeverity.NoSave
    }
  }
  return emptyValidation();
};