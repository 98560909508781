import React from "react";
import { Grid, useTheme } from "@mui/material";
import { css } from "@emotion/css";

interface ActionBarProps {
  isLoading?: boolean;
  leftComponents?: JSX.Element,
  rightComponents?: JSX.Element
}

const ActionBar = (props: ActionBarProps) => {
  const theme = useTheme();

  const actionBarCss = {
    bottomBar: css`
      padding: 8px;
      height: 57px;
      border-top-width: 1px;
      border-top-style: solid;
      border-top-color: ${theme.palette.grey["300"]};
      position: fixed;
      bottom: 0;
      width: 100%;
      background: ${theme.palette.common.white};
      z-index: 2;
    `
  };

  return (
    <>
      {!props.isLoading && (
        <Grid container className={actionBarCss.bottomBar} justifyContent="space-between">
          <Grid item>
            {props.leftComponents}
          </Grid>
          <Grid item>
            {props.rightComponents}
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default ActionBar;