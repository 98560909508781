import {observer} from "mobx-react-lite";
import React, {CSSProperties, useEffect, useState} from "react";
import {Grid, GridProps, Link, Theme, useTheme} from "@mui/material";
import ApplicationContainer from "./common/ApplicationContainer";
import {useMst} from "../models/Root";
import {Body2, H3} from "./common/Text";
import Line from "./common/Line";
import ActionBar from "./common/ActionBar";
import NextButton from "./common/NextButton";
import {useNavigate} from "react-router-dom";
import SelectFieldWrapper from "./editors/SelectFieldWrapper";
import CheckBoxFieldWrapper from "./editors/CheckBoxFieldWrapper";
import TextFieldWrapper from "./editors/TextFieldWrapper";
import {Icon, IconButton} from "@nutrien/ddc-components";
import styled from "@emotion/styled";
import BackButton from "./common/BackButton";
import YesNoRadioButtonFieldWrapper from "./editors/YesNoRadioButtonFieldWrapper";
import {PicNumbersModel} from "../models/PicNumbersModel";
import {Instance} from "mobx-state-tree";
import {PAYMENT_TYPES} from "../models/AccountModel";
import {IFieldValidationModel} from "../models/FieldValidationModel";
import {ValidationSeverity} from "../models/enums/ValidationSeverity";
import ActionButton from "./common/ActionButton";
import {isEditFrom} from "../helpers/urlHelpers";
import CancelButton from "./common/CancelButton";
import {ProgressStep} from "./ProgressBar/ProgressBarComponent";
import { formatBankBSB } from "./editors/bankBSBFormatter";
import AddLinkButton from "./common/AddLinkButton";
import NoAccessComponent from "./NoAccessComponent";

export type ThemeProps = {
    theme: Theme
}

const DeleteButton = styled(IconButton)`
  height: 35px;
  margin-top: 25px
`;

const Error = styled.span<ThemeProps>`
  margin-left: 8px;
  font-family: 'Open Sans',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  align-items: center;
  letter-spacing: 0.4px;

  /* error/main */
  color: ${props => props.theme.palette.error.main};
`;

const RequiredLabel = styled.div<ThemeProps>`
  font-family: 'Open Sans',sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  font-feature-settings: 'tnum' on, 'lnum' on;

  /* error/main */
  color: ${props => props.theme.palette.error.main};
`;

const Label = styled.div<ThemeProps>`
  font-family: 'Open Sans',sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  font-feature-settings: 'tnum' on, 'lnum' on;

  /* text/primary */
  color: ${props => props.theme.palette.text.primary};
`;

export const DisplayError = observer((props: {error: IFieldValidationModel, style?: CSSProperties}) => {
    const theme = useTheme();
    return <>{props.error.severity !== ValidationSeverity.Valid && <div style={props.style} ><Icon.AlertCircleFeather color="error" fontSize="xs" /><Error theme={theme} >{props.error.message}</Error></div>}</>
})

const DisplayLabel = ({label, isRequired, style}: {label: string, isRequired: boolean, style?: CSSProperties}) => {
    const theme = useTheme();
  return isRequired ? <RequiredLabel theme={theme} style={style}>{label}</RequiredLabel> : <Label theme={theme} style={style}>{label}</Label>
};

const Account = () => {
    const ROW_GRID_OPTIONS: GridProps = { xs: 12, md: 12 };
    const ITEM_GRID_OPTIONS: GridProps = { xs: 12, md: 3 };
    const ITEM_GRID_TRANSIT_INSURANCE: GridProps = { xs: 12, md: 6 };
    const ITEM_GRID_PIC_NUMBER_OPTIONS: GridProps = { xs: 12, md: 4 };

    const { customer, anzsicCodes } = useMst();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);

        Promise.all([
            anzsicCodes.load(),
            customer.load(sessionStorage.getItem("authorization") ?? "")
        ]).finally(() => {
            setIsLoading(false);
        });
    }, [customer, anzsicCodes]);

    const HeadingEntry = (heading: string) => {
        return (
            <Grid item {...ROW_GRID_OPTIONS}>
                <H3>{heading}</H3>
            </Grid>
        );
    }

    const LineEntry = () => {
        return (
            <Grid item {...ROW_GRID_OPTIONS}>
                <Line />
            </Grid>
        )
    }

    const NextReviewClicked = async () => {
        try {
            setIsSaving(true);

            if(await customer.saveAccount()) {
                if(isEditFrom()) {
                  navigate(-1);
                } else{
                  navigate("/review");
                }
            }
        } finally {
            setIsSaving(false);
        }
    }

    const CancelClicked = () => {
        customer.account.cancelChanges();
        navigate(-1);
    }

    function BackClicked() {
        if(customer.borrowingEntity.canAddMembers()) {
            navigate("/members");
        } else {
            navigate("/entity");
        }
    }

    function removePicNumber(model: Instance<typeof PicNumbersModel>, index: number) {
        model.removePicNumber(index);
        customer.account.validatePicNumbers(model);
    }

    function PicNumbers(model: Instance<typeof PicNumbersModel>) {
        return <Grid key={`PicNumbers_${model.activity}`} {...ITEM_GRID_PIC_NUMBER_OPTIONS} style={{marginTop: "0px"}} container item direction="column">
            <DisplayLabel label={"PIC Number"} isRequired={!model.validation.isValid()} style={{marginBottom: "-20px"}}/>
            {model.picNumbers.map((pn, index: number) => (<Grid item container direction="row" key={`PicNumbers_${model.activity}_${index}`} >
                    <TextFieldWrapper fieldModel={pn} gridOptions={{xs: 10, md: 10}} action={() => customer.account.validatePicNumbers(model)} placeholder="Optional"/>
                    {index !== 0 && <DeleteButton size="small"
                                                  onClick={() => removePicNumber(model, index)}><Icon.Trash2Feather
                        color="primary"/></DeleteButton>}
                </Grid>))}
            <DisplayError error={model.validation} style={{position: "relative", top: "-20px"}} />

            <AddLinkButton onClick={() => model.addPicNumber()}>Add PIC Number</AddLinkButton>
        </Grid>;
    }

    function isLivestockCheckboxDisabled() {
        return customer.action === "FirstTimeLivestockFollowUp";
    }

    return (
        <>
            <ApplicationContainer isLoading={isLoading} isSaving={isSaving} currentStep={ProgressStep.AccountDetailStep}>
                {customer.individual.isCoApplicant() && (
                  <NoAccessComponent/>
                )}
                {customer.individual.isLeadApplicant() && (
                  <>
                    <Grid container direction={"row"} alignItems={"flex-start"} spacing={1}>
                        {HeadingEntry("Select Your Primary Industry")}

                        <SelectFieldWrapper options={anzsicCodes.selectFieldOptions()} fieldModel={customer.account.anzsicCode} gridOptions={ITEM_GRID_OPTIONS} />

                        <LineEntry />

                        {HeadingEntry("Select One or More Business Activities")}
                        <DisplayLabel label="Activities" isRequired={!customer.account.activitiesValidationModel.isValid()} style={{marginLeft: "20px"}}/>
                        {customer.branch?.isMerchandiseOrFertiliser && <CheckBoxFieldWrapper fieldModel={customer.account.isMerchandiseOrFertiliser} action={() => customer.account.validateActivities()} />}
                        {customer.branch?.isLivestock && <CheckBoxFieldWrapper fieldModel={customer.account.isLivestock}  action={() => customer.account.validateActivities()} disabled={isLivestockCheckboxDisabled()} />}
                        {customer.branch?.isWool && <CheckBoxFieldWrapper fieldModel={customer.account.isWool}  action={() => customer.account.validateActivities()} />}
                        <DisplayError error={customer.account.activitiesValidationModel} style={{marginLeft: "20px"}} />

                        {customer.account.isLivestock.value && <>
                            <LineEntry/>
                            {HeadingEntry("Livestock Details")}
                            <YesNoRadioButtonFieldWrapper fieldModel={customer.account.addTransitInsurance}
                                                          description={<Body2>We offer Livestock Transit Insurance so you can insure your livestock whilst in transit. Please click <Link href={"https://www.nutrienagsolutions.com.au/livestockinsurance"} target={"_blank"}>here</Link> for further information about the terms and conditions including fees and charges related to this insurance product. If you would like to add Livestock Transit Insurance click "Yes" below.</Body2>}
                                                          gridOptions={{...ITEM_GRID_TRANSIT_INSURANCE, style: {marginTop: 0}}}/>
                            {PicNumbers(customer.account.livestockPicNumbersModel)}
                        </>}

                        {customer.account.isWool.value && <>
                            <LineEntry />
                            {HeadingEntry("Wool Details")}
                            {PicNumbers(customer.account.woolPicNumbersModel)}
                        </>}

                        {(customer.account.isLivestock.value || customer.account.isWool.value) && <>
                            <LineEntry />
                            {HeadingEntry("Livestock or Wool Proceed Instructions")}

                            <SelectFieldWrapper options={PAYMENT_TYPES} displayEmpty fieldModel={customer.account.paymentType}  gridOptions={ITEM_GRID_OPTIONS}  />
                            {customer.account.isEft() && <>
                                <TextFieldWrapper fieldModel={customer.account.bankBSB} gridOptions={ITEM_GRID_OPTIONS} formatter={formatBankBSB} />
                                <TextFieldWrapper fieldModel={customer.account.accountNumber} gridOptions={ITEM_GRID_OPTIONS}  />
                                <TextFieldWrapper fieldModel={customer.account.accountName}  gridOptions={ITEM_GRID_OPTIONS} />
                            </>}
                        </>}
                    </Grid>
                  </>
                )}
            </ApplicationContainer>
            {customer.individual.isLeadApplicant() && (
                <ActionBar
                  isLoading={isLoading}
                  leftComponents={isEditFrom() ? <CancelButton onClick={() => CancelClicked()} /> : <BackButton onClick={() => BackClicked()} />}
                  rightComponents={isEditFrom() ? <ActionButton onClick={async () => await NextReviewClicked()} disabled={isSaving}>Save</ActionButton>: <NextButton onClick={async () => await NextReviewClicked()} disabled={isSaving}>Next: Review</NextButton>} />
                )}
        </>
    )
}

export default observer(Account);