import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useMst } from "../models/Root";
import ApplicationContainer from "./common/ApplicationContainer";
import { Stack, useTheme } from "@mui/material";
import { ReactComponent as FileCircleCheckIcon } from "./assets/FileCircleCheckIcon.svg";
import {Body1, Bold, BoldBanner, Text} from "./common/Text";
import { useAuth } from "../hooks/useAuth";
import { observer } from "mobx-react-lite";

const ApplicationComplete = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const theme = useTheme();
  const auth = useAuth();

  const {customer, members} = useMst();

  const loadMembers = async () => {
    if(customer.individual.isLeadApplicant()) {
      await members.load(customer.applicationId);
    }
  }

  useEffect(() => {
    setIsLoading(true);

    customer.load(sessionStorage.getItem("authorization") ?? "")
      .then(() => {
        loadMembers()
          .finally(() => {
            // After loading the details, sign the user out of the application
            auth.signOut();

            setIsLoading(false);
          })
      })
  }, []);    // eslint-disable-line react-hooks/exhaustive-deps

  const getCoApplicantEmails = () => {
    let coApplicants = _.filter(members.members, x => !x.isLeadApplicant());
    let emails = _.map(coApplicants, x => x.email.value);
    return _.join(emails, ', ');
  }

  const onBackButtonEvent = (e: any) => {
    e.preventDefault();

    window.removeEventListener('popstate', onBackButtonEvent);
  }

  useEffect(() => {
    window.addEventListener('popstate', onBackButtonEvent);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {
        <ApplicationContainer isLoading={isLoading}>
          <Stack spacing={5} alignItems={"center"}>
            <BoldBanner color={theme.palette.text.secondary}>
              {"Wonderful News!"}
            </BoldBanner>
            <Body1 color={theme.palette.text.primary} className={"center"}>
              {"Your Application "}<Bold>{`(app #${customer.applicationId})`}</Bold> {`for a Trade Account has been started.`}
            </Body1>
            <FileCircleCheckIcon stroke={theme.palette.primary.main}/>
            {customer.individual.isLeadApplicant() && members.members.length > 1 && (
              <>
                <Body1 color={theme.palette.text.primary} className={"center"}>
                  <p>
                    Emails have also been sent to your co-applicant(s).
                  </p>
                  <p>
                    <Text color={theme.palette.text.secondary}>{getCoApplicantEmails()}</Text>
                  </p>
                  <>
                    to complete their part of the Trade Account application.
                  </>
                </Body1>
              </>
            )}
            {customer.individual.isLeadApplicant() && (
              <Body1 color={theme.palette.text.primary} className={"center"}>
                {"You will also receive an email confirming that your Trade Account application has started."}
              </Body1>
            )}
            {!customer.individual.isLeadApplicant() && (
              <Body1 color={theme.palette.text.primary} className={"center"}>
                {"You will receive an email confirming that your Trade Account application has started."}
              </Body1>
            )}
            <Body1 color={theme.palette.text.primary} className={"center"}>
              {"You have now signed out of the application and may close your browser."}
            </Body1>
          </Stack>
        </ApplicationContainer>
      }
    </>
  );
};

export default observer(ApplicationComplete);
