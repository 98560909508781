import _ from "lodash";

export class AddressLookupInfo {
  constructor(
    public subpremise: string,
    public streetNumber: string,
    public streetName: string,
    public city: string,
    public state: string,
    public country: string,
    public postalCode: string,
    public latitude: number,
    public longitude: number
  ) {}

  address(): string {
    return (!_.isEmpty(this.streetNumber) ? `${this.streetNumber} ` : "") + this.streetName;
  }

  static create(addressObject: any): AddressLookupInfo {
    const subpremise = _.find(addressObject.address_components, (x) => _.includes(x.types, "subpremise"));
    const streetNumber = _.find(addressObject.address_components, (x) => _.includes(x.types, "street_number"));
    const streetName = _.find(addressObject.address_components, (x) => _.includes(x.types, "route"));
    const city = _.find(addressObject.address_components, (x) => _.includes(x.types, "locality"));
    const state = _.find(addressObject.address_components, (x) => _.includes(x.types, "administrative_area_level_1"));
    const country = _.find(addressObject.address_components, (x) => _.includes(x.types, "country"));
    const postalCode = _.find(addressObject.address_components, (x) => _.includes(x.types, "postal_code"));

    return new AddressLookupInfo(
      subpremise?.short_name,
      streetNumber?.short_name,
      streetName?.short_name,
      city?.short_name,
      state?.short_name,
      country?.short_name,
      postalCode?.short_name,
      addressObject.geometry.location.lat(),
      addressObject.geometry.location.lng()
    );
  }
}
