import { flow, Instance, isAlive, types } from "mobx-state-tree";
import { AttachmentModel, IAttachmentModel } from "./AttachmentModel";
import * as _ from "lodash";
import dayjs from "dayjs";
import {
  ALL_ATTACHMENT_PARENT_TYPES,
  AttachmentParentType,
  AttachmentType
} from "./enums/AttachmentType";
import {AllBorrowingEntityTypes} from "./enums/BorrowingEntityType";
import {convertDateFromUtcToLocal} from "../helpers/dateHelpers";
import {getNextInteger} from "../helpers/numberHelpers";
import apiRoot from "../helpers/apiRoot";
import {AttachmentDto} from "../dto/AttachmentDto";

export const AllowedConfigurationModel = types.model({
  borrowingEntityId: types.boolean,
  individualId: types.boolean,
  applicationId: types.boolean
});

export const AttachmentTypeModel = types.model({
  accountType: types.string,
  attachmentType: types.string,
  sortValue: types.string,
  excludeFromBorrowingEntityTypes: types.array(types.enumeration(AllBorrowingEntityTypes)),
  readOnlyForBorrowingEntityTypes: types.array(types.enumeration(AllBorrowingEntityTypes)),
  unassignedAttachmentEligible: types.boolean,
  uploadStatuses: types.array(types.string),
  deleteStatuses: types.array(types.string),
  allowedConfigurations: types.array(AllowedConfigurationModel),
  excludeTradeSources: types.array(types.string),
  hideSectionIfEmpty: types.boolean
});

const unFormattedAttachmentDate = (key: string): string => {
  return key.substring(key.lastIndexOf(".") + 1);
};

export const AttachmentsModel = types
  .model({
    attachments: types.array(AttachmentModel),
    parentId: types.number,
    parentType: types.maybe(types.enumeration<AttachmentParentType>(ALL_ATTACHMENT_PARENT_TYPES)),
    isLoading: types.maybe(types.boolean),
    attachmentTypes: types.maybe(types.array(AttachmentTypeModel)),
    isLoadingAttachmentTypes: types.maybe(types.boolean),
    selectedAttachment: types.maybe(types.reference(AttachmentModel))
  })
  .views((self) => ({
    hasAttachments() {
      return self.attachments.some((x: IAttachmentModel) => x.isUploaded());
    },
    getUploadedAttachments() {
      return self.attachments.filter((x: IAttachmentModel) => x.isUploaded());
    }
  }))
  .views((self) => ({
    isNotAttached(type: AttachmentType, currentApplicationOnly = false) {
      const foundAttachments = currentApplicationOnly
        ? self.attachments.filter((x) => x.attachmentType === type && x.applicationId === self.parentId && x.isUploaded())
        : self.attachments.filter((x) => x.attachmentType === type && x.isUploaded());
      return _.isEmpty(foundAttachments);
    },
    get(type: AttachmentType, currentApplicationOnly = false) {
      return currentApplicationOnly
        ? self.attachments.find((x) => x.attachmentType === type && x.applicationId === self.parentId)
        : self.attachments.find((x) => x.attachmentType === type);
    },
    getAll(type: AttachmentType, currentApplicationOnly = false) {
      return _.chain(self.attachments ?? [])
        .filter((x) => x.attachmentType === type && (!currentApplicationOnly || x.applicationId === self.parentId))
        .sortBy((x) => x.date)
        .reverse()
        .value();
    },
    hasEmptyAttachment(type: AttachmentType) {
      return self.attachments.some((x: IAttachmentModel) => x.attachmentType === type && x.attachmentId === 0);
    },
    canSubmit() {
      return !self.isLoading && !self.attachments.find((x) => Boolean(x.isRequired) && !x.fileName);
    }
  }))
  .actions((self) => ({
    addAttachment(attachment: Instance<typeof AttachmentModel>) {
      self.attachments.replace([...self.attachments.filter((x) => x.attachmentType !== attachment.attachmentType || x.key), attachment]);
    }
  }))
  .actions((self) => ({
    selectAttachment(attachment?: Instance<typeof AttachmentModel>) {
      self.selectedAttachment = attachment;
    },
    initialize(parentId: number | string, parentType: AttachmentParentType) {
      if (self.parentId === +parentId && self.parentType === parentType) {
        return;
      }

      self.parentId = +parentId;
      self.parentType = parentType;

      self.attachments.clear();
    },
    load: flow(function* load(
      parentId: number | string,
      parentType: AttachmentParentType,
      filter: (dto: AttachmentDto) => boolean = (dto: AttachmentDto) => true
    ) {
      if (+parentId === self.parentId && parentType === self.parentType) {
        return;
      }
      self.parentType = parentType;
      self.parentId = +parentId;

      try {
        self.isLoading = true;
        self.selectedAttachment = undefined;
        self.attachments.clear();
        const response = yield apiRoot.attachmentApi.getAttachments(parentId, parentType);

        self.attachments.replace(
          response.filter(filter).map((item: AttachmentDto) => ({
            ...item,
            tempId: getNextInteger(),
            attachmentId: +item.id,
            parentId: +parentId,
            parentType: parentType,
            fileName: item.fileName,
            attachmentType: item.documentType,
            key: item.s3Key,
            applicationId: item.applicationId,
            borrowingEntityId: item.borrowingEntityId,
            individualId: item.individualId,
            date: dayjs(convertDateFromUtcToLocal(unFormattedAttachmentDate(item.s3Key))).toDate(),
            uploadStatus: item.uploadStatus
          }))
        );
      } catch (error) {
        apiRoot.informationalMessageHelper.addErrorMessage(error);
        self.parentType = undefined;
        self.parentId = 0;
      } finally {
        self.isLoading = false;
      }
    }),
    removeAttachment(attachment: IAttachmentModel) {
      self.attachments.remove(attachment);
    },
    addAttachment(fileName: string, key: string, date: Date, attachmentType: AttachmentType, applicationId: number | undefined = undefined, attachmentId: number = -99) {
      // const createdBy = registry.getCurrentUser()?.email;
      const newAttachment = AttachmentModel.create({
        tempId: getNextInteger(),
        attachmentId: attachmentId,
        key: key,
        parentId: self.parentId,
        fileName: fileName,
        attachmentType: attachmentType,
        parentType: self.parentType ?? AttachmentParentType.Application,
        date: date,
        applicationId: self.parentType === AttachmentParentType.Application ? self.parentId : applicationId,
        createdBy: ""
      });

      self.attachments.replace([
        ...self.attachments.filter((x) => x.isUploaded()),
        newAttachment,
        ...self.attachments.filter((x) => !x.isUploaded())
      ]);
    },
    addEmpty(
      type: AttachmentType,
      addOnlyIfNotPresent = true,
      isRequired = false,
      currentApplicationOnly = false,
      applicationId: number | undefined = undefined
    ) {
      if (!isAlive(self) || !self.parentType || !self.parentId) {
        return;
      }
      const attachment = self.get(type, currentApplicationOnly);
      if ((!addOnlyIfNotPresent || !attachment) && self.getAll(type).filter((x) => !x.attachmentId).length === 0) {
        self.attachments.push({
          tempId: getNextInteger(),
          attachmentId: 0,
          parentId: self.parentId,
          parentType: self.parentType,
          fileName: "",
          key: "",
          attachmentType: type,
          isRequired: isRequired,
          applicationId: self.parentType === AttachmentParentType.Application ? self.parentId : applicationId
        });
      } else if (attachment) {
        attachment.isRequired = isRequired;
      }
    }
  }))
  .actions((self) => ({
    uploaded(fileName: string, key: string, attachmentId: number, attachmentType: AttachmentType, applicationId: number | undefined) {
      self.addAttachment(fileName, key, new Date(), attachmentType, applicationId, attachmentId);
    },
    deleted(attachment: Instance<typeof AttachmentModel>) {
      self.removeAttachment(attachment);
    }
  }));

export type IAttachmentsModel = Instance<typeof AttachmentsModel>;
