import {ICustomerApi} from "./ICustomerApi";
import { CustomerDto, CustomerEntityDto, CustomerIndividualDto } from "../dto/CustomerDto";
import {callAPI} from "./apiUtilities";
import {apiConfig} from "../config/api.config";
import {AcceptedConditionsRequest} from "../dto/AcceptedConditionsRequest";
import {DigitalRequest, UpdateDigitalPathRequest} from "../dto/DigitalRequest";
import {CustomerAbnSearchResponse} from "../dto/CustomerAbnSearchRequest";

export default class CustomerApi implements ICustomerApi {
    getCustomer(token: string): Promise<CustomerDto> {
        return callAPI(`${apiConfig.fmsCustomerPathUrl}/auth/applications?token=${token}`, {
            method: "GET"
        });
    }

    saveDigital(dto: DigitalRequest): Promise<string> {
        return callAPI<any>(`${apiConfig.fmsCustomerPathUrl}/digital`, {
            method: "POST",
            body: JSON.stringify(dto)
        })
          .then(x => x.payload);
    }

    createApplicationForDigital(dto: UpdateDigitalPathRequest): Promise<any> {
        return callAPI<any>(`${apiConfig.fmsCustomerPathUrl}/auth/digital`, {
           method: "PUT",
           body: JSON.stringify(dto)
        });
    }

    saveBorrowingEntity(dto: CustomerEntityDto): Promise<any> {
        return callAPI(`${apiConfig.fmsCustomerPathUrl}/auth/entities/${dto.id}`, {
            method: "PUT",
            body: JSON.stringify(dto)
        });
    }

    searchBorrowingEntity(searchTerm: string, getTradeAccountCount: boolean = false): Promise<string> {
        return callAPI<any>(`${apiConfig.fmsCustomerPathUrl}/auth/entities?textValue=${encodeURI(searchTerm)}&maxNumberOfResults=1&getTradeAccountCount=${getTradeAccountCount}`, {
            method: "GET"
        })
          .then(x => x.uniqueSearchId);
    }

    searchBorrowingEntityResults(uniqueSearchId: string): Promise<CustomerAbnSearchResponse> {
        return callAPI<any>(`${apiConfig.fmsCustomerPathUrl}/auth/entities`, {
            method: "POST",
            body: JSON.stringify({uniqueSearchId: uniqueSearchId})
        });
    }

    saveIndividual(dto: CustomerIndividualDto): Promise<any> {
        return callAPI(`${apiConfig.fmsCustomerPathUrl}/auth/individuals/${dto.id}`, {
            method: "PUT",
            body: JSON.stringify(dto)
        });
    }

    acceptTermsAndConditions(individualId: number, request?: AcceptedConditionsRequest): Promise<any> {
        return callAPI(`${apiConfig.fmsCustomerPathUrl}/auth/individuals/${individualId}/accepted`, {
            method: "PUT",
            body: JSON.stringify(request ?? {})
        });
    }

    acceptOnlineServicesTermsOfUse(individualId: number): Promise<any> {
        return callAPI(`${apiConfig.fmsCustomerPathUrl}/auth/individuals/${individualId}/acceptedOnlineServicesTermsOfUse`, {
            method: "PUT",
            body: JSON.stringify({})
        });
    }

    updateDirectorLeadApplicant(individualId: number, directorIndividualId: number): Promise<any> {
        return callAPI(`${apiConfig.fmsCustomerPathUrl}/auth/director`, {
            method: "PUT",
            body: JSON.stringify({
                individualId: individualId,
                directorIndividualId: directorIndividualId
            })
        });
    }
}
