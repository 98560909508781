import { AddressLookupInfo } from "../../models/AddressLookupInfo";
import React from "react";
import { observer } from "mobx-react-lite";
import AddressLookupComponent from "../common/AddressLookupComponent";
import {css} from "@emotion/css";
import {Icon} from "@nutrien/ddc-components";
import {useMst} from "../../models/Root";

interface Props {
    gridOptions?: any;
    className?: string;
    disabled?: boolean;
}

const PlacesLookupComponent = (props: Props) => {
    const {branches} = useMst();

    function handleAddressSelected(info: AddressLookupInfo) {
        branches.setCurrentPlace(info);
    }

    return <AddressLookupComponent type="search" disabled={props.disabled} types={["(regions)"]} controlId="postcodeOrSuburbField" className={props.className} addressSelected={handleAddressSelected}  gridOptions={props.gridOptions} fieldModel={branches.suburbOrPostcodeField} placeholder="Search by suburb or postcode" startAdornment={<Icon.SearchFeather className={css`margin-left: 0; margin-right: 8px;`}/>} />;

};

export default observer(PlacesLookupComponent);
