import { ButtonProps } from "@nutrien/ddc-components/dist/components/Button";
import { useTheme } from "@mui/material";
import { Button, Icon } from "@nutrien/ddc-components";
import React from "react";

interface ExpandAndCollapseButtonProps extends ButtonProps {
  isExpanded: boolean;
}

const ExpandAndCollapseButton = ({isExpanded, ...rest}: ExpandAndCollapseButtonProps) => {
  const theme = useTheme();

  return (
    <Button
      fullWidth={false}
      variant="text"
      color="primary"
      endIcon={isExpanded ? <Icon.ChevronUpFeather color={theme.palette.common.white} /> : <Icon.ChevronDownFeather color={theme.palette.common.white} />}
      {...rest}
    />
  )
}

export default ExpandAndCollapseButton;