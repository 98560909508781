import {Grid} from "@mui/material";
import {Body1} from "./common/Text";
import {observer} from "mobx-react-lite";
import React from "react";

const NoAccessComponent = () => {
  return (
    <>
      <Grid container direction={"column"} justifyContent={"center"} alignItems={"flex-start"}>
        <Grid item className={"center"}>
          <Body1>
            You do not have access to this page. Contact your branch if you have received this message in error.
          </Body1>
        </Grid>
      </Grid>
    </>
  )
}

export default observer(NoAccessComponent);