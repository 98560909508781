import { useEffect } from "react";
import { AddressLookupInfo } from "../models/AddressLookupInfo";
import {GOOGLE_API_KEY} from "../config/constants";

const loadScript = (url: string, callback: () => void) => {
  const existingScriptTag = document.getElementById("google-address-script") ?? document.getElementById("__googleMapsScriptId");
  if (existingScriptTag) {
    callback();
    return;
  }

  const script = document.createElement("script");
  script.type = "text/javascript";

  // @ts-ignore
  if (script.readyState) {
    // @ts-ignore
    script.onreadystatechange = function () {
      // @ts-ignore
      if (script.readyState === "loaded" || script.readyState === "complete") {
        // @ts-ignore
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  script.id = "google-address-script";
  document.getElementsByTagName("head")[0].appendChild(script);
};

const handleScriptLoad = (config: { types?: string[], addressElementId: string; handler: (info: AddressLookupInfo) => void }[]) => {
  config.forEach((configuration) => {
    let counter = 0;
    const temp = () => {
      const inputElement = document.getElementById(configuration.addressElementId) as HTMLInputElement;
      if (!inputElement || !window.google) {
        console.log(`no ${configuration.addressElementId}!`);
        if (counter++ < 10) {
          setTimeout(temp, 1000);
        }
        return;
      }

      console.log(`Found ${configuration.addressElementId}.  Configuring google maps.`);

      const autoComplete1 = new window.google.maps.places.Autocomplete(inputElement, {
        types: configuration.types ?? ["address"]
      });

      autoComplete1.setFields(["address_components", "geometry"]);
      autoComplete1.setComponentRestrictions({ country: ["au"] });
      autoComplete1.addListener("place_changed", () => {
        const place = autoComplete1.getPlace();
        const info = AddressLookupInfo.create(place);
        if (!info.streetNumber) {
          const value = inputElement.getAttribute("value") ?? "";
          const split = value.split(" ");
          if (split.length > 0 && parseInt(split[0]) > 0) {
            // first word or streetAddress is streetNumber
            info.streetNumber = split[0];
          }
        }
        configuration.handler(info);
      });
    };
    temp();
  });
};

export const useAddress = (props: { isOpen: boolean; configurations: { types?: string[], addressElementId: string; handler: (info: AddressLookupInfo) => void }[] }) => {
  useEffect(() => {
    if (props.isOpen) {
      loadScript(`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`, () =>
        handleScriptLoad(props.configurations)
      );
    }
  }, [props.isOpen]);  // eslint-disable-line react-hooks/exhaustive-deps
};
