import React, {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useAuth} from "../hooks/useAuth";
import {TokenStatus} from "../models/enums/TokenStatus";

const AcceptLink = () => {
    const auth = useAuth();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const token = new URLSearchParams(location.search).get("token");
        auth.setToken(token).then((tokenStatus: TokenStatus) => {
            switch (tokenStatus) {
                case TokenStatus.Valid:
                    navigate("/landing");
                    break;

                case TokenStatus.Closed:
                    navigate("/closed");
                    break;

                default:
                    navigate("/expired");
            }
        });
    });
    return <></>
};

export default AcceptLink;