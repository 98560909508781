import React, { useState } from "react";
import { Card, CardContent, Grid, useMediaQuery } from "@mui/material";
import {observer} from "mobx-react-lite";
import {css} from "@emotion/css";
import DisplayValue from "./DisplayValueComponent";
import {Instance} from "mobx-state-tree";
import {IndividualModel} from "../../models/IndividualModel";
import {simplePhoneNumberFormat} from "../editors/formatPhoneNumber";
import dayjs from "dayjs";
import { SM_MEDIA_QUERY, UI_DATE_FORMAT } from "../../config/constants";
import ExpandAndCollapseButton from "../common/ExpandAndCollapseButton";
import {MemberType} from "../../models/enums/MemberType";
import {useMst} from "../../models/Root";


const IndividualReviewCard = ({individual, isCollapsable = false}: { individual: Instance<typeof IndividualModel>, isCollapsable?: boolean }) => {
  const headingCss = {
    card: css`
      min-height: 292px;
      min-width: 350px;
    `,
    cardDirector: css`
      min-height: 292px;
      min-width: 350px;
    `,
    smallCard: css`
      min-height: 114px;
    `,
    heading: css`
      font-size: 16px !important;
      font-weight: 700;
      padding-bottom: 5px;
    `,
    expandButton: css`
      position: relative;
      top: -7px;
      
    `
  };

  const {customer} = useMst();

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const isSm = useMediaQuery(SM_MEDIA_QUERY);
  const showExpandCollapseOption = isCollapsable && (!individual.onlyBasicInfo || individual.memberType === MemberType.Director) && isSm;

  const showSmallCard = !customer.individual.isLeadApplicant() || (isCollapsable && isSm);
  const showAdvancedInfo = customer.individual.isLeadApplicant() && (!showExpandCollapseOption || isExpanded) && !individual.onlyBasicInfo;
  const showDob = customer.individual.isLeadApplicant() && (!showExpandCollapseOption || isExpanded) && (!individual.onlyBasicInfo || individual.memberType === MemberType.Director);
  const showOwnershipPercentage = customer.individual.isLeadApplicant() && (!showExpandCollapseOption || isExpanded) && individual.memberType === MemberType.Director;
  const cardCss = individual.memberType === MemberType.Director ? headingCss.cardDirector : headingCss.card;

  return <>
    {
      <>
        <Card variant={"elevation"} elevation={1} className={showSmallCard ? headingCss.smallCard : cardCss}>
          <CardContent>
            <Grid container direction={"row"} justifyContent={"space-between"}>
              <Grid item className={headingCss.heading}>
                {`${individual.firstName.value} ${individual.lastName.value}`}
              </Grid>
              {showExpandCollapseOption && (
                <Grid item className={headingCss.expandButton}>
                  <ExpandAndCollapseButton isExpanded={isExpanded} onClick={() => setIsExpanded(!isExpanded)}>{ isExpanded ? "View less" : "View more"}</ExpandAndCollapseButton>
                </Grid>
              )}
            </Grid>
            <Grid container direction={"column"}>
              <DisplayValue label={"Email:"} value={individual.email.value} mediumSpacing={12} asRow={true} />
              <DisplayValue label={"Phone Number:"} value={simplePhoneNumberFormat(individual.primaryPhone.value)}
                            mediumSpacing={12} asRow={true} />
              {showAdvancedInfo && (<>
                <DisplayValue label={"Address:"} value={individual.residentialAddress.getFormattedAddressLine1()} mediumSpacing={12} asRow={true} />
                <DisplayValue label={""} value={individual.residentialAddress.getFormattedAddressLine2()} mediumSpacing={12} asRow={true} style={{marginTop: "-12px"}} />
              </>)}

              {showDob && <DisplayValue label={"Date of Birth:"} value={dayjs(individual.dateOfBirth.value).format(UI_DATE_FORMAT)} mediumSpacing={12} asRow={true} />}
              {showAdvancedInfo && (
                  <>
                  {individual.showIdStateOrDriversCardNumber() && (
                    <>
                      <DisplayValue label={"Driver's Licence Number:"} value={individual.identificationNumber.value}
                                    mediumSpacing={12} asRow={true} />
                      <DisplayValue label={"Driver's Licence Card Number:"} value={individual.driversCardNumber.value}
                                    mediumSpacing={12} asRow={true} />
                    </>
                  )}
                  {!individual.showIdStateOrDriversCardNumber() && (
                    <DisplayValue label={`${individual.identificationNumberLabel()}:`} value={individual.identificationNumber.value}
                                  mediumSpacing={12} asRow={true} />
                  )}
                </>
              )}
              {showOwnershipPercentage && <DisplayValue label={"Ownership:"} value={individual.ownershipPercentage.value ? individual.ownershipPercentage.value + "%" : ""} mediumSpacing={12} asRow={true} />}
            </Grid>
          </CardContent>
        </Card>
      </>
    }
  </>
}

export default observer(IndividualReviewCard);