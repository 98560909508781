import {ValidationSeverity} from "../models/enums/ValidationSeverity";
import {IFieldValidationModel} from "../models/FieldValidationModel";
import {AsyncValidationFunction, ValidationFunction} from "./ValidationFunction";
import {IFieldModel} from "../models/IFieldModel";

export const verificationCodeValidation = (title: string) => isNumberValidation(title, true);

export const firstNameValidation = (title: string) => nameValidation(title, true);
export const middleNameValidation = (title: string) => nameValidation(title, false);
export const lastNameValidation = (title: string) => nameValidation(title, true);

export const requiredToSaveValidation = (title: string) => requiredValidationBuilder(ValidationSeverity.NoSave, title, false);

export function emptyValidation(): IFieldValidationModel {
  return { message: "", severity: ValidationSeverity.Valid };
}

export const requiredValidationBuilder = (severity: ValidationSeverity, fieldName: string, isPlural = false) => {
  return (value: string | Date | null): IFieldValidationModel => {
    return value === "" || value === null
      ? { message: `${fieldName} ${isPlural ? "are" : "is"} required`, severity }
      : { message: "", severity: ValidationSeverity.Valid };
  };
};

export const conditionalRequiredValidationBuilder = <T>(severity: ValidationSeverity, predicate: (model: T) => boolean, fieldName: string, isPlural = false) => {
  return (value: string | Date | null, model: T): IFieldValidationModel => {
    const b = predicate(model);
    return b && (value === "" || value === null)
      ? { message: `${fieldName} ${isPlural ? "are" : "is"} required`, severity }
      : { message: "", severity: ValidationSeverity.Valid };
  };
};

export const nameValidation = (title: string, isRequired: boolean) => (value: string) => {
  if(isRequired && !value) {
    return requiredValidationBuilder(ValidationSeverity.NoSave, title, false)(value);
  }

  if(!isRequired && (value === "" || value === null)) {
    return { message: "", severity: ValidationSeverity.Valid };
  }

  if(value.startsWith(' ', 0)) {
    return { message: "No leading spaces are allowed", severity: ValidationSeverity.NoSave };
  }

  return value.match("^[a-zA-Z][a-zA-Z' -]*$")
    ? { message: "", severity: ValidationSeverity.Valid }
    : { message: "No special characters or numbers are allowed", severity: ValidationSeverity.NoSave };
}

export const isNumberValidation = (title: string, isRequired: boolean) => (value: string) => {
  if(isRequired && !value) {
    return requiredValidationBuilder(ValidationSeverity.NoSave, title, false)(value);
  }

  return value.match(/\D/g)
    ? { message: "Please enter numbers only", severity: ValidationSeverity.NoSave }
    : { message: "", severity: ValidationSeverity.Valid };
}

export const regexValidationBuilder = (title: string, regex: RegExp) => (value: string) => {
  return value.match(regex)
    ? {
      message: "",
      severity: ValidationSeverity.Valid
    }
    : {
      message: `Enter a valid ${title}`,
      severity: ValidationSeverity.NoSave
    };
};

export const buildConditionalValidation = <TModel>(
  title: string,
  baseValidationBuilder: ValidationFunctionBuilder,
  conditionPredicate: (model: TModel) => boolean
) => {
  const baseValidation = baseValidationBuilder(title);
  return (value: string, model: TModel) => {
    if (conditionPredicate(model)) {
      return baseValidation(value, model);
    }
    return { severity: ValidationSeverity.Valid, message: "" };
  };
};

export const buildAsyncConditionalValidation = <TModel>(
  title: string,
  baseValidationBuilder: AsyncValidationFunctionBuilder,
  conditionPredicate: (model: TModel) => boolean
) => {
  const baseValidation = baseValidationBuilder(title);
  return async (value: string, model: TModel) => {
    if (conditionPredicate(model)) {
      return await baseValidation(value, model);
    }
    return { severity: ValidationSeverity.Valid, message: "" };
  };
};

export type ValidationFunctionBuilder = (title: string) => ValidationFunction<string>;
export type AsyncValidationFunctionBuilder = (title: string) => AsyncValidationFunction<string>;


export const percentageValidator = (title: string) => (value: string) => {
  const numericValue = +value;

  if (numericValue > 100 || numericValue < 0) {
    return {
      message: `Enter a valid ${title}`,
      severity: ValidationSeverity.NoSave
    };
  }

  return emptyValidation();
};

// the ownershipPercentage field is manually validated within OwnershipPercentageEditor...
// but the validation result would be overwritten if no validator is configured for the field.
// This validator just returns whatever validation result was already there to prevent it from getting overwritten.
export const manuallyValidated = (field: IFieldModel) => {
  return () => () => {
    return field.validationResult;
  };
};

