import { observer } from "mobx-react-lite";
import React from "react";
import { useMst } from "../../models/Root";
import { useTheme } from "@mui/material";
import { css } from "@emotion/css";
import { H3 } from "./Text";
import { MOBILE_BREAKPOINT } from "../../config/constants";

interface ApplicationBannerProps {
  label?: string | undefined;
}

const ApplicationBanner = (props: ApplicationBannerProps) => {
  const theme = useTheme();

  const { customer } = useMst();

  const bannerCss = {
    title: css`
      background-color: ${theme.palette.primary.main};
      min-height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
    `,
    heading: css`
      @media (max-width: ${MOBILE_BREAKPOINT}) {
        max-width: calc(100vw - 60px);
        font-size: 14px;
        line-height: 16.8px;
      }      
    `
  };

  return (
    <>
      <div className={bannerCss.title}>
        <H3 color={theme.palette.common.white} className={bannerCss.heading}>{props.label ? props.label : `Application for ${customer.borrowingEntity.legalName.value}`}</H3>
      </div>
    </>
  )
}

export default observer(ApplicationBanner);