import {observer} from "mobx-react-lite";
import {Grid, GridProps, useTheme} from "@mui/material";
import {useMst} from "../../models/Root";
import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import ApplicationContainer from "../common/ApplicationContainer";
import {ProgressStep} from "../ProgressBar/ProgressBarComponent";
import TextFieldWrapper from "../editors/TextFieldWrapper";
import AddressLookupComponent from "../common/AddressLookupComponent";
import Line from "../common/Line";
import {H3} from "../common/Text";
import ActionButton from "../common/ActionButton";
import { Icon } from "@nutrien/ddc-components";
import AutoCompleteWrapper from "../common/AutoCompleteWrapper";
import {toJS} from "mobx";
import SelectFieldWrapper from "../editors/SelectFieldWrapper";
import ActionBar from "../common/ActionBar";
import BackButton from "../common/BackButton";
import apiRoot from "../../helpers/apiRoot";
import CheckBoxFieldWrapper from "../editors/CheckBoxFieldWrapper";
import NextButton from "../common/NextButton";
import {hasDirectorId} from "../../helpers/urlHelpers";


const CompanyTrusteeDetails = () => {
  const ROW_GRID_OPTIONS: GridProps = { xs: 12, md: 12 };
  const ITEM_GRID_OPTIONS: GridProps = { xs: 12, md: 3 };
  const COMPANY_ITEM_GRID_OPTIONS: GridProps = { xs: 12, md: 4 };

  const { customer, states } = useMst();
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);

    Promise.all([
      states.load(),
      customer.load(sessionStorage.getItem("authorization") ?? "")
    ])
      .then(() => {
        customer.childEntity.isMailingAddressSameAsBusinessAddress.update(!customer.childEntity.businessAddress.isAddressDifferentFrom(customer.borrowingEntity.businessAddress));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [customer]);  // eslint-disable-line react-hooks/exhaustive-deps

  const HeadingEntry = (heading: string) => {
    return (
      <Grid item {...ROW_GRID_OPTIONS}>
        <H3>{heading}</H3>
      </Grid>
    );
  }

  const LineEntry = () => {
    return (
      <Grid item {...ROW_GRID_OPTIONS}>
        <Line />
      </Grid>
    )
  }

  const BackButtonClicked = () => {
    customer.childEntity.cancelChanges();
    navigate("/trusteeType");
  }

  const SaveCompanyClicked = async () => {
    try {
      setIsSaving(true);
      let moveToMembers = customer.childEntityPersisted;
      if(!moveToMembers) {
        moveToMembers = await customer.addCompanyTrustee();
      }
      await customer.childEntity.save(true);

      if(hasDirectorId()) {
        const directorId = new URLSearchParams(location.search).get("directorId");
        if(directorId) {
          await apiRoot.customerApi.updateDirectorLeadApplicant(customer.individual.id, +directorId);
          await customer.reload();
        }
      }

      if(moveToMembers) {
        navigate("/members");
      } else {
        apiRoot.informationalMessageHelper.addErrorMessage("Company Trustee could not be saved");
      }

    } finally {
      setIsSaving(false);
    }
  }

  const CreateNextButton = () => {
    return (customer.childEntityPersisted ? <NextButton onClick={() => SaveCompanyClicked()} >Next: Member Details</NextButton> : <ActionButton
      startIcon={<Icon.PlusFeather fontSize={"small"} color={theme.palette.common.white} />}
      onClick={() => SaveCompanyClicked()}>Add as a Trustee</ActionButton>);
  }

  const copyAddressToCompanyTrustee = () => {
    if(customer.childEntity.isMailingAddressSameAsBusinessAddress.value) {
      customer.childEntity.businessAddress.copyFrom(customer.borrowingEntity.businessAddress);
    }
  }

  return (
    <>
      <ApplicationContainer isLoading={isLoading} isSaving={isSaving} currentStep={ProgressStep.MemberDetailStep}>
        <Grid container direction={"row"} spacing={1}>
          {HeadingEntry("Company Trustee Details")}
          <TextFieldWrapper fieldModel={customer.childEntity.legalName} disabled gridOptions={COMPANY_ITEM_GRID_OPTIONS} />
          <TextFieldWrapper fieldModel={customer.childEntity.abn} disabled gridOptions={COMPANY_ITEM_GRID_OPTIONS} />
          <TextFieldWrapper fieldModel={customer.childEntity.acn} disabled gridOptions={COMPANY_ITEM_GRID_OPTIONS} />
          <Grid item {...ITEM_GRID_OPTIONS} />

          <LineEntry />

          {HeadingEntry("Company Trustee Address")}
          <CheckBoxFieldWrapper fieldModel={customer.childEntity.isMailingAddressSameAsBusinessAddress}
                                action={() => copyAddressToCompanyTrustee()} />
          {!customer.childEntity.isMailingAddressSameAsBusinessAddress.value && (
            <>
              <AddressLookupComponent
                controlId={"companyTrusteeAddress"}
                fieldModel={customer.childEntity.businessAddress.streetAddress}
                addressSelected={customer.childEntity.businessAddress.addressSelected}
                gridOptions={ITEM_GRID_OPTIONS}
              />
              <AutoCompleteWrapper fieldModel={customer.childEntity.businessAddress.city} list={toJS(customer.childEntity?.businessAddress.cityPostcodes.cities)} gridOptions={ITEM_GRID_OPTIONS} />
              <SelectFieldWrapper fieldModel={customer.childEntity.businessAddress.state} options={states.selectFieldOptions()} gridOptions={ITEM_GRID_OPTIONS} />
              <AutoCompleteWrapper fieldModel={customer.childEntity.businessAddress.postCode} list={toJS(customer.childEntity?.businessAddress.cityPostcodes.postcodes)} gridOptions={ITEM_GRID_OPTIONS} />
            </>
          )}
        </Grid>
      </ApplicationContainer>

      <ActionBar
        isLoading={isLoading}
        leftComponents={!customer.childEntityPersisted ? <BackButton onClick={() => BackButtonClicked()} /> : undefined}
        rightComponents={CreateNextButton()}
      />
    </>
  )
}

export default observer(CompanyTrusteeDetails);