import {observer} from "mobx-react-lite";
import React from "react";
import styled from "@emotion/styled";
import {useMst} from "../../models/Root";
import BranchResult from "./BranchResult";
import {BranchModel} from "../../models/BranchesModel";
import {Instance} from "mobx-state-tree";
import {ThemeProps} from "../Account";
import { useTheme } from "@mui/material";
import _ from "lodash";
import {useNavigate} from "react-router-dom";

const Container = styled.div<ThemeProps>`
  @media (min-width: 961px) {
    width: calc(100vw - 64px);
    margin: 16px;
    max-width: 350px;
    max-height: 432px;
    position: fixed;
    top: 240px;
    z-index: 10;
  }
`;

const BranchResults = (props: {take?: number}) => {
    const {branches} = useMst();
    const theme = useTheme();
    const navigate = useNavigate();


    function handleOnClick(branch: Instance<typeof BranchModel>) {
        if (branch?.id !== branches.selectedBranch?.id) {
            branches.setSelectedBranch(branch.id);
            navigate("/yourBranch");
        } else {
            branches.setSelectedBranch(0);
        }
    }

    function getClosestBranches() {
        return props.take ? _.take(branches.filteredBranches, props.take) : branches.closestBranches;
    }

    return <Container theme={theme}>
        {getClosestBranches().map(branch => <BranchResult key={"branch_" + branch.id} branch={branch} isSelected={branches.selectedBranch?.id === branch.id} onClick={() => handleOnClick(branch)}/>)}
    </Container>;
};

export default observer(BranchResults);