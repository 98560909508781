import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from "react";
import ApplicationContainer from "../common/ApplicationContainer";
import {Body1, H3} from "../common/Text";
import {useMst} from "../../models/Root";
import {Grid, Link, useTheme} from "@mui/material";
import TextFieldWrapper from "../editors/TextFieldWrapper";
import CheckBoxFieldWrapper from "../editors/CheckBoxFieldWrapper";
import {Icon} from "@nutrien/ddc-components";
import {css} from "@emotion/css";
import ActionBar from "../common/ActionBar";
import ActionButton from "../common/ActionButton";
import {formatPhoneNumber, getMaxLength} from "../editors/formatPhoneNumber";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";

const AboutYou = () => {
  const auth = useAuth();

  const theme = useTheme();
  const navigate = useNavigate();
  const { customer, brand } = useMst();

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const aboutYouCss = {
    container: css`
      margin: auto;
      width: 330px;
    `,
    openPdfInNewTab: css`
      position: relative;
      left: 4px;
      top: 4px;
    `
  }

  useEffect(() => {
    customer.initialize();
    customer.individual.initialize(true);
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  const ApplyNow = async () => {
    try {
      setIsSaving(true);

      const [canSave, customerToken] = await customer.saveDigital(brand.companyCode);
      if(canSave) {
        await auth.setToken(customerToken);

        navigate("/customerVerification");
      }
    } finally {
      setIsSaving(false);
    }
  }

  return (
      <>
        {brand.digitalPathEnabled && (
          <>
            <ApplicationContainer isSaving={isSaving} label={"Start your trade account application"}>
              <div className={aboutYouCss.container}>
                <H3>About You</H3>
                <Grid container direction={"column"}>
                  <TextFieldWrapper fieldModel={customer.individual.firstName} />
                  <TextFieldWrapper fieldModel={customer.individual.lastName} />
                  <TextFieldWrapper fieldModel={customer.individual.email} />
                  <TextFieldWrapper fieldModel={customer.individual.primaryPhone} formatter={formatPhoneNumber} maxLength={getMaxLength(customer.individual.primaryPhone.value)}/>
                </Grid>
                <Grid container direction={"row"} alignItems={"center"} spacing={1} wrap={"nowrap"}>
                  <Grid item><CheckBoxFieldWrapper fieldModel={customer.isOnlineServicesTermsOfUseAccepted} /></Grid>
                  <Grid item>
                    <Body1>
                      <>
                        I hereby acknowledge I have read and understood the <Link href="https://www.nutrienagsolutions.com.au/terms-and-conditions-privacy-policy" target="_blank" color={theme.palette.info.dark}>Online Services Terms of Use<Icon.ExternalLinkFeather color={theme.palette.info.dark} className={aboutYouCss.openPdfInNewTab} /></Link>
                      </>
                    </Body1>
                  </Grid>
                </Grid>
              </div>
            </ApplicationContainer>

            <ActionBar
                rightComponents={<ActionButton disabled={!customer.isOnlineServicesTermsOfUseAccepted.value || isSaving} onClick={async () => await ApplyNow()}>Apply Now</ActionButton>}
            />
          </>
        )}
      </>
  )
}

export default observer(AboutYou);