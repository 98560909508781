import { SystemMessageSeverity } from "../models/enums/SystemMessageSeverity";
import { rootStore } from "../models/Root";

export interface IInformationalMessageHelper {
  addSuccessMessage: (message: string, autoClear?: boolean) => void;
  addWarnMessage: (message: string, autoClear?: boolean) => void;
  addErrorMessage: (message: any, autoClear?: boolean) => void;
}

export const informationalMessageHelper: IInformationalMessageHelper = {
  addSuccessMessage: (message: string, autoClear = true) => {
    rootStore.systemMessages.addMessage(message, SystemMessageSeverity.Success, autoClear);
  },

  addWarnMessage: (message: string, autoClear = true) => {
    rootStore.systemMessages.addMessage(message, SystemMessageSeverity.Warning, autoClear);
  },

  addErrorMessage: (message: any, autoClear = true) => {
    let messageAsString = "";
    if (typeof message === "string") {
      messageAsString = message as string;
    }
    if (message.errorMessage && typeof message.errorMessage === "string") {
      messageAsString = message.errorMessage as string;
    }
    if (!messageAsString) {
      messageAsString = message.toString();
    }

    rootStore.systemMessages.addMessage(messageAsString, SystemMessageSeverity.Error, autoClear);
  }
};
