import {Dialog, DialogContent, Grid, Typography, useTheme} from "@mui/material";
import {css} from "@emotion/css";
import React from "react";
import {observer} from "mobx-react-lite";
import {Icon, IconButton} from "@nutrien/ddc-components";

interface SimpleDialogProps {
    show: boolean;
    onClose: () => void;
    title?: string;
    detail?: string;
    showCloseWindow?: boolean;
}

const SimpleDialog = (props: SimpleDialogProps) => {
    const theme = useTheme();

    const SimpleDialogCss = {
        partnerText: css`
            font-family: "Open Sans";
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: ${theme.palette.text.secondary}
        `,
        banner: css`
            font-family: "Open Sans";
            font-weight: 300;
            font-size: 24px;
            line-height: 24px;
        `,
        container: css`
            text-align: center;
            min-height: 267px;
        `,
        close: css`
            position: absolute !important;
            top: 0;
            right: 0;
        `
    }

    return (
        <>
            <Dialog
                open={props.show}
                onClose={() => props.onClose()}
                maxWidth={"sm"}
            >
                <DialogContent>
                    {props.showCloseWindow && (
                      <Grid container direction={"row"} justifyContent={"flex-end"}>
                          <Grid item>
                              <IconButton size="small" aria-label="close" onClick={() => props.onClose()}>
                                  <Icon.XFeather color={theme.palette.common.black} />
                              </IconButton>
                          </Grid>
                      </Grid>
                    )}
                    <Grid container direction={"column"} spacing={5} alignContent={"center"} justifyContent="center" className={SimpleDialogCss.container}>
                        {props.title && (
                            <Grid item>
                                <Typography variant={"inherit"} color={theme.palette.primary.main} className={SimpleDialogCss.banner}>{props.title}</Typography>
                            </Grid>)}
                        {props.detail && (
                            <Grid item>
                                <Typography variant={"inherit"} className={SimpleDialogCss.partnerText}>{props.detail}</Typography>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default observer(SimpleDialog);