import React, {CSSProperties} from "react";
import {Grid, Typography} from "@mui/material";
import {observer} from "mobx-react-lite";
import {css} from "@emotion/css";

interface Props {
  label: string;
  value: string;
  mediumSpacing?: number;
  asRow?: boolean;
  style?: CSSProperties;
}

const DisplayValue = ({mediumSpacing = 3, asRow = false, ...props}: Props) => {

  const displayCss = {
    label: css`
      line-height: 20px;
      font-size: 14px !important;
      font-weight: 600;
      vertical-align: middle;
      padding: 5px;
    `,
    value: css`
      line-height: 20px;
      font-size: 16px !important;
      font-weight: 400;
      vertical-align: middle;
      padding: 5px;
    `,
  };

  return <>
    {
      <>
        <Grid container item xs={12} md={mediumSpacing} direction={asRow ? "row" : "column"} justifyContent="space-between" style={props.style}>
          <Grid item>
            <Typography variant="subtitle2" className={displayCss.label}>
              {props.label}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" className={displayCss.value}>
              {props.value !== "" ? props.value : "--"}
            </Typography>
          </Grid>
        </Grid>

      </>
    }
  </>
}

export default observer(DisplayValue);