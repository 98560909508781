import dayjs from "dayjs";

class ApiError extends Error {
    constructor(message: string, status: number | null) {
        super(message);
        this.status = status;
    }

    status: number | null;
}

function log(message: any, ...optionalParams: any[]) {
    if (typeof message === "string") {
        console.log(`${dayjs().format("YYYY-MM-DD HH:mm:ss")} - ${message}`, optionalParams);
    } else {
        log("");
        console.log(message); // log complex object on new line so it can be inspected in the console
    }
}

function isPromise(error: any) {
    return Boolean(error?.then);
}

export async function callAPI<T>(
    endpoint: string,
    init: any
): Promise<T> {
    try {
        log(endpoint, init);

        let authToken = sessionStorage.getItem("authorizationToken");

        if (init !== undefined) {
            init.headers = { ...init.headers, Authorization: authToken};
        } else {
            init = {
                headers: {
                    Authorization: authToken,
                },
            };
        }

        const response = await fetch(endpoint, init);

        const data = await response.json();
        log(endpoint, data);
        if (data.success || data.successful || response.ok) {
            return data;
        }
        throw new ApiError(data.errorMessage, response.status);
    } catch (error) {
        log(endpoint, error);

        if (isPromise(error)) {
            const result: any = await error;
            log(endpoint, result);

            if ("errorMessage" in result) {
                throw new Error(result.errorMessage);
            }
        }

        throw error;
    }
}
