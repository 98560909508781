import { useTheme } from "@mui/material";
import React from "react";
import { css } from "@emotion/css";

interface LineProps {
  color?: string
}

const Line = (props: LineProps) => {
  const theme = useTheme();

  const lineCss = css`
    height: 1px;
    border: none;
  `

  return (
    <hr color={props.color ?? theme.palette.primary.main} className={lineCss} />
  )
}

export default Line;