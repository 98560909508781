import { observer } from "mobx-react-lite"
import React from "react";
import { H4 } from "../common/Text";
import { Dialog, DialogContent, Grid, useMediaQuery, useTheme } from "@mui/material";
import { SM_MEDIA_QUERY } from "../../config/constants";
import ActionButton from "../common/ActionButton";
import DeleteButton from "../common/DeleteButton";
import { useMst } from "../../models/Root";

interface ConfirmRemoveMemberModalProps {
  show: boolean;
  onClose: () => void;
  setIsSaving: (isSaving: boolean) => void;
}

const ConfirmRemoveMemberModal = (props: ConfirmRemoveMemberModalProps) => {
  const { members } = useMst();

  const theme = useTheme();

  const isSm = useMediaQuery(SM_MEDIA_QUERY);

  const deleteButtonClicked = async () => {
    props.setIsSaving(true);
    props.onClose();
    try {
      await members.removeCurrentMember();
    } finally {
      props.setIsSaving(false);
    }
  }

  return (
    <>
      <Dialog
        open={props.show}
        fullWidth={!isSm}
        fullScreen={isSm}
        maxWidth={"sm"}
      >
        <DialogContent>
          {members.currentMember && (
            <Grid container direction={"column"} justifyContent={"center"} alignItems={"center"} spacing={3}>
              <Grid item>
                <H4 color={theme.palette.primary.main} className={"center"}>Are you sure you want to delete {members.currentMember.firstName.value} {members.currentMember.lastName.value}?</H4>
              </Grid>
              <Grid item>
                <Grid container direction={"row"} justifyContent={"space-between"} alignItems={"center"} spacing={2}>
                  <Grid item>
                    <ActionButton variant={"text"} onClick={() => props.onClose()}>Cancel</ActionButton>
                  </Grid>
                  <Grid item>
                    <DeleteButton onClick={deleteButtonClicked}>Yes</DeleteButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default observer(ConfirmRemoveMemberModal);