import {applySnapshot, cast, flow, Instance, types} from "mobx-state-tree";
import * as _ from "lodash";
import {AnzsicCodeDto} from "../dto/AnzsicCodeDto";
import apiRoot from "../helpers/apiRoot";

export const AnzsicCode = types
    .model({
        code: types.string,
        description: types.string,
        productTypeName: types.string
    })
    .views((self) => ({
        getDisplayName() {
            return `${self.code} - ${self.description}`;
        }
    }));

export const AnzsicCodes = types
    .model({
        values: types.array(AnzsicCode),
        loading: types.maybe(types.boolean)
    })
    .views((self) => ({
        find(code: string | null | undefined): {code: string, description: string} {
            const defaultValue = {code: "", description: ""};
            if (!code) {
                return defaultValue;
            }
            return _.find(self.values, (x) => x.code === code) ?? defaultValue;
        },
        selectFieldOptions() : {value: string, label: string}[] {
            return self.values.map(x => ({value: x.code, label: x.description}));
        }
    }))
    .actions((self) => ({
        load: flow(function* load() {
            if (self.values.length === 0) {
                self.loading = true;
                console.log(apiRoot);
                const values = yield apiRoot.lookupApi.getAnzsicCodes();
                applySnapshot(self.values, _.sortBy(values, (x) => x.description) as AnzsicCodeDto[])
                self.loading = false;
            }
            return self.values;
        })
    }));


export const defaultAnzsicCodesModel = {
    values: cast([]),
    loading: false
} as Instance<typeof AnzsicCodes>;