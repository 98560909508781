import {css, styled} from "@mui/material";
import {Props} from "@nutrien/ddc-components/dist/components/TextField/TextField.model";

export const Label = styled('label', {
  shouldForwardProp: prop => prop !== 'inlineLabel' && prop !== 'error',
})<Omit<Props, 'id' | 'error' | 'options' | 'onChange'> & { error: boolean }>(
    ({ theme, error, disabled, inlineLabel }) => css`
    font-family: 'Open Sans',sans-serif;
    font-size: 12px;
    margin-bottom: 4px;
    display: block;
    font-weight: 600;
    color: ${theme.palette.mode === 'dark'
        ? theme.palette.common.white
        : theme.palette.text.primary};
    ${inlineLabel
        ? css`
          flex-direction: row;
          gap: 10px;
          margin-top: 12px;
        `
        : null}
    ${error
        ? css`
          color: ${theme.palette.error.main};
        `
        : null}
    ${disabled
        ? css`
          color: ${theme.palette.action.disabled};
        `
        : null}
  `,
);