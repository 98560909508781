import React from "react";
import {AttachmentModel} from "../../models/AttachmentModel";
import {Instance} from "mobx-state-tree";
import {useCallback, useState} from "react";
import {
  downloadAttachment as downloadAttachmentHelper,
  isFileAllowedType,
  isFileNameInvalid, isFileValidSize
} from "../../helpers/attachmentHelpers";
import apiRoot from "../../helpers/apiRoot";
import {INVALID_FILE_NAME, MAX_ATTACHMENT_FILE_SIZE} from "../../config/constants";
import {useMst} from "../../models/Root";
import { Icon } from "@nutrien/ddc-components";
import {Grid, Typography, useTheme} from "@mui/material";
import ProgressIndicator from "../common/ProgressIndicator";
import clsx from "clsx";
import {makeStyles} from "tss-react/mui";
import {useDropzone} from "react-dropzone";

const useStyles = makeStyles()(
  (theme) => ({
    dragging: {
      background: `${theme.palette.warning.light} !important`,
      border: `1px solid ${theme.palette.primary.dark} !important`
    },
    dragAndDrop: {
      margin: "8px",
      alignContent: "center",
      height: "74px",
      background: `${theme.palette.background.paper2} !important`,
      border: `1px dashed ${theme.palette.primary.main}`,
      borderRadius: "4px",
      cursor: "pointer",
      "& .line-one": {
        color: theme.palette.primary.dark,
        textDecoration: "underline"
      }
    },
    dragAndDropError: {
      border: `1px dashed ${theme.palette.error.main} !important`,
    },
    dragAndDropVertical: {
      width: "calc(100% - 16px) !important"
    },
    uploadIcon: {
      marginRight: "8px"
    },
    loadingContainerLarge: {
      margin: "8px",
      height: "74px",
      border: "1px",
      position: "relative"
    },
    loadingContainerSmall: {
      margin: "8px",
      marginTop: "12px",
      height: "44px",
      border: "1px",
      position: "relative"
    },
    fileName: {
      marginTop: "8px",
      marginBottom: "12px",
      maxWidth: "100%",
      overflowWrap: "anywhere"
    },
    fileNameRow: {
      paddingTop: "14px",
      marginLeft: "8px"
    },
    attachmentOverflow: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "100%"
    },
    hasError: {
      color: `${theme.palette.error.main} !important`
    }
  })
);

interface Props {
  attachment?: Instance<typeof AttachmentModel>;
  canEdit: boolean;
  canDelete: boolean;
  isRequired: boolean;
  isRecommended?: boolean;
  showLineTwo?: boolean;
  className?: string;
  fileNameClassName?: string;
  isHorizontal?: boolean;
  disable?: boolean;
  hasError?: boolean;
  setHasError?: (hasError: boolean) => void;
  uploadText?: string;
}

const Attachment = ( props: Props) => {
  const theme = useTheme();
  const { classes } = useStyles();

  const { attachmentsModel } = useMst();

  const [isDragging, setIsDragging] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  function logValidationError(error: string) {
    if (!props.attachment) {
      return;
    }
    props.attachment.error(error);
  }

  async function uploadAttachment(file: File) {
    if (!props.attachment) {
      return;
    }
    try {
      setIsLoading(true);

      props.attachment.error();
      const response = await props.attachment.getAttachmentUploadUrl(file.name);
      if (response.preSignedUrl && response.success) {
        const url = new URL(response.preSignedUrl);
        const uploadResponse = await fetch(response.preSignedUrl, { method: "PUT", body: file });
        if (uploadResponse.status >= 200 && uploadResponse.status < 300) {
          if(props.setHasError) {
            props.setHasError(false);
          }
          attachmentsModel.uploaded(file.name, decodeURIComponent(url.pathname.substring(1)), response.attachmentId, props.attachment.attachmentType, props.attachment.applicationId);
        } else {
          throw Error("error uploading file: " + uploadResponse.statusText);
        }
      } else {
        throw Error("error getting pre-signed URL: " + response.preSignedUrl);
      }
    } catch (error) {
      apiRoot.informationalMessageHelper.addErrorMessage(`There was an error uploading the attachment ${file.name}`);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  const onDrop = useCallback((acceptedFiles: any) => {
    setIsDragging(0);

    if (!isFileValidSize(acceptedFiles[0])) {
      logValidationError(`attachments must be less than ${MAX_ATTACHMENT_FILE_SIZE / 1024 / 1024}MB`);
    } else if (!isFileAllowedType(acceptedFiles[0])) {
      logValidationError(`${acceptedFiles[0].name.substring(acceptedFiles[0].name.lastIndexOf("."))} files cannot be attached`);
    } else if (isFileNameInvalid(acceptedFiles[0])) {
      apiRoot.informationalMessageHelper.addErrorMessage(`${INVALID_FILE_NAME}`, false);
    } else {
      acceptedFiles.forEach(uploadAttachment);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  async function removeAttachment() {
    if (!props.attachment) {
      return;
    }
    try {
      setIsLoading(true);
      props.attachment.error();
      const response = await props.attachment.getAttachmentDeleteUrl();
      if (response.preSignedUrl && response.success) {
        const deleteResponse = await fetch(response.preSignedUrl, { method: "DELETE" });
        if (deleteResponse.status >= 200 && deleteResponse.status < 300) {
          attachmentsModel.deleted(props.attachment);
        } else {
          throw Error("error deleting attachment: " + deleteResponse.statusText);
        }
      } else {
        throw Error("error getting pre-signed URL: " + response.preSignedUrl);
      }
    } catch (error) {
      apiRoot.informationalMessageHelper.addErrorMessage(`There was an error removing the attachment ${props.attachment.fileName}}`);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function downloadAttachment() {
    if (!props.attachment) {
      return;
    }
    await downloadAttachmentHelper(props.attachment, setIsLoading, (e) => {
      apiRoot.informationalMessageHelper.addErrorMessage(`There was an error downloading the attachment ${props!.attachment!.fileName} - ${e.message ?? e.toString()}`);
    });
  }

  return (
    <>
      {isLoading && (
        <div className={props?.attachment?.fileName ? classes.loadingContainerSmall : classes.loadingContainerLarge}>
          <ProgressIndicator />
        </div>
      )}

      {!isLoading && props?.attachment?.fileName && (
        <Grid container direction="row" justifyContent="space-between" alignItems="center" className={clsx(props.className, classes.fileNameRow)}>
          <Grid item className={clsx(props.fileNameClassName)}>
            <div className={classes.attachmentOverflow}>
              <Typography
                id={`${props?.attachment?.tempId}_download`}
                variant="body2"
                color="primary"
                className={clsx(classes.fileName, "underlined", "clickable")}
                onClick={() => downloadAttachment()}
                noWrap
              >
                <Icon.FileTextFeather stroke={theme.palette.primary.main} />
                <span className="axp-fms-attachment-name-text">{props?.attachment.fileName}</span>
              </Typography>
            </div>
          </Grid>
          <Grid item>
            {props.canDelete && (
              <Icon.Trash2Feather
                className={"clickable"}
                stroke={theme.palette.error.main}
                onClick={() => removeAttachment()}
                id={`${props.attachment.tempId}_delete`}
              />
            )}
          </Grid>
        </Grid>
      )}

      {!isLoading && !props?.attachment?.fileName && (
        <>
          <Grid
            container
            justifyContent="center"
            direction="column"
            alignItems="center"
            {...getRootProps()}
            onDragEnter={() => setIsDragging((counter) => counter + 1)}
            onDragLeave={() => setIsDragging((counter) => counter - 1)}
            className={clsx(
              { [classes.dragging]: isDragging > 0 },
              classes.dragAndDrop,
              { [classes.dragAndDropVertical]: !props.isHorizontal },
              props.hasError ? classes.dragAndDropError : undefined,
              props.className
            )}
          >
            <input {...getInputProps()} id={`attachment_upload_input_${props?.attachment?.tempId}`} />
            <Grid item container justifyContent="center" direction="row" alignItems="center" className="line-one">
              <Grid item>
                <Icon.UploadFeather fontSize="small" className={classes.uploadIcon} stroke={props.hasError ? theme.palette.error.main : theme.palette.primary.main}/>
              </Grid>
              <Grid item id={`attachment${props?.attachment?.tempId}_upload_link_parent`}>
                <Typography variant="body2" color="inherit" id={`attachment${props?.attachment?.tempId}_upload_link`} className={props.hasError ? classes.hasError : undefined}>
                  {props.uploadText ?? "Upload Document"}
                </Typography>
              </Grid>
            </Grid>
            <Grid item className="line-two">
              <Typography variant="caption" color="textPrimary">
                or drag and drop here
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default Attachment;
