import React from "react";
import styled from "@emotion/styled";
import {useTheme} from "@mui/material";
import {ThemeProps} from "../Account";

const Container = styled.div<ThemeProps>`
  width: calc(100vw - 64px);
  padding: 40px 0 0 31px;
  margin: 0;
  max-width: 350px;
  position: fixed;
  left: 0;
  top: 127px;
  z-index: 9;
  background: ${props => props.theme.palette.background.paper};
  height: calc(100vh - 127px);
`;

const LeftPanel = () => {
    const theme = useTheme();
    return <Container theme={theme}/>
};

export default LeftPanel;
