import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Grid, GridProps } from "@mui/material";
import ApplicationContainer from "./common/ApplicationContainer";
import { useMst } from "../models/Root";
import { H3 } from "./common/Text";
import Line from "./common/Line";
import ActionBar from "./common/ActionBar";
import NextButton from "./common/NextButton";
import TextFieldWrapper from "./editors/TextFieldWrapper";
import {useNavigate} from "react-router-dom";
import SelectFieldWrapper from "./editors/SelectFieldWrapper";
import { toSelectFieldOptions } from "../helpers/selectFieldHelpers";
import { AllIdentificationTypes } from "../models/enums/IdentificationType";
import {formatPhoneNumber, getMaxLength} from "./editors/formatPhoneNumber";
import DatePickerWrapper from "./editors/DatePickerWrapper";
import ActionButton from "./common/ActionButton";
import { isEditFrom } from "../helpers/urlHelpers";
import CancelButton from "./common/CancelButton";
import AddressLookupComponent from "./common/AddressLookupComponent";
import AutoCompleteWrapper from "./common/AutoCompleteWrapper";
import {toJS} from "mobx";
import {ProgressStep} from "./ProgressBar/ProgressBarComponent";
import DriversLicenceNumberEditor from "./editors/DriversLicenceNumberEditor";
import CheckBoxFieldWrapper from "./editors/CheckBoxFieldWrapper";
import OwnershipPercentageEditor from "./editors/OwnershipPercentageEditor";
import NoAccessComponent from "./NoAccessComponent";

const EntityDetails = () => {
  const ROW_GRID_OPTIONS: GridProps = { xs: 12, md: 12 };
  const ITEM_GRID_OPTIONS: GridProps = { xs: 12, md: 3 };

  const { customer, states } = useMst();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);

    Promise.all([
      states.load(),
      customer.load(sessionStorage.getItem("authorization") ?? "")
    ])
      .then(() => {
        customer.individual.isResidentialAddressSameAsBusinessAddress.update(!customer.individual.residentialAddress.isAddressDifferentFrom(customer.borrowingEntity.businessAddress));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [customer]);  // eslint-disable-line react-hooks/exhaustive-deps

  const HeadingEntry = (heading: string) => {
    return (
      <Grid item {...ROW_GRID_OPTIONS}>
        <H3>{heading}</H3>
      </Grid>
    );
  }

  const LineEntry = () => {
    return (
      <Grid item {...ROW_GRID_OPTIONS}>
        <Line />
      </Grid>
    )
  }

  const CancelButtonClicked = () => {
    customer.individual.cancelChanges();
    navigate(-1);
  }

  const CoApplicantSave = async () => {
    try {
      setIsSaving(true);
      if(await customer.coApplicantSave()) {
        if(isEditFrom()) {
          navigate(-1);
        }
        navigate("/terms");
      }
    } finally {
      setIsSaving(false);
    }
  }

  const copyBusinessAddressToResidential = () => {
    if(customer.individual.isResidentialAddressSameAsBusinessAddress.value) {
      customer.individual.residentialAddress.copyFrom(customer.borrowingEntity.businessAddress);
    }
  }

  return (
    <>
      <ApplicationContainer isLoading={isLoading} isSaving={isSaving} currentStep={ProgressStep.MemberDetailStep}>
        {customer.individual.isLeadApplicant() && (
          <NoAccessComponent/>
        )}
        {customer.individual.isCoApplicant() && (
          <>
        <Grid container direction={"row"} spacing={1}>
          {HeadingEntry("Contact Details")}
          <TextFieldWrapper fieldModel={customer.individual.firstName} gridOptions={ITEM_GRID_OPTIONS} />
          <TextFieldWrapper fieldModel={customer.individual.middleName} gridOptions={ITEM_GRID_OPTIONS} />
          <TextFieldWrapper fieldModel={customer.individual.lastName} gridOptions={ITEM_GRID_OPTIONS} />
          <DatePickerWrapper fieldModel={customer.individual.dateOfBirth} gridOptions={ITEM_GRID_OPTIONS} />
          <TextFieldWrapper fieldModel={customer.individual.email} gridOptions={ITEM_GRID_OPTIONS} disabled={true}/>
          <TextFieldWrapper fieldModel={customer.individual.primaryPhone} formatter={formatPhoneNumber} gridOptions={ITEM_GRID_OPTIONS}  maxLength={getMaxLength(customer.individual.primaryPhone.value)} />
          <OwnershipPercentageEditor individual={customer.individual} currentUserIsLeadApplicant={false} gridOptions={ITEM_GRID_OPTIONS}/>

          <LineEntry />

          {HeadingEntry("Residential Address")}
          <CheckBoxFieldWrapper fieldModel={customer.individual.isResidentialAddressSameAsBusinessAddress}
                                action={() => copyBusinessAddressToResidential()}
          />
          {!customer.individual.isResidentialAddressSameAsBusinessAddress.value && (
            <>
              <AddressLookupComponent
                  controlId={"residentialAddress"}
                  fieldModel={customer.individual.residentialAddress.streetAddress}
                  addressSelected={customer.individual.residentialAddress.addressSelected}
                  gridOptions={ITEM_GRID_OPTIONS}
              />
              <AutoCompleteWrapper fieldModel={customer.individual.residentialAddress.city} list={toJS(customer.individual.residentialAddress.cityPostcodes.cities)} gridOptions={ITEM_GRID_OPTIONS} />
              <SelectFieldWrapper fieldModel={customer.individual.residentialAddress.state} options={states.selectFieldOptions()} gridOptions={ITEM_GRID_OPTIONS} />
              <AutoCompleteWrapper fieldModel={customer.individual.residentialAddress.postCode} list={toJS(customer.individual.residentialAddress.cityPostcodes.postcodes)} gridOptions={ITEM_GRID_OPTIONS} />
            </>
          )}
          <LineEntry />

          {HeadingEntry("Identification Details")}
          <SelectFieldWrapper fieldModel={customer.individual.identificationType} options={toSelectFieldOptions(AllIdentificationTypes)} gridOptions={ITEM_GRID_OPTIONS} />
          {customer.individual.showIdStateOrDriversCardNumber() && (
            <SelectFieldWrapper fieldModel={customer.individual.idState} options={states.selectFieldOptions()} gridOptions={ITEM_GRID_OPTIONS} />
          )}
          <TextFieldWrapper fieldModel={customer.individual.identificationNumber} label={customer.individual.identificationNumberLabel()} gridOptions={ITEM_GRID_OPTIONS} />
          <DriversLicenceNumberEditor individual={customer.individual} gridOptions={ITEM_GRID_OPTIONS} />

        </Grid>
          </>
        )}
      </ApplicationContainer>
      {customer.individual.isCoApplicant() && (
      <ActionBar
        isLoading={isLoading}
        leftComponents={isEditFrom() ? <CancelButton onClick={() => CancelButtonClicked()} /> : undefined}
        rightComponents={isEditFrom() ? <ActionButton onClick={async () => await CoApplicantSave()} disabled={isSaving}>Save</ActionButton>: <NextButton onClick={async () => await CoApplicantSave()} disabled={isSaving}>Next: Terms & Conditions</NextButton>}
      />
      )}
    </>
  )
}

export default observer(EntityDetails);