import React from "react";
import { Card, CardContent, Grid } from "@mui/material";
import {observer} from "mobx-react-lite";
import {css} from "@emotion/css";
import DisplayValue from "./DisplayValueComponent";
import {Instance} from "mobx-state-tree";
import { BorrowingEntityModel } from "../../models/BorrowingEntityModel";


const EntityReviewCard = ({entity}: { entity: Instance<typeof BorrowingEntityModel> }) => {
  const headingCss = {
    card: css`
      min-height: 258px;
      min-width: 350px;
    `,
    heading: css`
      font-size: 16px !important;
      font-weight: 700;
      padding-bottom: 5px;
    `
  };

  return <>
    {
      <>
        <Card variant={"elevation"} elevation={1} className={headingCss.card}>
          <CardContent>
            <Grid container direction={"row"} justifyContent={"space-between"}>
              <Grid item className={headingCss.heading}>
                {entity.legalName.value}
              </Grid>
            </Grid>
            <Grid container direction={"column"}>
              <DisplayValue label={"ABN:"} value={entity.abn.value} mediumSpacing={12} asRow={true} />
              {entity.acn.value && (
                <DisplayValue label={"ACN:"} value={entity.acn.value} mediumSpacing={12} asRow={true} />
              )}
              <DisplayValue label={"Address:"} value={entity.businessAddress.getFormattedAddressLine1()} mediumSpacing={12} asRow={true} />
              <DisplayValue label={""} value={entity.businessAddress.getFormattedAddressLine2()} mediumSpacing={12} asRow={true} style={{marginTop: "-12px"}} />
            </Grid>
          </CardContent>
        </Card>
      </>
    }
  </>
}

export default observer(EntityReviewCard);