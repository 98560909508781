import { ValidationSeverity } from "../models/enums/ValidationSeverity";
import { IIndividualModel } from "../models/IndividualModel";
import { requiredValidationBuilder } from "./validationFunctions";

export const emailAddressValidation = (title: string) => (value: string, model: IIndividualModel) => {
  if (
    !value ||
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    )
  ) {
    return requiredValidationBuilder(ValidationSeverity.NoSave, title, false)(value);
  }

  return {
    message: "Enter a valid email address",
    severity: ValidationSeverity.NoSave
  };
}