import { flow, getParent, types } from "mobx-state-tree";
import { defaultValidationResult, FieldValidationModel } from "./FieldValidationModel";
import { ValidationFunction } from "../validation/ValidationFunction";
import { ValidationSeverity } from "./enums/ValidationSeverity";
import { reValidateFields } from "./shared";
import { IFieldModel } from "./IFieldModel";

const FieldModel = types
  .model({
    title: types.string,
    value: types.string,
    initialValue: types.string,
    reValidateFields: types.array(types.string),
    id: types.string,
    validationResult: FieldValidationModel
  })
  .actions((self) => ({
    setValidationResult(result: any) {
      self.validationResult = result;
    }
  }))
  .actions((self) => {
    let validation: ValidationFunction<string> | null = null;

    async function validate() {
      try {
        self.setValidationResult(validation ? await validation(self.value, getParent(self)) : { severity: ValidationSeverity.Valid, message: "" });
      } catch (error) {
        console.log(error);
        console.log(self.title);
      }
    }

    async function initialize(
      title: string,
      validationFunction: ValidationFunction<string> | null = null,
      id?: string,
      fieldsToRevalidate = [],
      defaultValue = ""
    ) {
      self.value = defaultValue;
      self.title = title;
      self.id = id ?? title;
      self.reValidateFields.replace(fieldsToRevalidate);
      validation = validationFunction;
      // await validate();  Customer Path - should not validate off the initialization
    }

    return {
      initialize,
      validate,
      async revert() {
        self.value = self.initialValue;
        await validate();
      },
      trim() {
        self.value = self.value?.trim();
      }
    };
  })
  .views((self) => ({
    isDirty() {
      return self.initialValue !== self.value;
    },
    getFormattedValue() {
      return self.value;
    }
  }))
  .actions((self) => ({
    updateOnly(newValue: number | string | null | undefined, isInitialValue = false) {
      self.value = newValue ? newValue.toString() : "";
      if (isInitialValue) {
        self.initialValue = self.value;
      }
    }
  }))
  .actions((self) => ({
    update: flow(function* update(newValue: number | string | null | undefined, isInitialValue = false) {
      self.updateOnly(newValue, isInitialValue);

      if(!isInitialValue) {
        yield self.validate();
        yield reValidateFields(self);
      }
    })
  }));

export default FieldModel;

export const defaultFieldModel: IFieldModel = {
  title: "",
  validationResult: defaultValidationResult,
  value: "",
  initialValue: "",
  defaultValue: "",
  id: "",
  reValidateFields: []
};