import { ButtonProps } from "@nutrien/ddc-components/dist/components/Button";
import { useTheme } from "@mui/material";
import { Button, Icon } from "@nutrien/ddc-components";
import React from "react";

const EditLinkButton = (props: ButtonProps) => {
  const theme = useTheme();

  return (
    <Button
      {...props}
      fullWidth={false}
      variant="text"
      color="error"
      startIcon={<Icon.Trash2Feather color={theme.palette.common.white} />}
    >
      {props.children ? props.children : "Remove"}
    </Button>
  )
}

export default EditLinkButton;