import React, {useState} from "react";
import {BorrowingEntityType} from "../../models/enums/BorrowingEntityType";
import {css} from "@emotion/css";
import {Dialog, DialogActions, DialogContent, Grid, Typography, useTheme} from "@mui/material";
import {observer} from "mobx-react-lite";
import {useMst} from "../../models/Root";
import TextFieldWrapper from "../editors/TextFieldWrapper";
import {Button, Icon} from "@nutrien/ddc-components";
import {H5, Body1} from "../common/Text";
import ActionButton from "../common/ActionButton";
import EntitySearchResultCard from "./EntitySearchResultCard";
import DirectorValidationDialog from "./DirectorValidationDialog";


interface Props {
  isOpen: boolean;
  onClose: () => void;
  entityType: BorrowingEntityType;
}

const SearchEntityDialog = (props: Props) => {
  const theme = useTheme();

  const searchEntityDialogCss = {
    searchContainer: css`
      width: 368px;
      margin: auto;
    `,
    searchIcon: css`
      margin-right: 8px;
    `,
    noSearchResultsHeadingText: css`
      margin-bottom: 0px;      
    `,
    dialogActionContainer: css`
      border-top: solid 1px #E0E0E0;
      padding-top: 8px;
    `
  }

  const {customer, borrowingEntitySearch} = useMst();
  const [isDirectorValidationDialogOpen, setIsDirectorValidationDialogOpen] = useState<boolean>(false);

  const hasBorrowingEntity = () => {
    return !borrowingEntitySearch.isSearching && borrowingEntitySearch.borrowingEntitySearchResult ;
  }

  const isEmptyBorrowingEntity = () => {
    return !borrowingEntitySearch.isSearching && borrowingEntitySearch.isNotFound;
  }
  const isCompany = () => {

    return  !borrowingEntitySearch.isSearching && borrowingEntitySearch.borrowingEntitySearchResult ? borrowingEntitySearch.borrowingEntitySearchResult.type === BorrowingEntityType.Company : false;
  }
  const nextClicked = async () => {
    const borrowingEntity = borrowingEntitySearch.borrowingEntitySearchResult.getDto();
    borrowingEntitySearch.reset();

    await customer.setChildEntity(borrowingEntity);

    await borrowingEntitySearch.pullIndividuals(customer?.childEntity?.id);
    setIsDirectorValidationDialogOpen(true)

  }
  return (
    <>
      <Dialog
        open={props.isOpen}
        maxWidth={"md"}
      >
        <DialogContent>
          <div className={searchEntityDialogCss.searchContainer}>
            <Grid container direction={"column"} spacing={1}>
              <Grid item>
                <Typography variant={"inherit"} color={theme.palette.primary.main}>Enter the organisation's ABN or ACN</Typography>
              </Grid>
              <Grid item>
                <TextFieldWrapper
                  label={"ABN"}
                  fieldModel={borrowingEntitySearch.searchTerm}
                  startAdornment={<Icon.SearchFeather className={searchEntityDialogCss.searchIcon}/>}
                  placeholder={"Type the ABN or ACN"}
                />
              </Grid>
              <Grid item>
                <Button
                  id={"searchButton"}
                  disabled={borrowingEntitySearch.searchTerm.value === "" || borrowingEntitySearch.isSearching}
                  color="primary"
                  variant="contained"
                  isLoading={borrowingEntitySearch.isSearching}
                  onClick={() => {
                    borrowingEntitySearch.search();
                  }}
                >
                  Search
                </Button>
              </Grid>
              {isEmptyBorrowingEntity()  && (
                <Grid item>
                  <H5 className={searchEntityDialogCss.noSearchResultsHeadingText}>
                    {`No Results for " ${borrowingEntitySearch.searchedTerm} "`}
                  </H5>
                  <Body1>
                    Try another search
                  </Body1>
                </Grid>
              )}
              {!isCompany() && hasBorrowingEntity() && (
                  <Grid item>
                    <H5 className={searchEntityDialogCss.noSearchResultsHeadingText}>
                      {`The organisation that belongs to this ABN/ACN is not for company. Please enter ABN/ACN for a Company Trustee.`}
                    </H5>
                    <Body1>
                      Try another search
                    </Body1>
                  </Grid>

              )}
              {borrowingEntitySearch.hasError && (
                  <Grid item>
                    <Body1 className={searchEntityDialogCss.noSearchResultsHeadingText} color={theme.palette.error.main}>
                      {`Equifax is currently unavailable. Please try again in 15 minutes or contact your Branch for assistance.`}
                    </Body1>
                  </Grid>

              )}
              {hasBorrowingEntity() && isCompany() && (
                <Grid item>
                  <H5>
                    {`Results for " ${borrowingEntitySearch.searchedTerm} "`}
                  </H5>
                </Grid>
              )}
            </Grid>
          </div>
          {hasBorrowingEntity()  && isCompany() && (
            <Grid item>
              <EntitySearchResultCard result={borrowingEntitySearch.borrowingEntitySearchResult} isSecondEntity />
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Grid container direction={"row"} justifyContent={"space-between"} className={searchEntityDialogCss.dialogActionContainer}>
            <Grid item>
              <ActionButton variant={"outlined"} onClick={() => props.onClose()}>Cancel</ActionButton>
            </Grid>
            {hasBorrowingEntity() && isCompany() && (
              <Grid item>
                <ActionButton
                  disabled={borrowingEntitySearch.borrowingEntitySearchResult.isCancelled()}
                  onClick={() => nextClicked()}>Next</ActionButton>
              </Grid>
            )}
          </Grid>
        </DialogActions>
      </Dialog>
      <DirectorValidationDialog isOpen = {isDirectorValidationDialogOpen} onClose = {() => setIsDirectorValidationDialogOpen(false)}/>
    </>
  );
};

export default observer(SearchEntityDialog);
