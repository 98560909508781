import React from "react";
import {Grid, Typography} from "@mui/material";
import {observer} from "mobx-react-lite";
import DisplayValue from "./DisplayValueComponent";
import {css} from "@emotion/css";
import {useMst} from "../../models/Root";
import {useLocation, useNavigate} from "react-router-dom";
import {getEntityDetailsUrl} from "../../helpers/urlHelpers";
import {BorrowingEntityType} from "../../models/enums/BorrowingEntityType";
import {simplePhoneNumberFormat} from "../editors/formatPhoneNumber";
import {ApplicationSource} from "../../models/AccountModel";
import {formatCurrency} from "../../helpers/numberHelpers";
import EditLinkButton from "../common/EditLinkButton";

interface BusinessDetailsReviewProps {
  canEdit: boolean;
}

const BusinessDetailsReview = (props: BusinessDetailsReviewProps) => {
  const headingCss = {
    heading: css`
      font-size: 20px !important;
      font-weight: 700;
      padding-top: 16px;
      padding-bottom: 16px;
    `,
    value: css`
      line-height: 20px;
      font-size: 16px !important;
      font-weight: 400;
      vertical-align: middle;
      padding: 5px;
    `,
    tip: css`
      line-height: 10px;
      font-size: 10px !important;
      font-weight: 400;
      vertical-align: middle;
    `,
    label: css`
      line-height: 20px;
      font-size: 14px !important;
      font-weight: 600;
      vertical-align: middle;
      padding: 5px;
    `,
  };

  const containerCss = css`
    padding-bottom: 40px;
  `;

  const {customer, anzsicCodes} = useMst();
  const navigate = useNavigate();
  const location = useLocation();

  const onEditClick = () => {
    navigate(getEntityDetailsUrl(true), {state: {from: location }});
  }
  
  return <>
    {
      <>
        <Grid container direction="row" alignContent={"flex-start"} justifyContent={"space-between"}>
          <Grid item className="text">
            <Typography variant="h3" color="textSecondary" className={headingCss.heading}>
              Business Details
            </Typography>
          </Grid>
          <Grid item>
            {props.canEdit && (
              <EditLinkButton id="editButton" showEndIcon={true} onClick={() => onEditClick()}>{"Edit"}</EditLinkButton>
            )}
          </Grid>
        </Grid>
        <Grid container className={containerCss}>
          {customer.borrowingEntity.type !== BorrowingEntityType.HobbyFarm && (
              <DisplayValue label={"ABN"} value={customer.borrowingEntity.abn.value} mediumSpacing={2}/>
          )}
          {customer.borrowingEntity.displayAcn() && (

          <DisplayValue label={"ACN"} value={customer.borrowingEntity.acn.value} mediumSpacing={2}/>
          )}

          <DisplayValue label={"Name"} value={customer.borrowingEntity.legalName.value}/>

          <DisplayValue label={"Trading Name"} value={customer.borrowingEntity.tradingName.value}/>
        </Grid>
        {customer.source === ApplicationSource.Digital && (
          <Grid container className={containerCss}>
            <DisplayValue label={"Anticipated Monthly Spend"} value={formatCurrency(customer.account.monthlySpend.value)}
                          mediumSpacing={5} />
            <DisplayValue label={"Industry"} value={anzsicCodes.find(customer.account.anzsicCode.value).description} mediumSpacing={5}/>
          </Grid>
        )}
        <Grid container className={containerCss}>
          <DisplayValue label={"Business Address"}
                        value={customer.borrowingEntity.businessAddress.getFormattedAddress()} mediumSpacing={5}/>
          <DisplayValue label={"Mailing Address"}
                        value={customer.borrowingEntity.mailingAddress.getFormattedAddress()} mediumSpacing={5}/>
        </Grid>
        <Grid container className={containerCss}>
          <DisplayValue label={"Primary Contact"} value={`${customer.borrowingEntity.contactFirstName.value} ${customer.borrowingEntity.contactLastName.value}`}  mediumSpacing={2} />
          <DisplayValue label={"Primary Contact Phone Number"} value={simplePhoneNumberFormat(customer.borrowingEntity.primaryPhone.value)}
                        mediumSpacing={3} />
          <DisplayValue label={"Primary Contact Email"} value={customer.borrowingEntity.email.value}  mediumSpacing={3} />
        </Grid>
      </>
    }
  </>
}

export default observer(BusinessDetailsReview);