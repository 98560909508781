import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {Body1, H3} from "./common/Text";
import {useMst} from "../models/Root";
import {MemberType} from "../models/enums/MemberType";
import {AttachmentParentType, AttachmentType} from "../models/enums/AttachmentType";
import {Grid, Link, Typography, useTheme} from "@mui/material";
import {IAttachmentModel} from "../models/AttachmentModel";
import Attachment from "./Attachment/Attachment";
import ProgressIndicator from "./common/ProgressIndicator";
import {css} from "@emotion/css";
import {Icon} from "@nutrien/ddc-components";
import {downloadUnsignedPersonalGuaranteeDocument} from "../helpers/attachmentHelpers";

const PersonalGuarantee = (props: {showError: boolean}) => {
    const { customer, attachmentsModel } = useMst();
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const theme = useTheme();

    useEffect(() => {
        setHasError(props.showError && customer.individual.memberType === MemberType.Director && attachmentsModel.isNotAttached(AttachmentType.PersonalGuarantee))
    }, [props.showError, attachmentsModel.attachments.length]);  // eslint-disable-line react-hooks/exhaustive-deps

    const personalGuaranteeCss = {
        openPdfInNewTab: css`
          position: relative;
          left: 4px;
          top: 4px;
        `,

        container: css`
          max-width: 444px;      
        `,
        stepHeader: css`
          font-family: Open Sans,sans-serif;;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 120%;      
        `,
        infoIcon: css`
          vertical-align: bottom;
          margin-left: 8px;
        `
    };

    useEffect(() => {
        customer.load(sessionStorage.getItem("authorization") ?? "")
            .then(() => {
                attachmentsModel.load(customer.individual.id, AttachmentParentType.Individual).then(() => {
                    console.log("got attachments!");
                    attachmentsModel.addEmpty(AttachmentType.PersonalGuarantee, false, true, true, customer.applicationId);
                });
            });
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps

    return <>
        {
          customer.requiresPersonalGuarantee() && (<>
                <H3>Personal Guarantee</H3>
                <Grid container direction={"row"} justifyContent="center" alignItems={"center"} spacing={1}>
                    {hasError && (
                        <Grid item xs={12}>
                            <Typography variant="inherit" className={personalGuaranteeCss.stepHeader} color={theme.palette.error.main}>
                                A signed and witnessed personal guarantee is required to proceed.  If needed, please contact the Branch for assistance.
                            </Typography>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Body1>
                            As a director you are required to provide a personal guarantee for this Account.  Personal Guarantees must be executed with a physical and witnessed signature. As part of your application you will be required to print and upload a signed guarantee document.
                        </Body1>
                    </Grid>
                    <Grid item xs={12}>
                        <Body1>
                            First <Link className="clickable" onClick={() => downloadUnsignedPersonalGuaranteeDocument(setIsBusy)} color={theme.palette.info.dark}>view and download the Personal Guarantee here<Icon.ExternalLinkFeather color={theme.palette.info.dark} className={personalGuaranteeCss.openPdfInNewTab}/></Link> so you can sign and get it witnessed. Then you can upload it below.
                        </Body1>
                    </Grid>
                    <Grid item xs={12}>
                        {attachmentsModel.get(AttachmentType.PersonalGuarantee) && (
                            <>
                                {attachmentsModel.getAll(AttachmentType.PersonalGuarantee).map((attachment: IAttachmentModel) => (
                                    <Attachment
                                        className={css`max-width: 400px`}
                                        key={"attachment-" + attachment.fileName}
                                        attachment={attachment}
                                        isRequired
                                        canEdit={true}
                                        canDelete={true}
                                        hasError={hasError}
                                        setHasError={setHasError}
                                        uploadText="Upload signed Personal Guarantee"
                                    />
                                ))}
                            </>
                        )}
                        {(!attachmentsModel.get(AttachmentType.PersonalGuarantee) || isBusy) && (
                            <div style={{ position: "relative", width: "100%", height: "85px", margin: 0, padding: 0 }}>
                                <ProgressIndicator />
                            </div>
                        )}
                    </Grid>
                </Grid>

            </>)
        }
    </>;
};

export default observer(PersonalGuarantee)