import {useCallback, useEffect, useState} from "react";

const BranchMarker = (options: google.maps.MarkerOptions & {isSelected: boolean, branchName: string, branchId: number, onSelect: ((branchId: number) => void)}) => {
    const [marker, setMarker] = useState<google.maps.Marker>();

    const getOpts = useCallback(() => {
        return {
            ...options,
            clickable: true,
            icon: options.isSelected ? {
                path: google.maps.SymbolPath.CIRCLE,
                scale: 10,
                fillColor: "#00F",
                fillOpacity: 0.8,
                strokeWeight: 1
            } : undefined
        };
    }, [options]);


    useEffect(() => {
        if (!marker) {
            let marker1 = new google.maps.Marker(getOpts());

            marker1.addListener("click", () => {
                console.log("clicked!");
                options.onSelect(options.branchId);
            });
            setMarker(marker1);
        }

        // remove marker from map on unmount
        return () => {
            if (marker) {
                marker.setMap(null);
            }
        };
    }, [marker, options, getOpts]);

    useEffect(() => {
        if (marker) {
            marker.setOptions(getOpts());
        }
    }, [marker, options, getOpts]);

    return null;
}

export default BranchMarker;