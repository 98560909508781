import { observer } from "mobx-react-lite";
import React from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { SM_MEDIA_QUERY } from "../../config/constants";
import {Icon} from "@nutrien/ddc-components";
import { css } from "@emotion/css";
import {H5} from "../common/Text";
import { ReactComponent as CompanyIcon } from "../assets/CompanyIcon.svg";

interface AddPartnerModalProps {
  show: boolean;
  onClose: () => void;
  onIndividualClick: () => void;
  onEntityClick: () => void;
}

const AddPartnerModal = (props: AddPartnerModalProps) => {
  const theme = useTheme();

  const isSm = useMediaQuery(SM_MEDIA_QUERY);

  const AddPartnerModalCss = {
    textPartnerType: css`
      font-family: "Open Sans";
      font-weight: 700;
      font-size: 20px;
      line-height: 20px;
      width: 145px;
  `,
    textPartnerSmType: css`
      font-family: "Open Sans";
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      width: 113px;
    `,
    individualIcon: css`
      width: 74px;
      height: 74px;
      padding-top: 35px;
    `,
    companyIcon: css`
      width: 74px;
      height: 74px;
      padding-top: 15px;
    `,
    containerBox: css`
      width: 207px;
    `,
    smContainerBox: css`
      width: 150px;
    `,
    myPartnerSmText: css`
      font-family: "Open Sans";
      font-weight: 300 !important;
      font-size: 16px !important;
      line-height: 19.2px !important;
    `

  }

  const showButton = (isIndividual: boolean) => {
    return (<div
      style={{
        height: "261px",
        boxShadow: "0px 9px 12px rgba(0, 0, 0, 0.035), 0px 3px 16px rgba(0, 0, 0, 0.03), 0px 5px 6px rgba(0, 0, 0, 0.05)",
        cursor: "pointer",
        background: theme.palette.background.paper
      }}
      className={isSm ? AddPartnerModalCss.smContainerBox : AddPartnerModalCss.containerBox}
      onClick={() => {isIndividual ? props.onIndividualClick() : props.onEntityClick();}}
    >
      <Grid container direction="row" justifyContent="space-evenly" alignContent="center">
        <Grid item style={{ textAlign: "center" }}>
          <H5 color="theme.palette.text.secondary" className={isSm ? AddPartnerModalCss.myPartnerSmText : undefined}>
            {isIndividual ? "My Partner is an" : "My Partner is a"}
          </H5>
          {isIndividual && (
            <>
              <Typography variant="inherit" color="primary" className={isSm ? AddPartnerModalCss.textPartnerSmType : AddPartnerModalCss.textPartnerType}>
                {"INDIVIDUAL"}
              </Typography>
              <Icon.UserFeather stroke={theme.palette.primary.main} className={AddPartnerModalCss.individualIcon} />
            </>
          )}
          {!isIndividual && (
            <>
              <Typography variant="inherit" color="primary" className={isSm ? AddPartnerModalCss.textPartnerSmType : AddPartnerModalCss.textPartnerType}>
                {"COMPANY OR TRUST"}
              </Typography>
              <CompanyIcon stroke={theme.palette.primary.main} className={AddPartnerModalCss.companyIcon} />
            </>
          )}
        </Grid>
      </Grid>
    </div>);
  }


  return (
    <>
      <Dialog
        open={props.show}
        onClose={() => props.onClose()}
        maxWidth={"md"}
      >
        <DialogContent>
          <Grid container direction={"row"} spacing={5}>
            <Grid item>
              {showButton(true)}
            </Grid>
            <Grid item>
              {showButton(false)}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default observer(AddPartnerModal);