import { ButtonProps } from "@nutrien/ddc-components/dist/components/Button";
import { Button, Icon } from "@nutrien/ddc-components";
import React from "react";
import {STANDARD_ACTION_COLOR} from "../../config/constants";

export interface EditLinkButtonProps extends ButtonProps {
  showStartIcon?: boolean;
  showEndIcon?: boolean;
}

const EditLinkButton = ({showStartIcon = false, showEndIcon = false, ...rest}: EditLinkButtonProps) => {
  return (
    <Button
      {...rest}
      fullWidth={false}
      variant="text"
      style={{color: STANDARD_ACTION_COLOR}}
      startIcon={showStartIcon ? <Icon.Edit2Feather stroke={STANDARD_ACTION_COLOR} /> : undefined}
      endIcon={showEndIcon ? <Icon.Edit2Feather stroke={STANDARD_ACTION_COLOR} /> : undefined}
    >
      {rest.children ? rest.children : "Edit"}
    </Button>
  )
}

export default EditLinkButton;