import { types } from "mobx-state-tree";
import { ValidationSeverity } from "./enums/ValidationSeverity";

export interface IFieldValidationModel {
  message: string;
  severity: ValidationSeverity;
}

export const FieldValidationModel = types.model({
  message: types.string,
  severity: types.enumeration<ValidationSeverity>([ValidationSeverity.Valid, ValidationSeverity.NoSubmit, ValidationSeverity.NoSave])
}).views(self => ({
  isValid() {
    return self.severity === ValidationSeverity.Valid;
  }
}));

export const defaultValidationResult: IFieldValidationModel = {
  message: "",
  severity: ValidationSeverity.Valid
};