import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from "react";
import {useMst} from "../models/Root";
import ApplicationContainer from "./common/ApplicationContainer";
import {Body1, H3} from "./common/Text";
import ActionBar from "./common/ActionBar";
import NextButton from "./common/NextButton";
import BackButton from "./common/BackButton";
import {useNavigate} from "react-router-dom";
import CheckBoxFieldWrapper from "./editors/CheckBoxFieldWrapper";
import {Grid, Link, useTheme} from "@mui/material";
import {Icon} from "@nutrien/ddc-components";
import {css} from "@emotion/css";
import {ApplicantType} from "../models/enums/ApplicantType";
import {ProgressStep} from "./ProgressBar/ProgressBarComponent";
import {BorrowingEntityType} from "../models/enums/BorrowingEntityType";
import {AllHobbyFarmSections} from "../models/enums/HobbyFarmSection";
import {DisplayError} from "./Account";
import {ValidationSeverity} from "../models/enums/ValidationSeverity";
import {AcceptedConditionsRequest} from "../dto/AcceptedConditionsRequest";
import PersonalGuarantee from "./PersonalGuarantee";
import {AttachmentType} from "../models/enums/AttachmentType";

const TermsAndConditions = () => {
  const { customer, hobbyFarmConditions, attachmentsModel } = useMst();
  const theme = useTheme();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const termsAndConditionsCss = {
    openPdfInNewTab: css`
      position: relative;
      left: 4px;
      top: 4px;
    `,
    notQuotingABNContainer: css`
      padding: 0 50px 0 50px;
    `,
    notQuotingABNDescription: css`
      padding-left: 50px;
    `
  }

  const loadHobbyFarmConditions = async () => {
    if(customer.borrowingEntity.type === BorrowingEntityType.HobbyFarm) {
      await hobbyFarmConditions.load();
    }
  }

  useEffect(() => {
    setIsLoading(true);

    customer.load(sessionStorage.getItem("authorization") ?? "")
        .then(() => {
          loadHobbyFarmConditions()
              .finally(() => setIsLoading(false))
        });
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  const canNotSave = () => {
    return isSaving ||
        !customer.isTocAccepted.value ||
        (customer.borrowingEntity.type === BorrowingEntityType.HobbyFarm && customer.individual.isLeadApplicant() && !hobbyFarmConditions.isValid);
  }

  const SaveAccept = async () => {
    try {
      setShowErrors(true);

      if (canNotSave()) {
        setErrorMessage("You must accept the terms & conditions");
        return;
      } else {
        setErrorMessage("");
      }

      if (customer.requiresPersonalGuarantee() && attachmentsModel.isNotAttached(AttachmentType.PersonalGuarantee)) {
        return;
      }

      setIsSaving(true);

      const requestPayload = {
        hobbyFarmConditionIds: customer.borrowingEntity.type === BorrowingEntityType.HobbyFarm ? hobbyFarmConditions.selectedConditionIds() : undefined
      } as AcceptedConditionsRequest;

      await customer.acceptTermsAndConditions(requestPayload);

      navigate("/customerComplete");
    } finally {
      setIsSaving(false);
    }
  }

  return (
    <>
      <ApplicationContainer isLoading={isLoading} isSaving={isSaving} currentStep={customer.individual.isLeadApplicant() ? ProgressStep.AcceptStep : ProgressStep.ReviewStep}>
        <H3>Terms & Conditions</H3>

        <Grid container direction={"row"} alignItems={"center"} spacing={1}>
          <Grid item>
            {errorMessage && (
                <Grid item xs={12} md={12} className={css`margin: 0 0 -20px 12px !important;`}>
                  <DisplayError error={{message: errorMessage, severity: ValidationSeverity.NoSave}}/>
                </Grid>
            )}
            <Grid container direction={"row"} alignItems={"center"} spacing={1} wrap={"nowrap"}>
              <Grid item><CheckBoxFieldWrapper fieldModel={customer.isTocAccepted} /></Grid>
              <Grid item>
                <Body1>
                  <>
                    I hereby acknowledge I have read and understood the Trade Account <Link target={"_blank"} href={"https://www.nutrienagsolutions.com.au/terms-and-conditions-privacy-policy"} color={theme.palette.info.dark}>Terms and Conditions<Icon.ExternalLinkFeather color={theme.palette.info.dark} className={termsAndConditionsCss.openPdfInNewTab}/></Link>
                  </>
                </Body1>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <PersonalGuarantee showError={showErrors}/>
        {customer.individual.isLeadApplicant() && customer.borrowingEntity.type === BorrowingEntityType.HobbyFarm && (
            <>
              <div className={termsAndConditionsCss.notQuotingABNContainer}>
                <Body1>
                  There may be circumstances where you are a supplier of goods and/or services to Nutrien for GST purposes. If you do not have an ABN you must provide reasons for not quoting an ABN. In these circumstances, unless you have a legitimate reason why you are not quoting/you do not have an ABN, we may be required under tax laws to withhold an amount on account of tax from your payments. Please check the reasons that apply to you.
                </Body1>
              </div>
              <div className={termsAndConditionsCss.notQuotingABNContainer}>
                <Body1>
                  Under pay as you go legislation and guidelines administered by the Tax Office, I declare that I am not quoting an ABN for the current and future supply of goods or services for the following reasons:
                </Body1>
                {AllHobbyFarmSections.map(section => (
                    <>
                      {hobbyFarmConditions.conditions.filter(x => x.section.toUpperCase() === section).map(condition => (
                          <Grid key={condition.id} container direction={"row"} alignItems={"center"} spacing={1} wrap={"nowrap"}>
                            <Grid item><CheckBoxFieldWrapper fieldModel={condition.isSelected} action={() => hobbyFarmConditions.checkIfValid()} /></Grid>
                            <Grid item>
                              <Body1>
                                {condition.description}
                              </Body1>
                            </Grid>
                          </Grid>
                      ))}
                      <br/>
                    </>
                ))}
              </div>
            </>
        )}
      </ApplicationContainer>

      <ActionBar
        isLoading={isLoading}
        leftComponents={<BackButton onClick={() => navigate(customer.individual.applicantType === ApplicantType.LeadApplicant ? "/review" : "/individual")} disabled={isSaving} />}
        rightComponents={<NextButton disabled={isSaving} onClick={async () => await SaveAccept()}>I Accept</NextButton>}
      />
    </>
  );
}

export default observer(TermsAndConditions);