import {createTheme} from "@mui/material";

export const davidsonCameronTheme = createTheme({
  palette: {
    common: {
      black: "#000000",
      white: "#ffffff"
    },
    primary: {
      light: "#E1F5E4",
      main: "#002D62",
      dark: "#002D62",
      contrastText: "#ffffff"
    },
    secondary: {
      light: "#DCFAF2",
      main: "#6CD9C7",
      dark: "#277E7E",
      contrastText: "#000000"
    },
    tertiary: {
      light: "#E6F2FA",
      main: "#598DB2",
      dark: "#406580",
      contrastText: "#000000"
    },
    text: {
      primary: "#333333",
      secondary: "#707070",
      disabled: "#BDBDBD",
      hint: "#959595"
    },
    background: {
      paper: "#ffffff",
      paper2: "#F7F9FA",
      default: "#EDF0F2",
      // selected: "#E5F5E8"
    },
    error: {
      light: "#FFEBEE",
      main: "#F24965",
      dark: "#BF2640",
      contrastText: "#BF2640"
    },
    warning: {
      light: "#FFF7E0",
      main: "#FFD966",
      dark: "#8C5F31",
      contrastText: "#8C5F31"
    },
    info: {
      light: "#EBF7FF",
      main: "#73B6E5",
      dark: "#216EA5",
      contrastText: "#216EA5"
    },
    success: {
      light: "#E0FFE5",
      main: "#6CD97E",
      dark: "#26803D",
      contrastText: "#26803D"
    },
    grey: {
      50: "#fafafa",
      100: "#f5f5f5",
      200: "#eeeeee",
      300: "#e0e0e0",
      400: "#bdbdbd",
      500: "#9e9e9e",
      600: "#757575",
      700: "#616161",
      800: "#424242",
      900: "#212121",
      A100: "#f5f5f5",
      A200: "#eeeeee",
      A400: "#bdbdbd",
      A700: "#616161"
    },
    action: {
      active: "rgba(0, 0, 0, 0.16)",
      hover: "rgba(0, 0, 0, 0.08)",
      selected: "rgba(0, 0, 0, 0.12)",
      disabled: "#BDBDBD",
      disabledBackground: "rgba(0, 0, 0, 0.04)",
      focus: "rgba(0, 0, 0, 0.08)",
      selectedOpacity: 0.12,
      hoverOpacity: 0.08,
      disabledOpacity: 0.38,
      focusOpacity: 0.08,
      activatedOpacity: 0.16
    }
  },
});