import {CircularProgress} from "@mui/material";
import React from "react";
import {css} from "@emotion/css";

const ProgressIndicator = () => {
  const containerCss = {
    loadingContainer: css`
      position: fixed;
      top: 50%;
      left: 50%;

      margin-top: -30px;
      margin-left: -20px;
      z-index: 3;
    `
  };

  return (
    <div className={containerCss.loadingContainer}>
      <CircularProgress color={"primary"} />
    </div>
  )
}

export default ProgressIndicator