import _ from "lodash";
import React, {createContext, useContext, useEffect, useState} from "react";
import apiRoot from "../helpers/apiRoot";
import dayjs from "dayjs";
import {TokenStatus} from "../models/enums/TokenStatus";

interface IAuth {
  hasToken: boolean;
  isAuthenticated: boolean;

  setToken(token: string | null | undefined): Promise<TokenStatus>;
  verify(code: string): Promise<boolean>;
  continue(): Promise<any>;
  resend(): Promise<any>;
  isTokenExpired(): boolean;
  signOut(): void;
}

const sessionTokenKey = "authorization";
const sessionAuthTokenKey = "authorizationToken";
const sessionAuthTokenExpiryKey = "authorizationTokenExpiry";

const getSessionValue = (key: string) => {
  return sessionStorage.getItem(key);
}

const removeSessionValue = (key: string) => {
  sessionStorage.removeItem(key);
}

const isExpired = (expiryDateTimeAsString: string) => {
  const expiryDateTime = dayjs(expiryDateTimeAsString);
  const currentDateTime = dayjs();
  return !expiryDateTime.isValid() || expiryDateTime < currentDateTime;
}

const hasTokenImpl = (): boolean => {
  const token = getSessionValue(sessionTokenKey);
  return !_.isNil(token);
}

const isAuthenticatedImpl = (): boolean => {
  const authorization = getSessionValue(sessionAuthTokenKey);
  const authorizationExpiry = getSessionValue(sessionAuthTokenExpiryKey);

  return hasTokenImpl() && !_.isNil(authorization) && !_.isNil(authorizationExpiry) && !isExpired(authorizationExpiry);
}

const useAuthImpl = () => {
  const [hasToken, setHasToken] = useState<boolean>(hasTokenImpl());
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(isAuthenticatedImpl());
  const [authTimer, setAuthTimer] = useState<any>(undefined);

  const setSessionValue = (key: string, value: string | null | undefined, defaultValue: string = "") => {
    sessionStorage.setItem(key, value ?? defaultValue);
  }

  const setAuthenticationExpiryTimeout = (isAuthorized: boolean) => {
    if(isAuthorized) {
      const authorizationExpiry = getSessionValue(sessionAuthTokenExpiryKey);
      const currentDateTime = dayjs();
      const expiryDateTime = dayjs(authorizationExpiry);
      const timeoutMilliseconds = expiryDateTime.diff(currentDateTime, 'millisecond') + 1;

      setAuthTimer(setTimeout(() => {
        const isAuthorized = isAuthenticatedImpl();
        setIsAuthenticated(isAuthorized);
      }, timeoutMilliseconds));
    }
  }

  const removeTimeout = () => {
    if(authTimer) {
      clearTimeout(authTimer);
    }
  }

  const removeAuthenticated = () => {
    removeSessionValue(sessionAuthTokenKey);
    removeSessionValue(sessionAuthTokenExpiryKey);

    removeTimeout();
  }

  const setToken = async (token: string | null | undefined): Promise<TokenStatus> => {
    removeAuthenticated();

    const tokenDto = await apiRoot.authApi.verifyToken(token);
    const tokenStatus = tokenDto.status as TokenStatus;

    if(tokenStatus === TokenStatus.Valid) {
      setSessionValue(sessionTokenKey, token);
      setHasToken(true);
    }
    return tokenStatus;
  }

  const verify = async (code: string) => {
    let token = getSessionValue(sessionTokenKey);

    if(token) {
      const loginResponse = await apiRoot.authApi.login(token, code);
      if(loginResponse?.success) {
        setSessionValue(sessionAuthTokenKey, loginResponse.authorization);
        setSessionValue(sessionAuthTokenExpiryKey, loginResponse.expirationDateTime);

        const isAuthorized = isAuthenticatedImpl();
        setIsAuthenticated(isAuthorized);
        setAuthenticationExpiryTimeout(isAuthorized);
        return true;
      } else {
        removeAuthenticated();
        return false;
      }
    }
  }

  const resend = async () => {
    let token = getSessionValue(sessionTokenKey);

    if(token) {
      await apiRoot.authApi.sendVerificationCode(token);
    }
  }

  /**
   * Will only check if the token is expired.  If it is empty or not expired, it will return false.
   */
  const isTokenExpired = () => {
    const authTokenExpiry = getSessionValue(sessionAuthTokenExpiryKey);
    if(authTokenExpiry) {
      return isExpired(authTokenExpiry);
    }

    return false;
  }

  const signOut = () => {
    removeSessionValue(sessionTokenKey);
    removeAuthenticated();
  }

  useEffect(() => {
    const isAuthorized = isAuthenticatedImpl();
    setIsAuthenticated(isAuthorized);
    setAuthenticationExpiryTimeout(isAuthorized);

    return () => removeTimeout();
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  return {
    hasToken,
    isAuthenticated,
    setToken,
    verify,
    resend,
    isTokenExpired,
    signOut
  } as unknown as IAuth
}

const authContext = createContext({hasToken: hasTokenImpl(), isAuthenticated: isAuthenticatedImpl()} as unknown as IAuth);

export const AuthProvider = ({children}: {children: React.ReactNode}) => {
  return (<authContext.Provider value={useAuthImpl()}>{children}</authContext.Provider>);
}

export const useAuth = () => {
  return useContext(authContext);
}