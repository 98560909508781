import { IAddressModel } from "../models/AddressModel";
import { emptyValidation, requiredValidationBuilder } from "./validationFunctions";
import { IFieldValidationModel } from "../models/FieldValidationModel";
import { ValidationSeverity } from "../models/enums/ValidationSeverity";

export const addressFieldNames = ["poBox", "postCode", "state", "city", "streetAddress"];

export const poBoxValidation = (title: string) => (value: string, model: IAddressModel): IFieldValidationModel => {
  if(model.isPOBox.value) {
    return requiredValidationBuilder(ValidationSeverity.NoSave, title)(value);
  }
  return emptyValidation();
};

export const streetAddressValidation = (title: string) => (value: string, model: IAddressModel): IFieldValidationModel => {
  if(!model.isPOBox.value) {
    return requiredValidationBuilder(ValidationSeverity.NoSave, title)(value);
  }
  return emptyValidation();
};

export const addressValidationBuilder = async (
  title: string,
  value: string,
  model: IAddressModel,
  isValidFunc: (x: IAddressModel, y: boolean) => boolean
) => {
  const fieldToExclude = model.isPOBox.value ? "streetAddress" : "poBox";

  // @ts-ignore
  const hasAnyAddressInfo = addressFieldNames.filter((x) => !x.includes("state") && x !== fieldToExclude && model[x] && model[x].value).length > 0;
  await model.cityPostcodes.retrieve(model.city?.value, model.state?.value, model.postCode?.value);

  if (isValidFunc(model, hasAnyAddressInfo)) {
    return emptyValidation();
  } else if (hasAnyAddressInfo) {
    const validationResult = requiredValidationBuilder(ValidationSeverity.NoSave, title)(value);
    return validationResult.severity === ValidationSeverity.NoSave ? validationResult : { message: `${title} is invalid`, severity: ValidationSeverity.NoSave };
  } else {
    return requiredValidationBuilder(ValidationSeverity.NoSave, title)(value);
  }
};

export const cityValidation = (title: string) => async (value: string, model: IAddressModel): Promise<IFieldValidationModel> => {
  return await addressValidationBuilder(title, value, model,
    (x, y) => Boolean(x.city?.value && x.cityPostcodes.isValidCity(x.city?.value, y))
  );
};

export const stateValidation = (title: string) => async (value: string, model: IAddressModel) => {
  return await addressValidationBuilder(title, value, model,
    (x, y) => Boolean(x.state?.value && x.cityPostcodes.isValidState(x.state?.value, y))
  );
};

export const postcodeValidation = (title: string) => async (value: string, model: IAddressModel) => {
  return await addressValidationBuilder(title, value, model,
    (x, y) => Boolean(x.postCode?.value && x.cityPostcodes.isValidPostcode(x.postCode?.value, y))
  );
};