import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from "react";
import {useMst} from "../../models/Root";
import ApplicationContainer from "../common/ApplicationContainer";
import BackButton from "../common/BackButton";
import NextButton from "../common/NextButton";
import ActionBar from "../common/ActionBar";
import {useNavigate} from "react-router-dom";
import {H3} from "../common/Text";
import {Grid, GridProps, Typography, useMediaQuery} from "@mui/material";
import AddMemberCard from "./AddMemberCard";
import EntityMember from "./EntityMember";
import {css} from "@emotion/css";
import {MOBILE_BREAKPOINT, SM_MEDIA_QUERY} from "../../config/constants";
import ConfirmRemoveMemberModal from "./ConfirmRemoveMemberModal";
import {IIndividualModel, IndividualModel} from "../../models/IndividualModel";
import {getAccountUrl, getCompanyTrusteeDetails, getEntityDetailsUrl, isEditFrom} from "../../helpers/urlHelpers";
import ActionButton from "../common/ActionButton";
import {ProgressStep} from "../ProgressBar/ProgressBarComponent";
import {BorrowingEntityType} from "../../models/enums/BorrowingEntityType";
import AddPartnerModal from "./AddPartnerModal";
import AddPartnerEntityModal from "./AddPartnerEntityModal";
import PartnerErrorModal from "./PartnerErrorModal";
import {getMemberTypeName} from "../../models/enums/MemberType";
import {Instance} from "mobx-state-tree";
import {getMemberLabel, getMembersToDisplay, getMemberType, isAtMaximumMembers} from "./helpers";
import BasicAddOrEditMemberModal from "./BasicAddOrEditMemberModal";
import FullAddOrEditMemberModal from "./FullAddOrEditMemberModal";
import {Icon} from "@nutrien/ddc-components";
import {ApplicationSource} from "../../models/AccountModel";
import NoAccessComponent from "../NoAccessComponent";

const EntityMembersCss = {
  membersContainer: css`
    margin-top: 60px !important;
    @media (max-width: ${MOBILE_BREAKPOINT}) {
      margin-top: 40px !important;
    }
  `
}

const EntityMembers = () => {
  const { customer, members } = useMst();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showAddPartnerModal, setShowAddPartnerModal] = useState<boolean>(false);
  const [showCompanyTrustPartnerErrorModal, setShowCompanyTrustPartnerErrorModal] = useState<boolean>(false);
  const [showPartnerErrorModal, setShowPartnerErrorModal] = useState<boolean>(false);
  const [showAddOrEditMemberModal, setShowAddOrEditMemberModal] = useState<boolean>(false);
  const [showConfirmRemoveModal, setShowConfirmRemoveModal] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [showProvideDirectorDetailsMessage, setShowProvideDirectorDetailsMessage] = useState<boolean>(false);

  const isSm = useMediaQuery(SM_MEDIA_QUERY);
  const gridOptions: GridProps = isSm ? { direction: "column-reverse" } : {};

  useEffect(() => {
    if (isLoading) {
      return;
    }
    // if there are no Trustees then they need to set their initial trustee
    // if (customer.borrowingEntity.type === BorrowingEntityType.Trust  && Boolean(_.isNil(members.members.find(x => x.storedMemberType === MemberType.Trustee)) && customer.individual.isLeadApplicant())) {
    //   navigate("/trusteeType");
    // }

  }, [members.members, members.members.length, customer.borrowingEntity.type, customer.individual.storedMemberType, isLoading, navigate]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsLoading(true);

    Promise.all([
      customer.load(sessionStorage.getItem("authorization") ?? "")
    ])
      .then(() => {
        // Needs to load after Customer to get the ApplicationId (force reload as the members might have been modified by a previous screen)
        members.load(customer.applicationId, customer.hasCompanyTrustee(), true).then(() => {})
          .finally(() => {
            setIsLoading(false);
          })
      });
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  const canAddMembers = () => {
    if (isLoading || isSaving) {
      return false;
    }

    if (customer.hasCompanyTrustee() || customer.borrowingEntity.type === BorrowingEntityType.Company) {
      return false;
    }

    return !isAtMaximumMembers(customer.individual, customer.borrowingEntity, members);
  }

  const BackClicked = () => {
    if(customer.individual.isLeadApplicant() && customer.borrowingEntity.type === BorrowingEntityType.Trust) {
      if(customer.childEntityPersisted) {
        navigate(getCompanyTrusteeDetails());
      } else if((members.members.filter(member => !member.isLeadApplicant())).length === 0) {
        navigate("/trusteeType");
      } else{
        navigate("/trustDetails");
      }
    } else {
      navigate(getEntityDetailsUrl());
    }
  }

  const HasError = () => {
    if (customer.hasCompanyTrustee() || customer.borrowingEntity.type === BorrowingEntityType.Company) {
      const directorDetailsMissing =  Boolean(members.members.find(x => !x.email.value));
      if (directorDetailsMissing) {
        setShowProvideDirectorDetailsMessage(true);
        return true;
      }
    }

    if(customer.borrowingEntity.type === BorrowingEntityType.Partnership && members.members.length === 1) {
      setHasError(true);
      setShowPartnerErrorModal(true);
      return true;
    }
    return false;
  }

  const NextAccountDetailsClicked = async () => {
    if(HasError()) {
      return;
    }
    try {
      setIsSaving(true);
      if(customer.source === ApplicationSource.Digital && customer.account.isCropActivityOnly()) {
        navigate("/review");
      } else {
        navigate(getAccountUrl());
      }
    } finally {
      setIsSaving(false);
    }
  }

  const AddClicked = async () => {
    await members.addEmptyAndSetCurrentMember(getMemberType(customer.borrowingEntity.type));
    setShowAddOrEditMemberModal(true);
  }

  const AddPartnerClicked = async () => {
    setShowAddPartnerModal(true);
    setShowPartnerErrorModal(false);
  }

  const EditClicked = (individual: IIndividualModel) => {
    members.setCurrentMember(individual);
    setShowAddOrEditMemberModal(true);
  }

  const RemoveClicked = (individual: IIndividualModel) => {
    members.setCurrentMember(individual);
    setShowConfirmRemoveModal(true);
  }

  const handleIndividual = async () => {
    await AddClicked();
    setShowAddPartnerModal(false);
  }

  const handleEntity = () => {
    setShowCompanyTrustPartnerErrorModal(true);
    setShowAddPartnerModal(false);
  }

  const AddMemberModalOnClose = () => {
    setShowAddOrEditMemberModal(false);
    if(customer.borrowingEntity.type === BorrowingEntityType.Partnership && members.members.length > 1) {
      setHasError(false);
    }
  }

  return (
    <>
      <ApplicationContainer isLoading={isLoading} isSaving={isSaving || members.isSaving} currentStep={ProgressStep.MemberDetailStep}>
        {customer.individual.isCoApplicant() && (
          <NoAccessComponent/>
        )}
        {customer.individual.isLeadApplicant() && (
          <>
            <H3>{getMemberLabel(customer.hasCompanyTrustee() ? customer.childEntity.type : customer.borrowingEntity.type)}s</H3>
            {isAtMaximumMembers(customer.individual, customer.borrowingEntity, members) && !showAddOrEditMemberModal && (
              <div>
              <Typography variant="h6" color="error">
                <Icon.AlertCircleFeather color="error" fontSize = "xs" />
                {' '} You have reached the maximum allowable {getMemberTypeName(customer.borrowingEntity.type)}s for an online application.
              </Typography>
            </div>
            )}
            {showProvideDirectorDetailsMessage &&(
              <div>
              <Typography variant="h6" color="error">
                <Icon.AlertCircleFeather color="error" fontSize = "xs" />
                {' '} Provide Director Details.
              </Typography>
            </div>
            )}
            <Grid container {...gridOptions} spacing={2} className={EntityMembersCss.membersContainer}>
              {getMembersToDisplay(customer, members, isLoading, isSaving).map((member : Instance<typeof IndividualModel>) => (
                <Grid key={member.id} item>
                  <EntityMember
                    individual={member}
                    canEdit={true}
                    canRemove={member.canRemove()}
                    editClicked={EditClicked}
                    removeClicked={RemoveClicked}
                  />
                </Grid>
              ))}
              {canAddMembers() && (
                <Grid item>
                  <AddMemberCard
                    onAddMemberClicked={() => customer.borrowingEntity.type === BorrowingEntityType.Partnership ? AddPartnerClicked() : AddClicked()}
                    memberTypeText={getMemberLabel(customer.borrowingEntity.type)}
                    hasError={hasError}
                  />
                </Grid>
              )}
            </Grid>

            <BasicAddOrEditMemberModal show={showAddOrEditMemberModal && members.currentMember && members.currentMember.onlyBasicInfo} onClose={() => AddMemberModalOnClose()} setIsSaving={setIsSaving}/>
            <FullAddOrEditMemberModal show={showAddOrEditMemberModal && members.currentMember && !members.currentMember.onlyBasicInfo} onClose={() => AddMemberModalOnClose()} setIsSaving={setIsSaving}/>
            <ConfirmRemoveMemberModal show={showConfirmRemoveModal} setIsSaving={setIsSaving} onClose={() => setShowConfirmRemoveModal(false)} />
            <AddPartnerModal show={showAddPartnerModal} onClose={() => setShowAddPartnerModal(false)} onIndividualClick={() => handleIndividual()} onEntityClick={() => handleEntity()}/>
            <AddPartnerEntityModal show={showCompanyTrustPartnerErrorModal} onClose={() => setShowCompanyTrustPartnerErrorModal(false)} />
            <PartnerErrorModal show={showPartnerErrorModal} onClose={() => setShowPartnerErrorModal(false)} onAddPartnerClick={() => AddPartnerClicked()} />
          </>
        )}
      </ApplicationContainer>
      {customer.individual.isLeadApplicant() && (
        <ActionBar
          isLoading={isLoading}
          leftComponents={isEditFrom() ? undefined : <BackButton onClick={() => BackClicked()} />}
          rightComponents={isEditFrom() ? <ActionButton onClick={() => navigate(-1)}>Review</ActionButton> : <NextButton onClick={async () => await NextAccountDetailsClicked()} disabled={isSaving}>Next: Account Details</NextButton>} />
      )}
    </>
  )
}

export default observer(EntityMembers);