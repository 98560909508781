import { applySnapshot, flow, Instance, types } from "mobx-state-tree";
import FieldModel, { defaultFieldModel } from "./FieldModel";
import { cancelChanges, canSave, IFieldDefinition, initializeFields, reValidate, updateInitialValues } from "./shared";
import { AddressDto } from "../dto/CustomerDto";
import { AddressType, AllAddressTypes } from "./enums/AddressType";
import BooleanFieldModel, { defaultBooleanFieldModel } from "./BooleanFieldModel";
import { AddressLookupInfo } from "./AddressLookupInfo";
import {
  addressFieldNames,
  cityValidation,
  poBoxValidation,
  postcodeValidation,
  stateValidation,
  streetAddressValidation
} from "../validation/addressValidation";
import { CityPostcodesModel, defaultCityPostcodesModel } from "./CityPostcodeModel";

// @ts-ignore
export const AddressFieldBuilder: (self: IAddressModel) => IFieldDefinition[] = (self: IAddressModel) => [
  {
    title: "PO Box #",
    field: self.poBox,
    validation: poBoxValidation,
    fieldsToRevalidate: addressFieldNames
  } as IFieldDefinition,
  {
    title: "Address",
    field: self.streetAddress,
    validation: streetAddressValidation,
    fieldsToRevalidate: addressFieldNames
  } as IFieldDefinition,
  {
    title: "City",
    field: self.city,
    validation: cityValidation,
    fieldsToRevalidate: addressFieldNames
  } as IFieldDefinition,
  {
    title: "State",
    field: self.state,
    validation: stateValidation,
    fieldsToRevalidate: addressFieldNames
  } as IFieldDefinition,
  {
    title: "Postcode",
    field: self.postCode,
    validation: postcodeValidation,
    fieldsToRevalidate: addressFieldNames
  } as IFieldDefinition,
  {
    title: "Mailing Address is a P.O. Box",
    field: self.isPOBox,
    fieldsToRevalidate: addressFieldNames
  } as IFieldDefinition
];

// @ts-ignore
export const AddressModel = types.model({
  id: types.number,
  type: types.enumeration(AllAddressTypes),
  poBox: FieldModel,
  streetAddress: FieldModel,
  streetName: FieldModel,
  streetNumber: FieldModel,
  city: FieldModel,
  state: FieldModel,
  postCode: FieldModel,
  isPOBox: BooleanFieldModel,
  cityPostcodes: CityPostcodesModel
})
  .views((self) => ({
    isAddressDifferentFrom(address: IAddressModel) {
      if (self.city.value.length === 0 && address.city.value.length === 0) {
        return true;
      }

      return self.isPOBox?.value !== address.isPOBox?.value ||
        self.streetAddress.value !== address.streetAddress.value ||
        self.city.value !== address.city.value ||
        self.state.value !== address.state.value ||
        self.postCode.value !== address.postCode.value;
    },
    getFormattedAddress() {
      return `${self.isPOBox?.value ? self.poBox.value : self.streetAddress.value}, ${self.city.value}, ${self.state.value} ${self.postCode.value}`;
    },
    getFormattedAddressLine1() {
      return `${self.isPOBox?.value ? self.poBox.value : self.streetAddress.value}, ${self.city.value},`;
    },
    getFormattedAddressLine2() {
      return `${self.state.value} ${self.postCode.value}`;
    },
    getDto(): AddressDto {
      return {
        id: self.id,
        type: self.type,
        poBox: self.isPOBox.value ? self.poBox.value : undefined,
        streetAddress: !self.isPOBox.value ? self.streetAddress.value : undefined,
        streetName: !self.isPOBox.value ? self.streetName.value : undefined,
        streetNumber: !self.isPOBox.value ? self.streetNumber.value : undefined,
        city: self.city.value,
        state: self.state.value,
        postCode: self.postCode.value
      } as AddressDto;
    }
  }))
  .actions((self) => ({
    reset() {
      applySnapshot(self, defaultAddressModel);
    },
    initialize(addressType: AddressType) {
      this.reset();

      self.type = addressType;

      initializeFields(self, AddressFieldBuilder);
    },
    isValid: flow(function* isValid() {
      let fieldDefinitionBuilder = AddressFieldBuilder;
      yield reValidate(self, fieldDefinitionBuilder);
      return canSave(self, fieldDefinitionBuilder);
    }),
    cancelChanges() {
      cancelChanges(self, AddressFieldBuilder);
    },
    updateInitialValues() {
      updateInitialValues(self, AddressFieldBuilder);
    },
    addressSelected: flow(function* addressSelected(info: AddressLookupInfo) {
      const streetAddress = info.streetNumber ? `${info.streetNumber} ${info.streetName}` : info.streetName;
      const fullStreetAddress = info.subpremise ? `${info.subpremise}/${streetAddress}` : streetAddress;
      self.streetAddress.updateOnly(fullStreetAddress);
      self.streetNumber.updateOnly(info.streetNumber)
      self.streetName.updateOnly(info.streetName);
      self.city.updateOnly(info.city);
      self.state.updateOnly(info.state);
      self.postCode.updateOnly(info.postalCode);

      // Force City, State, and Postcode Validation
      yield self.city.update(info.city);
    }),
    copyFrom: flow(function* copyFrom(address: IAddressModel) {
      yield self.isPOBox.update(address.isPOBox.value);
      yield self.poBox.update(address.poBox.value);
      self.streetAddress.updateOnly(address.streetAddress.value);
      self.streetNumber.updateOnly(address.streetNumber.value);
      self.streetName.updateOnly(address.streetName.value);
      self.city.updateOnly(address.city.value);
      self.state.updateOnly(address.state.value);
      self.postCode.updateOnly(address.postCode.value);

      // Force City, State, and Postcode Validation
      yield self.city.update(address.city.value);
    })
  }))
  .actions((self) => ({
    load: flow(function* load(addressType: AddressType, addressDto: AddressDto) {
      self.initialize(addressType);

      self.id = addressDto?.id ?? -1;
      yield self.poBox.update(addressDto?.poBox, true);
      yield self.streetAddress.update(addressDto?.streetAddress, true);
      yield self.streetName.update(addressDto?.streetName, true);
      yield self.streetNumber.update(addressDto?.streetNumber, true);
      yield self.city.update(addressDto?.city, true);
      yield self.state.update(addressDto?.state, true);
      yield self.postCode.update(addressDto?.postCode, true);
      yield self.isPOBox.update(Boolean(addressDto?.poBox), true);
    })
  }));


// @ts-ignore
export type IAddressModel = Instance<typeof AddressModel>;

export const defaultAddressModel = {
  id: 0,
  type: AddressType.Trading,
  poBox: defaultFieldModel,
  streetAddress: defaultFieldModel,
  streetName: defaultFieldModel,
  streetNumber: defaultFieldModel,
  city: defaultFieldModel,
  state: defaultFieldModel,
  postCode: defaultFieldModel,
  isPOBox: defaultBooleanFieldModel,
  cityPostcodes: defaultCityPostcodesModel
} as Instance<typeof AddressModel>;