import {cast, types} from "mobx-state-tree";
import FieldModel, {defaultFieldModel} from "./FieldModel";
import {PicNumberDto} from "../dto/CustomerApplicationDto";
import {defaultValidationResult, FieldValidationModel} from "./FieldValidationModel";

export const PicNumbersModel = types.model({
    activity: types.string,
    picNumbers: types.array(FieldModel),
    validation: FieldValidationModel
}).actions(
    self => ({
        addPicNumber() {
            self.picNumbers.push(defaultFieldModel);
        },
        removePicNumber(index: number) {
            self.picNumbers.remove(self.picNumbers[index]);
        },
        load(dtos: PicNumberDto[]) {
            self.picNumbers.replace(dtos.filter(x => x.activity === self.activity).map(dto => cast({
                ...defaultFieldModel,
                value: dto.picNumber
            })));

            if (!self.picNumbers.length) {
                self.picNumbers.push(defaultFieldModel);
            }
        },
        validate() {
            self.validation = cast(defaultValidationResult)
            return true;
        }
    })
).views(self => ({
    getDtos(): PicNumberDto[] {
        return self.picNumbers.filter(x => x.value).map(x => ({picNumber: x.value, activity: self.activity}));
    }
}));