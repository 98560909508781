import * as _ from "lodash";
import {observer} from "mobx-react-lite";
import React, {useEffect} from "react";
import {useMst} from "../../models/Root";
import ApplicationContainer from "../common/ApplicationContainer";
import {CircularProgress, Grid, Stack, useTheme} from "@mui/material";
import {css} from "@emotion/css";
import {Body1, Body2, H4} from "../common/Text";
import { Icon } from "@nutrien/ddc-components";
import ActionButton from "../common/ActionButton";
import {useAuth} from "../../hooks/useAuth";
import {useLocation, useNavigate} from "react-router-dom";
import {ReactComponent as SentCodeIcon} from "../assets/SentCodeIcon.svg";
import TextFieldWrapper from "../editors/TextFieldWrapper";

const CustomerVerification = () => {
  const theme = useTheme();
  const auth = useAuth();
  const {verificationCode} = useMst();
  const location = useLocation();
  const navigate = useNavigate();


  const contentCss = {
    container: css`
      height: calc(100vh - 230px);
    `,
    sentCodeIcon: css`
      text-align: center;
      margin: 0px !important;
    `,
    sentCodeText: css`
      text-align: center;
      font-weight: 400;
      font-size: 24px;
      line-height: 24px;      
    `,
    body: css`
      text-align: center;
      line-height: 24px;
    `,
    footer: css`
      margin: auto;
      text-align: center;
      line-height: 24px;
      max-width: 340px;
    `,
    link: css`
      font-size: 11px;
      text-align: center;
      line-height: 16.5px;
      text-decoration: underline;
      cursor: pointer;
    `,
    newCodeMessage: css`
      font-size: 11px;
      font-weight: 600;
      text-align: center;
      line-height: 16.5px;
      padding-right: 20px;
    `,
    newCodeIcon: css`
      margin-right: 8px;
      position: relative;
      top: 8px;
    `,
    progress: css`
      align-items: center;
    `,
    submitButton: css`
      width: 340px
    `,
    errorMessage: css`
      margin-top: 10px;
      text-align: center;
      padding-bottom: 20px;
    `
  };

  useEffect(() => {
    const token = new URLSearchParams(location.search).get("token");
    if (!_.isNil(token)) {
      auth.setToken(token).then();
    }
    verificationCode.initialize();
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  const handleNewCodeSent = async () => {
    verificationCode.setIsLoading(true);
    try {
      verificationCode.clear();
      await auth.resend();
      verificationCode.sendNewCode();
    }
    finally {
      verificationCode.setIsLoading(false);
    }
  }

  const onSubmit = async () => {
    verificationCode.setIsLoading(true);
    try {
      const value = verificationCode.code.value;
      const result = await auth.verify(value);

      verificationCode.validate(result);
      if(verificationCode.isValid) {
        verificationCode.clear();
        const origin = location.state?.from?.pathname || '/landing';
        navigate(origin);
      }
    } finally {
      verificationCode.setIsLoading(false);
    }
  }

  return (
    <>
      {
        <>
          <ApplicationContainer label={"Trade Account Application"}>
            <Stack justifyContent={"space-between"} className={contentCss.container}>
              <Stack spacing={5}>
                <H4 color={theme.palette.text.primary} className={contentCss.sentCodeText}>
                  <strong>{"We’ve sent a code to your email address."}</strong>
                </H4>
                <div className={contentCss.sentCodeIcon}>
                  <SentCodeIcon />
                </div>
                <br/>
                <Body1 color={theme.palette.text.primary} className={contentCss.body}>
                  {"Enter the 6-digit security code we sent to your"}<br/>{"email to start your trade account application"}
                </Body1>
                <Grid container direction="column" alignContent={"center"} justifyContent={"center"}>
                  <TextFieldWrapper label={""} autoFocus fieldModel={verificationCode.code} maxLength={6} disabled={verificationCode.isLoading} />
                  {verificationCode.isInvalid && (
                    <Body2 color={theme.palette.error.main} className={contentCss.errorMessage}>
                      {"You've entered an incorrect code.  Please try"}<br/>{"again.  Make sure you have the most recent code"}<br/>{"that was sent to your email."}
                    </Body2>
                  )}
                  <ActionButton isLoading={verificationCode.isLoading} onClick={async () => {await onSubmit()}} disabled={!verificationCode.code.value || !verificationCode.isCodeValid()} className={contentCss.submitButton}>Submit</ActionButton>
                </Grid>
                {verificationCode.newCodeSent && (
                  <Body2
                    className={contentCss.newCodeMessage}
                    style={{ color: `${theme.palette.primary.main}` }}
                  >
                    <Icon.CheckCircleFeather color={theme.palette.primary.main} className={contentCss.newCodeIcon}/>
                    {"We just sent you a new code!"}
                  </Body2>
                )}
                <Grid container direction="row" alignContent={"center"} justifyContent={"center"}>
                  {!verificationCode.isLoading && (
                    <Body2
                      className={contentCss.link}
                      onClick={async () => await handleNewCodeSent()}
                      style={{ color: `${theme.palette.info.dark}` }}
                    >
                      {"Didn't receive a code? Click here to"}<br/>{"get a new one sent to your email."}
                    </Body2>
                  )}
                  {verificationCode.isLoading && (
                    <div className={contentCss.progress}>
                      <CircularProgress size={24} color={"primary"} className={contentCss.progress}/>
                    </div>
                  )}
                </Grid>
              </Stack>
              <div className={contentCss.footer}>
                <Body1 color={theme.palette.text.primary} className={contentCss.body}>
                  {"Why do we send you a code? This is a security measure to help protect the confidentiality of your application and your personal data."}
                </Body1>
              </div>
            </Stack>
          </ApplicationContainer>
        </>
      }
    </>
  );
};

export default observer(CustomerVerification);
