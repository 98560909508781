import _ from "lodash";
import React, {FunctionComponent, SVGProps} from "react";
import {observer} from "mobx-react-lite";
import {css} from "@emotion/css";
import {Grid, Hidden, Typography, useTheme} from "@mui/material";
import {Icon} from "@nutrien/ddc-components";
import {useMst} from "../../models/Root";
import clsx from "clsx";
import {IGenericDictionary} from "../../helpers/GenericDictionary";
import {ReactComponent as WheatIcon} from "../assets/WheatIcon.svg";
import {ApplicantType} from "../../models/enums/ApplicantType";

export enum ProgressStep {
  BusinessDetailStep,
  MemberDetailStep,
  AccountDetailStep,
  ReviewStep,
  AcceptStep
}

export const AllProgressSteps = [
  ProgressStep.BusinessDetailStep,
  ProgressStep.MemberDetailStep,
  ProgressStep.AccountDetailStep,
  ProgressStep.ReviewStep
];

export interface IProgressStep {
  index: number;
  title?: string;
  noLine?: boolean;
  icon: typeof React.Component | FunctionComponent<SVGProps<SVGSVGElement>>;
  isHidden?: (current: IProgressStep) => boolean;
  applicableToApplicantTypes: string[];
}

interface Props {
  currentStep: ProgressStep;
}

const ProgressBarComponent = ({currentStep}: Props) => {
  const theme = useTheme();
  const {customer} = useMst();

  const progressBarCss = {
    grid: css`
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      height: 68px;
      background: ${theme.palette.background.paper};
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
    `,
    iconContainer: css`
      position: relative;
      top: 3px;
      margin-right: 5px;
      width: 35px;
      height: 35px;
    `,
    circleCurrent: css`
      fill: ${theme.palette.primary.main};
      stroke: ${theme.palette.primary.main};
    `,
    circleFuture: css`
      fill: ${theme.palette.common.white};
      stroke: ${theme.palette.grey["600"]};
    `,
    circle: css`
      width: 35px;
      height: 35px;
    `,
    icon: css`
      position: absolute;
      stroke-width: 1px;
      left: 7px;
      top: 8px;
      width: 20px;
      height: 20px;
    `,
    iconCurrent: css`
      stroke:  ${theme.palette.common.white}
    `,
    iconValid: css`
      color: ${theme.palette.primary.main} !important;
      width: 30px;
      height: 30px;
    `,
    titleContainer: css`
      margin-right: 4px;
    `,
    text: css`
      font-family: "Open Sans";
      font-weight: 600;
      font-size: 10px;
      line-height: 20px;
      padding-left: 4px;
      padding-top: 10px;
    `,
    stepIndicator: css`
      margin: 20px 4px;
      width: 26px;
      border: solid 1px ${theme.palette.primary.main};
    `,
    stepIndicatorOff: css`
      margin: 20px 4px;
      width: 26px;
      border: solid 1px ${theme.palette.grey["600"]};
    `,
  };

  const ProgressSteps: IGenericDictionary<IProgressStep> = {
    [ProgressStep.BusinessDetailStep]: {
      index: 0,
      icon: WheatIcon,
      title: "Business Details",
      applicableToApplicantTypes: [ApplicantType.LeadApplicant]
    },
    [ProgressStep.MemberDetailStep]: {
      index: 1,
      icon: customer.individual.isLeadApplicant() ? Icon.UsersFeather : Icon.UserFeather,
      title: customer.individual.isLeadApplicant() ? "Member Details" : "Personal Details",
      isHidden: (current: IProgressStep) => !customer.borrowingEntity.canAddMembers(),
      applicableToApplicantTypes: [ApplicantType.LeadApplicant, ApplicantType.CoApplicant]
    },
    [ProgressStep.AccountDetailStep]: {
      index: 2,
      icon: Icon.DollarSignFeather,
      title: "Account Details",
      applicableToApplicantTypes: [ApplicantType.LeadApplicant]
    },
    [ProgressStep.ReviewStep]: {
      index: 3,
      icon: Icon.Edit3Feather,
      title: "Review & Accept",
      noLine: true,
      applicableToApplicantTypes: [ApplicantType.LeadApplicant, ApplicantType.CoApplicant]
    },
    [ProgressStep.AcceptStep]: {
      index: 4,
      isHidden: (current: IProgressStep) => true,
      icon: Icon.Edit3Feather,
      applicableToApplicantTypes: [ApplicantType.LeadApplicant]
    }
  };

  return (
    <div className={progressBarCss.grid}>
      <Grid container direction="row" justifyContent="center" alignContent="center" style={{ paddingTop: "13px", paddingBottom: "14px" }}>
        <>
        {AllProgressSteps.map((progressStepEnum) => {
          const progressStep = ProgressSteps[progressStepEnum];

          // Filter Progress Steps based on applicant type
          if(!_.includes(progressStep.applicableToApplicantTypes, customer.individual.applicantType.toString())) {
            return <></>;
          }

          const current = ProgressSteps[currentStep];

          const isCurrent = progressStepEnum === currentStep;
          const isPast = current.index > progressStep.index;
          const isFuture = current.index < progressStep.index;
          const isHidden = progressStep.isHidden ? progressStep.isHidden(current) : false;

          return (
            <>
              {!isHidden && (
                <>
                  {isCurrent && (
                    <>
                      <Grid item className={progressBarCss.iconContainer}>
                        <Icon.CircleFeather fontSize={"large"}
                                            className={clsx(progressBarCss.circleCurrent, progressBarCss.circle)}/>
                        <progressStep.icon className={clsx(progressBarCss.icon, progressBarCss.iconCurrent)}
                                           fontSize="medium"/>
                      </Grid>
                    </>
                  )}
                  {isPast && (
                    <Grid item className={progressBarCss.iconContainer}>
                      <Icon.CheckCircleIcon fontSize={"large"} className={progressBarCss.iconValid}/>
                    </Grid>
                  )}
                  {isFuture && (
                    <>
                      <Grid item className={progressBarCss.iconContainer}>
                        <Icon.CircleFeather fontSize={"large"}
                                            className={clsx(progressBarCss.circleFuture, progressBarCss.circle)}/>
                        <progressStep.icon className={progressBarCss.icon} fontSize="medium"/>
                      </Grid>
                    </>
                  )}
                  <Hidden smDown={!isCurrent}>
                    <Grid item className={progressBarCss.titleContainer}>
                      <Typography color={isFuture ? theme.palette.grey["600"] : theme.palette.primary.main} className={progressBarCss.text}>
                        {progressStep.title}
                      </Typography>
                    </Grid>
                    {!progressStep.noLine && (
                      <Grid item>
                        <hr className={isFuture ? progressBarCss.stepIndicatorOff : progressBarCss.stepIndicator}/>
                      </Grid>
                    )}
                  </Hidden>
                </>
              )}
            </>
          );
        })}
        </>
      </Grid>
    </div>
  );
}

export default observer(ProgressBarComponent);