import styled from "@emotion/styled";
import {ThemeProps} from "../Account";

export const H3 = styled.h3`
  font-family: Open Sans,sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: ${(props) => props.color || "Black"};
`;

export const H4 = styled.h4`
  font-family: Open Sans,sans-serif;
  font-weight: 300;
  font-size: 24px;
  line-height: 28.8px;
  color: ${(props) => props.color || "Black"};
`;

export const H5 = styled.h5`
  font-family: Open Sans,sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  color: ${(props) => props.color || "Black"};
`;

export const Subtitle = styled.span`
  font-family: Open Sans,sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${(props) => props.color || "Black"};
`;

export const BoldBanner = styled.p`
  font-family: Open Sans,sans-serif;
  font-weight: 700;
  font-size: 48px;
  line-height: 57.6px;
  color: ${(props) => props.color || "Black"};
`;

export const Body1 = styled.p`
  font-family: Open Sans,sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${(props) => props.color || "Black"};
`;

export const Body2 = styled.p`
  font-family: Open Sans,sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${(props) => props.color || "Black"};
`;

export const TextBlock = styled.span`
  display: block;
  font-family: Open Sans,sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: ${(props) => props.color || "Black"};  
`;

export const Bold = styled.span`
  font-weight: 600;
`;

export const Text = styled.span`
  color: ${(props) => props.color || "Black"};
`;

export const Hint = styled.span<ThemeProps>`
  font-family: Open Sans,sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 120%;
  color: ${(props) => props.theme.palette.text.hint};
`;


export const StepHeader = styled.span<ThemeProps>`
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  color: ${(props) => props.theme.palette.primary.main};
`;

