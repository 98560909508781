import {flow, Instance, types} from "mobx-state-tree";
import apiRoot from "../helpers/apiRoot";
import BooleanFieldModel, {defaultBooleanFieldModel} from "./BooleanFieldModel";
import FieldModel, {defaultFieldModel} from "./FieldModel";
import _ from "lodash";
import {LatLong} from "../components/BranchLocator/Map";
import {AddressLookupInfo} from "./AddressLookupInfo";
import {BranchDto} from "../dto/BranchDto";

export const LatLongModel = types.model({latitude: types.number, longitude: types.number});

export const Activities = types.model({
    cropField: BooleanFieldModel,
    isCropSupported: types.boolean,
    livestockField: BooleanFieldModel,
    isLivestockSupported: types.boolean,
    woolField: BooleanFieldModel,
    isWoolSupported: types.boolean
}).views(self => ({
    isActivitySelected() {
        return Boolean(self.cropField.value || self.livestockField.value || self.woolField.value);
    }
}));

export const BranchModel = types.model({
    id: types.identifierNumber,
    code: types.string,
    name: types.string,
    fullAddress: types.string,
    longitude: types.number,
    latitude: types.number,
    isMerchandiseOrFertiliser: types.boolean,
    isLivestock: types.boolean,
    isWool: types.boolean,
    distanceInMeters: types.maybe(types.number),
    phoneNumber: types.maybe(types.string),
    branchLocator: types.boolean
}).views((self) => ({
    getActivitiesOffered() {
        let activitiesString = "";
        let hasActivity = false;
        if(self.isMerchandiseOrFertiliser) {
            hasActivity = true;
            activitiesString += "Crop";
        }
        if(self.isLivestock) {
            if(hasActivity) {
                activitiesString += self.isWool ? ", " : ", & "
            }
            hasActivity = true;
            activitiesString += "Livestock";
        }
        if(self.isWool) {
            if(hasActivity) {
                activitiesString += ", & "
            }
            activitiesString += "Wool";
        }

        return activitiesString;
    }
}));

function calculateDistance(lat1: number, long1: number, lat2: number, long2: number): number {
    return google.maps.geometry.spherical.computeDistanceBetween({lat: lat1, lng: long1}, {lat: lat2, lng: long2});
}


export const BranchesModel = types.model({
    companyCode: types.string,
    searchLocation: types.maybe(LatLongModel),
    activities: Activities,
    suburbOrPostcodeField: FieldModel,
    branches: types.array(BranchModel),
    selectedBranch: types.maybe(types.reference(BranchModel)),
    closestBranches: types.array(types.reference(BranchModel)),
    filteredBranches: types.array(types.reference(BranchModel)),

}).views((self) => ({
    getBranches() {
        if (!self.activities.isActivitySelected() || !self.searchLocation ) {
            return [];
        }

        const branchesFilter = (branch: Instance<typeof BranchModel>) =>(!self.activities.cropField.value || branch.isMerchandiseOrFertiliser) && (!self.activities.livestockField.value || branch.isLivestock) && (!self.activities.woolField.value || branch.isWool) && (branch.branchLocator);
        return self.branches.filter(branchesFilter);
    }
})).views((self) => ({
    getCloseBranches() {
        const DISTANCE_THRESHOLD = 50000; // 50km
        const TARGET_NUMBER_OF_BRANCHES = 5;

        const branches = self.filteredBranches;
        const closestBranches = _.chain(branches).orderBy(x => x.distanceInMeters, "asc").take(TARGET_NUMBER_OF_BRANCHES).value();
        if (closestBranches.find(x => (x.distanceInMeters ?? 0) <= DISTANCE_THRESHOLD)) {
            return closestBranches.filter(x => (x.distanceInMeters ?? 0) <= DISTANCE_THRESHOLD);
        }
        return closestBranches;
    }
}))
    .actions((self) => ({
        setCurrentLocation(location?: LatLong) {
            self.searchLocation = location ? {latitude: location.latitude, longitude: location.longitude} : undefined;
            if (self.branches.length > 0 && location) {
                self.branches.forEach(branch => {
                    branch.distanceInMeters = calculateDistance(self.searchLocation!.latitude, self.searchLocation!.longitude, branch.latitude, branch.longitude)
                });
                const sortedAndFilteredBranches = _.chain(self.getBranches()).orderBy(x => x.distanceInMeters, "asc").value();
                self.filteredBranches.replace(sortedAndFilteredBranches);
                // console.log("filteredBranches");
                // console.log(toJS(self.filteredBranches));
                self.closestBranches.replace(self.getCloseBranches());
                // console.log("closestBranches");
                // console.log(toJS(self.closestBranches));
            } else {
                self.filteredBranches.replace([]);
                self.closestBranches.replace([]);
            }
        },
    }))
    .actions((self) => (
        {
            load: flow(function* load(companyCode: string) {
                try {
                    const response = yield apiRoot.branchApi.getBranches(companyCode);
                    self.companyCode = companyCode;

                    // Branch name replacements/filters here are temporary.
                    // There is an upcoming story to add a "friendly name" and a flag to indicate whether it's eligible for
                    // Digital Path to the branch (among other things)... which we would use here at that point.
                    self.branches = response.payload.filter((branch: BranchDto) => !branch.name.includes("Field Day")).map((branch: BranchDto) => ({...branch, name: branch.name.replace(" Distribution Centre", "").replace(" Retail Store", "")}))

                    self.activities.isCropSupported = Boolean(self.branches.find(x => x.isMerchandiseOrFertiliser));
                    self.activities.isLivestockSupported = Boolean(self.branches.find(x => x.isLivestock));
                    self.activities.isWoolSupported = Boolean(self.branches.find(x => x.isWool));

                    // as-per Mark last night - if only livestock and wool are supported then we'll treat it like only livestock is supported -
                    // so there will be no need to select activities and we will choose livestock behind-the-scenes.  The applicant can add/change that
                    // when they get to Account.tsx if they need wool.
                    if (!self.activities.isCropSupported && self.activities.isLivestockSupported && self.activities.isWoolSupported) {
                        self.activities.isLivestockSupported = true;
                        self.activities.isWoolSupported = false;
                    }

                    // if only one activity is supported - auto-select activity and disable all activities (there is no point in them seeing/selecting activities)
                    if ([self.activities.isCropSupported, self.activities.isLivestockSupported, self.activities.isWoolSupported].filter(b => b).length === 1) {
                        self.activities.cropField.update(self.activities.isCropSupported);
                        self.activities.livestockField.update(self.activities.isLivestockSupported);
                        self.activities.woolField.update(self.activities.isWoolSupported);
                        self.activities.isCropSupported = false;
                        self.activities.isLivestockSupported = false;
                        self.activities.isWoolSupported = false;
                        if (self.branches.length === 1) {
                            // auto-select if we don't need activities or branch to be selected... since there is only one of each!
                            self.selectedBranch = self.branches[0];
                        }
                    }

                    self.setCurrentLocation(self.searchLocation); // just in case current location is already set... do the filtering, etc.
                } catch (e) {
                    console.log(e);
                }
            }),
            setCurrentPlace(location: AddressLookupInfo) {
                self.setCurrentLocation(location);
                if (location.city && location.state && location.postalCode) {
                    self.suburbOrPostcodeField.update(`${location.city} ${location.state}, ${location.postalCode}`);
                } else if (location.city && location.state) {
                    self.suburbOrPostcodeField.update(`${location.city} ${location.state}`);
                } else if (location.state) {
                    self.suburbOrPostcodeField.update(location.state);
                }
            },
            updateFilteredBranches() {
                self.setCurrentLocation(self.searchLocation);
            },
            setSelectedBranch(id: number) {
                self.selectedBranch = self.branches.find(x => x.id === id);
            }
        }
)
    );

export const defaultBranchesModel = {
    companyCode: "8200",
    activities: {
        cropField: {...defaultBooleanFieldModel, title: "Crop (including Store Merchandise)", id: "cropCheckbox"},
        isCropSupported: false,
        livestockField: {...defaultBooleanFieldModel, title: "Livestock", id: "livestockCheckbox"},
        isLivestockSupported: false,
        woolField: {...defaultBooleanFieldModel, title: "Wool", id: "woolCheckbox"},
        isWoolSupported: false
    },
    suburbOrPostcodeField: defaultFieldModel,
    branches: []}
;