import React from "react";
import {Instance} from "mobx-state-tree";
import {BranchModel} from "../../models/BranchesModel";
import styled from "@emotion/styled";
import {useTheme} from "@mui/material";
import {ThemeProps} from "../Account";
import { ReactComponent as WoolIcon } from "../assets/activities/Wool.svg";
import { ReactComponent as CropIcon } from "../assets/activities/Crop.svg";
import { ReactComponent as LivestockIcon } from "../assets/activities/Livestock.svg";
import {Icon} from "@nutrien/ddc-components";
import {css} from "@emotion/css";

interface Props {
    branch: Instance<typeof BranchModel>;
    isSelected: boolean;
    onClick?: () => void;
}

interface ContainerProps extends ThemeProps {
    selected: boolean;
}

const Container = styled.div<ContainerProps>`
  position: relative;
  height: 57px;
  cursor: pointer;
  background: ${props => props.selected ? props.theme.palette.primary.light : props.theme.palette.background.paper};
  border-bottom: 1px solid ${props => props.theme.palette.divider};
  user-select: none;
`;

const Name = styled.div<ThemeProps>`
  position: absolute;
  left: 30px;
  top: 0;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.palette.text.primary}
`;

const Address = styled.div<ThemeProps>`
  position: absolute;
  bottom: 10px;
  left: 30px;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 0.25px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: ${props => props.theme.palette.text.secondary};
`;

const Distance = styled.div<ThemeProps>`
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: ${props => props.theme.palette.primary.main};
  position: absolute;
  right: 0;
  top: 10px;
`;

const BranchResult = ({branch, isSelected, onClick}: Props) => {
    const theme = useTheme();

    function handleOnClick() {
        if (onClick) {
            onClick();
        }
    }

    return <Container theme={theme} selected={isSelected} onClick={handleOnClick}>
        <Icon.MapPinFeather stroke={theme.palette.text.secondary}  className={css`position: absolute; top: 10px; width: 20px;`} />
        <Name theme={theme}>
            {branch.name}
            {branch.isMerchandiseOrFertiliser && <CropIcon stroke={theme.palette.text.secondary} className={css`margin-left: 8px; height: 20px; position: relative; top: 4px;`} />}
            {branch.isLivestock && <LivestockIcon stroke={theme.palette.text.secondary}  className={css`margin-left: 8px; height: 20px; position: relative; top: 4px;`}/>}
            {branch.isWool && <WoolIcon stroke={theme.palette.text.secondary}  className={css`margin-left: 4px; height: 20px; position: relative; top: 4px;`}/>}
        </Name>
        <Address theme={theme}>
            {branch.fullAddress}
        </Address>
        <Distance theme={theme}>
            {((branch.distanceInMeters ?? 0) / 1000).toFixed(2) + " km"}
        </Distance>
    </Container>;
}

export default BranchResult;