import { Button } from "@nutrien/ddc-components";
import React from "react";
import { ButtonProps } from "@nutrien/ddc-components/dist/components/Button";

const ActionButton = (props: ButtonProps) => {
    return (
        <Button
            fullWidth={false}
            variant="contained"
            color="primary"
            {...props}
        />
    )
}

export default ActionButton;