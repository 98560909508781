import {ATTACHMENT_VALUES, AttachmentParentType, AttachmentType} from "../models/enums/AttachmentType";
import {PreSignedUrlResponseDto} from "../dto/PreSignedUrlResponseDto";
import {callAPI} from "./apiUtilities";
import {apiConfig} from "../config/api.config";
import {IAttachmentApi} from "./IAttachmentApi";
import {AttachmentDto} from "../dto/AttachmentDto";

export default class AttachmentApi implements IAttachmentApi {
  getGeneratedPersonalGuaranteeDownloadUrl(): Promise<PreSignedUrlResponseDto> {
    return callAPI<PreSignedUrlResponseDto>(`${apiConfig.fmsCustomerPathUrl}/auth/personalGuaranteePresignedUrl`, {
      method: "GET"
    });
  }

  getAttachmentUploadUrl(
    parentId: string | number,
    fileName: string,
    type: AttachmentType,
    parentType: AttachmentParentType,
    borrowingEntityId: number | string | null = null,
    applicationId: number | string | null = null,
    individualId: number | string | null = null
  ): Promise<PreSignedUrlResponseDto> {
    borrowingEntityId = parentType === AttachmentParentType.BorrowingEntity ? parentId : borrowingEntityId;
    applicationId = parentType === AttachmentParentType.Application ? parentId : applicationId;
    individualId = parentType === AttachmentParentType.Individual ? parentId : individualId;

    return this.getAttachmentMultiParentUploadUrl(fileName, type, borrowingEntityId, applicationId, individualId);
  }

  getAttachmentMultiParentUploadUrl(
    fileName: string,
    type: AttachmentType,
    borrowingEntityId?: number | string | null,
    applicationId?: number | string | null,
    individualId?: number | string | null
  ): Promise<PreSignedUrlResponseDto> {
    return callAPI(
      `${apiConfig.fmsCustomerPathUrl}/auth/attachments/preSignedUrl?fileName=${encodeURIComponent(fileName)}&fileType=${
        ATTACHMENT_VALUES[type].value
      }&borrowingEntityId=${borrowingEntityId}&applicationId=${applicationId}&individualId=${individualId}`,
      {
        method: "GET"
      }
    );
  }

  getAttachmentDownloadUrl(attachmentId: string | number): Promise<PreSignedUrlResponseDto> {
    return callAPI<PreSignedUrlResponseDto>(`${apiConfig.fmsCustomerPathUrl}/auth/attachments/${encodeURIComponent(attachmentId)}/preSignedUrl?verb=GET`, {
      method: "GET"
    });
  }

  getAttachmentDeleteUrl(attachmentId: string | number): Promise<PreSignedUrlResponseDto> {
    return callAPI<PreSignedUrlResponseDto>(
      `${apiConfig.fmsCustomerPathUrl}/auth/attachments/${encodeURIComponent(attachmentId)}/preSignedUrl?verb=DELETE`,
      {
        method: "get"
      }
    );
  }

  getAttachments(parentId: string | number, parentType: AttachmentParentType): Promise<AttachmentDto[]> {
    return callAPI<{ payload: AttachmentDto[] }>(`${apiConfig.fmsCustomerPathUrl}/auth/${parentType}/${parentId}/attachments`, {
        method: "get"
      })
      .then((x) => x.payload);
  }
}
