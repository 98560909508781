export function handleTabFocus(e: any) {
  const elementPosition = e.target.getBoundingClientRect().top;
  const windowHeight = window.innerHeight;
  const headerHeight = 60;
  const topMargin = 100;
  const footerHeight = 100;
  const bottomMargin = 150;
  if (elementPosition > windowHeight - footerHeight) {
    window.scrollBy(0, footerHeight + bottomMargin);
  } else if (elementPosition < headerHeight + topMargin) {
    window.scrollBy(0, -(headerHeight + topMargin));
  }
}
