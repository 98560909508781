import { Grid, useTheme } from "@mui/material";
import { IBooleanFieldModel } from "../../models/IFieldModel";
import { Checkbox } from "@nutrien/ddc-components";
import { handleTabFocus } from "./handleTabFocus";
import React from "react";
import { observer } from "mobx-react-lite";
import { Subtitle } from "../common/Text";

export interface CheckBoxFieldWrapperProps {
  fieldModel: IBooleanFieldModel & { update: (value: boolean) => void };
  className?: string;
  autoDisable?: boolean;
  labelClassName?: string;
  label?: any;
  action?: () => void;
  disabled?: boolean;
}

const CheckBoxFieldWrapper = ({ fieldModel, className, labelClassName, label, action, disabled, autoDisable = true }: CheckBoxFieldWrapperProps) => {
  const theme = useTheme();

  return (
    <Grid item container direction="row" alignContent="flex-start" alignItems={"center"} className={className}>
      <Grid item>
        <Checkbox
          id={fieldModel.id}
          color="primary"
          checked={fieldModel.value}
          onFocus={handleTabFocus}
          onChange={(event, value) => {
            fieldModel.update(value);
            if (action) {
              action();
            }
          }}
          disabled={disabled}
        />
      </Grid>
      <Grid item>
        <label htmlFor={fieldModel.id} style={{ cursor: "pointer" }}>
          <Subtitle color={theme.palette.text.primary}>{label ?? fieldModel.title}</Subtitle>
        </label>
      </Grid>
    </Grid>
  );
};

export default observer(CheckBoxFieldWrapper);
