import {observer} from "mobx-react-lite";
import React from "react";
import InvalidToken from "./InvalidToken";

const ClosedApplicationToken = () => {
  return (
    <InvalidToken message={"This link is no longer available because the application has been closed.  Please contact your branch if you'd like to re-open this trade account application."} />
  )
}

export default observer(ClosedApplicationToken);