import * as _ from "lodash";
import { getParent, hasParent } from "mobx-state-tree";
import { IBaseFieldModelWithActions, IBooleanFieldModel, IDateFieldModel, IFieldModel } from "./IFieldModel";
import { ValidationFunction } from "../validation/ValidationFunction";
import { ValidationSeverity } from "./enums/ValidationSeverity";

export interface IFieldDefinition {
  title: string;
  field: IBaseFieldModelWithActions<any>;
  validation?: (title: string) => ValidationFunction<any> | null | any;  // Any required due to mix of async and non async validations
  fieldsToRevalidate?: string[];
  id?: string;
  defaultValue?: any;
}

export function initializeFields(self: any, fieldDefinitionBuilder: (x: any) => IFieldDefinition[]) {
  fieldDefinitionBuilder(self).forEach((x) => {
    x.field.initialize(x.title, x.validation ? x.validation(x.title) : null, x.id, x.fieldsToRevalidate, x.defaultValue);
  });
}

export const reValidateFields = async (self: any) => {
  if (self.reValidateFields.length > 0) {
    const parent = getParent(self) as { [key: string]: IBaseFieldModelWithActions<any> };
    for (const field of self.reValidateFields) {
      const fieldModel = _.get(parent, field, undefined);
      if (fieldModel && fieldModel.validate && fieldModel !== self) {
        await fieldModel.validate();
      }
    }
  }
};

export const reValidate = async (self: any, fieldDefinitionBuilder: (x: any) => IFieldDefinition[]) => {
  for (const fieldDefinition of fieldDefinitionBuilder(self)) {
    await fieldDefinition.field.validate();
  }
}

export function canSave(self: any, fieldDefinitionBuilder: (x: any) => IFieldDefinition[]) {
  const active = isActive(self);
  for (const fieldDefinition of fieldDefinitionBuilder(self)) {
    // @ts-ignore
    if (fieldDefinition.field.validationResult?.severity === ValidationSeverity.NoSave) {
      console.log(`NoSave - ${fieldDefinition.title}`);

      return false;
    }
    if (active && fieldDefinition.field.validationResult?.severity === ValidationSeverity.NoSubmit) {
      console.log(`NoSubmit - ${fieldDefinition.title}`);

      return false;
    }
  }
  return true;
}

export function cancelChanges(self: any, fieldDefinitionBuilder: (x: any) => IFieldDefinition[]) {
  for (const fieldDefinition of fieldDefinitionBuilder(self)) {
    // @ts-ignore
    fieldDefinition.field.revert();
  }
}

export function updateInitialValues(self: any, fieldDefinitionBuilder: (x: any) => IFieldDefinition[]) {
  for (const fieldDefinition of fieldDefinitionBuilder(self)) {
    // @ts-ignore
    fieldDefinition.field.update(fieldDefinition.field.value, true);
  }
}

export function isActive(model: any): boolean {
  if (!hasParent(model)) {
    return false;
  }
  const parent = getParent(model) as any;
  if (!parent) {
    return false;
  }
  if (parent.status && parent.status === "Active") {
    return true;
  }
  return isActive(parent);
}

export function buildError(fieldModel: IFieldModel | IBooleanFieldModel | IDateFieldModel) {
  if (fieldModel.validationResult) {
    if (isActive(fieldModel)) {
      return {
        error: fieldModel.validationResult.severity !== ValidationSeverity.Valid ? fieldModel.validationResult.message : undefined
      };
    }
    return {
      error: fieldModel.validationResult.severity === ValidationSeverity.NoSave ? fieldModel.validationResult.message : undefined
    };
  }
  return {};
}