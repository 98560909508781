import {applySnapshot, flow, Instance, types} from "mobx-state-tree";
import {BorrowingEntityModel, createBorrowingEntityModelFrom} from "./BorrowingEntityModel";
import FieldModel, {defaultFieldModel} from "./FieldModel";
import apiRoot from "../helpers/apiRoot";
import {createIndividualModelFrom, IndividualModel} from "./IndividualModel";
import _ from "lodash";
import {sleep} from "../helpers/sleepHelper";
import {ABN_SEARCH_POLLING_DELAY} from "../config/constants";
import {BorrowingEntityType} from "./enums/BorrowingEntityType";
import {IFieldDefinition, initializeFields} from "./shared";
import {abnAcnValidation} from "../validation/abnAcnValidation";

const BorrowingEntitySearchBuilder: (self: IBorrowingEntitySearch) => IFieldDefinition[] = (self: IBorrowingEntitySearch) => {
  return [
    {
      title: "ABN or ACN",
      field: self.searchTerm,
      validation: abnAcnValidation
    } as IFieldDefinition
  ];
};

export const BorrowingEntitySearch = types.model({
  hasError: types.boolean,
  hasExistingAccounts: types.boolean,
  isSearching: types.boolean,
  isNotFound: types.boolean,
  searchTerm: FieldModel,
  searchedTerm: types.maybe(types.string),
  borrowingEntitySearchResult: types.maybe(BorrowingEntityModel),
  individuals: types.array(IndividualModel)
})
  .views((self) => ({
    isPartnershipWithTrustOrCompany() {
      return self.borrowingEntitySearchResult &&
        self.borrowingEntitySearchResult.type === BorrowingEntityType.Partnership &&
        (self.borrowingEntitySearchResult.legalName.value.toUpperCase().includes("TRUST") ||
          self.borrowingEntitySearchResult.legalName.value.toUpperCase().includes("TRUSTEE") ||
          self.borrowingEntitySearchResult.legalName.value.toUpperCase().includes("PTY LTD") ||
          self.borrowingEntitySearchResult.legalName.value.toUpperCase().includes("PTY. LTD."));
    }
  }))
  .actions((self) => ({
    pullIndividuals: flow(function* pullIndividuals(borrowingEntityId: number) {
      const members = yield apiRoot.membersApi.GetBorrowingEntityIndividuals(borrowingEntityId);

      const alphabetical = _.orderBy(members, [(x) => x.firstName], ["desc"])

      const individuals = [];

      for (const member of alphabetical) {
        individuals.push(yield createIndividualModelFrom(member, true))
      }
      self.individuals.replace(individuals);
    })
  }))
  .actions((self) => ({
    reset() {
      applySnapshot(self, defaultBorrowingEntitySearch);
    },
    initialize() {
      this.reset();
      initializeFields(self, BorrowingEntitySearchBuilder);
    },
  }))
  .actions((self) => ({
    search: flow(function* search(getTradeAccountCount: boolean = false) {
      try {
        self.hasError = false;
        self.isSearching = true;
        self.borrowingEntitySearchResult = undefined;

        let uniqueSearchId = yield apiRoot.customerApi.searchBorrowingEntity(self.searchTerm.value, getTradeAccountCount);
        for (let counter = 0; counter < 20; counter++) {
          yield sleep(ABN_SEARCH_POLLING_DELAY);

          let response = yield apiRoot.customerApi.searchBorrowingEntityResults(uniqueSearchId);
          if (response.status === "Completed") {
            let borrowingEntity = response.customerEntity;

            self.hasError = Boolean(borrowingEntity.hasError);
            self.hasExistingAccounts = Boolean(borrowingEntity.hasExistingAccounts);

            self.isNotFound = borrowingEntity.id === 0;
            if (borrowingEntity.id > 0) {
              self.borrowingEntitySearchResult = yield createBorrowingEntityModelFrom(borrowingEntity);
            }

            self.searchedTerm = self.searchTerm.value;
            break;  // Break Out of For Loop
          }
        }
      } catch (error) {
        console.log(error);
        self.hasError = true;
      } finally {
        self.isSearching = false;
      }
    })
  }));

export type IBorrowingEntitySearch = Instance<typeof BorrowingEntitySearch>;

export let defaultBorrowingEntitySearch = {
  isSearching: false,
  isNotFound: false,
  hasError: false,
  hasExistingAccounts: false,
  searchTerm: defaultFieldModel
} as Instance<typeof BorrowingEntitySearch>;