import { observer } from "mobx-react-lite";
import { Grid } from "@mui/material";
import { handleTabFocus } from "./handleTabFocus";
import { IFieldModel } from "../../models/IFieldModel";
import { buildError } from "../../models/shared";
import { SelectField } from "@nutrien/ddc-components";
import { SelectFieldProps } from "@nutrien/ddc-components/dist/components/SelectField";
import React from "react";

export declare type SelectPropsWithoutVariant = Omit<Omit<Omit<SelectFieldProps, "variant">, "children">, "onChange">;
export interface SelectFieldWrapperProps extends Omit<SelectPropsWithoutVariant, "id" | "onChange" | "value"> {
  fieldModel: IFieldModel & { update: (value: string) => void };
  options: any[];
  onChange?: (event: any, value?: any) => void;
  noLabel?: boolean;
  label?: string;
  disabled?: boolean;
  gridOptions?: any;
  afterChangeAction?: (newValue: any) => void;
}

const SelectFieldWrapper = observer(({ fieldModel, noLabel, gridOptions, ...rest }: SelectFieldWrapperProps) => {
  return (
    <Grid item {...gridOptions}>
      <SelectField
        {...rest}
        {...buildError(fieldModel)}
        id={fieldModel.id}
        label={noLabel ? "" : rest.label ?? fieldModel.title}
        value={fieldModel.value}
        onFocus={handleTabFocus}
        onChange={(value: string | string[]) => {
         fieldModel.update(value as string);
         if(rest.afterChangeAction) {
           rest.afterChangeAction(value);
         }
        }}
        disabled={rest.disabled}
      />
    </Grid>
  );
});

export default SelectFieldWrapper;