import {observer} from "mobx-react-lite";
import React from "react";
import {Card, Icon} from "@nutrien/ddc-components";
import {Grid, GridProps, useMediaQuery, useTheme} from "@mui/material";
import {css} from "@emotion/css";
import {H3} from "../common/Text";
import {
  MOBILE_BREAKPOINT,
  SM_MEDIA_QUERY,
  STANDARD_ACTION_COLOR,
  STANDARD_ACTION_LIGHT_COLOR
} from "../../config/constants";
import clsx from "clsx";

interface AddMemberCardProps {
  onAddMemberClicked: () => void;
  memberTypeText?: string;
  hasError?: boolean;
}

const AddMemberCard = (props: AddMemberCardProps) => {
  const theme = useTheme();

  const AddMemberCardCss = {
    icon: css`
      transform: scale(1.5);
      @media (max-width: ${MOBILE_BREAKPOINT}) {
        transform: scale(1.25);
        padding-right: 16px;
      }
    `,
    card: css`
      margin: 0px !important;
      background-color: ${STANDARD_ACTION_LIGHT_COLOR} !important;
      width: 203px;
      min-height: 236px;
      @media (max-width: ${MOBILE_BREAKPOINT}) {
        width: 100%;        
        min-height: 114px;
      }
    `,
    cardContentContainer: css`
      min-height: 236px;
      @media (max-width: ${MOBILE_BREAKPOINT}) {
        min-height: 114px;
      }      
    `,
    withError: css`
      border: solid 1px ${theme.palette.error.main} !important;
    `
  };

  const isSm = useMediaQuery(SM_MEDIA_QUERY);
  const gridOptions: GridProps = isSm
    ? { direction: "row", justifyContent: "center", alignItems: "center" }
    : { direction: "column", justifyContent: "center", alignItems: "center" };

  return (
    <div className={"clickable"} onClick={() => props.onAddMemberClicked()}>
      <Card variant={"elevation"} elevation={1} className={clsx(AddMemberCardCss.card, props.hasError ? AddMemberCardCss.withError : undefined)}>
        <Grid container {...gridOptions} className={AddMemberCardCss.cardContentContainer}>
          <Grid item>
            <Icon.UserPlusFeather color={"primary"} fontSize={"large"} className={AddMemberCardCss.icon} />
          </Grid>
          <Grid item>
            <H3 color={STANDARD_ACTION_COLOR}>Add a {props.memberTypeText ?? "Member"}</H3>
          </Grid>
        </Grid>
      </Card>
    </div>
  )
}

export default observer(AddMemberCard);