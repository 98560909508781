import {IBranchApi} from "./IBranchApi";
import {CustomerBranchDto} from "../dto/CustomerDto";
import {callAPI} from "./apiUtilities";
import {apiConfig} from "../config/api.config";

export default class BranchApi implements IBranchApi {
    getBranches(companyCode: string): Promise<{payload: CustomerBranchDto[]}> {
        return callAPI(`${apiConfig.fmsCustomerPathUrl}/auth/brands/${companyCode}/branches`, {
            method: "GET"
        });
    }
}
