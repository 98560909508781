import { Instance, types } from "mobx-state-tree";
import { nutrienTheme } from "../themes/NutrienTheme";
import { agriwestRuralTheme } from "../themes/AgriwestRuralTheme";
import { queenslandRuralTheme } from "../themes/QueenslandRuralTheme";
import { grantDanielLongTheme } from "../themes/GrantDanielLongTheme";
import { platinumTheme } from "../themes/PlatinumTheme";
import { brAndCAgentsTheme } from "../themes/BrAndCAgentsTheme";
import { dairyLivestockServicesTheme } from "../themes/DairyLivestockServicesTheme";
import { sejTheme } from "../themes/SejTheme";
import { davidsonCameronTheme } from "../themes/davidsonCameronTheme";
import { qfhMultipartsTheme } from "../themes/QFHMultipartsTheme";
import { dalbyRuralSuppliesTheme } from "../themes/DalbyRuralSupplies";
import { clarkeAndStokesTheme } from "../themes/ClarkeAndStokes";
import { greatNorthernRuralTheme } from "../themes/GreatNorthernRuralTheme";
import { northernlpTheme } from "../themes/NorthernlpTheme";
import { kimberleyLivestockTheme } from "../themes/KimberleyLivestockTheme";

const defaultBrand = {
  name: "Nutrien Ag Solutions",
  domain: "nutrienagsolutions.com.au",
  favIconUrl: "/logos/Nutrien.png",
  logoUrl: "/logos/nutrien.png",
  companyCode: "8200",
  theme: nutrienTheme,
  digitalPathEnabled: true,
  logoAreaHeight: "63",
  logoHeight: 38,
};

const brands = [
  defaultBrand,
  {
    name: "Stevens Egan Johnston",
    domain: "apply.sej.com.au",
    favIconUrl: "/icons/SEJ.png",
    logoUrl: "/logos/SEJ.png",
    companyCode: "2010",
    theme: sejTheme,
    digitalPathEnabled: false,
    logoAreaHeight: "112",
    logoHeight: 90,
  },
  {
    name: "Clarke and Stokes",
    domain: "clarkeandstokes.com.au",
    favIconUrl: "/icons/ClarkeAndStokes.png",
    logoUrl: "/logos/ClarkeAndStokes.png",
    companyCode: "7008",
    theme: clarkeAndStokesTheme,
    digitalPathEnabled: true,
    logoAreaHeight: "92",
    logoHeight: 72,
  },
  {
    name: "Dalby",
    domain: "dalbyruralsupplies.com.au",
    favIconUrl: "/icons/DalbyRuralSupplies.png",
    logoUrl: "/logos/DalbyRuralSupplies.png",
    companyCode: "8430",
    theme: dalbyRuralSuppliesTheme,
    digitalPathEnabled: true,
    logoAreaHeight: "120",
    logoHeight: 102,
  },
  {
    name: "Great Northern Rural",
    domain: "greatnorthernrural.com.au",
    favIconUrl: "/icons/GreatNorthernRural.png",
    logoUrl: "/logos/GreatNorthernRural.png",
    companyCode: "7006",
    theme: greatNorthernRuralTheme,
    digitalPathEnabled: false,
    logoAreaHeight: "118",
    logoHeight: 102,
  },
  {
    name: "Platinum",
    domain: "platinumagservices.com.au",
    favIconUrl: "/icons/Platinum.png",
    logoUrl: "/logos/Platinum.png",
    companyCode: "6001",
    theme: platinumTheme,
    digitalPathEnabled: true,
    logoAreaHeight: "114",
    logoHeight: 102,
  },
  {
    name: "AgriWest",
    domain: "agriwestrural.com.au",
    favIconUrl: "/icons/AgriWest.png",
    logoUrl: "/logos/AgriWest.png",
    companyCode: "5005",
    theme: agriwestRuralTheme,
    digitalPathEnabled: true,
    logoAreaHeight: "128",
    logoHeight: 120,
  },
  {
    name: "QFH Multiparts",
    domain: "qfhmultiparts.com.au",
    favIconUrl: "/icons/QFHMultiparts.png",
    logoUrl: "/logos/QFHMultiparts.png",
    companyCode: "7009",
    theme: qfhMultipartsTheme,
    digitalPathEnabled: true,
    logoAreaHeight: "122",
    logoHeight: 102,
  },
  {
    name: "Queensland Rural",
    domain: "qldrural.com.au",
    favIconUrl: "/icons/Queensland.png",
    logoUrl: "/logos/queensland.png",
    companyCode: "3010",
    theme: queenslandRuralTheme,
    digitalPathEnabled: false,
    logoAreaHeight: "128",
    logoHeight: 120,
  },
  {
    name: "Grant Daniel Long",
    domain: "gdlrural.com.au",
    favIconUrl: "/icons/GrantDanielLong.png",
    logoUrl: "/logos/GrantDanielLong.png",
    companyCode: "3002",
    theme: grantDanielLongTheme,
    digitalPathEnabled: false,
    logoAreaHeight: "114",
    logoHeight: 92,
  },
  {
    name: "BR&C Agents Pty Ltd",
    domain: "brcagents.com.au",
    favIconUrl: "/icons/BR_C.png",
    logoUrl: "/logos/BR_C.png",
    companyCode: "2013",
    theme: brAndCAgentsTheme,
    digitalPathEnabled: false,
    logoAreaHeight: "160",
    logoHeight: 138,
  },
  {
    name: "Davidson Cameron Pty Ltd",
    domain: "dcco.com.au",
    favIconUrl: "/icons/davidsonCameron.png",
    logoUrl: "/logos/DavidsonCameron.png",
    companyCode: "5020",
    theme: davidsonCameronTheme,
    digitalPathEnabled: false,
    logoAreaHeight: "112",
    logoHeight: 92,
  },
  {
    name: "Dairy Livestock Services Pty Ltd",
    domain: "dairylivestockservices.com.au",
    favIconUrl: "/icons/DairyLivestockServices.png",
    logoUrl: "/logos/DairyLivestockServices.png",
    companyCode: "2006",
    theme: dairyLivestockServicesTheme,
    digitalPathEnabled: false,
    logoAreaHeight: "86",
    logoHeight: 62,
  },
  {
    name: "Northern Livestock",
    domain: "northernlp.com.au",
    favIconUrl: "/icons/NorthernLivestock.png",
    logoUrl: "/logos/NorthernLivestock.png",
    companyCode: "3014",
    theme: northernlpTheme,
    digitalPathEnabled: false,
    logoAreaHeight: "162",
    logoHeight: 142,
  },
  {
    name: "Kimberley Livestock",
    domain: "kimberleylivestock.com.au",
    favIconUrl: "/icons/KimberleyLivestock.png",
    logoUrl: "/logos/KimberleyLivestock.png",
    companyCode: "8460",
    theme: kimberleyLivestockTheme,
    digitalPathEnabled: false,
    logoAreaHeight: "102",
    logoHeight: 82,
  },
  {
    name: "Southern Livestock Agencies Pty Ltd",
    domain: "nutrienagsolutions.com.au",
    favIconUrl: "/logos/Nutrien.png",
    logoUrl: "/logos/nutrien.png",
    companyCode: "5030",
    theme: nutrienTheme,
    digitalPathEnabled: true,
    logoAreaHeight: "63",
    logoHeight: 38,
  },
];

export const BrandModel = types
  .model({
    isLoaded: types.boolean,
    name: types.string,
    favIconUrl: types.string,
    logoUrl: types.string,
    companyCode: types.string,
    digitalPathEnabled: types.boolean,
    logoAreaHeight: types.string,
    logoHeight: types.number,
  })
  .views((self) => {
    return {
      getTheme() {
        const theme = brands.find((x) => x.name === self.name)?.theme;
        return theme ?? nutrienTheme;
      },
    };
  })
  .actions((self) => {
    return {
      init() {
        self.isLoaded = false;

        const brand = brands.find((x) =>
          window.location?.host?.includes(x.domain)
        );

        const brandToUse = brand ?? defaultBrand;
        self.name = brandToUse.name;
        self.favIconUrl = brandToUse.favIconUrl;
        self.logoUrl = brandToUse.logoUrl;
        self.companyCode = brandToUse.companyCode;
        self.digitalPathEnabled = brandToUse.digitalPathEnabled;
        self.logoAreaHeight = brandToUse.logoAreaHeight;
        self.logoHeight = brandToUse.logoHeight;
        self.isLoaded = true;
      },
    };
  });

export const defaultBrandModel = {
  isLoaded: false,
  name: defaultBrand.name,
  favIconUrl: "",
  logoUrl:
    "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==", // CTA-2451 small transparent image so no image is visible until we've figured out what brand is being displayed
  companyCode: defaultBrand.companyCode,
  digitalPathEnabled: false,
  logoAreaHeight: "63",
  logoHeight: 38,
} as Instance<typeof BrandModel>;
