import apiRoot from "./apiRoot";
import { informationalMessageHelper } from "./systemMessageHelpers";
import LookupApi from "../api/LookupApi";
import BranchApi from "../api/BranchApi";
import CustomerApi from "../api/CustomerApi";
import ApplicationApi from "../api/ApplicationApi";
import AuthApi from "../api/AuthApi";
import MembersApi from "../api/MembersApi";
import AttachmentApi from "../api/AttachmentApi";

export function initializeApiRoot() {
  apiRoot.informationalMessageHelper = informationalMessageHelper;

  apiRoot.authApi = new AuthApi();
  apiRoot.customerApi = new CustomerApi();
  apiRoot.lookupApi = new LookupApi();
  apiRoot.branchApi = new BranchApi();
  apiRoot.applicationApi = new ApplicationApi();
  apiRoot.membersApi = new MembersApi();
  apiRoot.attachmentApi = new AttachmentApi();
}