import {BorrowingEntityType} from "../../models/enums/BorrowingEntityType";
import _ from "lodash";
import {ValidationSeverity} from "../../models/enums/ValidationSeverity";
import {MembersModel} from "../../models/MembersModel";
import {Instance} from "mobx-state-tree";
import {IBorrowingEntityModel} from "../../models/BorrowingEntityModel";
import {MemberType} from "../../models/enums/MemberType";
import {IIndividualModel, IndividualModel} from "../../models/IndividualModel";
import {IFieldModel} from "../../models/IFieldModel";
import {CustomerModel} from "../../models/CustomerModel";

export const getMemberLabel = (entityType: BorrowingEntityType) => {
    return getMemberType(entityType) ?? "Member";
}

export function getMemberType(type: BorrowingEntityType): MemberType | undefined {
    if (type === BorrowingEntityType.Partnership) {
        return MemberType.Partner;
    }
    if (type === BorrowingEntityType.Trust) {
        return MemberType.Trustee;
    }
    if (type === BorrowingEntityType.Company) {
        return MemberType.Director;
    }
    return undefined;
}

export const validateForUnique = (emailValue: string, members: Instance<typeof MembersModel>) => {
    if(_.some(members.members, x => x.id !== members.currentMember.id && x.email.value === emailValue)) {
        members.currentMember.email.setValidationResult({ severity: ValidationSeverity.NoSave, message: "Each co-owner requires a unique email" });
        return false;
    } else {
        members.currentMember.email.setValidationResult({ severity: ValidationSeverity.Valid, message: "" });
        return true;
    }
}

export const validateForOwnershipPercentage = (leadApplicant: Instance<typeof IndividualModel>, members: Instance<typeof MembersModel>, fieldModel: IFieldModel, currentApplicant?: Instance<typeof IndividualModel>) => {
    const applicants = [leadApplicant, ...members.members.filter(x => x.id !== leadApplicant.id)];
    const totalOwnership = applicants.reduce((partialSum, x) => partialSum + +x.ownershipPercentage.value, 0)
    const numericValue = +fieldModel.value;
    console.log("numericValue=" + numericValue);
    if (numericValue > 100 || numericValue < 0) {
        (currentApplicant ?? members.currentMember).ownershipPercentage.setValidationResult({
            message: `Enter a valid Ownership Percentage`,
            severity: ValidationSeverity.NoSave
        });
    } else if(totalOwnership > 100) {
        (currentApplicant ?? members.currentMember).ownershipPercentage.setValidationResult({ severity: ValidationSeverity.NoSave, message: "Total % Ownership must be less than or equal to 100 for an organisation." });
    } else {
        (currentApplicant ?? members.currentMember).ownershipPercentage.setValidationResult({ severity: ValidationSeverity.Valid, message: "" });
    }
}

export function getMembersToDisplay(customer: Instance<typeof CustomerModel>, members: Instance<typeof MembersModel>, isLoading = false, isSaving = false): Instance<typeof IndividualModel>[] {
    if (isLoading || isSaving) {
        return [];
    }

    return [customer.individual, ...members.members.filter(member => !member.isLeadApplicant())];
}

export const isAtMaximumMembers = (leadApplicant: IIndividualModel, borrowingEntity: IBorrowingEntityModel, members: Instance<typeof MembersModel>) => {
    if((borrowingEntity.type === BorrowingEntityType.Partnership || borrowingEntity.type === BorrowingEntityType.Trust || borrowingEntity.type === BorrowingEntityType.HobbyFarm)) {
        const maxMembers = members.maxMembers(borrowingEntity.type);
        const length = getMembersToDisplay(leadApplicant, members).length;
        return length === maxMembers;
    }
    return false;
}
