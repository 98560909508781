import React, {useEffect, useState} from "react";
import { makeStyles } from "tss-react/mui";
import {observer} from "mobx-react-lite";
import {Grid, Link, useMediaQuery, useTheme} from "@mui/material";
import {useMst} from "./models/Root";
import {Button, Icon} from "@nutrien/ddc-components";
import {useNavigate} from "react-router-dom";
import ActionBar from "./components/common/ActionBar";
import { BorrowingEntityType } from "./models/enums/BorrowingEntityType";
import {ApplicantType} from "./models/enums/ApplicantType";
import BrandLogo from "./components/common/BrandLogo";
import StepOne from "./components/assets/CanolaCircle.png";
import StepTwo from "./components/assets/SheepCircle.png";
import styled from "@emotion/styled";
import {DisplayError, ThemeProps} from "./components/Account";
import {css} from "@emotion/css";
import CheckBoxFieldWrapper from "./components/editors/CheckBoxFieldWrapper";
import {Body1} from "./components/common/Text";
import {ValidationSeverity} from "./models/enums/ValidationSeverity";
import ProgressIndicator from "./components/common/ProgressIndicator";
import {ApplicationSource} from "./models/AccountModel";
import {MemberType} from "./models/enums/MemberType";


const useStyles = makeStyles()(
    (theme) => ({
        companyText: {
            font: "Open Sans",
            fontSize: "22px",
            color: theme.palette.primary.contrastText,
            fontWeight: 800,
            [theme.breakpoints.down("sm")]: {
                fontWeight: 600,
                fontSize: "21px"
            }
        },
        topBar: {
            width: "100%",
            color: theme.palette.primary.contrastText,
            background: theme.palette.primary.main,
            padding: "16px",
            textAlign: "center",
            "& .text": {
                font: "Open Sans",
                fontWeight: 600,
                fontSize: "21px",
                lineHeight: "28px",
                color: theme.palette.primary.contrastText,
            },
            [theme.breakpoints.down("sm")]: {
                "& .text": {
                    fontSize: "12px",
                    lineHeight: "18px"
                },
                "& .text + .text": {
                    marginTop: "8px"
                },
            }
        },
        subHeaderBar: {
            width: "100%",
            padding: "16px",
            textAlign: "center",
            "& .text": {
                font: "Open Sans",
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "20px",
                color: theme.palette.text.secondary
            },
            [theme.breakpoints.down("sm")]: {
                "& .text": {
                    fontSize: "12px",
                    lineHeight: "14.4px"
                }
            }
        }
    })
);



const CardMainText = styled.span<ThemeProps>`
  font-family: Open Sans,sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  margin-bottom: 14px;
  margin-top: 25px;
  display: block;
  color: ${(props) => props.theme.palette.primary.main};
`;

const CardSubText = styled.span<ThemeProps>`
  font-family: Open Sans,sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  display: block;
  margin-bottom: 14px;
  color: ${(props) => props.theme.palette.text.secondary};
`;


const LandingScreen = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const { customer, borrowingEntitySearch } = useMst();
    const navigate = useNavigate();
    const theme = useTheme();

    const load = async () => {
        await customer.load(sessionStorage.getItem("authorization") ?? "");

        if(customer.source === "Customer" && customer.borrowingEntity?.type === BorrowingEntityType.Company) {
            await borrowingEntitySearch.pullIndividuals(customer.borrowingEntity?.id);
        }
    }

    useEffect(() => {
        setIsLoading(true);

        load()
          .finally(() => {
            if (customer.source === ApplicationSource.Digital) {
                if(customer.applicationId === 0) {
                    navigate("/branches");
                } else if(customer.individual.isLeadApplicant()){
                    navigate("/entity");
                }
            }

            setIsLoading(false);
        });
    }, [customer, navigate]);  // eslint-disable-line react-hooks/exhaustive-deps

    const includeSecondStep = customer.borrowingEntity.type !== BorrowingEntityType.SoleTrader;

    const isSmall = useMediaQuery("(max-width:959.95px)");

    const { classes } = useStyles();

    const cardCss = css`
      margin: 0 16px 32px 16px !important;
      text-align: center;
    `;

    const cardTextAreaCss = css`
      text-align: left;
      @media (max-width: 992px) {
        text-align: center;
      }
    `;

    const Card = (props: {step: number, mainText: string, subText: string}) => {
        return <Grid item container direction="row" className={cardCss}>
            <Grid item xs={12} md={3}>
                <>
                    {props.step === 1 && <img width={147} src={StepOne} alt="Step 1"/>}
                    {props.step === 2 && <img width={147} src={StepTwo} alt="Step 2"/>}
                </>
            </Grid>
            <Grid item container direction="column" sm={12} md={9} className={cardTextAreaCss}>
                <Grid item className="main-text">
                    <CardMainText theme={theme}>{props.mainText}</CardMainText>
                </Grid>
                {props.subText.split(/\s\s+/).map(str => <Grid item className="sub-text"><CardSubText theme={theme}>{str}</CardSubText></Grid>)}
            </Grid>
        </Grid>
    };

    const NoIconCard = (props: {subText: string}) => {
        return <Grid item container direction="row" className={cardCss}>
            <Grid item container direction="column" sm={12} md={12} className={cardTextAreaCss} style={{paddingLeft: "30px"}}>
                {props.subText.split(/\s\s+/).map(str => <Grid item className="sub-text"><CardSubText theme={theme}>{str}</CardSubText></Grid>)}
            </Grid>
        </Grid>
    };

    const termsAndConditionsCss = {
        openPdfInNewTab: css`
      position: relative;
      left: 4px;
      top: 4px;
    `,
        notQuotingABNContainer: css`
      padding: 0 50px 0 50px;
    `
    }

    const SaveAccept = async () => {
        try {

            if (!customer.isOnlineServicesTermsOfUseAccepted.value) {
                setErrorMessage("You must accept the terms & conditions");
                return;
            } else {
                setErrorMessage("");
            }

            setIsSaving(true);

            await customer.acceptOnlineServicesTermsOfUse();

            navigate(customer.individual.applicantType === ApplicantType.LeadApplicant ? "/entity" : "/review");
        } finally {
            setIsSaving(false);
        }
    }

    function clearErrorIfAcceptChecked() {
        if (customer.isOnlineServicesTermsOfUseAccepted.value) {
            setErrorMessage("");
        }
    }

    function showCoApplicantText(): boolean {
        // single-applicant applications are guaranteed if the entity is a sole trader or a company with a single director
        return !(customer.borrowingEntity?.type === BorrowingEntityType.Company && borrowingEntitySearch.individuals.length === 1) && customer.borrowingEntity?.type !== BorrowingEntityType.SoleTrader;
    }

    function showPersonalGuaranteeCard(): boolean {
        return (customer.individual.isLeadApplicant() && customer.individual.memberType === MemberType.Trustee) // lead applicant Trustee can actually turn out to be a Director of the Trustee
            || (customer.individual.memberType === MemberType.Director); // if they are a director they need to do a personal guarantee
    }

    function getTrustText() {
        return customer.borrowingEntity?.type === BorrowingEntityType.Trust ? " If you operate a Trust, also please have your Trust Deed available." : "";
    }

    return <>
        {isLoading && (
          <ProgressIndicator />
        )}

        {
            !isLoading && <>
                <BrandLogo/>
                <Grid direction="column" container alignContent={"center"} justifyContent="center" className={classes.topBar}>
                    <Grid item className="text">
                        Let's start your new Trade Account application for {isSmall && <br/>}<span className={classes.companyText}>{customer.borrowingEntity.legalName.value}</span>
                    </Grid>
                    <Grid item className="text">
                        Here's what to expect:
                    </Grid>
                </Grid>
                <Grid direction="column" container alignContent={"center"} justifyContent="center" className={classes.subHeaderBar}>
                    <Grid item className="text">
                        You've requested a Trade Account with {customer.branchName} at {customer.companyName}.
                    </Grid>
                </Grid>
                <Grid direction="row" container justifyContent="center" className={css`max-width: 950px; margin: 50px auto 0;`}>
                    {customer.individual.applicantType === ApplicantType.LeadApplicant && <>
                        <Card step={1} mainText="Providing Your Business and Personal Details" subText={"Have your passport or driver's licence ready, as you'll need these details to prove your identity." + getTrustText()}/>
                        {includeSecondStep && showCoApplicantText() && <Card step={2} mainText="Providing Your Co-Applicant’s Details" subText={`You will need to provide basic details for each of the co-applicants of your business and a unique email address for each. We will reach out to them separately as joint applicants for ${customer.borrowingEntity.legalName.value}‘s Trade Account.`}/>}
                    </>}
                    {customer.individual.applicantType === ApplicantType.CoApplicant && <>
                        <Card step={1} mainText="Providing Your Business and Personal Details" subText="Have your passport or driver's licence ready, as you'll need these details to prove your identity."/>
                    </>}
                    {showPersonalGuaranteeCard() && <NoIconCard subText="If your business is a Company, as a Director you are required to provide a personal guarantee for this account. Personal guarantees must be executed with a physical and witnessed signature. As part of your application you will be required to print and upload a signed guarantee document."/>}
                    <NoIconCard subText="You will have an opportunity to read and download the Trade Account Terms and Conditions prior to accepting them."/>
                </Grid>

                <Grid container direction={"row"} justifyContent={"center"} className={css`max-width: 700px; margin: 50px auto 50px;`}>
                    <Grid item container>
                        {errorMessage && (
                            <Grid item xs={12} md={12} className={css`margin: 0 0 -20px 12px !important;`}>
                                <DisplayError error={{message: errorMessage, severity: ValidationSeverity.NoSave}}/>
                            </Grid>
                        )}
                        <Grid container direction={"row"} alignItems={"center"} spacing={1} wrap={"nowrap"}>
                            <Grid item><CheckBoxFieldWrapper disabled={isLoading || isSaving} action={() => clearErrorIfAcceptChecked()} fieldModel={customer.isOnlineServicesTermsOfUseAccepted} /></Grid>
                            <Grid item>
                                <Body1>
                                    <>
                                        I hereby acknowledge I have read and understood the <Link href="https://www.nutrienagsolutions.com.au/terms-and-conditions-privacy-policy" target="_blank" color={theme.palette.info.dark}>Online Services Terms of Use<Icon.ExternalLinkFeather color={theme.palette.info.dark} className={termsAndConditionsCss.openPdfInNewTab}/></Link>
                                    </>
                                </Body1>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <ActionBar
                  leftComponents={<Button fullWidth={false} variant="text" color="primary" onClick={() => {navigate("/endSession")}}>Cancel</Button>}
                  rightComponents={<Button fullWidth={false} variant="contained" color="primary" isLoading={isLoading || isSaving} onClick={() => SaveAccept()}>Let's Get Started</Button>}
                />
            </>
        }
    </>
}

export default observer(LandingScreen);