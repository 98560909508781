import {useCallback, useEffect, useState} from "react";

const SearchLocationMarker = (options: google.maps.MarkerOptions) => {
    const [marker, setMarker] = useState<google.maps.Marker>();

    const getOpts = useCallback(() => {
        return {
            ...options,
            clickable: true,
            icon: {
                path: google.maps.SymbolPath.CIRCLE,
                scale: 8,
                fillColor: "#FFFFFF",
                fillOpacity: 1.0,
                strokeWeight: 3,
                strokeColor: "#FF0000"
            }
        };
    }, [options]);


    useEffect(() => {
        if (!marker) {
            let marker1 = new google.maps.Marker(getOpts());
            setMarker(marker1);
        }

        // remove marker from map on unmount
        return () => {
            if (marker) {
                marker.setMap(null);
            }
        };
    }, [marker, options, getOpts]);

    useEffect(() => {
        if (marker) {
            marker.setOptions(getOpts());
        }
    }, [marker, options, getOpts]);

    return null;
}

export default SearchLocationMarker;