import {observer} from "mobx-react-lite";
import React from "react";
import {useMst} from "../../models/Root";
import CheckBoxFieldWrapper from "../editors/CheckBoxFieldWrapper";
import styled from "@emotion/styled";
import {Grid, useMediaQuery, useTheme} from "@mui/material";
import {css} from "@emotion/css";
import { ReactComponent as WoolIcon } from "../assets/activities/Wool.svg";
import { ReactComponent as CropIcon } from "../assets/activities/Crop.svg";
import { ReactComponent as LivestockIcon } from "../assets/activities/Livestock.svg";
import {ThemeProps} from "../Account";
import PlacesLookupComponent from "./PlacesLookupComponent";

const Container = styled.div<ThemeProps>`
  @media (min-width: 961px) {
      padding: 0 0 0 31px;
      margin: 0;
      position: fixed;
      left: -16px;
      top: 130px;
      z-index: 10;
      align-content: center;
  }
`;

const LabelText = styled.div<ThemeProps>`
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 20px;
    letter-spacing: 0.5px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: ${props => props.theme.palette.text.secondary};
`;

const SearchCriteria = () => {
    const {branches} = useMst();
    const theme = useTheme();
    const isSuperTiny = useMediaQuery("(max-width:350px)")

    const checkboxFieldCss = css`padding-left: 12px !important; width: auto !important; position: relative; top: -24px;`;
    const textFieldCss = css`max-width: calc(100% - 16px)`;

    const showActivities = branches.activities.isCropSupported || branches.activities.isLivestockSupported || branches.activities.isWoolSupported;

    return <Container theme={theme}>
        <Grid container direction="row">
            <PlacesLookupComponent gridOptions={{xs: 12, className: css`margin: 0 0 0 10px !important; padding: 0 !important; width: 315px`}} className={textFieldCss} />
        </Grid>
        {showActivities && (
            <Grid container direction="row">
                {branches.activities.isCropSupported && <CheckBoxFieldWrapper action={() => branches.updateFilteredBranches()} className={checkboxFieldCss} disabled={!branches.activities.isCropSupported} fieldModel={branches.activities.cropField} label={<LabelText theme={theme}><CropIcon className={css`position: relative; top: 4px; margin-right: 4px;`} stroke={theme.palette.text.secondary}/>{!isSuperTiny && "Crop"}</LabelText>} />}
                {branches.activities.isLivestockSupported && <CheckBoxFieldWrapper action={() => branches.updateFilteredBranches()} className={checkboxFieldCss} disabled={!branches.activities.isLivestockSupported} fieldModel={branches.activities.livestockField} label={<LabelText theme={theme}><LivestockIcon className={css`position: relative; top: 4px; margin-right: 4px;`} stroke={theme.palette.text.secondary}/>{!isSuperTiny && "Livestock"}</LabelText>}/> }
                {branches.activities.isWoolSupported && <CheckBoxFieldWrapper action={() => branches.updateFilteredBranches()} className={checkboxFieldCss} disabled={!branches.activities.isWoolSupported} fieldModel={branches.activities.woolField} label={<LabelText theme={theme}><WoolIcon className={css`position: relative; top: 6px; margin-right: 4px;`} stroke={theme.palette.text.secondary}/>{!isSuperTiny && "Wool"}</LabelText>}/> }
            </Grid>
        )}
    </Container>;
}

export default observer(SearchCriteria);