import {observer} from "mobx-react-lite";
import React, {useEffect} from "react";
import {useMst} from "../../models/Root";
import CheckBoxFieldWrapper from "../editors/CheckBoxFieldWrapper";
import styled from "@emotion/styled";
import {StepHeader, Subtitle} from "../common/Text";
import {Grid, useTheme} from "@mui/material";
import {css} from "@emotion/css";
import {Button, Icon} from "@nutrien/ddc-components";
import PlacesLookupComponent from "./PlacesLookupComponent";
import {ThemeProps} from "../Account";
import {useNavigate} from "react-router-dom";

interface ContainerProps extends ThemeProps {
    activityCount: number;
}

function getHeight(activityCount: number) {
    if (activityCount === 0) {
        return "200px";
    } else if (activityCount === 2) {
        return "390px"
    }
    else {
        return "432px";
    }
}

const Container = styled.div<ContainerProps>`
  width: calc(100vw - 64px);
  height: calc(100vh - 64px);
  padding: 40px 0 0 31px;
  margin: 16px;
  max-width: 350px;
  max-height: ${props => getHeight(props.activityCount)};
  z-index: 10;
  background: ${props => props.theme.palette.background.paper};
  position: fixed;
  left: calc(50% - 16px);
  top: calc(50% - 16px);
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 40px;
`;

const InitialSearchCriteria = () => {
    const {branches} = useMst();
    const theme = useTheme();
    const navigate = useNavigate();

    useEffect(() => {
        if (branches.selectedBranch) {
            navigate("/yourBranch");
        }
    }, [branches.selectedBranch]); // eslint-disable-line react-hooks/exhaustive-deps


    function getCurrentLocation() {
        navigator.geolocation.getCurrentPosition(location => {
            branches.setCurrentLocation(location.coords);
            console.log(location.coords);
        });
    }

    const checkboxFieldCss = css`padding-left: 4px !important;`;
    const textFieldCss = css`max-width: 326px; margin: 0 !important; position: relative; top: -8px;`;
    const buttonCss = css`color: ${theme.palette.primary.main} !important; width: 200px;`;

    if (!branches.branches.length) {
        // Don't know which activities to display until after the branches are loaded.  Just don't display anything.
        return <></>;
    }


    const activityCount = [branches.activities.isCropSupported, branches.activities.isLivestockSupported, branches.activities.isWoolSupported].filter(x => x).length;
    const showActivities = activityCount > 0;

    return <Container theme={theme} activityCount={activityCount}>
        <Grid container direction="column" spacing={2} className={css`padding-right: 16px;`}>
            {showActivities && <>
                    <Grid item>
                        <StepHeader theme={theme}>Please select all of your activities</StepHeader>
                    </Grid>
                    <Grid item>
                        <Subtitle>Activities</Subtitle>
                        {/*    TODO - put the tooltip in for "Activities" */}
                    </Grid>
                    {branches.activities.isCropSupported && <CheckBoxFieldWrapper className={checkboxFieldCss} fieldModel={branches.activities.cropField} />}
                    {branches.activities.isLivestockSupported && <CheckBoxFieldWrapper className={checkboxFieldCss} fieldModel={branches.activities.livestockField} />}
                    {branches.activities.isWoolSupported && <CheckBoxFieldWrapper className={checkboxFieldCss} fieldModel={branches.activities.woolField} />}
                </>
            }
            <Grid item>
                <StepHeader theme={theme}><Icon.MapPinFeather stroke={theme.palette.primary.main} className={css`margin-right: 12px; margin-left: -4px; margin-top: 8px; position: relative; top: 3px;`} />Find a branch nearest you</StepHeader>
            </Grid>
            <PlacesLookupComponent disabled={!branches.activities.isActivitySelected()} gridOptions={{xs: 12, className: css`margin: 0 0 0 10px !important; padding: 0 !important;`}} className={textFieldCss} />
            {navigator?.geolocation && <Button  disabled={!branches.activities.isActivitySelected()} variant="text" onClick={getCurrentLocation} className={buttonCss} color="inherit"><Icon.NavigationFeather stroke={theme.palette.primary.main} className={css`margin-right: 8px`}/>or search by current location</Button>}
        </Grid>
    </Container>;
}

export default observer(InitialSearchCriteria);