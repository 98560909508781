import { observer } from "mobx-react-lite";
import React from "react";
import { IIndividualModel } from "../../models/IndividualModel";
import { Grid, useTheme } from "@mui/material";
import IndividualReviewCard from "../Review/IndividualReviewCard";
import { Card } from "@nutrien/ddc-components";
import clsx from "clsx";
import { css } from "@emotion/css";
import EditLinkButton from "../common/EditLinkButton";
import RemoveLinkButton from "../common/RemoveLinkButton";
import {STANDARD_ACTION_LIGHT_COLOR} from "../../config/constants";

interface EntityMemberProps {
  individual: IIndividualModel;
  canEdit?: boolean;
  canRemove?: boolean;
  editClicked?: (individual: IIndividualModel) => void;
  removeClicked?: (individual: IIndividualModel) => void;
}

const EntityMember = (props: EntityMemberProps) => {
  const theme = useTheme();

  const EntityMemberCss = {
    action: css`
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
    `,
    editAction: css`
      background-color: ${STANDARD_ACTION_LIGHT_COLOR};
    `,
    removeAction: css`
      background-color: ${theme.palette.error.light};
    `,
    individualItem: css`
      z-index: 2;
    `,
    actionItem: css`
      z-index: 1;
    `,
    actionCard: css`
      margin: -2px -0px 0px 0px !important;
    `
  }

  const actionContainerSize = props.canRemove ? 6 : 12;

  const EditLinkButtonClicked = () => {
    if(props.editClicked) {
      props.editClicked(props.individual);
    }
  }

  const RemoveLinkButtonClicked = () => {
    if(props.removeClicked) {
      props.removeClicked(props.individual);
    }
  }

  return  (
    <>
      <Grid container direction={"column"}>
        <Grid item className={EntityMemberCss.individualItem}>
          <IndividualReviewCard individual={props.individual} isCollapsable={true}/>
        </Grid>
        {props.canEdit && (
          <Grid item className={EntityMemberCss.actionItem}>
            <Card variant={"elevation"} elevation={1} className={EntityMemberCss.actionCard}>
              <Grid container direction={"row"} justifyContent={"space-between"}>
                {props.canRemove && (
                  <Grid item xs={actionContainerSize} className={clsx(EntityMemberCss.action, EntityMemberCss.removeAction)}>
                    <RemoveLinkButton onClick={() => RemoveLinkButtonClicked()} />
                  </Grid>
                )}
                {props.canEdit && (
                  <Grid item xs={actionContainerSize} className={clsx(EntityMemberCss.action, EntityMemberCss.editAction)}>
                    <EditLinkButton showStartIcon={true} onClick={() => EditLinkButtonClicked()} children={props.individual.getNonStandardEditButtonText()} />
                  </Grid>
                )}
              </Grid>
            </Card>
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default observer(EntityMember);