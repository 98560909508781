export enum BorrowingEntityType {
  GovernmentOther = "GovernmentOther",
  HobbyFarm = "HobbyFarm",
  SoleTrader = "SoleTrader",
  Company = "Company",
  Partnership = "Partnership",
  Trust = "Trust",
  Individual = "Individual"
};

export const TypesWhereAdditionalMembersCanBeEntered: BorrowingEntityType[] = [BorrowingEntityType.HobbyFarm, BorrowingEntityType.Partnership, BorrowingEntityType.Trust, BorrowingEntityType.Company];

export const AllBorrowingEntityTypes: BorrowingEntityType[] = [
  BorrowingEntityType.GovernmentOther,
  BorrowingEntityType.HobbyFarm,
  BorrowingEntityType.SoleTrader,
  BorrowingEntityType.Company,
  BorrowingEntityType.Partnership,
  BorrowingEntityType.Trust,
  BorrowingEntityType.Individual
];

export enum BorrowingEntityStatus {
  Draft = "Draft",
  Cancelled = "Cancelled",
  Active = "Active"
}

export const AllBorrowingEntityStatusTypes: BorrowingEntityStatus[] = [
  BorrowingEntityStatus.Draft,
  BorrowingEntityStatus.Cancelled,
  BorrowingEntityStatus.Active
];

export const getBorrowingEntityTypeName = (type: BorrowingEntityType | string) => {
  if (type === BorrowingEntityType.SoleTrader) {
    return "Sole Trader";
  }

  if (type === BorrowingEntityType.HobbyFarm) {
    return "Hobby Farm";
  }

  if (type === BorrowingEntityType.GovernmentOther) {
    return "Government/Other";
  }

  return type;
};