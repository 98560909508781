import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useMst } from "../../models/Root";
import { observer } from "mobx-react-lite";
import { useFeatureFlags } from "../../hooks/useFeatureFlags";
import {Backdrop, Grid, useTheme} from "@mui/material";
import {Button, Dialog, Icon, IconButton} from "@nutrien/ddc-components";
import { css } from "@emotion/css";
import {MOBILE_BREAKPOINT} from "../../config/constants";
import ActionButton from "../common/ActionButton";

const NOTIFICATION_HEIGHT_DESKTOP = 59;
const NOTIFICATION_HEIGHT_MOBILE = 95;

// NOTE - this works very similarly to the AXP platform notification bar except:
//        1) There is no support for rolesToTarget or appsToTarget (not relevant to customer app)
//        2) The configurations used to show/hide the bar are named differently ("Customer Notification Bar Enabled" and
//           "Customer Notification Bar Details")
//        3) There is only a single pair of settings for the bar (not separate blue and red configurations), but blue/red
//           (and even green) can still be shown by changing the "Customer Notification Bar Details" (see below)

// Config Cat configurations
// -------------------------
// Customer Notification Bar Enabled = true to turn on (and false to turn off)
// Customer Notification Bar Details:
//        red bar with background disabled: {... "enabled": true, "green": false, "active": true, ...}
//        blue bar: {... "enabled": true, "green": false, "active": false, ...}
//        green bar: {... "enabled": true, "green": true, "active": false, ...}

const root = css`
    background-color: #468EA8;
    flex-grow: 1;
    position: fixed;
    width: 100%;
    margin: 0;
    padding: 0;
    left: 0;
    top: 0;
    z-index: 1203;
    font-family: 'Open Sans', Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    height: ${NOTIFICATION_HEIGHT_DESKTOP}px;
    @media (max-width: ${MOBILE_BREAKPOINT}) {
      height: ${NOTIFICATION_HEIGHT_MOBILE}px;
    }
`;

const whiteBackdrop = css`
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1202;
`;


const barButtonFont = css`
  font-size: 11px !important;
  text-transform: uppercase;
`;

const barCloseButton = css`
  position: absolute;
  right: 0;
`;

const dialogContent = css`
  font-family: 'Open Sans', Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
`;

const green = css`
  background-color: #2AAB3F;
`;

const alert = css`
  background-color: #E96A6A;
`;

const notificationContainer = css`
   text-align: center;
   color: #fff;
   height: 100%;
   margin: 0;
   padding-left: 59px;
   padding-right: 59px;
`;


const NotificationBarComponent = () => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const theme = useTheme();

    const barButton = css`
      color: ${theme.palette.common.white};
      height: auto;
      padding: 5px;
    `;

    const handleModalClickOpen = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleClose = () => {
        sessionStorage.setItem("hideNotificationBar", "true");
        notificationBarState.setIsOpen(false);
    };

    const {
        customerNotificationBarEnabled,
        customerNotificationBarDetails
    } = useFeatureFlags();

    const { notificationBar: notificationBarState } = useMst();

    const hideNotificationBar: boolean = sessionStorage.getItem("hideNotificationBar") === "true";

    useEffect(() => {
        const notificationBar = customerNotificationBarEnabled;
        const notificationBarDetails = customerNotificationBarEnabled
            ? JSON.parse(customerNotificationBarDetails) : {};

        notificationBarState.update(notificationBarDetails, notificationBar, hideNotificationBar);
    }, [customerNotificationBarEnabled, customerNotificationBarDetails]); // eslint-disable-line react-hooks/exhaustive-deps

    function openUrl(url: string) {
        window.open(url, "_blank");
    }
    
    return (
        <>
            {notificationBarState.isOpen && (
                <>
                    <div
                        className={clsx(root, {
                            [alert]: notificationBarState.active,
                            [green]: notificationBarState.green
                        })}
                    >
                        {!notificationBarState.active && (
                            <div className={clsx(barCloseButton)}>
                                <IconButton onClick={handleClose}>
                                    <Icon.XFeather stroke={theme.palette.common.white} fontSize="large" />
                                </IconButton>
                            </div>
                        )}
                        <Grid container justifyContent="center" alignItems="center" className={notificationContainer} spacing={1}>
                            <Grid item xs={12} sm={7} md={8}>
                                {notificationBarState.barText} <strong>{notificationBarState.date} </strong>
                                {notificationBarState.timeText} <strong>{notificationBarState.time}</strong>{" "}
                            </Grid>
                            <Grid item container justifyContent="center" alignItems="center" spacing={1} xs={12} sm={5} md={4}>
                                <Grid item>
                                    <Button
                                        classes={{ root: barButton }}
                                        style={{ display: "inline", color: theme.palette.common.white }}
                                        size="small"
                                        fullWidth={false}
                                        variant="outlined"
                                        aria-label="open-modal"
                                        color="inherit"
                                        onClick={() => (notificationBarState.buttonUrl ? openUrl(notificationBarState.buttonUrl) : handleModalClickOpen())}
                                    >
                                        <div className={barButtonFont}>{notificationBarState.buttonText}</div>
                                    </Button>
                                </Grid>
                                {notificationBarState.button2Url && (
                                    <Grid item>
                                        <Button
                                            classes={{ root: barButton }}
                                            style={{ display: "inline", color: theme.palette.common.white }}
                                            size="small"
                                            fullWidth={false}
                                            variant="outlined"
                                            aria-label="open-url-2"
                                            color="inherit"
                                            onClick={() => openUrl(notificationBarState.button2Url)}
                                        >
                                            <div className={barButtonFont}>{notificationBarState.button2Text}</div>
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </div>
                    <Dialog
                        onClose={handleCloseModal}
                        aria-labelledby="customized-dialog-title"
                        open={modalOpen}
                        title={notificationBarState.modalTitle}
                        primaryActions={
                            <Grid container direction="row-reverse">
                                <Grid item>
                                    <ActionButton variant="contained" color="primary" style={{ display: "block" }} fullWidth={false} onClick={handleCloseModal}>
                                        Close
                                    </ActionButton>
                                </Grid>
                            </Grid>
                        }
                    >
                        <div className={dialogContent}>{notificationBarState.modalText}</div>
                    </Dialog>
                    <Backdrop open={notificationBarState.isBackdropOpen} className={whiteBackdrop} />
                </>
            )}
        </>
    );
};

export default observer(NotificationBarComponent);
