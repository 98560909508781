import {Grid} from "@mui/material";
import { IBooleanFieldModel } from "../../models/IFieldModel";
import { RadioGroup} from "@nutrien/ddc-components";
import React from "react";
import { observer } from "mobx-react-lite";
import { Label } from "../common/DDCComponents";

export interface CheckBoxFieldWrapperProps {
  fieldModel: IBooleanFieldModel & { update: (value: boolean) => void };
  description?: JSX.Element;
  action?: () => void;
  disabled?: boolean;
  gridOptions?: any;
}

const NO = "No";
const YES = "Yes";

const YesNoRadioButtonFieldWrapper = ({ description, fieldModel, disabled, gridOptions }: CheckBoxFieldWrapperProps) => {
  return (
  <Grid item {...gridOptions}>
      {description && <>
        <Label error={false}>{fieldModel.title}</Label>
        {description}
      </>}
      <RadioGroup disabled={disabled} label={description ? "" : fieldModel.title} id={fieldModel.id} options={[{value: YES, label: YES}, {value: NO, label: NO}]} onChange={(newValue) => {fieldModel.update(newValue === YES)}} value={fieldModel.value ? YES : NO} margin={description ? "none" : "normal"} />
  </Grid>
  );
};

export default observer(YesNoRadioButtonFieldWrapper);
