import {ButtonProps} from "@nutrien/ddc-components/dist/components/Button";
import {Button, Icon} from "@nutrien/ddc-components";
import {STANDARD_ACTION_COLOR} from "../../config/constants";
import React from "react";

const AddLinkButton = (props: ButtonProps) => {
  return (
    <Button
      {...props}
      fullWidth={false}
      variant="text"
      style={{color: STANDARD_ACTION_COLOR}}
      startIcon={<Icon.PlusFeather stroke={STANDARD_ACTION_COLOR} />}
    >
      {props.children ? props.children : "Edit"}
    </Button>
  )
}

export default AddLinkButton;