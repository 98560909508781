import {applySnapshot, cast, flow, getParent, Instance, types} from "mobx-state-tree";
import FieldModel, {defaultFieldModel} from "./FieldModel";
import BooleanFieldModel, {defaultBooleanFieldModel} from "./BooleanFieldModel";
import {CustomerApplicationDto} from "../dto/CustomerApplicationDto";
import {cancelChanges, IFieldDefinition, initializeFields} from "./shared";
import apiRoot from "../helpers/apiRoot";
import {PicNumbersModel} from "./PicNumbersModel";
import {IBooleanFieldModel, IFieldModel} from "./IFieldModel";
import {defaultValidationResult, FieldValidationModel} from "./FieldValidationModel";
import {ValidationSeverity} from "./enums/ValidationSeverity";
import {conditionalRequiredValidationBuilder, requiredValidationBuilder} from "../validation/validationFunctions";
import {bankBSBValidation} from "../validation/bankBSBValidation";
import _ from "lodash";
import {ICustomerModel} from "./CustomerModel";
import {parseCurrency} from "../helpers/numberHelpers";

export const CROP_DESCRIPTION = "Crop (including Store Merchandise)";
export const WOOL_DESCRIPTION = "Wool";
export const LIVESTOCK_DESCRIPTION = "Livestock";

export const WOOL_ACTIVITY = "Wool";
export const LIVESTOCK_ACTIVITY = "Livestock";
export const CROP_ACTIVITY = "";

interface IPaymentType {
    label: string,
    value: string
}

const DEFAULT_PAYMENT_TYPE : IPaymentType = {
    label: "",
    value: ""
}

export const EFT_PAYMENT_TYPE : IPaymentType = {
    label: "Electronic Funds Transfer",
    value: "EFT"
}

export const CHEQUE_PAYMENT_TYPE : IPaymentType = {
    label: "Cheque",
    value: "Cheque"
}

export const PAYMENT_TYPES = [
    EFT_PAYMENT_TYPE,
    CHEQUE_PAYMENT_TYPE
];

export enum ApplicationSource {
    Customer = "Customer",
    Digital = "Digital"
}

interface IActivity {
    label: string,
    value: string
}

// @ts-ignore
export const AccountModelFieldBuilder: (self: IAccountModel) => IFieldDefinition[] = (self: IAccountModel) => {
    const paymentTypeRequiredPredicate = (model: typeof self) => model.isWool.value || model.isLivestock.value;
    const bankingDetailsRequiredPredicate = (model: typeof self) => paymentTypeRequiredPredicate(model) && model.paymentType.value === EFT_PAYMENT_TYPE.value;
    const monthlySpendRequiredPredicate = (model: typeof self) => (getParent(model) as ICustomerModel).source === ApplicationSource.Digital;
    return [
        {
            title: "Industry",
            field: self.anzsicCode,
            validation: (title: string) => requiredValidationBuilder(ValidationSeverity.NoSave, title, false)
        } as IFieldDefinition,
        {
            title: "Wool",
            field: self.isWool
        } as IFieldDefinition,
        {
            title: "Livestock",
            field: self.isLivestock
        } as IFieldDefinition,
        {
            title: "Add Transit insurance",
            field: self.addTransitInsurance
        } as IFieldDefinition,
        {
            title: "Payment Type",
            field: self.paymentType,
            validation: (title: string) => conditionalRequiredValidationBuilder<typeof self>(ValidationSeverity.NoSave, paymentTypeRequiredPredicate, title, false)
        } as IFieldDefinition,
        {
            title: "BSB",
            field: self.bankBSB,
            validation: bankBSBValidation
        } as IFieldDefinition,
        {
            title: "Account Number",
            field: self.accountNumber,
            validation: (title: string) => conditionalRequiredValidationBuilder<typeof self>(ValidationSeverity.NoSave, bankingDetailsRequiredPredicate, title, false)
        } as IFieldDefinition,
        {
            title: "Account Name",
            field: self.accountName,
            validation: (title: string) => conditionalRequiredValidationBuilder<typeof self>(ValidationSeverity.NoSave, bankingDetailsRequiredPredicate, title, false)
        } as IFieldDefinition,
        {
            title: "Crop (including Store Merchandise)",
            field: self.isMerchandiseOrFertiliser
        } as IFieldDefinition,
        {
            title: "Anticipated Monthly Spend",
            field: self.monthlySpend,
            validation: (title: string) => conditionalRequiredValidationBuilder<typeof self>(ValidationSeverity.NoSave, monthlySpendRequiredPredicate, title, false)
        } as IFieldDefinition,
    ];
};

function getActivities(self: {isWool: IBooleanFieldModel, isLivestock: IBooleanFieldModel, isMerchandiseOrFertiliser: IBooleanFieldModel}):  IActivity[] {
    return [
        {label: CROP_DESCRIPTION, value: CROP_ACTIVITY, isSelected: self.isMerchandiseOrFertiliser.value},
        {label: WOOL_DESCRIPTION, value: WOOL_ACTIVITY, isSelected: self.isWool.value},
        {label: LIVESTOCK_DESCRIPTION, value: LIVESTOCK_ACTIVITY, isSelected: self.isLivestock.value},
    ].filter(x => x.isSelected);
}

export const AccountModel = types.model({
    id: types.number,
    anzsicCode: FieldModel,
    isWool: BooleanFieldModel,
    isLivestock: BooleanFieldModel,
    isMerchandiseOrFertiliser: BooleanFieldModel,
    addTransitInsurance: BooleanFieldModel,
    paymentType: FieldModel,
    bankBSB: FieldModel,
    accountNumber: FieldModel,
    accountName: FieldModel,
    livestockPicNumbersModel: PicNumbersModel,
    woolPicNumbersModel: PicNumbersModel,
    activitiesValidationModel: FieldValidationModel,
    monthlySpend: FieldModel
})
    .actions((self) => ({
        reset() {
            applySnapshot(self, defaultAccountModel);
        },
        initialize() {
            this.reset();
            initializeFields(self, AccountModelFieldBuilder);
        },
        validateActivities() {
            const hasActivities = self.isWool.value || self.isLivestock.value || self.isMerchandiseOrFertiliser.value;
            if (hasActivities) {
                self.activitiesValidationModel = cast(defaultValidationResult)
            } else {
                self.activitiesValidationModel = cast({  message: "At least one Activity is required", severity: ValidationSeverity.NoSave})
            }
        },
        validatePicNumbers(model: Instance<typeof PicNumbersModel>) {
          model.validate();
        },
        cancelChanges() {
            cancelChanges(self, AccountModelFieldBuilder);
        }
    }))
    .actions((self) => ({
        isValid: flow(function* save() {
            self.validateActivities();
            self.validatePicNumbers(self.woolPicNumbersModel);
            self.validatePicNumbers(self.livestockPicNumbersModel);
            yield self.anzsicCode.validate();
            yield self.paymentType.validate();
            yield self.bankBSB.validate();
            yield self.accountNumber.validate();
            yield self.accountName.validate();

            return self.anzsicCode.validationResult.isValid() &&
                self.paymentType.validationResult.isValid() &&
                self.bankBSB.validationResult.isValid() &&
                self.accountNumber.validationResult.isValid() &&
                self.accountName.validationResult.isValid() &&
                self.activitiesValidationModel.isValid() &&
                self.woolPicNumbersModel.validation.isValid() &&
                self.livestockPicNumbersModel.validation.isValid();
        }),
        isValidForEntity: flow(function* isValidForEntity() {
            yield self.anzsicCode.validate();
            yield self.monthlySpend.validate();

            return self.anzsicCode.validationResult.isValid() &&
              self.monthlySpend.validationResult.isValid();
        }),
        load: function (dto: CustomerApplicationDto) {
            try {
                self.initialize();

                self.id = dto.id;
                self.anzsicCode.updateOnly(dto.anzsicCode, true);
                self.isWool.update(dto.isWool, true);
                self.isLivestock.update(dto.isLivestock, true);
                self.isMerchandiseOrFertiliser.update(dto.isMerchandiseOrFertiliser, true);
                self.addTransitInsurance.update(dto.addTransitInsurance, true);
                self.paymentType.updateOnly(dto.paymentType, true);
                self.bankBSB.updateOnly(dto.bankBSB, true);
                self.accountNumber.updateOnly(dto.accountNumber, true);
                self.accountName.updateOnly(dto.accountName, true);
                self.livestockPicNumbersModel.load(dto.picNumbers);
                self.woolPicNumbersModel.load(dto.picNumbers);
                self.monthlySpend.updateOnly(dto.monthlySpend ?? undefined);
            } catch (e) {
                console.log(e);
            }
        },
        save: flow(function* save() {
            yield apiRoot.applicationApi.update({
                id: self.id,
                anzsicCode: self.anzsicCode.value,
                isWool: self.isWool.value,
                isLivestock: self.isLivestock.value,
                isMerchandiseOrFertiliser: self.isMerchandiseOrFertiliser.value,
                addTransitInsurance: self.addTransitInsurance.value,
                paymentType: self.paymentType.value,
                bankBSB: self.bankBSB.value,
                accountNumber: self.accountNumber.value,
                accountName: self.accountName.value,
                picNumbers: [...self.livestockPicNumbersModel.getDtos(), ...self.woolPicNumbersModel.getDtos()],
                monthlySpend: !_.isNil(self.monthlySpend.value) ? parseCurrency(self.monthlySpend.value) : null
            })
        })
    })).views(self => ({
        getActivityDescriptions() {
            return getActivities(self).map(x => x.label).join(", ");
        },
        isCropActivityOnly() {
          const activities = getActivities(self);
          return activities.length === 1 && activities[0].value === CROP_ACTIVITY;
        },
        getPaymentType() : IPaymentType {
            return PAYMENT_TYPES.find(x => x.value === self.paymentType.value) ?? DEFAULT_PAYMENT_TYPE;
        },

        isEft() : boolean {
            return self.paymentType.value === EFT_PAYMENT_TYPE.value;
        },
        isLivestockOrWool() {
          return self.isWool.value || self.isLivestock.value;
        },
    }));

export const defaultAccountModel = {
    id: 0,
    anzsicCode: defaultFieldModel,
    isWool: defaultBooleanFieldModel,
    isLivestock: defaultBooleanFieldModel,
    isMerchandiseOrFertiliser: defaultBooleanFieldModel,
    addTransitInsurance: defaultBooleanFieldModel,
    paymentType: defaultFieldModel,
    bankBSB: defaultFieldModel,
    accountNumber: defaultFieldModel,
    accountName: defaultFieldModel,
    activitiesValidationModel: defaultValidationResult,
    livestockPicNumbersModel: {activity: LIVESTOCK_ACTIVITY, picNumbers: [] as IFieldModel[], validation: defaultValidationResult},
    woolPicNumbersModel: {activity: WOOL_ACTIVITY, picNumbers: [] as IFieldModel[], validation: defaultValidationResult},
    monthlySpend: defaultFieldModel
} as Instance<typeof AccountModel>;

// @ts-ignore
export type IAccountModel = Instance<typeof AccountModel>;