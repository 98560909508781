import {observer} from "mobx-react-lite";
import React from "react";
import ApplicationContainer from "../common/ApplicationContainer";
import {Grid} from "@mui/material";
import {Body1} from "../common/Text";

interface InvalidTokenProps {
  message?: string;
}

const InvalidToken = (props: InvalidTokenProps) => {
  return (
    <>
      <ApplicationContainer label={"Trade Account Application"}>
        <Grid container direction={"column"} justifyContent={"center"} alignItems={"flex-start"}>
          <Grid item className={"center"}>
            <Body1>
              {props.message ? props.message : "This link is no longer available.  Please contact your branch if you'd like open a trade account application."}
            </Body1>
          </Grid>
        </Grid>
      </ApplicationContainer>
    </>
  )
}

export default observer(InvalidToken);
