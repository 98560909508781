import React from "react";
import dayjs, { Dayjs } from "dayjs";
import { IDateFieldModel } from "../../models/IFieldModel";
import { observer } from "mobx-react-lite";
import { Grid } from "@mui/material";
import {DatePicker} from "@nutrien/ddc-components";
import { UI_DATE_FORMAT } from "../../config/constants";
import {DatePickerProps} from "@nutrien/ddc-components/dist/components/DatePicker/DatePicker.model";
import {buildError} from "../../models/shared";


// TODO: Use DatePicker when available in DDC Components
export interface DatePickerFieldWrapperProps extends Omit<DatePickerProps, "id" | "fieldModel" | "onChange" | "value" | "maxDate"> {
  maxDate?: Dayjs;
  fieldModel: IDateFieldModel & { update: (value: Date | null) => void };
  action?: () => void;
  noLabel?: boolean;
  gridOptions?: any;
}

const DatePickerWrapper = observer(({ fieldModel, action, noLabel, gridOptions, ...rest }: DatePickerFieldWrapperProps) => {
  return (
    <Grid item {...gridOptions}>
      <DatePicker
        {...rest}
        {...buildError(fieldModel)}
        id={fieldModel.id}
        value={dayjs(fieldModel.value, { utc: false })}
        label={fieldModel.title}
        inputFormat={UI_DATE_FORMAT}
        onChange={(newValue: Dayjs | null) => {
          fieldModel.update(newValue ? newValue.toDate() : null);
          if (action) {
            action();
          }
        }}
      />

    </Grid>
  );
});

export default DatePickerWrapper;