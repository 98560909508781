import React from "react";
import {Grid, GridProps, Typography} from "@mui/material";
import {observer} from "mobx-react-lite";
import DisplayValue from "./DisplayValueComponent";
import {css} from "@emotion/css";
import {useMst} from "../../models/Root";
import {useLocation, useNavigate} from "react-router-dom";
import {getAccountUrl} from "../../helpers/urlHelpers";
import Line from "../common/Line";
import {EFT_PAYMENT_TYPE} from "../../models/AccountModel";
import EditLinkButton from "../common/EditLinkButton";

const PaymentReview = () => {
  const ROW_GRID_OPTIONS: GridProps = {xs: 12, md: 12};
  const {customer} = useMst();

  const headingCss = {
    heading: css`
      font-size: 20px !important;
      font-weight: 700;
      padding-top: 16px;
      padding-bottom: 16px;
    `,
  };

  const containerCss = css`
    padding-bottom: 40px;
  `;

  const navigate = useNavigate();
  const location = useLocation();

  const onEditClick = () => {
    navigate(getAccountUrl(true), {state: {from: location }});
  }

  return <>
    {customer.account?.isLivestockOrWool() && (<>
      <Grid item {...ROW_GRID_OPTIONS}>
        <Line/>
      </Grid>
      <Grid container direction="row" alignContent={"flex-start"} justifyContent={"space-between"}>
        <Grid item className="text">
          <Typography variant="h3" color="textSecondary" className={headingCss.heading}>
            Livestock or Wool Proceed Instructions
          </Typography>
        </Grid>
        {customer.individual.isLeadApplicant() && (
          <Grid item>
            <EditLinkButton id="editButton" showEndIcon={true} onClick={() => {onEditClick()}}>{"Edit"}</EditLinkButton>
          </Grid>
        )}
      </Grid>
      <Grid container className={containerCss}>
        <DisplayValue label={"Payment Type"} value={customer.account.getPaymentType().label}/>
        {customer.account.getPaymentType() === EFT_PAYMENT_TYPE && (
          <>
            <DisplayValue label={"BSB"} value={customer.account.bankBSB.value}/>
            <DisplayValue label={"Account Number"} value={customer.account.accountNumber.value}/>
            <DisplayValue label={"Account Name"} value={customer.account.accountName.value}/>
          </>
        )}
      </Grid>
    </>)}
  </>
}

export default observer(PaymentReview);