import React, {useEffect, useState} from "react";
import {useMst} from "../../models/Root";
import {observer} from "mobx-react-lite";
import BusinessDetailsReview from "./BusinessDetailsReview";
import AccountDetailsReview from "./AccountDetailsReview";
import PaymentReview from "./PaymentReview";
import ApplicantDetailsReview from "./ApplicantDetailsReview";
import ApplicationContainer from "../common/ApplicationContainer";
import NextButton from "../common/NextButton";
import ActionBar from "../common/ActionBar";
import BackButton from "../common/BackButton";
import {useNavigate} from "react-router-dom";
import ActionButton from "../common/ActionButton";
import {ProgressStep} from "../ProgressBar/ProgressBarComponent";
import {ApplicationSource} from "../../models/AccountModel";

const ApplicationReview = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { customer, members, anzsicCodes } = useMst();

  const navigate = useNavigate();

  const loadMembers = async () => {
    await members.load(customer.applicationId);
  }

  useEffect(() => {
    setIsLoading(true);

    Promise.all([
      anzsicCodes.load(),
      customer.load(sessionStorage.getItem("authorization") ?? "")
    ]).then(() => {
      // Needs to load after Customer to get the ApplicationId
      loadMembers().then(() => {})
        .finally(() => {
          setIsLoading(false);
        })
    });
  }, [customer, anzsicCodes]);  // eslint-disable-line react-hooks/exhaustive-deps

  return <>
    {
      <>
        <ApplicationContainer isLoading={isLoading} currentStep={customer.individual.isLeadApplicant() ? ProgressStep.ReviewStep : undefined}>
          <BusinessDetailsReview canEdit={customer.individual.isLeadApplicant()}/>
          <ApplicantDetailsReview/>
          <AccountDetailsReview canEdit={customer.individual.isLeadApplicant()}/>
          <PaymentReview/>
        </ApplicationContainer>
        <ActionBar
          isLoading={isLoading}
          leftComponents={customer.individual.isLeadApplicant() ? <BackButton onClick={() => {
            if(customer.source === ApplicationSource.Digital && customer.account.isCropActivityOnly()) {
              if(customer.borrowingEntity.canAddMembers()) {
                navigate("/members");
              } else {
                navigate("/entity");
              }
            } else {
              navigate("/account");
            }
          }} /> : undefined}
          rightComponents={
            customer.individual.isLeadApplicant()
              ? <NextButton onClick={() => navigate("/terms")}>Next: Terms & Conditions</NextButton>
              : <ActionButton onClick={() => navigate("/individual")}>Begin Application</ActionButton>
          }
        />
      </>
    }
  </>
}

export default observer(ApplicationReview);