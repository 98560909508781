import _ from "lodash";
import {applySnapshot, cast, flow, Instance, types} from "mobx-state-tree";
import {createIndividualModelFrom, IIndividualModel, IndividualModel} from "./IndividualModel";
import apiRoot from "../helpers/apiRoot";
import {ApplicantType} from "./enums/ApplicantType";
import {BorrowingEntityType} from "./enums/BorrowingEntityType";
import {MemberType} from "./enums/MemberType";

export const MembersModel = types.model({
  isSaving: types.boolean,
  applicationId: types.number,
  members: types.array(IndividualModel),
  currentMember: types.maybe(types.reference(IndividualModel)),
  showMaxMembers: types.maybe(types.boolean)
})
  .views((self) => ({
    maxMembers(borrowingEntityType: BorrowingEntityType) {
      switch(borrowingEntityType) {
        case BorrowingEntityType.SoleTrader: return 1;
        case BorrowingEntityType.HobbyFarm: return 2;
        case BorrowingEntityType.Partnership: return 4;
        case BorrowingEntityType.Trust: return 4;

        default: return 0;
      }
    }
  }))
  .actions((self) => ({
    reset() {
      applySnapshot(self, defaultMembersModel);
    },
    setShowMaxMembers(value: boolean) {
      self.showMaxMembers = value;
    },
    initialize() {
      this.reset();
    }
  }))
  .actions((self) => ({
    setCurrentMember(individual?: IIndividualModel) {
      self.currentMember = undefined;
      self.currentMember = individual;
    },
    removeCurrentMemberFromModel() {
      const individual = self.currentMember;
      self.currentMember = undefined;
      self.members.remove(individual);
    },
    addEmptyAndSetCurrentMember: flow(function* addEmptyAndSetCurrentMember(memberType: MemberType | undefined) {
      self.members.push(yield createIndividualModelFrom(undefined, true, memberType));
      self.currentMember = self.members[self.members.length - 1];
    }),
    load: flow(function* load(applicationId: number, fullEditForLeadApplicant: boolean = false, forceReload: boolean = false) {
      if(self.applicationId === applicationId && !forceReload) {
        return;
      }

      self.initialize();

      const members = yield apiRoot.membersApi.getMembers(applicationId);
      self.applicationId = applicationId;

      const individuals = [];
      const sortedMembers = _.orderBy(members, [(x) => x.applicantType === ApplicantType.LeadApplicant], ["desc"]);
      for(const member of sortedMembers) {
        const onlyBasicInfo = !(member.applicantType === ApplicantType.LeadApplicant && fullEditForLeadApplicant) &&
          (member.applicantType === ApplicantType.CoApplicant || member.memberType !== MemberType.Trustee);
        individuals.push(yield createIndividualModelFrom(member, onlyBasicInfo));
      }
      self.members.replace(individuals);
    })
  }))
  .actions((self) => ({
    removeCurrentMember: flow(function* removeCurrentMember() {
      try {
        self.isSaving = true;

        if(self.currentMember) {
          if (self.currentMember.applicantType === ApplicantType.LeadApplicant) {
            yield self.currentMember.save();
          } else {
            yield apiRoot.membersApi.removeMember(self.applicationId, self.currentMember.id);
            self.removeCurrentMemberFromModel();
          }
        }
      } finally {
        self.isSaving = false;
      }
    }),
    saveCurrentMember: flow(function* saveCurrentMember() {
      try {
        self.isSaving = true;

        if(self.currentMember) {
          if(self.currentMember.isExistingIndividual()) {
            yield self.currentMember.save();
          } else {
            const dto = self.currentMember.getBasicDto();
            yield apiRoot.membersApi.addMember(self.applicationId, dto);
            yield self.load(self.applicationId, false, true);
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        self.isSaving = false;
      }
    })
  }));

export const defaultMembersModel = {
  isSaving: false,
  applicationId: 0,
  members: cast([]),
  currentMember: undefined,
  showMaxMembers: false
} as Instance<typeof MembersModel>
