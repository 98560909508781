let nextInteger = 1;

export function getNextInteger() {
  return nextInteger++;
}

export function removeLeadingZeroes(searchText: string): string {
  //if the search Text is a number, remove leading zeroes, else return as it is
  if (!isNaN(Number(searchText))) {
    return Number(searchText).toString();
  }
  return searchText;
}

export function parseCurrency(value: string) {
  const number = parseFloat(value.replace(/[^0-9.-]+/g, ""));
  return isNaN(number) ? null : number;
}

export function formatCurrency(value: any, defaultValue = "", decimalPlaces = 2) {
  if (value === undefined || value === null || value.toString().replace(/\D/g, "").length === 0) {
    return defaultValue;
  }
  return new Intl.NumberFormat("en-AU", {
    currencyDisplay: "narrowSymbol",
    style: "currency",
    currency: "AUD",
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces
  }).format(typeof value === "string" ? parseCurrency(value) : value);
}