export const isEditFrom = () => {
  return window.location.search.includes("editFrom");
}

export const hasDirectorId = () => {
  return window.location.search.includes("directorId");
}

export const getEntityDetailsUrl = (includeEditFrom = false) => {
  return `/entity${includeEditFrom ? `?editFrom` : ""}`;
}

export const getEntityMembersUrl = (includeEditFrom = false) => {
  return `/members${includeEditFrom ? `?editFrom` : ""}`;
}

export const getAccountUrl = (includeEditFrom = false) => {
  return `/account${includeEditFrom ? `?editFrom` : ""}`;
}

export const getBranchesUrl = (includeEditFrom = false) => {
  return `/branches${includeEditFrom ? `?editFrom` : ""}`;
}

export const getCompanyTrusteeDetails = (includeEditFrom = false, directorId: number | null | undefined = null) => {
  return `/companyTrusteeDetails${includeEditFrom ? `?editFrom` : ""}${directorId ? `?directorId=${directorId}` : ""}`;
}

export const verificationCodeRoutesForPromptOnAppExit: string[] = ["/requestCode", "/customerVerification"]
export const routesToNotPromptOnAppExit:string[] = ["/closed", "/expired", "/customerComplete", "/endSession"];