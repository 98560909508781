import {Instance} from "mobx-state-tree";
import {AttachmentModel} from "../models/AttachmentModel";
import apiRoot from "./apiRoot";
import download from "downloadjs";
import {ALLOWED_ATTACHMENT_TYPES, MAX_ATTACHMENT_FILE_SIZE} from "../config/constants";
import {PreSignedUrlResponseDto} from "../dto/PreSignedUrlResponseDto";

export function isFileValid(file: { name: string; size: number }): boolean {
  return isFileValidSize(file) && isFileAllowedType(file);
}

export function isFileValidSize(file: { name: string; size: number }): boolean {
  return file.size <= MAX_ATTACHMENT_FILE_SIZE;
}

export function isFileAllowedType(file: { name: string; size: number }): boolean {
  const extension = file.name.substring(file.name.lastIndexOf(".") + 1).toUpperCase();
  return ALLOWED_ATTACHMENT_TYPES.some((x) => x === extension);
}

export function isFileNameInvalid(file: { name: string; size: number }): boolean {
  const specialCharacters = /[.]/;
  let fileName = file.name.substring(0, file.name.lastIndexOf("."));
  return specialCharacters.test(fileName);
}


export async function downloadAttachment(attachment: Instance<typeof AttachmentModel>, isBusy: (b: boolean) => void, errorHandler?: (error: any) => void) {
  if (!attachment) {
    return;
  }

  if (!errorHandler) {
    errorHandler = (e) => {
      apiRoot.informationalMessageHelper.addErrorMessage(e);
    };
  }

  try {
    isBusy(true);
    attachment.error();
    const response = await attachment.getAttachmentDownloadUrl();
    await downloadFromPresignedUrl(attachment.fileName, response);
  } catch (error) {
    errorHandler(error);
  } finally {
    isBusy(false);
  }
}
export async function downloadUnsignedPersonalGuaranteeDocument(isBusy: (b: boolean) => void, errorHandler?: (error: any) => void) {
  if (!errorHandler) {
    errorHandler = (e) => {
      apiRoot.informationalMessageHelper.addErrorMessage(e);
    };
  }

  try {
    isBusy(true);
    const response = await apiRoot.attachmentApi.getGeneratedPersonalGuaranteeDownloadUrl();
    await downloadFromPresignedUrl("UnsignedPersonalGuarantee.pdf", response);
  } catch (error) {
    errorHandler(error);
  } finally {
    isBusy(false);
  }
}

async function downloadFromPresignedUrl(fileName: string, response: PreSignedUrlResponseDto) {
  if (response.preSignedUrl && response.success) {
    const fileResponse = await fetch(response.preSignedUrl, { method: "GET" });
    if (fileResponse.status >= 200 && fileResponse.status < 300) {
      download(await fileResponse.blob(), fileName, fileResponse.headers.get("Content-Type") ?? "text/plain");
    } else {
      throw Error("error downloading file: " + fileResponse.statusText);
    }
  } else {
    throw Error("error getting pre-signed URL: " + response.preSignedUrl);
  }
}