import {callAPI} from "./apiUtilities";
import {apiConfig} from "../config/api.config";
import {IApplicationApi} from "./IApplicationApi";
import {CustomerApplicationDto} from "../dto/CustomerApplicationDto";

export default class ApplicationApi implements IApplicationApi {


    get(applicationId: number): Promise<{payload: CustomerApplicationDto}> {
        return callAPI(`${apiConfig.fmsCustomerPathUrl}/auth/applications/${applicationId}`, {
            method: "GET"
        });
    };
    update(request: CustomerApplicationDto): Promise<{}> {
        return callAPI(`${apiConfig.fmsCustomerPathUrl}/auth/applications/${request.id}`, {
            method: "PUT",
            body: JSON.stringify(request)
        });
    };
}
