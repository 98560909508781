import { IFieldValidationModel } from "../models/FieldValidationModel";
import { ValidationSeverity } from "../models/enums/ValidationSeverity";
import { IIndividualModel } from "../models/IndividualModel";
import { requiredValidationBuilder } from "./validationFunctions";

function isValidPhoneNumber(digitsOnly: string) {
  if (digitsOnly.length === 0) {
    return true;
  }
  if (digitsOnly.length === 6 && digitsOnly.startsWith("13")) {
    return true;
  }
  if (digitsOnly.length === 10) {
    if (
      digitsOnly.startsWith("04") ||
      digitsOnly.startsWith("1300") ||
      digitsOnly.startsWith("1800") ||
      digitsOnly.startsWith("02") ||
      digitsOnly.startsWith("03") ||
      digitsOnly.startsWith("07") ||
      digitsOnly.startsWith("08")
    ) {
      return true;
    }
  }

  return false;
}

export function phoneNumberValidation(value: string): IFieldValidationModel {
  const digitsOnly = value.replace(/\D/g, "");
  if (!isValidPhoneNumber(digitsOnly)) {
    return {
      message: "Invalid phone number",
      severity: ValidationSeverity.NoSave
    };
  }

  return {
    message: "",
    severity: ValidationSeverity.Valid
  };
}

export const primaryPhoneNumberValidation = (title: string) => (value: string, model: IIndividualModel) => {
  if (!model.primaryPhone.value) {
    return requiredValidationBuilder(ValidationSeverity.NoSave, title, false)(value);
  }
  return phoneNumberValidation(value);
};