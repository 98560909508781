import { flow, getParent, types } from "mobx-state-tree";
import { FieldValidationModel } from "./FieldValidationModel";
import { ValidationFunction } from "../validation/ValidationFunction";
import { ValidationSeverity } from "./enums/ValidationSeverity";
import { reValidateFields } from "./shared";
import { IBooleanFieldModel } from "./IFieldModel";
import { defaultFieldModel } from "./FieldModel";

const BooleanFieldModel = types
  .model({
    title: types.string,
    value: types.boolean,
    initialValue: types.boolean,
    reValidateFields: types.array(types.string),
    id: types.string,
    validationResult: FieldValidationModel
  })
  .actions((self) => ({
    setValidationResult(result: any) {
      self.validationResult = result;
    }
  }))
  .actions((self) => {
    let validation: ValidationFunction<boolean> | null = null;

    async function validate() {
      self.setValidationResult(validation ? await validation(self.value, getParent(self)) : { severity: ValidationSeverity.Valid, message: "" });
    }

    async function initialize(
      title: string,
      validationFunction: ValidationFunction<boolean> | null = null,
      id?: string,
      fieldsToRevalidate = [],
      defaultValue = false
    ) {
      self.value = defaultValue;
      self.title = title;
      self.id = id ?? title;
      self.reValidateFields.replace(fieldsToRevalidate);
      validation = validationFunction;
      // await validate();  Customer Path - should not validate off the initialization
    }

    return {
      initialize,
      validate,
      async revert() {
        self.value = self.initialValue;
        await validate();
      }
    };
  })
  .views((self) => ({
    isDirty() {
      return self.initialValue !== self.value;
    },
    getFormattedValue() {
      return self.value ? "Yes" : "No";
    }
  }))
  .actions((self) => ({
    updateOnly(newValue: any, isInitialValue = false) {
      if (typeof newValue === "string") {
        if (newValue.toLowerCase() === "true") {
          newValue = true;
        } else if (newValue.toLowerCase() === "false") {
          newValue = false;
        }
      }
      self.value = Boolean(newValue);
      if (isInitialValue) {
        self.initialValue = Boolean(newValue);
      }
    }
  }))
  .actions((self) => ({
    update: flow(function* update(newValue: any, isInitialValue = false) {
      self.updateOnly(newValue, isInitialValue);

      if(!isInitialValue) {
        yield self.validate();
        yield reValidateFields(self);
      }
    })
  }));

export default BooleanFieldModel;

export const defaultBooleanFieldModel: IBooleanFieldModel = {
  ...defaultFieldModel,
  value: false,
  initialValue: false,
  defaultValue: false
};