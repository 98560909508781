import React, {useEffect} from "react";
import {rootStore} from "../models/Root";
import InvalidToken from "./InvalidToken/InvalidToken";

const SessionEnded = () => {
    useEffect(() => {
        sessionStorage.clear();
        rootStore.reset();
    }, []);

    // Temporary until we get the contents
    return <>
        <InvalidToken message={"You have been signed out of the system.  You may close this window or tab."} />
    </>
}

export default SessionEnded;