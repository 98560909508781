import { Button } from "@nutrien/ddc-components";
import React from "react";
import { ButtonProps } from "@nutrien/ddc-components/dist/components/Button";

const CancelButton = (props: ButtonProps) => {
  return (
    <Button
      fullWidth={false}
      variant="text"
      {...props}
    >Cancel
    </Button>
  )
}

export default CancelButton;