import {BorrowingEntityType} from "./BorrowingEntityType";

export enum MemberType {
  Borrower = "Borrower",
  Director = "Director",
  BeneficialOwner = "BeneficialOwner",
  Trustee = "Trustee",
  Partner = "Partner",
  Member = "Member"
}

export const MemberTypes: MemberType[] = [
  MemberType.Borrower,
  MemberType.Director,
  MemberType.BeneficialOwner,
  MemberType.Trustee,
  MemberType.Partner,
  MemberType.Member
];

export const getMemberTypeName = (borrowingEntityType: BorrowingEntityType) => {
  if (borrowingEntityType === BorrowingEntityType.Trust) {
    return "trustee";
  }

  if (borrowingEntityType === BorrowingEntityType.Partnership) {
    return "partner";
  }
  if(borrowingEntityType === BorrowingEntityType.HobbyFarm){
    return "member"
  }

  return ""
}