import {IGenericDictionary} from "../../helpers/GenericDictionary";

export enum AttachmentType {
  TrustDeed = "Trust Deed",
  PersonalGuarantee = "Personal Guarantee"
}

export const ATTACHMENT_VALUES: IGenericDictionary<{ value: string; ui: string; }> = {
  [AttachmentType.TrustDeed]: { value: "TrustDeed", ui: AttachmentType.TrustDeed },
  [AttachmentType.PersonalGuarantee]: { value: "PersonalGuarantee", ui: AttachmentType.PersonalGuarantee }
};

export const ALL_ATTACHMENT_TYPES = [
  AttachmentType.TrustDeed,
  AttachmentType.PersonalGuarantee
];

export enum AttachmentParentType {
  BorrowingEntity = "entities",
  Individual = "individuals",
  Application = "applications"
}

export const ALL_ATTACHMENT_PARENT_TYPES = [
  AttachmentParentType.BorrowingEntity,
  AttachmentParentType.Individual,
  AttachmentParentType.Application
];