import React from "react";
import {Grid, GridProps, Typography, useMediaQuery} from "@mui/material";
import {observer} from "mobx-react-lite";
import Line from "../common/Line";
import {css} from "@emotion/css";
import IndividualReviewCard from "./IndividualReviewCard";
import {useMst} from "../../models/Root";
import {useLocation, useNavigate} from "react-router-dom";
import {getEntityMembersUrl} from "../../helpers/urlHelpers";
import {SM_MEDIA_QUERY} from "../../config/constants";
import EntityReviewCard from "./EntityReviewCard";
import {BorrowingEntityType} from "../../models/enums/BorrowingEntityType";
import EditLinkButton from "../common/EditLinkButton";

const ApplicantDetailsReview = () => {
  const ROW_GRID_OPTIONS: GridProps = {xs: 12, md: 12};
  const headingCss = {
    heading: css`
      font-size: 20px !important;
      font-weight: 700;
      padding-top: 16px;
      padding-bottom: 16px;
    `,
  };

  const containerCss = css`
    padding-bottom: 40px;
  `;

  const {customer, members} = useMst();
  const navigate = useNavigate();
  const location = useLocation();

  const isSm = useMediaQuery(SM_MEDIA_QUERY);
  const individualCardGridOptions: GridProps = isSm ? {xs: 12} : {};

  const onEditClick = () => {
    if (customer.borrowingEntity.canAddMembers()) {
      navigate(getEntityMembersUrl(true), {state: {from: location}});
    } else {
      navigate("/entity", {state: {from: location}});
    }
  }

  return (
    <>
      {customer.hasCompanyTrustee() && (
        <>
          <Grid item {...ROW_GRID_OPTIONS}>
            <Line/>
          </Grid>
          <Grid container direction="row" alignContent={"flex-start"} justifyContent={"space-between"}>
            <Grid item className="text">
              <Typography variant="h3" color="textSecondary" className={headingCss.heading}>
                Trustee Details
              </Typography>
            </Grid>
            {customer.individual.isLeadApplicant() && (
              <Grid item>
                <EditLinkButton id="editButton" showEndIcon={true} onClick={() => navigate("/companyTrusteeDetails", {state: {from: location}})}>{"Edit"}</EditLinkButton>
              </Grid>
            )}
          </Grid>
          <Grid container spacing={2} className={containerCss}>
            <Grid item {...individualCardGridOptions}>
              <EntityReviewCard entity={customer.childEntity}/>
            </Grid>
          </Grid>
        </>
      )}
      <Grid item {...ROW_GRID_OPTIONS}>
        <Line/>
      </Grid>
      <Grid container direction="row" alignContent={"flex-start"} justifyContent={"space-between"}>
        <Grid item className="text">
          <Typography variant="h3" color="textSecondary" className={headingCss.heading}>
            {customer.borrowingEntity.type === BorrowingEntityType.Company || customer.hasCompanyTrustee() ? "Director Details" : "Applicant Details"}
          </Typography>
        </Grid>
        {customer.individual.isLeadApplicant() && (
          <Grid item>
            <EditLinkButton id="editButton" showEndIcon={true} onClick={() => onEditClick()}>{"Edit"}</EditLinkButton>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2} className={containerCss}>
        {members.members.map(member => (
          <Grid item {...individualCardGridOptions}>
            <IndividualReviewCard
              individual={member.id === customer.individual.id ? customer.individual : member}
              isCollapsable={member.isLeadApplicant() ? false : true}></IndividualReviewCard>
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default observer(ApplicantDetailsReview);