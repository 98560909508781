import {applySnapshot, flow, Instance, types} from "mobx-state-tree";
import apiRoot from "../helpers/apiRoot";
import {
  BorrowingEntityModel,
  createBorrowingEntityModelFrom,
  defaultBorrowingEntityModel
} from "./BorrowingEntityModel";
import {createIndividualModelFrom, defaultIndividualModel, IndividualModel} from "./IndividualModel";
import {AccountModel, ApplicationSource, defaultAccountModel} from "./AccountModel";
import BooleanFieldModel, {defaultBooleanFieldModel} from "./BooleanFieldModel";
import {getNextInteger} from "../helpers/numberHelpers";
import {AcceptedConditionsRequest} from "../dto/AcceptedConditionsRequest";
import {BorrowingEntityType} from "./enums/BorrowingEntityType";
import {CustomerEntityDto} from "../dto/CustomerDto";
import {DigitalRequest, UpdateDigitalPathRequest} from "../dto/DigitalRequest";
import {MemberType} from "./enums/MemberType";

export const SimpleBranchModel = types.model({
  id: types.identifierNumber,
  code: types.string,
  name: types.string,
  isMerchandiseOrFertiliser: types.boolean,
  isLivestock: types.boolean,
  isWool: types.boolean
});

export const CustomerModel = types.model({
  isLoaded: types.boolean,
  token: types.string,
  branchName: types.string,
  branch: types.maybe(SimpleBranchModel),
  companyName: types.string,
  action: types.maybe(types.string),
  source: types.string,
  applicationId: types.number,
  borrowingEntity: BorrowingEntityModel,
  individual: IndividualModel,
  account: AccountModel,
  isOnlineServicesTermsOfUseAccepted: BooleanFieldModel,
  isTocAccepted: BooleanFieldModel,
  tocAcceptedOn: types.maybe(types.string),
  childEntity: types.maybe(BorrowingEntityModel),
  childEntityPersisted: types.maybe(types.boolean)
})
  .actions((self) => ({
    reset() {
      applySnapshot(self, defaultCustomerModel);
    },
    initialize() {
      this.reset();

      self.borrowingEntity.initialize();
      self.individual.initialize();
    },
    hasCompanyTrustee() {
      return self.borrowingEntity.type === BorrowingEntityType.Trust && self.childEntityPersisted;
    },
    requiresPersonalGuarantee() {
      return self.borrowingEntity.type === BorrowingEntityType.Company || (self.borrowingEntity.type === BorrowingEntityType.Trust && self.childEntityPersisted);
    }
  }))
  .actions((self) => ({
    setChildEntity: flow(function* setChildEntity(borrowingEntity: CustomerEntityDto) {
      self.childEntity = yield createBorrowingEntityModelFrom(borrowingEntity);
    }),
    load: flow(function* load(token: string) {
      try {
        if(token === self.token) {
          return;
        }

        self.initialize();

        const customer = yield apiRoot.customerApi.getCustomer(token);
        self.token = token;
        if (customer.company) {
          self.companyName = customer.company.name;
        }
        if (customer.branch) {
          self.branchName = customer.branch.name;
          self.branch = customer.branch;
        }

        self.applicationId = customer.applicationId;
        self.action = customer.action;
        self.source = customer.source;

        yield self.isTocAccepted.update(customer.isTocAccepted ?? false, true);
        self.tocAcceptedOn = customer.tocAcceptedOn;

        if (customer.entity) {
          yield self.borrowingEntity.load(customer.entity);
        }
        self.individual = yield createIndividualModelFrom(customer.individual);

        if(customer.childEntity) {
          yield self.childEntity.load(customer.childEntity);
          self.childEntityPersisted = true;
        }

        self.account.load(customer.application);

        self.isLoaded = true;

        // if only one activity is supported - auto-select the activity / deselect other activities
        if ([self.branch?.isMerchandiseOrFertiliser, self.branch?.isLivestock, self.branch?.isWool].filter(b => b).length === 1) {
          self.account.isMerchandiseOrFertiliser.update(self.branch?.isMerchandiseOrFertiliser);
          self.account.isLivestock.update(self.branch?.isLivestock);
          self.account.isWool.update(self.branch?.isWool);
        }
      } catch (e) {
        apiRoot.informationalMessageHelper.addErrorMessage(e);
      }
    }),
    saveAccount: flow(function* saveAccount() {
      const isAccountValid = yield self.account.isValid();

      const canSave = isAccountValid;
      if(canSave) {
        yield self.account.save();
      }
      return canSave;
    }),
    saveDigital: flow(function* saveDigital(companyCode: string) {
      const canSave = yield self.individual.isValid();

      try {
        if(canSave) {
          const request = {
            companyCode: companyCode,
            individual: self.individual.getBasicDto()
          } as DigitalRequest;

          const customerToken = yield apiRoot.customerApi.saveDigital(request);
          return [canSave, customerToken];
        }

        return [canSave, ""];
      } catch (e) {
        apiRoot.informationalMessageHelper.addErrorMessage(e);

        return [false, ""];
      }
    }),
    leadApplicantSave: flow(function* leadApplicantSave() {
      // temporarily check for address issues since there is no way for a user to enter an address currently - see future story CTA-1821
      let isIndividualValid = yield self.individual.isValid();

      if (self.individual.isResidentialAddressSameAsBusinessAddress.value) {
        self.individual.residentialAddress.copyFrom(self.borrowingEntity.businessAddress);
      }

      const isBorrowingEntityValid = yield self.borrowingEntity.isValid();
      isIndividualValid = yield self.individual.isValid();
      let isAccountValid = true;

      if(self.source === ApplicationSource.Digital) {
        isAccountValid = yield self.account.isValidForEntity();
      }

      const canSave = isBorrowingEntityValid && isIndividualValid && isAccountValid;
      try {
        if(canSave) {
          yield self.borrowingEntity.save();
          yield self.individual.save();
          if(self.source === ApplicationSource.Digital) {
            yield self.account.save();
          }
        }

        return canSave;
      } catch (e) {
        apiRoot.informationalMessageHelper.addErrorMessage(e);

        return false;
      }
    }),
    coApplicantSave: flow(function* coApplicantSave() {
      const canSave = yield self.individual.isValid();

      try {
        if(canSave) {
          yield self.individual.save();
        }

        return canSave;
      } catch (e) {
        apiRoot.informationalMessageHelper.addErrorMessage(e);

        return false;
      }
    }),
    acceptTermsAndConditions: flow(function* acceptTermsAndConditions(request?: AcceptedConditionsRequest) {
      try {
        yield apiRoot.customerApi.acceptTermsAndConditions(self.individual.id, request);
      } catch (e) {
        apiRoot.informationalMessageHelper.addErrorMessage(e);
        throw e;
      }
    }),
    acceptOnlineServicesTermsOfUse: flow(function* acceptOnlineServicesTermsOfUse() {
      try {
        yield apiRoot.customerApi.acceptOnlineServicesTermsOfUse(self.individual.id);
      } catch (e) {
        apiRoot.informationalMessageHelper.addErrorMessage(e);
        throw e;
      }
    }),
    addCompanyTrustee: flow(function* addCompanyTrustee() {
      const canSave = yield self.childEntity.isValid(true);
      try {
        if(canSave) {
          const newIndividualId = yield apiRoot.membersApi.addCompanyTrustee(self.applicationId, self.childEntity.id);

          // The lead applicant has now become a director and was potentially merged with a duplicate individual, so the id might have changed.
          self.individual.id = newIndividualId;
          self.individual.memberType = MemberType.Director;

          self.childEntityPersisted = true;
        }

        return canSave;
      } catch (e) {
        apiRoot.informationalMessageHelper.addErrorMessage(e);

        return false;
      }
    }),
  }))
  .actions((self) => ({
    reload: flow(function* reload() {
      const token = self.token;
      self.initialize();
      yield self.load(token);
    })
  }))
  .actions((self) => ({
    createApplicationForDigitalPath: flow(function* createApplicationForDigitalPath(branchId: number, isMerchandiseOrFertiliser: boolean, isLivestock: boolean, isWool: boolean, borrowingEntityId?: number | null | undefined) {
      const request = {
        branchId: branchId,
        isHobbyFarm: !Boolean(borrowingEntityId),
        borrowingEntityId: borrowingEntityId,
        isMerchandiseOrFertiliser: isMerchandiseOrFertiliser,
        isWool: isWool,
        isLivestock: isLivestock,
      } as UpdateDigitalPathRequest;

      try {
        yield apiRoot.customerApi.createApplicationForDigital(request);
        yield self.reload();
      } catch (e) {
        apiRoot.informationalMessageHelper.addErrorMessage(e);
      }
    })
  }));

export enum DirectorMatch{
  Exact = "Exact",
  Multiple = "Multiple",
  Partial = "Partial",
  None = "None"
}
export type ICustomerModel = Instance<typeof CustomerModel>;

export let defaultCustomerModel = {
  token: "",
  branchName: "",
  companyName: "",
  source: "",
  applicationId: 0,

  isLoaded: false,

  borrowingEntity: defaultBorrowingEntityModel,
  individual: { ...defaultIndividualModel, mobxId: getNextInteger() },
  account: defaultAccountModel,

  isTocAccepted: defaultBooleanFieldModel,
  isOnlineServicesTermsOfUseAccepted: defaultBooleanFieldModel,
  childEntity: defaultBorrowingEntityModel,
  childEntityPersisted: false
} as Instance<typeof CustomerModel>;