import {observer} from "mobx-react-lite";
import React from "react";
import styled from "@emotion/styled";
import {ThemeProps} from "../Account";
import {useTheme} from "@mui/material";
import SearchCriteria from "./SearchCriteria";

const Container = styled.div<ThemeProps>`
  padding: 4px 4px 8px 4px;
  width: 374px;
  max-width: calc(100vw - 24px);
  max-height: 101px;
  z-index: 10;
  background: ${props => props.theme.palette.background.paper};
  position: fixed;
  left: calc(50%);
  top: 130px;
  transform: translate(-50%, 0);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
`;

const MobileSearchCriteria = () => {
    const theme = useTheme();
    return <Container theme={theme}>
        <SearchCriteria />
    </Container>;
}

export default observer(MobileSearchCriteria);
