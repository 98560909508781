import {observer} from "mobx-react-lite";
import React, {useState} from "react";
import ApplicationContainer from "../common/ApplicationContainer";
import {Grid, Stack, useTheme} from "@mui/material";
import {css} from "@emotion/css";
import {Body1} from "../common/Text";
import ActionButton from "../common/ActionButton";
import {useAuth} from "../../hooks/useAuth";
import {useLocation, useNavigate} from "react-router-dom";
import {ReactComponent as EmailIcon} from "../assets/EmailIcon.svg";

const RequestVerificationCode = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const theme = useTheme();
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const contentCss = {
    expiredBody: css`
      margin: auto;
      max-width: 750px;
      text-align: center;
    `,
    welcomeBody: css`
      margin: auto;
    `,
    body: css`
      text-align: center;
      line-height: 24px;
      max-width: 500px;
    `,
    submitButton: css`
      width: 240px
    `,
    welcomeText: css`
      font-family: Open Sans,sans-serif;
      font-weight: 600;
      font-size: 46px;
      line-height: 55.2px;
      text-align: center;
    `,
    emailIconSection: css`
      text-align: center;
      margin-top: 24px;
      margin-bottom: 32px;
    `
  };

  const requestCode = () => {
    setIsLoading(true);
    auth.resend().then(() => {
        setIsLoading(false);
        navigate("/customerVerification", {
          state: {from: location.state?.from},
          replace: true
        });
      });
  }

  return (
    <>
      {
        <>
          <ApplicationContainer label={"Trade Account Application"}>
            <Stack spacing={3} alignContent={"center"}>
              {auth.isTokenExpired() && (
                  <>
                    <Body1 color={theme.palette.text.primary} className={contentCss.expiredBody}>
                      Looks like you've been idle for too long.  Don't worry we've saved your progress!  For security purposes you must request a new code to continue with your trade account applications.
                    </Body1>
                  </>
              )}
              {!auth.isTokenExpired() && (
                  <>
                    <div className={contentCss.welcomeBody}>
                      <div color={theme.palette.text.primary} className={contentCss.welcomeText}>
                        Welcome!
                      </div>
                      <div className={contentCss.emailIconSection}>
                        <EmailIcon />
                      </div>
                      <Body1 color={theme.palette.text.primary} className={contentCss.body}>
                        {"To begin your Trade Account application, please request your security code by following the prompt below."}<br/>
                        <br/>
                        {"This security code will be sent to your email to verify the email address associated with your Trade Account."}<br/>
                        <br/>
                        {"(Occasionally, security codes may appear in your spam folder). Once received you will be prompted to visit a security login page, where you will insert your security code."}
                      </Body1>
                    </div>
                  </>
              )}
              <Grid container direction="column" alignContent={"center"} justifyContent={"center"}>
                <ActionButton isLoading={isLoading} onClick={requestCode} className={contentCss.submitButton}>Send Me a Code</ActionButton>
              </Grid>
            </Stack>
          </ApplicationContainer>
        </>
      }
    </>
  );
};

export default observer(RequestVerificationCode);
