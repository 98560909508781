import { AddressLookupInfo } from "../../models/AddressLookupInfo";
import { useAddress } from "../../hooks/useAddress";
import React from "react";
import { observer } from "mobx-react-lite";
import TextFieldWrapper, { TextFieldWrapperProps } from "../editors/TextFieldWrapper";

type AddressHandler = (info: AddressLookupInfo) => void;

interface AddressLookupProps extends TextFieldWrapperProps {
  controlId: string;
  addressSelected?: AddressHandler;
  types?: string[];
}

const AddressLookupComponent = ({ controlId, addressSelected, ...rest }: AddressLookupProps) => {
  const handler: AddressHandler = async (value) => {
    if (addressSelected)
    {
      await addressSelected(value);
    }
  };

  useAddress({
    isOpen: true,
    configurations: [{ types: rest.types, addressElementId: controlId, handler: handler}]
  });

  return (
    <>
      <TextFieldWrapper
        {...rest}
        controlId={controlId}
      />
    </>
  );
};

export default observer(AddressLookupComponent);
