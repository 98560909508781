import React, {useEffect, useState} from "react";
import ActionBar from "../common/ActionBar";
import BackButton from "../common/BackButton";
import {useNavigate} from "react-router-dom";
import {useMst} from "../../models/Root";
import {ProgressStep} from "../ProgressBar/ProgressBarComponent";
import ApplicationContainer from "../common/ApplicationContainer";
import {H3} from "../common/Text";
import styled from "@emotion/styled";
import {Icon} from "@nutrien/ddc-components";
import {Grid, useMediaQuery, useTheme} from "@mui/material";
import {ReactComponent as CompanyIcon} from "../assets/CompanyIcon.svg";
import {css} from "@emotion/css";
import {SM_MEDIA_QUERY} from "../../config/constants";
import {observer} from "mobx-react-lite";
import {BorrowingEntityType} from "../../models/enums/BorrowingEntityType";
import SearchEntityDialog from "./SearchEntityDialog";


const TrusteeTypeSelection = () => {
    const { customer, members, states } = useMst();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    // AT: Commenting Code out as it is no longer referenced but need to check with Tea
    // const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isCompanyDialogOpen, setIsCompanyDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        if (isLoading) {
            return;
        }
    }, [members.members, members.members.length, customer.borrowingEntity.type, customer.individual.storedMemberType, isLoading, navigate]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setIsLoading(true);

        Promise.all([
            customer.load(sessionStorage.getItem("authorization") ?? ""), states.load()
        ])
            .then(() => {
                // Needs to load after Customer to get the ApplicationId
                members.load(customer.applicationId).then(() => {})
                    .finally(() => {
                        setIsLoading(false);
                    })
            });
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps

    const BackClicked = () => {
        navigate("/trustDetails");
    }

    const theme = useTheme();

    const Card = styled.div`
      height: 261px;
      width: 207px;
      box-shadow: 0 9px 12px rgba(0, 0, 0, 0.035), 0 3px 16px rgba(0, 0, 0, 0.03), 0 5px 6px rgba(0, 0, 0, 0.05);
      cursor: pointer;
      background: ${theme.palette.background.paper};
      @media only screen and (max-width:959px) {
        width: 150px;
      }
    `;


    const CardLineOne = styled.p`
      font-family: Open Sans,sans-serif;
      font-weight: 300;
      font-size: 20px;
      line-height: 120%;
      color: ${theme.palette.text.secondary};
      padding-top: 17px;
      
      @media only screen and (max-width:959px) {
        padding-top: 37px;
        font-size: 16px !important;
      }
    `;

    const CardLineTwo = styled.p`
      font-family: Open Sans,sans-serif;
      font-weight: 700;
      font-size: 20px;
      line-height: 20px;
      color: ${theme.palette.primary.main};
      
      @media only screen and (max-width:959px) {
        font-weight: 300 !important;
        font-size: 16px !important;
        line-height: 19.2px !important;
      }
    `;

    const TrusteeTypeSelectionCss = {
    textPartnerSmType: css`
      font-family: Open Sans,sans-serif;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      width: 113px;
    `,
    individualIcon: css`
      width: 74px;
      height: 74px;
      padding-top: 15px;
    `,
    companyIcon: css`
      width: 74px;
      height: 74px;
      padding-top: 15px;
    `,
    containerBox: css`
      width: 207px;
    `,
    smContainerBox: css`
      width: 150px;
    `,
    myPartnerSmText: css`
      font-family: Open Sans,sans-serif;
      font-weight: 300 !important;
      font-size: 16px !important;
      line-height: 19.2px !important;
    `
    }

    const isSm = useMediaQuery(SM_MEDIA_QUERY);
    const isTiny = useMediaQuery("(max-width:375px)");

    function getSpacing() {
        if (isTiny) {
            return 1;
        }
        return isSm ? 2 : 5;
    }

    return <>
        <ApplicationContainer isLoading={isLoading} isSaving={members.isSaving} currentStep={ProgressStep.MemberDetailStep}>
            <H3 color={theme.palette.text.secondary}>Trustees</H3>


            <Grid container direction={"row"} spacing={5} alignContent="center" justifyContent="center" className={css`margin-bottom: 65px`}>
                <Grid item>
                <H3 color={theme.palette.text.secondary}>Is the trustee an individual(s) or company?</H3>
                </Grid>
            </Grid>

                <Grid container direction={"row"} spacing={getSpacing()} alignContent="center" justifyContent="center">
                    <Grid item>
                        <Card onClick={() => navigate("/members")}>
                            <Grid container direction="row" justifyContent="space-evenly" alignContent="center">
                                <Grid item style={{ textAlign: "center" }}>
                                    <CardLineOne>The trustee is an</CardLineOne>
                                    <CardLineTwo>INDIVIDUAL(s)</CardLineTwo>
                                    <Icon.UserFeather className={TrusteeTypeSelectionCss.individualIcon} color="primary"/>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item>
                        <Card onClick={() => setIsCompanyDialogOpen(true)}>
                            <Grid container direction="row" justifyContent="space-evenly" alignContent="center">
                                <Grid item style={{ textAlign: "center" }}>
                                    <CardLineOne>The trustee is a</CardLineOne>
                                    <CardLineTwo>COMPANY</CardLineTwo>
                                    <CompanyIcon stroke={theme.palette.primary.main} className={TrusteeTypeSelectionCss.companyIcon} />
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>

        </ApplicationContainer>
        <SearchEntityDialog isOpen = {isCompanyDialogOpen} onClose = {() => setIsCompanyDialogOpen(false)} entityType = {BorrowingEntityType.Company}/>

        <ActionBar
            isLoading={isLoading}
            leftComponents={<BackButton onClick={() => BackClicked()} />}
            rightComponents={undefined} />
    </>
}

export default observer(TrusteeTypeSelection);