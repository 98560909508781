import {applySnapshot, Instance, types} from "mobx-state-tree";
import FieldModel, {defaultFieldModel} from "./FieldModel";
import {canSave, IFieldDefinition, initializeFields} from "./shared";
import {verificationCodeValidation} from "../validation/validationFunctions";

const VerificationCodeFieldBuilder: (self: IVerificationCodeModel) => IFieldDefinition[] = (self: IVerificationCodeModel) => {
  return [
    {
      title: "Verification Code",
      field: self.code,
      validation: verificationCodeValidation
    } as IFieldDefinition
  ];
}
export const VerificationCodeModel = types.model({
  code: FieldModel,
  isValid: types.boolean,
  isInvalid: types.boolean,
  newCodeSent: types.boolean,
  isLoading: types.boolean
})
.actions(
  self => ({
    reset() {
      applySnapshot(self, defaultVerificationCodeModel);
    },
    initialize() {
      this.reset();

      initializeFields(self, VerificationCodeFieldBuilder);
    },
    isCodeValid() {
      return canSave(self, VerificationCodeFieldBuilder) && self.code?.value?.length === 6;
    },
    clear() {
      self.code.update('');
      self.isValid = false;
      self.newCodeSent = false;
    },
    sendNewCode() {
      self.newCodeSent = true;
    },
    setIsLoading(isLoading: boolean) {
      self.isLoading = isLoading;
    },
    setIsValid(isValid: boolean) {
      self.isValid = isValid;
    },
    setIsInvalid(isInvalid: boolean) {
      self.isValid = false;
    },
    validate(validated: boolean) {
      if (validated) {
        self.isInvalid = false;
        self.isValid = true;
      } else {
        self.isInvalid = true;
        self.isValid = false;
      }
    }
  })
);

export type IVerificationCodeModel = Instance<typeof VerificationCodeModel>;

export const defaultVerificationCodeModel = {
  code: defaultFieldModel,
  isValid: false,
  isInvalid: false,
  newCodeSent: false,
  isLoading: false
} as Instance<typeof VerificationCodeModel>;