import {IIndividualModel} from "../../models/IndividualModel";
import TextFieldWrapper from "./TextFieldWrapper";
import React from "react";
import {observer} from "mobx-react-lite";
import {InputAdornment, useTheme} from "@mui/material";
import {css} from "@emotion/css";
import {Hint} from "../common/Text";
import {validateForOwnershipPercentage} from "../EntityMembers/helpers";
import {useMst} from "../../models/Root";

const OwnershipPercentageEditor = (props: {individual: IIndividualModel; gridOptions: any; currentUserIsLeadApplicant: boolean}) => {
    const theme = useTheme();

    const {customer, members} = useMst();

    return props.individual.displayOwnershipPercentage() && (
        <TextFieldWrapper
            gridOptions={{...props.gridOptions, style: {paddingTop: 0, marginTop: "-8px"}}}
            label={<><div>{props.individual.ownershipPercentage.title}</div><Hint theme={theme}>(Leave empty if unknown)</Hint></>}
            endAdornment={<InputAdornment className={css`width: 0; padding-right: 8px`} position="end">%</InputAdornment>}
            fieldModel={props.individual.ownershipPercentage}
            action={() => validateForOwnershipPercentage(customer.individual, members, props.individual.ownershipPercentage, props.individual)}
            inputProps={{type: "number"}}/>
    )
};

export default observer(OwnershipPercentageEditor);