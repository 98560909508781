import {useNavigate} from "react-router-dom";
import { Dialog, DialogContent, Grid, Typography, useTheme} from "@mui/material";
import {css} from "@emotion/css";
import {useMst} from "../../models/Root";
import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {getCompanyTrusteeDetails} from "../../helpers/urlHelpers";
import {Button} from "@nutrien/ddc-components";
import SelectFieldWrapper from "../editors/SelectFieldWrapper";
import {toSelectFieldOptionsProp} from "../../helpers/selectFieldHelpers";
import FieldModel, {defaultFieldModel} from "../../models/FieldModel";
import { H5} from "../common/Text";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    isEntityDirectorCheck?: boolean;
    preventAutoRedirect?: boolean;  // There is an auto-redirect built into the component, but in the case of company, we don't want it to redirect
    directorSelected?: (directorIndividualId: number) => void;
}

const DirectorValidationDialog =  (props: Props) => {
    const theme = useTheme();
    const navigate = useNavigate();

    const [showCancelSection, setShowCancelSection] = useState<boolean>(false);

    const directorDialogCss = {
        searchContainer: css`
          width: 368px;
          margin: auto;
        `,
        textSpacing: css `
           padding-bottom: 20px;
          padding-top:10px;
        `,
        backButton:css  `
            padding-top: 20px;
        `
    }

    const {customer, borrowingEntitySearch} = useMst();

    const directorIdFieldModel = FieldModel.create(defaultFieldModel);
    useEffect(() =>{
        directorIdFieldModel.initialize("Directors", null, "directorsId", [])
    },[])// eslint-disable-line react-hooks/exhaustive-deps

    const individuals = borrowingEntitySearch.individuals;

  function isMatch(firstName: string, lastName: string, dateOfBirth: string){
        return firstName === customer.individual.firstName.value.toUpperCase() && lastName === customer.individual.lastName.value.toUpperCase() && Date.parse(dateOfBirth) === Date.parse(customer.individual.dateOfBirth.value);
  }

    // The Component Re-directs to the Company Trustee Details page when the component is loaded up but we don't want this executed all the time (added the prevent auto redirect property)
    if(!props.preventAutoRedirect && individuals.some((x)=> isMatch(x.firstName.value.toUpperCase(), x.lastName.value.toUpperCase(), x.dateOfBirth.value))){
        navigate(getCompanyTrusteeDetails());
        props.onClose()
    }

    function isPartialMatch(lastName:string, dateOfBirth: string){
        return Date.parse( dateOfBirth) === Date.parse(customer.individual.dateOfBirth.value);
    }
    const partialMatchIndividuals = individuals.filter((x) => isPartialMatch(x.lastName.value.toUpperCase(), x.dateOfBirth.value));

    const directorSelected = async (directorIndividualId: number) => {
      if(props.directorSelected) {
        props.directorSelected(directorIndividualId);
        props.onClose();
      } else {
        navigate(getCompanyTrusteeDetails(false, directorIndividualId));
      }
    }

    const cancelClicked = () => {
      if(props.isEntityDirectorCheck) {
        if(!showCancelSection) {
          setShowCancelSection(true);
        } else {
          try {
            props.onClose();
          } finally {
            setShowCancelSection(false);
          }
        }
      } else {
        props.onClose();
      }
    }

    return (
        <>
            <Dialog
                open={props.isOpen}
                maxWidth={"md"}
            >
                <DialogContent>
                    <div className={directorDialogCss.searchContainer}>
                        <Grid container direction={"column"} spacing={1}>
                        {partialMatchIndividuals.length === 1 && !showCancelSection && (
                            <Grid>
                              {props.isEntityDirectorCheck && (
                                <>
                                  <Grid item>
                                    <Typography variant = {"subtitle1"} color = {theme.palette.common.black} align ={"center"} fontWeight = {"bold"} className={directorDialogCss.textSpacing}>
                                      You are {individuals[0].firstName.value}{' '}{individuals[0].lastName.value}
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography variant = {"body1"} color = {theme.palette.common.black} align = {"center"} className={directorDialogCss.textSpacing}>
                                      This action cannot be reversed... Please proceed if this information is correct.
                                    </Typography>
                                  </Grid>
                                </>
                              )}
                              {!props.isEntityDirectorCheck && (
                                <>
                                  <Grid item>
                                    <Typography variant = {"subtitle1"} color = {theme.palette.common.black} align ={"center"} fontWeight = {"bold"} className={directorDialogCss.textSpacing}>
                                      Are you certain that you are :
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography variant={"h4"} color={theme.palette.primary.main} align ={"center"} className={directorDialogCss.textSpacing}>
                                      {individuals[0].firstName.value}{' '}{individuals[0].lastName.value}
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography variant = {"body1"} color = {theme.palette.common.black} align = {"center"} className={directorDialogCss.textSpacing}>
                                      This action cannot be reversed. If you have selected the wrong name please click
                                      "cancel" to go back and change your selection. Please proceed if this
                                      information is correct.
                                    </Typography>
                                  </Grid>
                                </>
                              )}
                                <Grid item>
                                    <Button
                                        id={"directorProceedButton"}
                                        className={directorDialogCss.textSpacing}
                                        disabled={false}
                                        color="primary"
                                        variant="contained"
                                        onClick={async () => await directorSelected(individuals[0].id)}
                                    >
                                        Yes, Proceed
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                        {partialMatchIndividuals.length >= 2 && !showCancelSection && (
                        <Grid >
                            <Grid item>
                                <Typography variant={"subtitle1"} color={theme.palette.common.black} align ={"center"} className={directorDialogCss.textSpacing}>
                                    Please select yourself from the list of directors in the dropdown below.

                                </Typography>
                                    <Typography variant={"subtitle1"} color={theme.palette.common.black} align ={"center"} className={directorDialogCss.textSpacing}>
                                    If you are not any of these directors please contact your local branch for further
                                    assistance.
                                    </Typography>
                            </Grid>
                            <Grid item>
                                <SelectFieldWrapper options={toSelectFieldOptionsProp(partialMatchIndividuals, x => x.firstName.value + " " +x.lastName.value, x => x.id)} fieldModel={directorIdFieldModel} afterChangeAction={async (newValue) => await directorSelected(+newValue)}/>
                            </Grid>
                        </Grid>
                        )}
                        {(partialMatchIndividuals.length === 0 || showCancelSection) && (
                            <Grid className={directorDialogCss.textSpacing}>
                                <Grid item>
                                    <H5 >
                                      {props.isEntityDirectorCheck ? "Based on a search of government supplied company information, your name does not match any of the Directors. Please contact your local branch for further assistance." : "Please contact your local branch for further assistance"}
                                    </H5>
                                </Grid>
                            </Grid>
                        )}
                        <Grid item>
                            <Button className = {directorDialogCss.backButton} variant={"text"} onClick={() => cancelClicked()}>Cancel</Button>
                        </Grid>
                        </Grid>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
};
export default observer(DirectorValidationDialog)