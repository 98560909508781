import { Button, Icon } from "@nutrien/ddc-components";
import React from "react";
import { ButtonProps } from "@nutrien/ddc-components/dist/components/Button";
import {useTheme} from "@mui/material";

const BackButton = (props: ButtonProps) => {
    const theme = useTheme();

    return (
        <Button
            fullWidth={false}
            variant="outlined"
            startIcon={<Icon.ArrowLeftFeather stroke={theme.palette.primary.main}  />}
            {...props}
        >Back
        </Button>
    )
}

export default BackButton;