import { Button, Icon } from "@nutrien/ddc-components";
import React from "react";
import { useTheme } from "@mui/material";
import { ButtonProps } from "@nutrien/ddc-components/dist/components/Button";

const NextButton = (props: ButtonProps) => {
  const theme = useTheme();

  return (
    <Button
      fullWidth={false}
      variant="contained"
      color="primary"
      endIcon={<Icon.ArrowRightFeather color={theme.palette.common.white} />}
      {...props}
  />
  )
}

export default NextButton;