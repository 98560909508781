import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {useMst} from "../../models/Root";
import {Grid, useMediaQuery, useTheme} from "@mui/material";
import {Body1, Subtitle} from "../common/Text";
import {Icon, TextField} from "@nutrien/ddc-components";
import {css} from "@emotion/css";
import {ReactComponent as CropIcon} from "../assets/CropIconSmall.svg";
import {ReactComponent as LivestockIcon} from "../assets/LivestockIconSmall.svg";
import {ReactComponent as WoolIcon} from "../assets/WoolIconSmall.svg";
import ActionBar from "../common/ActionBar";
import BackButton from "../common/BackButton";
import NextButton from "../common/NextButton";
import {useNavigate} from "react-router-dom";
import {simplePhoneNumberFormat} from "../editors/formatPhoneNumber";
import {Status, Wrapper} from "@googlemaps/react-wrapper";
import {GOOGLE_API_KEY, SM_MEDIA_QUERY} from "../../config/constants";
import Map from "./Map";
import BranchMarker from "./BranchMarker";
import styled from "@emotion/styled";
import {ApplicantType} from "../../models/enums/ApplicantType";
import ApplicationContainer from "../common/ApplicationContainer";
import NoAccessComponent from "../NoAccessComponent";

const YourBranch = () => {
  const DEFAULT_ZOOM = 15;

  const { customer, branches } = useMst();
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmall = useMediaQuery(SM_MEDIA_QUERY);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const yourBranchCss = {
    iconStyle: css`
      padding-right: 4px;
    `,
    phoneIconStyle: css`
      padding-right: 4px;
    `,
    phoneStyle: css`
      margin-top: -2px;      
    `,
    container: css`
      padding: 40px;
    `,
  };

  function render(status: Status) {
    if (status === Status.FAILURE) {
      return <h1>ERROR</h1>
    }
    if (status === Status.SUCCESS) {
      return <h1>{status}</h1>;
    }
    return <h1>Loading</h1>
  }

  const MyHeader = styled.span`
    font-family: Open Sans,sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 28.8px;
    color: ${theme.palette.primary.main};
  `;

  useEffect(() => {
    setIsLoading(true);

    customer.load(sessionStorage.getItem("authorization") ?? "").then(() => {
      if(customer.applicationId > 0) {
        // If Application is Already Set, then move to entity page
        navigate(customer.individual.applicantType === ApplicantType.LeadApplicant ? "/entity" : "/review");
      } else {
        // Redirect to Branches Page if there is no selected Branch
        if(!branches.selectedBranch) {
          navigate("/branches");
        }
      }
    })
      .finally(() => {
        setIsLoading(false);
      });
  }, [customer]);  // eslint-disable-line react-hooks/exhaustive-deps

  function backToBranches() {
    branches.setSelectedBranch(0);
    navigate("/branches");
  }

  return <>
    <ApplicationContainer label={"Trade Account Application"} isLoading={isLoading}>
      {customer.isLoaded && customer.individual.isCoApplicant() && (
        <NoAccessComponent/>
      )}
      {customer.individual.isLeadApplicant() && (
        <>
      <Grid container direction={"row"} spacing={3} justifyContent={"center"} alignItems={"center"} className={yourBranchCss.container}>
        <Grid item>
          <Grid container direction={"column"} spacing={1}>
            <Grid item>
              <MyHeader color={theme.palette.primary.main}>Your Branch</MyHeader>
            </Grid>
            <Grid item>
              <TextField
                id={"branch"}
                value={branches?.selectedBranch?.name}
                startAdornment={<Icon.MapPinFeather fontSize={"small"} stroke={theme.palette.text.secondary} className={yourBranchCss.iconStyle}/>}
                disabled={true}
              />
            </Grid>
            <Grid item>
              <Body1 color={theme.palette.text.secondary}>{branches?.selectedBranch?.fullAddress}</Body1>
            </Grid>
            <Grid item>
              <Grid container direction={"row"} justifyContent={"flex-start"}>
                <Grid item>
                  <Icon.PhoneFeather fontSize="small" stroke={theme.palette.text.secondary} className={yourBranchCss.phoneIconStyle}/>
                </Grid>
                <Grid item>
                  <Body1 color={theme.palette.text.secondary} className={yourBranchCss.phoneStyle}>{branches?.selectedBranch?.phoneNumber ? simplePhoneNumberFormat(branches?.selectedBranch?.phoneNumber) : ""}</Body1>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction={"row"} spacing={1}>
                <Grid item><Subtitle color={theme.palette.primary.main}>This branch offers {branches?.selectedBranch?.getActivitiesOffered()} Activities</Subtitle></Grid>
                {branches?.selectedBranch?.isMerchandiseOrFertiliser && <Grid item><CropIcon/></Grid>}
                {branches?.selectedBranch?.isLivestock && <Grid item><LivestockIcon/></Grid>}
                {branches?.selectedBranch?.isWool && <Grid item><WoolIcon/></Grid>}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Wrapper apiKey={GOOGLE_API_KEY} libraries={["visualization", "places", "localContext", "geometry"]} render={render}>
            <Map className={isSmall ? css`width: 326px; height: 250px;` : css`width: 397px; height: 250px;`} center={{lat: branches?.selectedBranch?.latitude ?? 0, lng: branches?.selectedBranch?.longitude ?? 0}} zoom={DEFAULT_ZOOM} disableDefaultUI isYourBranch>
              <BranchMarker key={branches?.selectedBranch?.code} position={{lat: branches?.selectedBranch?.latitude ?? 0, lng: branches?.selectedBranch?.longitude ?? 0}} branchId={branches?.selectedBranch?.id ?? 0} branchName={branches?.selectedBranch?.name ?? ""} isSelected={true} onSelect={() => {}}/>
            </Map>
          </Wrapper>
        </Grid>
      </Grid>
        </>
      )}
    </ApplicationContainer>
    {customer.individual.isLeadApplicant() && (
      <ActionBar
        isLoading={isLoading}
        leftComponents={<BackButton onClick={() => backToBranches()} />}
        rightComponents={<NextButton onClick={() => navigate("/yourEntity")} >Next</NextButton>}
      />
    )}
  </>;
}

export default observer(YourBranch);