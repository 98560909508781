export function bankBSBFormat(input: string): string {
  if (!input) return input;

  input = input.replace(/\s+/g, " ");
  const digitsOnly = input.replace(/\D/g, "");

  return digitsOnly.length >= 3 ? `${digitsOnly.substring(0, 3)}-${digitsOnly.substring(3)}` : digitsOnly;
}

export function formatBankBSB(input: string, previousValue: string): string {
  const formatted = bankBSBFormat(input);
  // Will allow the user to delete the value
  if (`${input}-` === previousValue) {
    return input;
  }
  return formatted;
}

formatBankBSB.isNumericFormatter = true;