import { IFieldModel } from "../../models/IFieldModel";
import { observer } from "mobx-react-lite";
import React from "react";
import { Autocomplete, Grid } from "@mui/material";
import { AutocompleteProps } from "@mui/material/Autocomplete/Autocomplete";
import { buildError } from "../../models/shared";
import { TextField } from "@nutrien/ddc-components";

export interface AutocompleteWrapperProps extends Omit<AutocompleteProps<any, any, any, any>, "onChange" | "value" | "getOptionLabel" | "options" | "renderInput"> {
  fieldModel: IFieldModel & { update: (value: string | null) => void };
  list: any[];
  afterChangeAction?: (newValueSimple: any, newValue: any) => void;
  noLabel?: boolean;
  label?: string;
  required?: boolean;
  gridOptions?: any;
}

const AutoCompleteWrapper = ({ fieldModel, noLabel, list, required, gridOptions, ...rest }: AutocompleteWrapperProps) => {
  return (
    <>
      <Grid item {...gridOptions}>
        <Autocomplete
          {...rest}
          {...buildError(fieldModel)}
          freeSolo
          autoSelect
          id={fieldModel.id}
          value={fieldModel.value}
          options={Object.values(list ?? [])}
          renderInput={(params) => <TextField {...params} {...buildError(fieldModel)} required={required} label={noLabel ? "" : rest.label ?? fieldModel.title} />}
          onChange={async (event, newValue) => {
            await fieldModel.update(newValue);
            if (rest.afterChangeAction) {
              rest.afterChangeAction(newValue, newValue);
            }
          }}
          onAbort={async () => {
            await fieldModel.update(null);
          }}
          disabled={rest.disabled}
        />
      </Grid>
    </>
  )
}

export default observer(AutoCompleteWrapper);