import * as configcat from "configcat-js";
import {singletonHook} from "react-singleton-hook";
import {useEffect, useState} from "react";
import config from "../config/config";

const init: { [name: string]: any } = {};

const useFeatureFlagsImpl = () => {
  const [featureFlags, setFeatureFlags] = useState(init);

  const updateFeatureFlags = (configCatClient: any) => {
    configCatClient.getAllValues((keyValues: any) => {
      const values: { [name: string]: any } = {};

      for(const keyValue of keyValues) {
        values[keyValue.settingKey] = keyValue.settingValue;
      }

      setFeatureFlags(values);
    });
  }

  useEffect(() => {
    const configCatClient = configcat.getClient(config.configCatKey, configcat.PollingMode.AutoPoll);
    configCatClient.on('configChanged', () => updateFeatureFlags(configCatClient));

    updateFeatureFlags(configCatClient);
  }, []);

  return featureFlags;
}

export const useFeatureFlags = singletonHook(init, useFeatureFlagsImpl)