import {ABN_LENGTH, ACN_LENGTH} from "../config/constants";


export function stripNonNumericCharactersOut(input?: string): string | undefined {
  if (input === undefined) {
    return undefined;
  }
  return input.replace(/\D/g, "");
}

export function formatPoBox(str: string) {
  return str && str.toLowerCase().trim().startsWith("po box") ? str : `PO Box ${str}`;
}

export function formatAbn(str?: string | number | null | undefined) {
  return str ? str.toString().padStart(ABN_LENGTH, "0") : "";
}

export function formatAcn(str?: string | number | null | undefined) {
  return str ? str.toString().padStart(ACN_LENGTH, "0") : "";
}

export function isAbn(str?: string | number | null | undefined) {
  const expr: RegExp = /^\d{11}$/;
  return expr.test(str ? str?.toString() : "");
}

export function isAcn(str?: string | number | null | undefined) {
  const expr: RegExp = /^\d{9}$/;
  return expr.test(str ? str?.toString() : "");
}
