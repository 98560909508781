import React, {useEffect, useState} from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {Provider, resetRootStore, rootStore} from "./models/Root";
import SystemMessages from "./components/SystemMessages";
import {ThemeProvider} from "@mui/material";
import AcceptLink from "./components/AcceptLink";
import LandingScreen from "./LandingScreen";
import SessionEnded from "./components/SessionEnded";
import ApplicationReview from "./components/Review/ApplicationReview";
import EntityDetails from "./components/EntityDetails";
import Branches from "./components/BranchLocator/BranchLocator";
import { initializeApiRoot } from "./helpers/apiRootBootstrap";
import ApplicationComplete from "./components/ApplicationComplete";
import Account from "./components/Account";
import TermsAndConditions from "./components/TermsAndConditions";
import CustomerVerification from "./components/Verification/CustomerVerification";
import { AuthProvider } from "./hooks/useAuth";
import SecureRoutes from "./components/common/SecureRoutes";
import RequestVerificationCode from "./components/Verification/RequestVerificationCode";
import EntityMembers from "./components/EntityMembers/EntityMembers";
import IndividualDetails from "./components/IndividualDetails";
import ExitApplicationPrompt from "./components/ExitApplicationPrompt/ExitApplicationPrompt";
import TrusteeTypeSelection from "./components/EntityMembers/TrusteeTypeSelection";
import ExpiredToken from "./components/InvalidToken/InvalidToken";
import ClosedApplicationToken from "./components/InvalidToken/ClosedApplicationToken";
import TrustDetails from "./components/TrustDetails";
import CompanyTrusteeDetails from "./components/EntityMembers/CompanyTrusteeDetails";
import AboutYou from "./components/AboutYou/AboutYou";
import YourBranch from "./components/BranchLocator/YourBranch";
import YourEntity from "./components/YourEntity/YourEntity";
import {nutrienTheme} from "./themes/NutrienTheme";
import NotificationBar from "./components/NotificationBar/NotificationBar";

initializeApiRoot();

const App = () => {
  const [theme, setTheme] = useState<any>(nutrienTheme);
  // const {brand} = useMst();

  useEffect(() => {
    // CTA-625 - Reset the root store when unloading the app.  Different user logging in afterwards can get leaked data from store if we don't.
    // Only really an issue for "external" users which we currently don't have, since internal users always reload the full app when they log in.
    // We do test with external users in dev though, which is how this issue was identified.
    return () => resetRootStore();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    rootStore.brand.init();

    let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = rootStore.brand.favIconUrl;

    const title = document.querySelector("title") as HTMLTitleElement;
    title.text = "Apply - " + rootStore.brand.name;

    setTheme(rootStore.brand.getTheme());
  }, []);
  
  return (
    <>
      <AuthProvider>
        <Provider value={rootStore}>
          <ExitApplicationPrompt />

          <ThemeProvider theme={theme}>
            <NotificationBar />
            <SystemMessages />

            <Router>
              <Routes>
                <Route path="/customer" element={<AcceptLink />} />
                <Route path="/expired" element={<ExpiredToken />} />
                <Route path="/closed" element={<ClosedApplicationToken />} />
                <Route path="/endSession" element={<SessionEnded />} />
                <Route path="/requestCode" element={<RequestVerificationCode/>} />
                <Route path="/customerVerification" element={<CustomerVerification/>} />
                <Route path="/customerComplete" element={<ApplicationComplete />} />

                <Route path="/aboutYou" element={<AboutYou />} />

                <Route element={<SecureRoutes />}>
                  <Route path="/customerpath" element={<CustomerPath />} />
                  <Route path="/landing" element={<LandingScreen />} />
                  <Route path="/entity" element={<EntityDetails />} />
                  <Route path="/individual" element={<IndividualDetails />} />
                  <Route path="/members" element={<EntityMembers />} />
                  <Route path="/trusteeType" element={<TrusteeTypeSelection />} />
                  <Route path="/account" element={<Account />} />
                  <Route path="/review" element={<ApplicationReview />} />
                  <Route path="/terms" element={<TermsAndConditions />} />
                  <Route path="/trustDetails" element={<TrustDetails />} />
                  <Route path="/companyTrusteeDetails" element={<CompanyTrusteeDetails />} />
                  <Route path="/branches" element={<Branches />} />
                  <Route path="/yourBranch" element={<YourBranch />} />
                  <Route path="/yourEntity" element={<YourEntity />} />
                </Route>
              </Routes>
            </Router>
          </ThemeProvider>

        </Provider>
      </AuthProvider>
    </>
  );
}

function CustomerPath() {
  return <h3>Customer Path</h3>
}

export default App;
