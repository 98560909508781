import React, {useEffect, useRef, useState} from "react";
import {isLatLngLiteral} from "@googlemaps/typescript-guards";
import {createCustomEqual} from "fast-equals";
import _ from "lodash";
import {observer} from "mobx-react-lite";

export interface LatLong {
    latitude: number;
    longitude: number;
}

interface MapProps extends google.maps.MapOptions {
    className: string;
    onClick?: (e: google.maps.MapMouseEvent) => void;
    onIdle?: (map: google.maps.Map) => void;
    children?: React.ReactNode;
    visiblePoints?: LatLong[],
    isYourBranch?: boolean;
}

const deepCompareEqualsForMaps = createCustomEqual(
// @ts-ignore
    (deepEqual) => (a: any, b: any) => {
        if (
            isLatLngLiteral(a) ||
            a instanceof google.maps.LatLng ||
            isLatLngLiteral(b) ||
            b instanceof google.maps.LatLng
        ) {
            return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
        }

        // TODO extend to other types

        // use fast-equals for other objects
// @ts-ignore
        return deepEqual(a, b);
    }
);

function useDeepCompareMemoize(value: any) {
    const ref = React.useRef();

    if (!deepCompareEqualsForMaps(value, ref.current)) {
        ref.current = value;
    }

    return ref.current;
}

function useDeepCompareEffectForMaps(
    callback: React.EffectCallback,
    dependencies: any[]
) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(callback, dependencies.map(useDeepCompareMemoize));
}

const Map: React.FC<MapProps> = ({
                                     onClick,
                                     onIdle,
                                     children,
                                     isYourBranch,
                                     ...options
                                 }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<google.maps.Map>();
    const [visiblePositions, setVisiblePositions] = useState<LatLong[]>();
    const [firstLoadCompleted, setFirstLoadCompleted] = useState(false);

    useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {}));
        }
    }, [ref, map]);

    // because React does not do deep comparisons, a custom hook is used
    // see discussion in https://github.com/googlemaps/js-samples/issues/946
    useDeepCompareEffectForMaps(() => {
        if (map) {
            map.setOptions(options);
        }
    }, [map, options]);

    useEffect(() => {
        if (map) {
            ["click", "idle"].forEach((eventName) =>
                google.maps.event.clearListeners(map, eventName)
            );

            if (onClick) {
                map.addListener("click", onClick);
            }

            if (onIdle) {
                map.addListener("idle", () => onIdle(map));
            }
        }
    }, [map, onClick, onIdle]);

    useEffect(() => {
        if (map && options.visiblePoints && options.visiblePoints.length > 0) {
            const sorted = _.orderBy(options.visiblePoints, ["latitude", "longitude"]);
            if (JSON.stringify(sorted) !== JSON.stringify(visiblePositions)) {
                // don't recenter unexpectedly
                const bounds = new google.maps.LatLngBounds();
                for (let i = 0; i < options.visiblePoints.length; i++) {
                    bounds.extend({lat: options.visiblePoints[i].latitude, lng: options.visiblePoints[i].longitude});
                }
                const center = bounds.getCenter();
                setVisiblePositions(sorted)
                map.setCenter(center);
                map.fitBounds(bounds);
            }
        }
    }, [options.visiblePoints, map, options.visiblePoints?.length, visiblePositions])

    // On some users browser's, they are noticing some part's of the map not being loaded (when they hit reload)
    // Zooming in and out on the first time the page load seems to have solved the trick
    useEffect(() => {
        if(map && !firstLoadCompleted && !isYourBranch) {
            setTimeout(() => map.setZoom(map.getZoom() as number + 1), 100);
            setTimeout(() => {
                map.setZoom(map.getZoom() as number - 1);
                setFirstLoadCompleted(true);
            }, 300);
        }
    }, [map, firstLoadCompleted, isYourBranch]);

    return (
        <>
            <div ref={ref} className={options.className}/>
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    // set the map prop on the child component
                    // @ts-ignore
                    return React.cloneElement(child, { map });
                }
            })}
        </>
    );
};

export default observer(Map);