import { observer } from "mobx-react-lite";
import React from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  Typography,
  useTheme
} from "@mui/material";
import { css } from "@emotion/css";

interface AddPartnerEntityModalProps {
  show: boolean;
  onClose: () => void;
}

const AddPartnerEntityModal = (props: AddPartnerEntityModalProps) => {
  const theme = useTheme();

  const AddPartnerEntityModalCss = {
    partnerText: css`
      font-family: "Open Sans";
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: ${theme.palette.text.secondary}
    `,
    banner: css`
      font-family: "Open Sans";
      font-weight: 300;
      font-size: 24px;
      line-height: 24px;
    `,
    container: css`
      text-align: center;
      height: 257px;
    `
  }


  return (
    <>
      <Dialog
        open={props.show}
        onClose={() => props.onClose()}
        maxWidth={"sm"}
      >
        <DialogContent>
          <Grid container direction={"column"} spacing={5} alignContent={"center"} justifyContent="center" className={AddPartnerEntityModalCss.container}>
            <Grid item>
              <Typography variant={"inherit"} color={theme.palette.primary.main} className={AddPartnerEntityModalCss.banner}>You've selected "Company or Trust"</Typography>
            </Grid>
            <Grid item>
              <Typography variant={"inherit"} className={AddPartnerEntityModalCss.partnerText}>Unfortunately we can't process an application online with a
                partnership that involves a Trust or a Company. Please visit your branch to complete your application.</Typography>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default observer(AddPartnerEntityModal);