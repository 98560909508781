import { flow, getParent, types } from "mobx-state-tree";
import { FieldValidationModel } from "./FieldValidationModel";
import { ValidationFunction } from "../validation/ValidationFunction";
import { ValidationSeverity } from "./enums/ValidationSeverity";
import dayjs from "dayjs";
import { QUERY_PARAMETER_DATE_FORMAT, UI_DATE_FORMAT } from "../config/constants";
import { getFormattedDateForJsonString } from "../helpers/dateHelpers";
import { reValidateFields } from "./shared";
import { IDateFieldModel } from "./IFieldModel";
import { defaultFieldModel } from "./FieldModel";


const DateFieldModel = types
  .model({
    title: types.string,
    value: types.maybeNull(types.Date),
    initialValue: types.maybeNull(types.Date),
    reValidateFields: types.array(types.string),
    id: types.string,
    validationResult: FieldValidationModel
  })
  .actions((self) => ({
    setValidationResult(result: any) {
      self.validationResult = result;
    }
  }))
  .actions((self) => {
    let validation: ValidationFunction<Date | null> | null = null;

    async function validate() {
      self.setValidationResult(validation ? await validation(self.value, getParent(self)) : { severity: ValidationSeverity.Valid, message: "" });
    }

    async function initialize(
      title: string,
      validationFunction: ValidationFunction<Date | null> | null = null,
      id?: string,
      fieldsToRevalidate = [],
      defaultValue = null
    ) {
      self.value = defaultValue;
      self.title = title;
      self.id = id ?? title;
      self.reValidateFields.replace(fieldsToRevalidate);
      validation = validationFunction;
      // await validate();  Customer Path - should not validate off the initialization
    }

    return {
      initialize,
      validate,
      async revert() {
        self.value = self.initialValue;
        await validate();
      }
    };
  })
  .views((self) => ({
    isDirty() {
      if (self.initialValue === null || self.value === null) {
        return self.value !== null || self.initialValue !== null;
      }
      return dayjs(self.initialValue).toISOString() !== dayjs(self.value).toISOString();
    },
    getFormattedValue() {
      return self.value ? dayjs(self.value).format(UI_DATE_FORMAT) : "";
    },
    getFormattedDateForJsonString() {
      return getFormattedDateForJsonString(self.value);
    },
    getLocaleDateString() {
      return self.value ? dayjs(self.value).format(QUERY_PARAMETER_DATE_FORMAT) : null;
    }
  }))
  .actions((self) => ({
    updateOnly(newValue: any, isInitialValue = false) {
      const day = dayjs(newValue);
      const date = day.isValid() && newValue !== null && newValue !== undefined && newValue !== 0 ? day.toDate() : null;
      self.value = date;
      if (isInitialValue) {
        self.initialValue = date;
      }
    }
  }))
  .actions((self) => ({
    update: flow(function* update(newValue: any, isInitialValue = false) {
      self.updateOnly(newValue, isInitialValue);

      if(!isInitialValue) {
        yield self.validate();
        yield reValidateFields(self);
      }
    })
  }));

export default DateFieldModel;

export const defaultDateFieldModel: IDateFieldModel = {
  ...defaultFieldModel,
  value: null,
  initialValue: null,
  defaultValue: null
};