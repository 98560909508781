import {applySnapshot, flow, Instance, types} from "mobx-state-tree";
import {AddressModel, defaultAddressModel} from "./AddressModel";
import FieldModel, {defaultFieldModel} from "./FieldModel";
import {cancelChanges, canSave, IFieldDefinition, initializeFields, reValidate, updateInitialValues} from "./shared";
import {AddressDto, CustomerEntityDto} from "../dto/CustomerDto";
import {AddressType} from "./enums/AddressType";
import BooleanFieldModel, {defaultBooleanFieldModel} from "./BooleanFieldModel";
import apiRoot from "../helpers/apiRoot";
import {
  BorrowingEntityStatus,
  BorrowingEntityType,
  TypesWhereAdditionalMembersCanBeEntered
} from "./enums/BorrowingEntityType";
import DateFieldModel, {defaultDateFieldModel} from "./DateFieldModel";

// @ts-ignore
const BorrowingEntityFieldBuilder: (self: IBorrowingEntityModel) => IFieldDefinition[] = (self: IBorrowingEntityModel) => [
  {
    title: "ABN",
    field: self.abn
  } as IFieldDefinition,
  {
    title: "ACN",
    field: self.acn
  } as IFieldDefinition,
  {
    title: "Business Start Date",
    field: self.businessStartDate
  } as IFieldDefinition,
  {
    title: "Name",
    field: self.legalName,
  } as IFieldDefinition,
  {
    title: "Trading Name",
    field: self.tradingName,
  } as IFieldDefinition,
  {
    id: "isMailingAddressSameAsBusinessAddress",
    title: "Check if same as Business Address",
    field: self.isMailingAddressSameAsBusinessAddress,
  } as IFieldDefinition,
  {
    title: "Email",
    field: self.email
  } as IFieldDefinition,
  {
    title: "Primary Phone",
    field: self.primaryPhone
  } as IFieldDefinition,
  {
    title: "First Name",
    field: self.contactFirstName
  } as IFieldDefinition,
  {
    title: "Last Name",
    field: self.contactLastName
  } as IFieldDefinition
];

// @ts-ignore
export const BorrowingEntityModel = types.model({
  id: types.number,
  type: types.string,
  status: types.maybe(types.string),
  abn: FieldModel,
  acn: FieldModel,
  businessStartDate: DateFieldModel,
  legalName: FieldModel,
  tradingName: FieldModel,
  businessAddress: AddressModel,
  mailingAddress: AddressModel,
  isMailingAddressSameAsBusinessAddress: BooleanFieldModel,
  primaryPhone: FieldModel,
  email: FieldModel,
  contactFirstName: FieldModel,
  contactLastName: FieldModel
})
  .views((self) => ({
    isCancelled(): boolean {
      return self.status === BorrowingEntityStatus.Cancelled;
    },
    displayAbn(): boolean {
      return self.type !== BorrowingEntityType.HobbyFarm;
    },
    displayAcn(): boolean {
      return self.type === BorrowingEntityType.Company;
    },
    canAddMembers(): boolean {
      return TypesWhereAdditionalMembersCanBeEntered.includes(self.type as BorrowingEntityType);
    },
    getDto(): CustomerEntityDto {
      return {
        id: self.id,
        type: self.type,
        status: self.status,
        abn: self.abn.value,
        acn: self.acn.value,
        dateOfCommencement: self.businessStartDate?.value?.toString() ?? "",
        legalName: self.legalName.value,
        tradingName: self.tradingName.value,
        addresses: [self.businessAddress.getDto(), self.mailingAddress.getDto()].filter(x => x.city !== ""),
        primaryPhone: self.primaryPhone.value,
        email: self.email.value,
        contactFirstName: self.contactFirstName.value,
        contactLastName: self.contactLastName.value
      } as CustomerEntityDto;
    }
  }))
  .actions((self) => ({
    reset() {
      applySnapshot(self, defaultBorrowingEntityModel);
    },
    initialize(borrowingEntityType?: string | null | undefined) {
      this.reset();

      if(borrowingEntityType) {
        self.type = borrowingEntityType;
      }
      self.businessAddress.initialize(AddressType.Trading);
      self.mailingAddress.initialize(AddressType.Mailing);

      initializeFields(self, BorrowingEntityFieldBuilder);
    },
    isValid: flow(function* isValid(skipMailingAddressValidation = false) {
      // Prior to Validation Adjustments
      if(self.isMailingAddressSameAsBusinessAddress.value) {
        yield self.mailingAddress.copyFrom(self.businessAddress);
      }

      yield reValidate(self, BorrowingEntityFieldBuilder);

      const isBusinessAddressValid = yield self.businessAddress.isValid();
      const isMailingAddressValid = yield self.mailingAddress.isValid();

      const isCanSave = canSave(self, BorrowingEntityFieldBuilder);

      return isCanSave && isBusinessAddressValid && (isMailingAddressValid || skipMailingAddressValidation);
    }),
    cancelChanges() {
      cancelChanges(self, BorrowingEntityFieldBuilder);
      self.businessAddress.cancelChanges();
      self.mailingAddress.cancelChanges();
    }
  }))
  .actions((self) => ({
    load: flow(function* load(borrowingEntity: CustomerEntityDto) {
      self.initialize();

      self.id = borrowingEntity.id;
      self.type = borrowingEntity.type;
      self.status = borrowingEntity.status;
      yield self.abn.update(borrowingEntity.abn, true);
      yield self.acn.update(borrowingEntity.acn, true);
      yield self.businessStartDate.update(borrowingEntity.dateOfCommencement, true);
      yield self.legalName.update(borrowingEntity.legalName, true);
      yield self.tradingName.update(borrowingEntity.tradingName, true);

      yield self.contactFirstName.update(borrowingEntity.contactFirstName, true);
      yield self.contactLastName.update(borrowingEntity.contactLastName, true);
      yield self.email.update(borrowingEntity.email, true);
      yield self.primaryPhone.update(borrowingEntity.primaryPhone, true);

      yield self.businessAddress.load(AddressType.Trading, borrowingEntity.addresses.find((x: AddressDto) => x?.type === AddressType.Trading) as AddressDto);
      yield self.mailingAddress.load(AddressType.Mailing, borrowingEntity.addresses.find((x: AddressDto) => x?.type === AddressType.Mailing) as AddressDto);

      self.isMailingAddressSameAsBusinessAddress.update(!self.mailingAddress.isAddressDifferentFrom(self.businessAddress));
    }),
    save: flow(function* save(skipMailingAddressValidation = false) {
      if(yield self.isValid(skipMailingAddressValidation)) {
        const dto = self.getDto();
        yield apiRoot.customerApi.saveBorrowingEntity(dto);

        updateInitialValues(self, BorrowingEntityFieldBuilder);
        self.businessAddress.updateInitialValues();
        self.mailingAddress.updateInitialValues();
      }
    })
  }));

// @ts-ignore
export type IBorrowingEntityModel = Instance<typeof BorrowingEntityModel>;

export const defaultBorrowingEntityModel = {
  id: 0,
  type: "",
  abn: defaultFieldModel,
  acn: defaultFieldModel,
  businessStartDate: defaultDateFieldModel,
  legalName: defaultFieldModel,
  tradingName: defaultFieldModel,
  businessAddress: defaultAddressModel,
  mailingAddress: defaultAddressModel,
  isMailingAddressSameAsBusinessAddress: defaultBooleanFieldModel,
  email: defaultFieldModel,
  primaryPhone: defaultFieldModel,
  contactFirstName: defaultFieldModel,
  contactLastName: defaultFieldModel
} as Instance<typeof BorrowingEntityModel>;

export const createBorrowingEntityModelFrom = async (dto: CustomerEntityDto): IBorrowingEntityModel => {
  const borrowingEntityModel = BorrowingEntityModel.create(defaultBorrowingEntityModel);
  await borrowingEntityModel.load(dto);
  return borrowingEntityModel;
}