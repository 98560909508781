import { useMst } from "../models/Root";
import { Grid, Typography } from "@mui/material";
import { NO_PRINT_CLASS_NAME } from "../config/constants";
import React from "react";
import { observer } from "mobx-react-lite";
import { cx } from "@emotion/css";
import { SystemMessageSeverity } from "../models/enums/SystemMessageSeverity";
import { makeStyles } from "tss-react/mui";
import {Button} from "@nutrien/ddc-components";

const useStyles = makeStyles()(
  (theme) => ({
    "container": {
      marginTop: "0"
    },
    "banner": {
      height: "65px",
      paddingLeft: "16px",
      paddingRight: "16px",
      color: theme.palette.common.black,
      "& > div": {
        marginTop: "auto",
        marginBottom: "auto"
      },
      "& .axp-ti-system-message p": {
        color: theme.palette.common.black
      }
    },
    "success": {
      color: theme.palette.primary.dark,
      background: theme.palette.success.light,
      borderBottom: `solid 1px ${theme.palette.success.dark}`
    },
    "error": {
      color: theme.palette.error.dark,
      background: theme.palette.error.light,
      borderBottom: `solid 1px ${theme.palette.error.dark}`
    },
    "warning": {
      color: theme.palette.warning.dark,
      background: theme.palette.warning.light,
      borderBottom: `solid 1px ${theme.palette.warning.dark}`
    }
  })
);

const SystemMessages = (props: { containerClassName?: string }) => {
  const { classes } = useStyles();
  const { systemMessages } = useMst();

  return (
    <>
      <Grid container direction="column" className={cx(classes.container, props.containerClassName, NO_PRINT_CLASS_NAME)}>
        {systemMessages.messages.map((message) => (
          <Grid
            key={message.id}
            container
            item
            direction={"row"}
            justifyContent={"space-between"}
            className={cx(
              { [classes.success]: message.severity === SystemMessageSeverity.Success },
              { [classes.warning]: message.severity === SystemMessageSeverity.Warning },
              { [classes.error]: message.severity === SystemMessageSeverity.Error },
              classes.banner
            )}
          >
            <Grid item className="axp-ti-system-message">
              <Typography variant="body2" color="textPrimary">
                {message.message}
              </Typography>
            </Grid>
            <Grid item>
              <Button onClick={() => systemMessages.dismissMessage(message.id)} variant={"text"} color={"inherit"}>
                Dismiss
              </Button>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default observer(SystemMessages);