function mobileFormat(input: string, digitsOnly: string): string {
    if ((digitsOnly.length === 4 || digitsOnly.length === 7) && /^\d\d\d\d\s*(\d\d\d\s*)?$/.test(input)) {
        return input;
    }

    if (digitsOnly.length < 5) {
        return digitsOnly;
    }

    if (digitsOnly.length === 6 && digitsOnly.startsWith("13")) {
        return `${digitsOnly.substr(0, 2)} ${digitsOnly.substr(2, 2)} ${digitsOnly.substr(4, 2)}`;
    }

    if (digitsOnly.length < 8) {
        return `${digitsOnly.substr(0, 4)} ${digitsOnly.substr(4, 3)}`;
    }

    return `${digitsOnly.substr(0, 4)} ${digitsOnly.substr(4, 3)} ${digitsOnly.substr(7, 3)}`;
}

function landlineFormat(input: string, digitsOnly: string) {
    if ((digitsOnly.length === 6 || digitsOnly.length === 2) && /^\(\d\d\)\s*(\d\d\d\d\s*)?$/.test(input)) {
        return input;
    }

    if (digitsOnly.length < 3) {
        return `(${digitsOnly}`;
    }

    if (digitsOnly.length < 7) {
        return `(${digitsOnly.substr(0, 2)}) ${digitsOnly.substr(2, 4)}`;
    }

    // TODO: see CTA-1932 - temporarily remove the substring so that validation gets hit properly as the textfield has a bug where the value is not being updated properly with formatting
    return `(${digitsOnly.substr(0, 2)}) ${digitsOnly.substr(2, 4)} ${digitsOnly.substr(6, 4)}`;
}

function startsWithMobileFormat(digitsOnly: string) {
    return digitsOnly.startsWith("04") || digitsOnly.startsWith("13") || digitsOnly.startsWith("18");
}

export function simplePhoneNumberFormat(input: string) {
    input = input.replace(/\s+/g, " ");
    const digitsOnly = input.replace(/\D/g, "");

    if (digitsOnly.length === 0) {
        return input === "(" ? input : "";
    }

    if ((digitsOnly.length < 2 && !input.startsWith("(")) || startsWithMobileFormat(digitsOnly)) {
        return mobileFormat(input, digitsOnly);
    }

    return landlineFormat(input, digitsOnly);
}

export function formatPhoneNumber(input: string, previousValue: string) {
    const formatted = simplePhoneNumberFormat(input);
    if (
        input.startsWith(previousValue) &&
        input.replace(previousValue, "").length === 1 &&
        formatted.length < previousValue.length &&
        previousValue.startsWith(formatted)
    ) {
        // IF single character added to end of input
        // AND the formatted value is shorter than the previous value
        // THEN the added character is not part of the format and should be ignored
        return previousValue;
    }
    return formatted;
}

formatPhoneNumber.isNumericFormatter = true;

export function getMaxLength(input: string) {
    if(startsWithMobileFormat(input)) {
        return 12;
    }
    return 14;
}
