import _ from "lodash";
import {applySnapshot, cast, flow, Instance, types} from "mobx-state-tree";
import apiRoot from "../helpers/apiRoot";
import BooleanFieldModel, {defaultBooleanFieldModel} from "./BooleanFieldModel";
import {HobbyFarmConditionDto} from "../dto/HobbyFarmConditionDto";

export const HobbyFarmTermConditionModel = types.model({
    id: types.number,
    description: types.string,
    section: types.string,
    sortOrder: types.number,
    isSelected: BooleanFieldModel
})
    .actions((self) => ({
      load(dto: HobbyFarmConditionDto) {
          self.id = dto.id;
          self.description = dto.description;
          self.section = dto.section;
          self.sortOrder = dto.sortOrder;
      }
    }));

export const HobbyFarmTermsAndConditionsModel = types.model({
    conditions: types.array(HobbyFarmTermConditionModel),
    isValid: types.maybe(types.boolean)
})
    .actions((self) => ({
        reset() {
            applySnapshot(self, defaultHobbyFarmTermsAndConditionsModel);
        },
        initialize() {
            this.reset();
        },
        checkIfValid() {
            self.isValid = _.some(self.conditions, x => x.isSelected.value);
        },
        selectedConditionIds() {
            return _.chain(self.conditions)
                .filter(x => x.isSelected.value)
                .map(x => x.id);
        }
    }))
    .actions((self) => ({
        load: flow(function* load() {
            self.initialize();

            const hobbyFarmConditionDtos = yield apiRoot.lookupApi.getHobbyFarmConditions();
            const hobbyFarmConditions = [];
            for(const dto of hobbyFarmConditionDtos) {
                hobbyFarmConditions.push(createHobbyFarmCondition(dto));
            }
            self.conditions.replace(hobbyFarmConditions);
        })
    }));

const createHobbyFarmCondition = (dto: HobbyFarmConditionDto): Instance<typeof HobbyFarmTermConditionModel> => {
    const hobbyFarmConditionModel = HobbyFarmTermConditionModel.create(defaultHobbyFarmConditionModel);
    hobbyFarmConditionModel.load(dto);
    return hobbyFarmConditionModel;
}

export let defaultHobbyFarmConditionModel = {
    id: 0,
    description: "",
    section: "",
    sortOrder: 0,
    isSelected: defaultBooleanFieldModel
};

export let defaultHobbyFarmTermsAndConditionsModel = {
    conditions: cast([])
} as Instance<typeof HobbyFarmTermsAndConditionsModel>;