import { observer } from "mobx-react-lite";
import React, {useEffect} from "react";
import { Dialog, DialogActions, DialogContent, Grid, GridProps, useMediaQuery, useTheme } from "@mui/material";
import TextFieldWrapper from "../editors/TextFieldWrapper";
import { formatPhoneNumber } from "../editors/formatPhoneNumber";
import {H3, Subtitle} from "../common/Text";
import { SM_MEDIA_QUERY } from "../../config/constants";
import ActionButton from "../common/ActionButton";
import { Icon } from "@nutrien/ddc-components";
import { css } from "@emotion/css";
import { useMst } from "../../models/Root";
import DatePickerWrapper from "../editors/DatePickerWrapper";
import AddressLookupComponent from "../common/AddressLookupComponent";
import AutoCompleteWrapper from "../common/AutoCompleteWrapper";
import {toJS} from "mobx";
import SelectFieldWrapper from "../editors/SelectFieldWrapper";
import {toSelectFieldOptions} from "../../helpers/selectFieldHelpers";
import {AllIdentificationTypes} from "../../models/enums/IdentificationType";
import {getMemberLabel, validateForUnique} from "./helpers";
import DriversLicenceNumberEditor from "../editors/DriversLicenceNumberEditor";
import CheckBoxFieldWrapper from "../editors/CheckBoxFieldWrapper";
import {MemberType} from "../../models/enums/MemberType";
import OwnershipPercentageEditor from "../editors/OwnershipPercentageEditor";

interface AddOrEditMemberModalProps {
  show: boolean;
  onClose: () => void;
  setIsSaving: (isSaving: boolean) => void;
  addButtonText?: string;
}

const AddOrEditMemberModalCss = {
  dialogActionContainer: css`
    border-top: solid 1px #E0E0E0; 
    padding-top: 8px;
  `
}

const FullAddOrEditMemberModal = (props: AddOrEditMemberModalProps) => {
  const ITEM_GRID_OPTIONS: GridProps = { xs: 12, md: 4 };
  const ITEM_GRID_OPTIONS_SM: GridProps = { xs: 12, md: 3 };

  const { customer, members, states } = useMst();
  const theme = useTheme();

  const isSm = useMediaQuery(SM_MEDIA_QUERY);

  useEffect(() => {
    states.load();
    customer.individual.isResidentialAddressSameAsBusinessAddress.update(!customer.individual.residentialAddress.isAddressDifferentFrom(customer.borrowingEntity.businessAddress));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const CancelClicked = () => {
    if(!members.currentMember.isExistingIndividual()) {
      members.removeCurrentMemberFromModel();
    } else {
      members.currentMember.cancelChanges();
    }
    props.onClose();
  }

  const SaveMemberClicked = async () => {
    props.setIsSaving(true);

    if(validateForUnique(members.currentMember.email.value, members) && await members.currentMember.isValid()) {
      props.onClose();
      await members.saveCurrentMember();
      props.setIsSaving(false);
    }

    props.setIsSaving(false);
  }

  const copyBusinessAddressToResidential = () => {
    if(customer.individual.isResidentialAddressSameAsBusinessAddress.value) {
      customer.individual.residentialAddress.copyFrom(customer.borrowingEntity.businessAddress);
    }
  }

  const disableNameAndDobFields = members.currentMember?.memberType === MemberType.Director;

  return (
    <>
      <Dialog
        open={props.show}
        fullWidth={!isSm}
        fullScreen={isSm}
        maxWidth={"lg"}
      >
        <DialogContent>
          {members.currentMember && (
            <>
              <H3>{getMemberLabel(customer.hasCompanyTrustee() ? customer.childEntity.type : customer.borrowingEntity.type)} Details</H3>

              <Grid container direction={"row"} spacing={1}>
                <TextFieldWrapper fieldModel={members.currentMember.firstName} gridOptions={ITEM_GRID_OPTIONS} disabled={disableNameAndDobFields}/>
                <TextFieldWrapper fieldModel={members.currentMember.middleName} gridOptions={ITEM_GRID_OPTIONS} disabled={disableNameAndDobFields}/>
                <TextFieldWrapper fieldModel={members.currentMember.lastName} gridOptions={ITEM_GRID_OPTIONS} disabled={disableNameAndDobFields}/>
                <DatePickerWrapper fieldModel={members.currentMember.dateOfBirth} gridOptions={ITEM_GRID_OPTIONS} disabled={disableNameAndDobFields}/>
                <TextFieldWrapper fieldModel={members.currentMember.email} gridOptions={ITEM_GRID_OPTIONS} onBlur={(x) => validateForUnique(x, members)} disabled={true}/>
                <TextFieldWrapper fieldModel={members.currentMember.primaryPhone} formatter={formatPhoneNumber} gridOptions={ITEM_GRID_OPTIONS} disabled={true}/>
                <OwnershipPercentageEditor individual={members.currentMember} gridOptions={ITEM_GRID_OPTIONS} currentUserIsLeadApplicant/>
              </Grid>

              <H3>Residential Address</H3>

              <Grid container direction={"row"} spacing={1}>
                <CheckBoxFieldWrapper fieldModel={customer.individual.isResidentialAddressSameAsBusinessAddress}
                                      action={() => copyBusinessAddressToResidential()}
                />
                {!customer.individual.isResidentialAddressSameAsBusinessAddress.value && (
                  <>
                  <AddressLookupComponent
                      controlId={"residentialAddress"}
                      fieldModel={members.currentMember.residentialAddress.streetAddress}
                      addressSelected={members.currentMember.residentialAddress.addressSelected}
                      gridOptions={ITEM_GRID_OPTIONS_SM}
                  />
                  <AutoCompleteWrapper fieldModel={members.currentMember.residentialAddress.city} list={toJS(members.currentMember.residentialAddress.cityPostcodes.cities)} gridOptions={ITEM_GRID_OPTIONS_SM} />
                  <SelectFieldWrapper fieldModel={members.currentMember.residentialAddress.state} options={states.selectFieldOptions()} gridOptions={ITEM_GRID_OPTIONS_SM} />
                  <AutoCompleteWrapper fieldModel={members.currentMember.residentialAddress.postCode} list={toJS(members.currentMember.residentialAddress.cityPostcodes.postcodes)} gridOptions={ITEM_GRID_OPTIONS_SM} />
                  </>
                )}
              </Grid>

              <H3>Identification Details</H3>
              <Subtitle color={theme.palette.text.hint} className={css`margin-top: -16px; display: block;`}>This is used to verify your identity for your application</Subtitle>

              <Grid container direction={"row"} spacing={1}>

                <SelectFieldWrapper fieldModel={members.currentMember.identificationType} options={toSelectFieldOptions(AllIdentificationTypes)} gridOptions={ITEM_GRID_OPTIONS_SM} />
                {members.currentMember.showIdStateOrDriversCardNumber() && (
                    <SelectFieldWrapper fieldModel={members.currentMember.idState} options={states.selectFieldOptions()} gridOptions={ITEM_GRID_OPTIONS_SM} />
                )}
                <TextFieldWrapper fieldModel={members.currentMember.identificationNumber} label={members.currentMember.identificationNumberLabel()} gridOptions={ITEM_GRID_OPTIONS_SM} />
                <DriversLicenceNumberEditor individual={members.currentMember} gridOptions={ITEM_GRID_OPTIONS_SM} />
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Grid container direction={"row"} justifyContent={"space-between"} className={AddOrEditMemberModalCss.dialogActionContainer}>
            <Grid item>
              <ActionButton variant={"outlined"} onClick={() => CancelClicked()}>Cancel</ActionButton>
            </Grid>
            <Grid item>
              {members.currentMember?.isExistingIndividual() && !props.addButtonText && (
                <ActionButton onClick={() => SaveMemberClicked()}>Save</ActionButton>
              )}
              {props.addButtonText && (
                  <ActionButton
                      startIcon={props.addButtonText.includes("Add") ? <Icon.PlusFeather fontSize={"small"} color={theme.palette.common.white} /> : undefined}
                      onClick={() => SaveMemberClicked()}>{props.addButtonText}</ActionButton>
              )}
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default observer(FullAddOrEditMemberModal);