import _ from "lodash";
import React, {useEffect} from "react";
import {routesToNotPromptOnAppExit, verificationCodeRoutesForPromptOnAppExit} from "../../helpers/urlHelpers";
import {useAuth} from "../../hooks/useAuth";

const ExitApplicationPrompt = () => {
    const auth = useAuth();

    useEffect(() => {
        const handleClose = (event:any) => {
            const isRouteExempt = _.some(routesToNotPromptOnAppExit, route => window.location.href.indexOf(route) > -1);
            const isNotExpiredTokenAndOnVerificationCodeRoutes = !auth.isTokenExpired() && _.some(verificationCodeRoutesForPromptOnAppExit, route => window.location.href.indexOf(route) > -1);
            if(isRouteExempt || isNotExpiredTokenAndOnVerificationCodeRoutes) {
                return;
            } else {
                event.preventDefault();

                // Can't prompt a custom message anymore
                // Reference: https://stackoverflow.com/questions/38879742/is-it-possible-to-display-a-custom-message-in-the-beforeunload-popup/38880926#38880926
                return (event.returnValue = 'Are you sure you want to exit?');
            }
        }

        window.addEventListener('beforeunload', handleClose);

        return () => {
            window.removeEventListener('beforeunload', handleClose);
        }
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps

    return <></>;
}

export default ExitApplicationPrompt;