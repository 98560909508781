import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from "react";
import {useMst} from "../models/Root";
import ApplicationContainer from "./common/ApplicationContainer";
import {H3, Subtitle} from "./common/Text";
import ActionBar from "./common/ActionBar";
import NextButton from "./common/NextButton";
import BackButton from "./common/BackButton";
import {useNavigate} from "react-router-dom";
import {Grid, Tooltip, Typography, useTheme} from "@mui/material";
import {ProgressStep} from "./ProgressBar/ProgressBarComponent";
import {AttachmentParentType, AttachmentType} from "../models/enums/AttachmentType";
import {IAttachmentModel} from "../models/AttachmentModel";
import Attachment from "./Attachment/Attachment";
import ProgressIndicator from "./common/ProgressIndicator";
import {css} from "@emotion/css";
import { Icon } from "@nutrien/ddc-components";
import {getEntityDetailsUrl, getEntityMembersUrl} from "../helpers/urlHelpers";
import {BorrowingEntityType} from "../models/enums/BorrowingEntityType";
import TrustDeedTooltip from "./assets/TrustDeedTooltip.png";

const TrustDetails = () => {
  const theme = useTheme();
  const { customer, attachmentsModel, members } = useMst();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);

  const trustDetailsCss = {
    container: css`
      max-width: 444px;      
    `,
    stepHeader: css`
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 120%;      
    `,
    infoIcon: css`
      vertical-align: bottom;
      margin-left: 8px;
    `,
    tooltip: css`
      max-height: 558px;
      max-width: 326px;
    `
  };

  useEffect(() => {
    setIsLoading(true);

    customer.load(sessionStorage.getItem("authorization") ?? "")
      .then(() => {
        attachmentsModel.load(customer.borrowingEntity.id, AttachmentParentType.BorrowingEntity).then(() => {
          attachmentsModel.addEmpty(AttachmentType.TrustDeed, false, false);
          setIsLoading(false);
        });
      });
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps


  const NextClicked = () => {
    if((attachmentsModel.get(AttachmentType.TrustDeed))?.fileName) {

      if(customer.borrowingEntity.type === BorrowingEntityType.Trust && customer.individual.isLeadApplicant()){
        if(customer.childEntityPersisted) {
          navigate("/companyTrusteeDetails");
        } else if((members.members.filter(member => !member.isLeadApplicant())).length > 0) {
          navigate(getEntityMembersUrl());
        } else {
          navigate("/trusteeType");
        }
      }
      else {
        navigate(getEntityMembersUrl());
      }

    } else {
      setHasError(true);
    }
  }


  return (
    <>
      <ApplicationContainer isLoading={isLoading} currentStep={ProgressStep.BusinessDetailStep}>
        <Grid container direction={"column"} justifyContent="center" alignItems={"center"} spacing={1}>
        <Grid container direction={"column"} justifyContent="flex-start" alignItems={"flex-start"} spacing={1} className={trustDetailsCss.container}>
        <H3 color={theme.palette.text.secondary}>Trust Details</H3>

        <Grid container direction={"row"} justifyContent="center" alignItems={"center"} spacing={1}>
          {hasError && (
            <Grid item xs={12}>
              <Typography variant="inherit" className={trustDetailsCss.stepHeader} color={theme.palette.error.main}>
                You must upload a trust deed
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid container direction={"row"}>
              <Grid item xs={5} md={4}>
              <Subtitle color={theme.palette.text.secondary}>
                Upload Trust Deed
              </Subtitle>
              </Grid>
              <Grid item xs={7} md={8}>
              <Tooltip
                componentsProps={{tooltip: {sx: {maxWidth: "120vw", margin: 0, padding: "0px !important", background: "none", boxShadow: "none"}}}}
                title={<img src={TrustDeedTooltip} alt="Trust Deed" className={trustDetailsCss.tooltip} />}
                placement={"bottom-end"}
                enterTouchDelay={0}
                leaveTouchDelay={60000}>
                <div>
                  <Icon.InfoFeather fontSize="small" strokeWidth={1.5} stroke={theme.palette.info.dark} className={trustDetailsCss.infoIcon}/>
                </div>
              </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="inherit" className={trustDetailsCss.stepHeader} color={theme.palette.text.hint}>
              Upload a pdf or photo of your trust deed here by clicking "upload document".
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {attachmentsModel.get(AttachmentType.TrustDeed) && (
              <>
                {attachmentsModel.getAll(AttachmentType.TrustDeed).map((attachment: IAttachmentModel) => (
                  <Attachment
                    key={"attachment-" + attachment.fileName}
                    attachment={attachment}
                    isRequired
                    canEdit={true}
                    canDelete={true}
                    hasError={hasError}
                    setHasError={setHasError}
                  />
                ))}
              </>
            )}
            {!attachmentsModel.get(AttachmentType.TrustDeed) && (
              <div style={{ position: "relative", width: "100%", height: "85px", margin: 0, padding: 0 }}>
                <ProgressIndicator />
              </div>
            )}
          </Grid>
        </Grid>
        </Grid>
        </Grid>
      </ApplicationContainer>

      <ActionBar
        isLoading={isLoading}
        leftComponents={<BackButton onClick={() => navigate(getEntityDetailsUrl())} />}
        rightComponents={<NextButton onClick={() => NextClicked()} >Next: Member Details</NextButton>}
      />
    </>
  );
}

export default observer(TrustDetails);