import _ from "lodash";
import { cast, flow, Instance, types } from "mobx-state-tree";
import apiRoot from "../helpers/apiRoot";
import { toSelectFieldOptions } from "../helpers/selectFieldHelpers";

export const StatesModel = types.model({
  loading: types.maybe(types.boolean),
  values: types.array(types.string)
})
  .views((self) => ({
    selectFieldOptions() {
      return toSelectFieldOptions(self.values);
    }
  }))
  .actions((self) => ({
    load: flow(function* load() {
      if(self.values.length === 0) {
        self.loading = true;
        self.values.replace(_.orderBy(yield apiRoot.lookupApi.getStates(), x => x));
        self.loading = false;
      }

      return self.values;
    })
  }));

export const defaultStatesModel = {
  loading: false,
  values: cast([])
} as Instance<typeof StatesModel>;