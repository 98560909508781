import { observer } from "mobx-react-lite";
import React from "react";
import { Dialog, DialogActions, DialogContent, Grid, GridProps, useMediaQuery, useTheme } from "@mui/material";
import TextFieldWrapper from "../editors/TextFieldWrapper";
import {formatPhoneNumber, getMaxLength} from "../editors/formatPhoneNumber";
import { H3 } from "../common/Text";
import { SM_MEDIA_QUERY } from "../../config/constants";
import ActionButton from "../common/ActionButton";
import { Icon } from "@nutrien/ddc-components";
import { css } from "@emotion/css";
import { useMst } from "../../models/Root";
import {getMemberLabel, isAtMaximumMembers, validateForUnique} from "./helpers";
import OwnershipPercentageEditor from "../editors/OwnershipPercentageEditor";
import {MemberType} from "../../models/enums/MemberType";

interface AddOrEditMemberModalProps {
  show: boolean;
  onClose: () => void;
  setIsSaving: (isSaving: boolean) => void;
}

const AddOrEditMemberModalCss = {
  dialogActionContainer: css`
    border-top: solid 1px #E0E0E0; 
    padding-top: 8px;
  `
}

const BasicAddOrEditMemberModal = (props: AddOrEditMemberModalProps) => {
  const ITEM_GRID_OPTIONS: GridProps = { xs: 12, md: 6 };


  const { customer, members } = useMst();
  const theme = useTheme();

  const isSm = useMediaQuery(SM_MEDIA_QUERY);

  const CancelClicked = () => {
    if(!members.currentMember.isExistingIndividual()) {
      members.removeCurrentMemberFromModel();
    } else {
      members.currentMember.cancelChanges();
    }
    props.onClose();
  }

  const SaveMemberClicked = async (isAdd: boolean) => {
    if(validateForUnique(members.currentMember.email.value, members) && await members.currentMember.isValid()) {
      props.onClose();

      props.setIsSaving(true);
      await members.saveCurrentMember();
      props.setIsSaving(false);

      if(isAdd && isAtMaximumMembers(customer.individual, customer.borrowingEntity, members)) {
        members.setShowMaxMembers(true);
      }
      members.setCurrentMember(undefined);
    }
  }

  const disableNameFields = members.currentMember?.memberType === MemberType.Director;

  return (
    <>
      <Dialog
        open={props.show}
        fullWidth={!isSm}
        fullScreen={isSm}
        maxWidth={"md"}
      >
        <DialogContent>
          {members.currentMember && (
            <>
              <H3>{getMemberLabel(customer.hasCompanyTrustee() ? customer.childEntity.type : customer.borrowingEntity.type)} Details</H3>

              <Grid container direction={"row"} spacing={1}>
                <TextFieldWrapper fieldModel={members.currentMember.firstName} gridOptions={ITEM_GRID_OPTIONS} disabled={disableNameFields}/>
                <TextFieldWrapper fieldModel={members.currentMember.lastName} gridOptions={ITEM_GRID_OPTIONS} disabled={disableNameFields}/>
                <TextFieldWrapper fieldModel={members.currentMember.email} gridOptions={ITEM_GRID_OPTIONS} onBlur={(x) => validateForUnique(x, members)}/>
                <TextFieldWrapper fieldModel={members.currentMember.primaryPhone} formatter={formatPhoneNumber}
                                  gridOptions={ITEM_GRID_OPTIONS} maxLength={getMaxLength(members.currentMember.primaryPhone.value)}/>
                <OwnershipPercentageEditor individual={members.currentMember} gridOptions={ITEM_GRID_OPTIONS} currentUserIsLeadApplicant/>
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Grid container direction={"row"} justifyContent={"space-between"} className={AddOrEditMemberModalCss.dialogActionContainer}>
            <Grid item>
              <ActionButton variant={"outlined"} onClick={() => CancelClicked()}>Cancel</ActionButton>
            </Grid>
            <Grid item>
              {members.currentMember?.isExistingIndividual() && (
                <ActionButton onClick={() => SaveMemberClicked(false)}>{members.currentMember?.getUpdateIndividualButtonText() ?? "Save"}</ActionButton>
              )}
              {!members.currentMember?.isExistingIndividual() && (
                <ActionButton
                  startIcon={<Icon.PlusFeather fontSize={"small"} color={theme.palette.common.white} />}
                  onClick={() => SaveMemberClicked(true)}>Add {getMemberLabel(customer.borrowingEntity.type)}</ActionButton>
              )}
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default observer(BasicAddOrEditMemberModal);