import {IAccountModel} from "../models/AccountModel";
import {ValidationSeverity} from "../models/enums/ValidationSeverity";
import apiRoot from "../helpers/apiRoot";
import {emptyValidation, requiredValidationBuilder} from "./validationFunctions";

export const bankBSBValidation = (title: string) => async (value: string, model: IAccountModel) => {
    if ((model.isWool.value || model.isLivestock.value) && model.paymentType.value === "EFT") {
        return !value
            ? requiredValidationBuilder(ValidationSeverity.NoSave, title, false)(value)
            : (await apiRoot.lookupApi.getBankBSB(value)).isValid
                ? {
                    message: "",
                    severity: ValidationSeverity.Valid
                }
                : {
                    message: `Enter a valid ${title}`,
                    severity: ValidationSeverity.NoSave
                };
    }

    return emptyValidation();
};