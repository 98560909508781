export function currencyFormatter(input: string | undefined | null | number): string {
    if (!input) {
        return "";
    }

    input = input.toString();

    let processedString = input.replace(/[^0-9.-]+/g, "");
    if (!processedString) {
        return "";
    }
    const afterDecimal = processedString.split(".")[1];
    if (afterDecimal && afterDecimal.length > 2) {
        processedString = processedString.substr(0, processedString.length - afterDecimal.length + 2);
    }
    return "$" + Number(processedString).toLocaleString();
}

currencyFormatter.isNumericFormatter = true;
